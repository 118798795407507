<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div style="margin-right: 10px;">
        <div class="cardbox" style="width: 100%;">
          <div class="search-list">
            <div class="row" style="height: 33px;">
              <div *ngIf="dataLoaded && !editMode" class="button-row">
                <button
                  class="btn confirm-blue-btn"
                  (click)="action_edit()"
                >EDIT
                </button>
              </div>
              <!--<div *ngIf="dataLoaded && !editMode && activeObject['active'] === 'Active'" class="button-row" style="margin-left: 10px;">
                <button
                  class="btn reject-btn"
                  (click)="showConfirmDialog('inactivate')"
                >INACTIVATE
                </button>
              </div> -->
              <div *ngIf="dataLoaded && !editMode && activeObject['active'] === 'Active'" class="button-row" style="margin-left: 10px;">
                <button
                  class="btn reject-btn"
                  (click)="showConfirmDialog('resign')"
                >MARK RESIGN
                </button>
              </div>
              <div *ngIf="dataLoaded && !editMode && activeObject['active'] === 'Inactive'" class="button-row" style="margin-left: 10px;">
                <button
                  class="btn confirm-green-btn"
                  (click)="showConfirmDialog('activate')"
                >ACTIVATE
                </button>
              </div>
              <div *ngIf="dataLoaded && !editMode && activeObject['active'] === 'Active'" class="button-row" style="margin-left: 10px;">
                <button
                  class="btn reject-btn"
                  (click)="openChangePasswordDialog()"
                >CHANGE PASSWORD
                </button>
              </div>
              <div *ngIf="editMode" class="button-row" style="margin-left: 10px;">
                <button
                  style="margin-left:0px !important;"
                  class="btn grey-btn"
                  (click)="discard()"
                >
                  DISCARD
                </button>
              </div>
              <div *ngIf="editMode" class="button-row" style="margin-left: 10px;">
                <button
                  class="btn confirm-blue-btn"
                  (click)="action_save()"
                  [disabled]="userform.form.invalid"
                >
                  SAVE
                </button>
              </div>
              <div *ngIf="!newMode && !editMode && listLength > 0" style="position: absolute;right: 40px;">
                <div class="button-row">
                  <div style="display: inline-block; margin-right: 20px;" class="statuscard"
                       [ngClass]="{'success': activeObject['active'] === 'Active',
                        'error': activeObject['active'] === 'Inactive'}">
                          <span class="statusindicator"
                                [ngClass]="{'success': activeObject['active'] === 'Active',
                        'error': activeObject['active'] === 'Inactive'}"></span>
                    <span [ngClass]="{'success': activeObject['active'] === 'Active',
                        'error': activeObject['active'] === 'Inactive'}">{{ activeObject['active'] }}</span>
                  </div>
                  <span (click)="movePrevious()"><i
                    [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                    class="fal fa-arrow-circle-left fa-2x"></i></span>
                  <span
                    style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{ rowIndex + 1 }}
                    /{{ listLength }}</span>
                  <span (click)="moveNext()"><i
                    [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                    class="fal fa-arrow-circle-right fa-2x"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cardbox mt-4">
          <form novalidate (ngSubmit)="action_save()" id="ngForm" #userform="ngForm">
            <div class="row">
              <div>
                <img *ngIf="activeObject['photo'] && activeObject['photo'] != ''" [src]="activeObject['photo']"
                     style="width:150px;height:150px; cursor: pointer; margin-right: 15px;"
                     (click)="showImagePopup(activeObject['photo'], 'res_users')">
                <img *ngIf="!activeObject['photo'] || activeObject['photo'] == ''" [src]="'/assets/no-image-icon.png'"
                     style="width:180px;height:180px; margin-right: 15px;">
                <div style="display: block">
                  <input
                    type="file"
                    #file
                    style="display:none"
                    accept="image/png, image/jpeg"
                    (change)="handleFileSelect($event)"/>
                    <span *ngIf="editMode" class="detailvaluedesc" (click)="file.click()"
                          style="display: block; font-size: 14px; font-weight: 500; text-align: center; cursor: pointer">Update Profile Photo</span>
                </div>

              </div>

              <div style="flex-grow: 1;">
                <h2 *ngIf="!editMode">{{ activeObject['user_name'] }}</h2>
                <div *ngIf="editMode">
                  <input
                    class="forminput userspan"
                    type="text"
                    name="user_name"
                    [(ngModel)]="activeObject.user_name"
                    #user_name="ngModel"
                    required
                  >
                  <div *ngIf="user_name.errors && (user_name.dirty || user_name.touched)">
                    <span *ngIf="user_name.errors.required" class="text-danger">Username is required</span>
                  </div>
                </div>
                <span style="display: block; margin-top: 5px;">ID : {{ activeObject.id }}</span>
                <div class="mt-5 col-md-12 pad0">
                  <div class="col-md-2 pl-0 pr-5">
                    <label class="detaillabel">Code</label>
                    <p *ngIf="!editMode" class="detailvalue1">{{ activeObject['emp_code'] }}</p>
                    <div *ngIf="editMode">
                      <input
                        class="forminput"
                        style="width: 100%;"
                        type="text"
                        name="code"
                        [(ngModel)]="activeObject.emp_code"
                        #code="ngModel"
                        required
                      >
                      <div *ngIf="code.errors && (code.dirty || code.touched)">
                        <span *ngIf="code.errors.required" class="text-danger">Employee Code is required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-5">
                    <label class="detaillabel">Login/Username</label>
                    <p *ngIf="!editMode" class="detailvalue1">{{ activeObject['login'] }}</p>
                    <div *ngIf="editMode">
                      <input
                        class="forminput"
                        style="width: 100%;"
                        type="text"
                        name="login"
                        [(ngModel)]="activeObject.login"
                        #login="ngModel"
                        required
                      >
                      <div *ngIf="login.errors && (login.dirty || login.touched)">
                        <span *ngIf="login.errors.required" class="text-danger">Login/Username is required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-5">
                    <label class="detaillabel">Email</label>
                    <p *ngIf="!editMode" class="detailvalue1">{{ activeObject['user_email'] }}</p>
                    <div *ngIf="editMode">
                      <input
                        class="forminput"
                        style="width: 100%;"
                        type="text"
                        name="email"
                        [(ngModel)]="activeObject.user_email"
                        #email="ngModel"
                        required
                      >
                      <div *ngIf="email.errors && (email.dirty || email.touched)">
                        <span *ngIf="email.errors.required" class="text-danger">Email is required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-5">
                    <label class="detaillabel">Mobile</label>
                    <p *ngIf="!editMode" class="detailvalue1">{{ activeObject['mobilenumber'] }}</p>
                    <div *ngIf="editMode">
                      <input
                        class="forminput"
                        style="width: 100%;"
                        type="text"
                        name="mobilenumber"
                        [(ngModel)]="activeObject.mobilenumber"
                        #mobilenumber="ngModel"
                        required
                      >
                      <div *ngIf="mobilenumber.errors && (mobilenumber.dirty || mobilenumber.touched)">
                        <span *ngIf="mobilenumber.errors.required" class="text-danger">Mobile is required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-5">
                    <label class="detaillabel">Role</label>
                    <p class="detailvalue1">{{ activeObject['role'] }}</p>
                  </div>
                  <div class="col-md-2">
                    <label class="detaillabel">Team</label>
                    <p class="detailvalue1">{{ activeObject['team'] }}</p>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
        <div class="col-md-12 cardbox mt-4">
          <div class="search-list">
            <div class="col-md-6 col-12 nopadding pl-0">
              <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
                <mat-tab label="Territory">
                </mat-tab>
                <mat-tab label="Attributes">
                </mat-tab>
                <mat-tab label="Devices">
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="col-md-6 col-12 pad0 text-right">
              <div class="filters">
                <ul class="list-unstyled">
                  <li class="margLR" style="margin-left: 10px; margin-right: 10px;">
                    <div style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;"
                         (click)="action_add()"
                         *ngIf="editMode && (activetab=='Territory' || activetab == 'Attributes')">
                      <i class="fas fa-plus-circle"
                         style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                      <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                    </div>
                    <div class="search" style="display: inline-block;">
                      <span class="search-control"><img src="../../../../assets/searchicon.png"/></span>
                      <input id="filter-text-box" type="text" (input)="quickSearch()" class="search-control-area" autocomplete="off"
                             placeholder="Search">
                    </div>
                  </li>
                  <li class="margLR" *ngIf="activetab==='Territory'">
                    <app-excelicon [gridOption]="terr_gridOptions" [title]="'Territory List'"></app-excelicon>
                  </li>
                  <li class="margLR" *ngIf="activetab==='Attributes'">
                    <app-excelicon [gridOption]="attr_gridOptions" [title]="'Attributes Report'"></app-excelicon>
                  </li>
                  <li class="margLR" *ngIf="activetab==='Devices'">
                    <app-excelicon [gridOption]="dev_gridOptions" [title]="'Attributes Report'"></app-excelicon>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-0 pad0">
            <div *ngIf="activetab==='Territory'" style="margin-top: 10px;">
              <div id="terrgrid" style="width:100%">
                <ag-grid-angular style="width: 100%; height: 40vh" *ngIf="terr_dataLoaded"
                                 id="myGrid2" class="ag-theme-alpine"
                                 [columnDefs]="terr_columnDefs"
                                 [gridOptions]="terr_gridOptions"
                                 (gridReady)="terrOnGridReady($event)"
                >
                </ag-grid-angular>
              </div>
            </div>
            <div *ngIf="activetab==='Attributes'" style="margin-top: 10px;">
              <div id="attrgrid" style="width:100%">
                <ag-grid-angular style="width: 100%; height: 40vh" *ngIf="attr_dataLoaded"
                                 id="myGrid2" class="ag-theme-alpine"
                                 [columnDefs]="attr_columnDefs"
                                 [gridOptions]="attr_gridOptions"
                                 (gridReady)="attrOnGridReady($event)"
                                 (cellClicked)="attrCellClicked($event)"
                >
                </ag-grid-angular>
              </div>
            </div>
            <div *ngIf="activetab==='Devices'" style="margin-top: 10px;">
              <div id="devgrid" style="width:100%">
                <ag-grid-angular style="width: 100%; height: 40vh" *ngIf="dev_dataLoaded"
                                 id="myGrid2" class="ag-theme-alpine"
                                 [columnDefs]="dev_columnDefs"
                                 [gridOptions]="dev_gridOptions"
                                 (gridReady)="devOnGridReady($event)"
                >
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
