import {Component, ElementRef, OnInit, Renderer2} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";
import {MatDialogConfig} from "@angular/material/dialog";
import {AGGridEditButtonRendererComponent} from "./AGGridEditButtonRendererComponent";
import {CreateEditTeamComponent} from "./create_edit_team/create_edit_team.component";


@Component({
  selector: 'app-team-list',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})
export class UserTeamComponent extends PageBase implements OnInit, IPage {

  ngOnInit(): void {

    this.frameworkComponents = {
      buttonRenderer: AGGridEditButtonRendererComponent,
    };

    this.sharedService.setHeader('Teams');

    this.showableFilters.region = false;
    this.showableFilters.state = false;
    this.showableFilters.team = false;
    this.customFilter.values = [
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'},
      {id: '', itemName: 'All'}
    ];

    this.showDate = false;
    this.showCreate = true;
    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/crm_teams/getTeamListPWA';
    const inthis = this;
    this.columnDefs = [
      {headerName: 'Name', field: 'name', width: 150},
      {headerName: 'Code', field: 'code', width: 100, hide: true },
      {headerName: 'Lead', field: 'teamlead', width: 150},
      {headerName: 'Lead_user_ID', field: 'user_id', hide: true},
      {headerName: 'Parent Team', field: 'parent_team', width: 100},
      {headerName: 'Parent Team ID', field: 'parent_id', hide: true},
      {headerName: 'Active', field: 'status',  width: 80},
      {
        headerName: "Create Date", field: "create_date", width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.create_date) {
              return inthis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.create_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'Members', field: 'members_count',  width: 80},
      {
        headerName: '',
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.onEditClick.bind(this),
          label: 'edit'
        }
      },

    ];
  }

  onEditClick(e) {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        maxWidth: "20%",
        height: "auto",
        maxHeight: '100%',
        panelClass: 'custom-dialog-container',
        data: {
          teamObject : e.rowData
        }
      };
      creatediaeref = this.dialog.open(CreateEditTeamComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          teamObject : e.rowData
        }
      };
      creatediaeref = this.dialog.open(CreateEditTeamComponent, config);
    }

    creatediaeref.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.loadServerData();
      }
    });

  }

  openCreateDialog() {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        maxWidth: "20%",
        height: "auto",
        maxHeight: '100%',
        panelClass: 'custom-dialog-container',
        data: {
          teamObject : null
        }
      };
      creatediaeref = this.dialog.open(CreateEditTeamComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          teamObject : null
        }
      };
      creatediaeref = this.dialog.open(CreateEditTeamComponent, config);
    }

    creatediaeref.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.loadServerData();
      }
    });

  }

}
