import {Component, ElementRef, Renderer2} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'image-click',
  template: `<div class="eod-top" layout="row">
  <span class="element-po" style="padding-left: 0.8vw;"><img [ngClass]="{'filter': params.value.image == null}" (click)="clickPhoto()" class="element-img" src={{params.value.po}} alt=""></span>
  </div>`
})
export class ImageClickRenderer implements ICellRendererAngularComp {
  public params: any;
  public field = 'PHOTO';

  constructor(private elRef: ElementRef,
              private renderer: Renderer2){}

  agInit(params: any): void {
    this.params = params;
  }

  clickPhoto() {
    if (this.params.colDef['field'] === 'POD') {
      this.field = "POD";
      if (this.params.data.POD && this.params.data.POD.image) {
        this.params.context.componentParent.clickPhotoPOD(this.params.data);
      }
    } else {
      this.params.context.componentParent.openGRNpopup(this.params.data);
      /*if (this.params.data[this.field].image != null) {
        this.params.context.componentParent.clickPhoto(this.params.data[this.field].image);
      }*/
    }

  }

  refresh(): boolean {
    return false;
  }
}
