import {Component, OnInit} from "@angular/core";
import {PageBase} from "../pagebase/pagebase";


@Component({
  selector: 'app-delivery-plan',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})


export class SecondaryDeliveryPlanListComponent extends PageBase implements OnInit {

  ngOnInit(): void {

    this.sharedService.setHeader('Delivery Plan');
    this.showableFilters.region = true;
    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/pwa_connects/get_delivery_plan_list';
    const inthis = this;
    this.columnDefs = [
      {headerName: "ID", field: "id", width : 80},
      {headerName: "Date", field: "date"},
      {headerName: "Name", field: "name"},
      {headerName: "Emp Code", field: "van_code"},
      {headerName: "Salesman", field: "van_name"},
      {headerName: "Vehicle NO", field: "vehicle"},
      {headerName: "Delivery Person", field: "del_person"},
      {headerName: "Orders", field: "orders", width : 100, cellStyle: {textAlign: 'right'}},
      {headerName: "Delivered", field: "delivered", width : 100, cellStyle: {textAlign: 'right'}},
      {headerName: "Not Delivered", field: "not_delivered", width : 100, cellStyle: {textAlign: 'right'}},
      {headerName: "Grand Total", field: "grand_total", width : 100, cellStyle: {textAlign: 'right'}}
    ];
  }

  onCellClicked(event) {
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      this.router.navigate([this.apiService.redirect_url + '/secondary_delivery_plan/detail'], {
        relativeTo: this.route,
        queryParams: {id: event.data.id}
      });
    }
  }

  postProcessData() {
    this.rowData = this.apiData;
    this.storeDPData(this.apiData);
    return;
  }

  storeDPData(data) {
    const ids = [];
    for (const key in data) {
      ids.push(data[key].id);
    }
    localStorage.setItem('dpdata', JSON.stringify(ids));
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });

    this.gridApi.sizeColumnsToFit();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    /* const columnState = JSON.parse(localStorage.getItem('myColumnState'));
     if (columnState) {
       this.gridColumnApi.applyColumnState(
         {
           state: columnState,
           applyOrder: true,
         }
       );
     }*/

    /*fetch('C:\Users\Nivil\Downloads\pwaconfig.txt').then(response => response.text())
      .then(data => {
        // Do something with your data
        console.log(data);
      });*/
  }
}
