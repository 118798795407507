<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div>
        <app-page-loader [show]="!pageReady"></app-page-loader>
      </div>
      <div style="margin-right: 10px;">
        <div class="col-md-12 cardbox" style="width: 100%;">
          <div>
            <div class="search-list">
              <div class="col-md-12 pt-3 col-12 nopadding pl-0">
                <div class="row">
                  <div *ngIf="showApprove && showRejectBTn && activeObject['status'] !== 'Confirmed'" class="button-row">
                    <button (click)="confirmRequest()"
                            class="btn confirm-green-btn"
                    > Confirm
                    </button>
                  </div>
                  <div *ngIf="feature_asset_assign_vendor && !editMode && enableAssignVendor" class="button-row">
                    <button (click)="openAssignVendorPopup()"
                            class="btn confirm-blue-btn"
                    > Assign Vendor
                    </button>
                  </div>
                  <div *ngIf="feature_asset_assign_service_executive && !editMode && !enableAssignVendor && enableAssignVendorExecutive" class="button-row">
                    <button (click)="openAssignServiceExecutivePopup()"
                            class="btn confirm-blue-btn"
                    > Assign Vendor Executive
                    </button>
                  </div>
                  <div *ngIf="feature_asset_travel_cost && !editMode && enableTravelCostEntry" class="button-row">
                    <button (click)="addTravelCost()"
                            class="btn confirm-green-btn"
                    > Add Travel Cost
                    </button>
                  </div>
                  <div *ngIf="!newMode && !editMode && listLength > 0" style="position: absolute;right: 40px;">
                    <div class="button-row">
                         <span (click)="movePrevious()"><i
                           [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                           class="fal fa-arrow-circle-left fa-2x"></i></span>
                      <span
                        style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{rowIndex + 1}}
                        /{{listLength}}</span>
                      <span (click)="moveNext()"><i
                        [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                        class="fal fa-arrow-circle-right fa-2x"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 40px;">
            <div class="col-md-12 pt-3 pad0">

              <div  *ngIf="activeObject['id']"  class="col-md-2 pl-0 pr-30">
                <label class="lbl">Batch ID</label>
                <div style="margin-top: 5px;">
                  <div>
                    <p class="lbl3">{{activeObject['id']}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="activeObject['name']" class="col-md-2 pl-0 pr-30">
                <label class="lbl">Batch Name</label>
                <div style="margin-top: 5px;">
                  <div>
                    <p class="lbl3">{{activeObject['name']}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="activeObject['status']" class="col-md-2 pl-0 pr-30">
                <label class="lbl">STATUS</label>
                <div style="margin-top: 5px;">
                  <div>
                    <p class="lbl3">{{activeObject['status']}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="activeObject['total']" class="col-md-2 pl-0 pr-30">
                <label class="lbl">TOTAL</label>
                <div style="margin-top: 5px;">
                  <div>
                    <p class="lbl3">{{activeObject['total']}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="activeObject['approved']"  class="col-md-2 pl-0 pr-30">
                <label class="lbl">APPROVED</label>
                <div style="margin-top: 5px;">
                  <div>
                    <p class="lbl3">{{activeObject['approved']}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="activeObject['rejected']" class="col-md-2 pl-0 pr-30">
                <label class="lbl">REJECTED</label>
                <div style="margin-top: 5px;">
                  <div>
                    <p class="lbl3">{{activeObject['rejected']}}</p>
                  </div>
                </div>

              </div>
              <div *ngIf="activeObject['req_by']" class="col-md-2 pl-0 pr-30">
                <label class="lbl">REQUESTED BY</label>
                <div style="margin-top: 5px;">
                  <div>
                    <p class="lbl3">{{activeObject['req_by']}}</p>
                  </div>
                </div>

              </div>
              <div *ngIf="activeObject['req_on']" class="col-md-2 pl-0 pr-30">
                <label class="lbl">REQUESTED ON</label>
                <div style="margin-top: 5px;">
                  <div>
                    <p class="lbl3">{{activeObject['req_on']}}</p>
                  </div>
                </div>

              </div>
              <div *ngIf="activeObject['app_by']" class="col-md-2 pl-0 pr-30">
                <label class="lbl">APPROVED BY</label>
                <div style="margin-top: 5px;">
                  <div>
                    <p class="lbl3">{{activeObject['app_by']}}</p>
                  </div>
                </div>

              </div>
              <div *ngIf="activeObject['app_on']" class="col-md-2 pl-0 pr-30">
                <label class="lbl">APPROVED ON</label>
                <div style="margin-top: 5px;">
                  <div>
                    <p class="lbl3">{{activeObject['app_on']}}</p>
                  </div>
                </div>

              </div>
              <div   *ngIf="activeObject['t_cost']" class="col-md-2 pl-0 pr-30">
                <label class="lbl">TRAVEL COST</label>
                <div style="margin-top: 5px;">
                  <div>
                    <p class="lbl3">{{activeObject['t_cost']}}</p>
                  </div>
                </div>

              </div>
              <div *ngIf="activeObject['a_cost']" class="col-md-2 pl-0 pr-30">
                <label class="lbl">INSTALLATION COST</label>
                <div style="margin-top: 5px;">
                  <div>
                    <p class="lbl3">{{activeObject['a_cost']}}</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 cardbox mt-4">
          <div class="search-list">
            <div class="col-md-12 pad0">
              <ag-grid-angular id="myGrid1" *ngIf="dataLoaded" style="height: calc(70vh - 160px);"
                               class="ag-theme-balham"
                               [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                               (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                               (rowSelected)="onSelectionChanged($event)"
                               [getRowId]="getRowId"
                               [context]="context"

              >
              </ag-grid-angular>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


