import {Component, OnInit} from "@angular/core";
import {GetRowIdFunc, GetRowIdParams, GridOptions} from "@ag-grid-enterprise/all-modules";
import {DetailPageBase} from "../../pagebase/DetailPageBase";
import {ErrorHandler} from "../../error/error-handler";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {MatDialogConfig} from "@angular/material/dialog";
import {ApproveRejectCommentsPopupComponent} from "../../components/approve_reject_comments_popup/approve_reject_comments_popup.component";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {LocationPopupComponent} from "../../components/location_popup/location_popup.component";

@Component({
  selector: 'app-meet-detail',
  templateUrl: 'meet_detail.html',
  styleUrls: ['../../pagebase/pagebase_detail.scss']
})

export class MeetDetailComponent extends DetailPageBase implements IDetailPage, OnInit {

  show_approve_button = false;
  feature_meet_approve = false;
  period = '';
  totalQuantity = 0;
  totalClaimAmount = 0;
  totalInvoices = 0;
  totalOutlets = 0;
  totalExpenses = 0;
  activetab = 'Attendees';

  attendees_gridContext: any;
  attendees_columnDefs = [];
  attendees_gridOptions: GridOptions;
  attendees_gridApi: any;
  attendees_gridColumnApi: any;
  attendees_rowData = [];
  attendees_gridStyle: any;

  budget_gridContext: any;
  budget_columnDefs = [];
  budget_gridOptions: GridOptions;
  budget_gridApi: any;
  budget_gridColumnApi: any;
  budget_rowData = [];
  budget_gridStyle: any;

  expense_gridContext: any;
  expense_columnDefs = [];
  expense_gridOptions: GridOptions;
  expense_gridApi: any;
  expense_gridColumnApi: any;
  expense_rowData = [];
  expense_gridStyle: any;

  approverConfig: any;
  showApproverDetails = false;
  approverDetails = {};
  meetStatus = '';
  user_role_id;
  user_role_name;
  finalApprovalStage = false;

  galleryData = [];
  photos: any = [];

  expenseMaxImages = 0;
  expenseGallery = [];

  same_day_plan_n_execution = false;
  same_day_start_n_end = false;

  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/partner_meets/get_meet_detail_pwa";
    this.context = {componentParent: this};
    this.sharedService.setHeader('Meet');
    // const company_config = JSON.parse(localStorage.getItem('company_config'));
    // if (company_config && company_config.length > 0) {
    //   this.approverConfig = company_config.find(x => x.type === 'Claim Approval');
    //   if (this.approverConfig && this.approverConfig?.config_json?.config && this.approverConfig.config_json.config.length > 0) {
    //     this.approverConfig = this.approverConfig?.config_json.config[0].appr_roles;
    //   }
    // }
    // this.feature_meet_approve = this.sharedService.ACLcheck('feature_meet_approve');
    this.feature_meet_approve = true;
    this.show_approve_button = false;

    this.user_role_id = JSON.parse(localStorage.getItem('user')).role_id;
    this.user_role_name = JSON.parse(localStorage.getItem('user')).role.name;
    this.attendees_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressRowTransform: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        } else {
          if (params.data.a % 2 === 0) {
            return {background: '#F3F6F8'};
          } else {
            return {background: 'white'};
          }
        }
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.budget_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
    } as GridOptions;

    this.expense_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
    } as GridOptions;
    this.readPageParms(params => {
      this.loadPage();
    });
  }

  configureGrid() {
    this.attendees_columnDefs = [
      {headerName: "ID", field: "id", width: 20, hide: true},
      {headerName: "Code", field: "partner_code", width: 20},
      {headerName: "name", field: "partner_name", width: 100, valueGetter: this.configValueGetter('config_json', 'name')},
      {headerName: "Role", field: "role", width: 100},
      {headerName: "Mobile No", field: "mobile", width: 100, valueGetter: this.configValueGetter('config_json', 'mobile')},
      {headerName: "email", field: "email", width: 100, valueGetter: this.configValueGetter('config_json', 'email')},
      {headerName: "Time", field: "time", width: 100},
    ];

    this.budget_columnDefs = [
      {headerName: "ID", field: "id", width: 20, hide: true},
      {headerName: "Type", field: "type"},
      {headerName: "Budget", field: "t_bud"},
      {headerName: "per Person", field: "exp_per_att",
        valueGetter(params) {
          if (params.data) {
            if (params.data.exp_per_att) {
              return parseFloat(params.data.exp_per_att).toFixed(2);
            }
          }
        }},
      {headerName: "Variance", field: "variance",  valueGetter(params) {
          if (params.data) {
            if (params.data.variance) {
              return parseFloat(params.data.variance).toFixed(2);
            }
          }
        }},
    ];

    this.expense_columnDefs = [
      {headerName: "ID", field: "id", width: 20, hide: true},
      {headerName: "Active", field: "active", width: 30, hide: true},
      {headerName: 'Date', field: 'expense_date', width: 100},
      {headerName: "Policy", field: 'policy_name', width: 100},
      {headerName: "Expense Type", field: 'exp_type', width: 120},
      {headerName: 'Claimed', field: 'total', filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'right'}, width: 80, aggFunc: 'sum'},
      {headerName: "Approved", field: 'app_amount', cellStyle: {textAlign: 'right'}, width: 80, aggFunc: 'sum'},
      {headerName: "1st Approver", field: 'appr1', width: 100},
      {headerName: "Status", field: 'app1_state', width: 100},
      {headerName: "2nd Approver", field: 'appr2', width: 100},
      {headerName: "status", field: 'app2_state', width: 100},
      {headerName: " ", field: 'rej_amount', cellStyle: {textAlign: 'right'}, width: 80, aggFunc: 'sum'},
      {headerName: "Remarks", field: 'remarks', width: 150}
    ];

  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  }

  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
  }

  loadPage() {
    this.dataLoaded = false;
    this.totalQuantity = 0;
    this.totalOutlets = 0;
    this.totalInvoices = 0;
    this.totalClaimAmount = 0;
    this.attendees_rowData = [];
    this.budget_rowData = [];
    this.expense_rowData = [];
    this.expenseMaxImages = 0;
    this.configureGrid();
    if (this.activeID > 0) {
      this.newMode = false;
      this.editMode = false;
      //   View Mode
      //   Page Can be ready only after detail Object is fetched
      this.loadPageForView(() => {
        this.pageReady = true;
      });
    } else {
      this.newMode = true;
      this.editMode = true;
      //   Page Can be ready only after detail Object is fetched
      //    Throw Error
    }
  }

  loadPageForView(callback) {

    function prepareLineData(lineItems) {
      const rowData = lineItems;
      return rowData;
    }

    this.show_approve_button = false;
    this.loadActiveObject(() => {
      //
      // if (this.activeObject['supplier_id'] === undefined) {
      //   this.toastr.error('invalid state: supplier_id not found');
      // }

      /*if (this.activeObject && this.activeObject['lines'] && this.activeObject.lines.length > 0) {
        this.rowData = this.activeObject.lines;
        this.gridOptions.api.setRowData(this.rowData);
      } else if (this.activeObject && this.activeObject['invoices'] && this.activeObject.invoices.length > 0) {
        this.rowData = this.activeObject.invoices;
        this.gridOptions.api.setRowData(this.rowData);
      }*/
      this.photos = [];
      this.expenseMaxImages = 0;

      if (this.activeObject && this.activeObject['expenses'] && this.activeObject.expenses.length > 0) {
        this.expense_rowData = this.activeObject['expenses'];

        this.expense_rowData.forEach(e => {
          if (e.app2_state === 'pending') {
            this.show_approve_button = true;
          }
          if (e.app2_state !== 'approved') {
            e.remarks = e.reason;
          }

          if (e.gallery && e.gallery.length > 0) {
            if (this.expenseMaxImages < e.gallery.length) {
              this.expenseMaxImages = e.gallery.length;
            }
            for (let i = 0; i < e.gallery.length; i++) {
              e['img_' + i] = e.gallery[i];
            }
          }

        });
      }

      if (this.activeObject && this.activeObject['attendees'] && this.activeObject.attendees.length > 0) {
        this.attendees_rowData = this.activeObject['attendees'];
      }

      if (this.activeObject && this.activeObject['config_json'] && this.activeObject['config_json'] !== null) {

        if (this.activeObject.config_json.hasOwnProperty('var_by_type')) {
          this.budget_rowData = this.activeObject['config_json']['var_by_type'];
        } else if (this.activeObject.config_json.hasOwnProperty('budget')) {
          this.budget_rowData = this.activeObject['config_json']['budget'];
          this.budget_rowData.forEach(b => {
            b.t_bud = b.amount;
          });
        }

        let index = 1;
        this.budget_rowData.forEach(b => {
          b.id = index;
          index = index + 1;
        });
        if (this.activeObject.config_json.hasOwnProperty('expense_status')) {
          if (this.activeObject.config_json['expense_date']) {
            try {
              this.activeObject.config_json['expense_date'] = this.datePipe.transform((new Date(this.activeObject.config_json['expense_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } catch (e) {
              console.log("Invalid expense date");
            }
          }
          // this.approverDetails = {
          //   status: this.activeObject.config_json['expense_status'],
          //   by: this.activeObject.config_json['expense_apr_by'],
          //   role: this.activeObject.config_json['expense_apr_role'],
          //   dt: this.activeObject.config_json['expense_date'],
          //   comments: this.activeObject.config_json['comments'],
          // };
        }

      }

      if (this.activeObject && this.activeObject['meet_gallery']) {
        this.galleryData = this.activeObject.meet_gallery;
      }

      // this.same_day_plan_n_execution = this.validateSame(this.activeObject['planned_date'], this.activeObject['start_time']);
      this.same_day_start_n_end = this.validateSame(this.activeObject['planned_date'], this.activeObject['start_time']);

      // tslint:disable-next-line:max-line-length
      //{"address":"1 Block, 776/1, 2nd Cross Road,HRBR Layout 1st Block, Balaji Layout, Subbaiahnapalya,Banaswadi,Bengaluru Urban,Karnataka,560043,","adv_received":"50000","prod_categ":0,"created_by":"Mayur Vithob Nayak",
      // "budget":[{"type":"Food","amount":210,"name":"food","amt_from_config":true,"per_attendee":true},{"type":"Gift","amount":465,"name":"gift","amt_from_config":true,"per_attendee":true}],
      // "req_bud":675,
      // "meet_config":{"type":"Plumber Workshop","attendee_type":"all","organiser_type":"retailer,influencer,project,supplier","location_config":"venue","product_category":false,"allow_budget":true,
      // "budget_config":{
      //          "HIL":[{"name":"food","type":"Food","per_attendee":true,"amount":70},{"name":"gift","type":"Gift","per_attendee":true,"amount":155}],
      //          "Salesman":[{"name":"food","type":"Food","per_attendee":true,"amount":70},{"name":"gift","type":"Gift","per_attendee":true,"amount":155}],
      //          "Channel Partner":[{"name":"food","type":"Food","per_attendee":true,"amount":70},{"name":"gift","type":"Gift","per_attendee":true,"amount":155}]},
      //          "allowed_expenses_config":"Food,Gift,Others"},
      //          "reminderBefore":10,
      //          "var_by_type":[{"type":"food","bud_per_att":70,"t_bud":210,"exp_per_att":25,"t_exp":50,"variance":-64.28571428571429},
      //                          {"type":"gift","bud_per_att":155,"t_bud":465,"exp_per_att":25,"t_exp":50,"variance":-83.87096774193549}],
      //                          "t_var":-85.18518518518519,"t_bud":675,"t_exp":100,"appr_by":153,"appr_bud":675}

      //this.constructSummaryData();
      // this.approveCheck();

      this.dataLoaded = true;

      if (this.galleryData && this.galleryData.length > 0) {
        this.fetchImages();
      }

      if (this.expenseMaxImages > 0) {
        for (let i = 0; i < this.expenseMaxImages; i++) {
          this.expense_columnDefs.push(
            {
              headerName: "Image " + (i + 1),
              field: 'img_' + i,
              width: 80,
              valueGetter: this.generateImageUrl('img_' + i, 'expense_gallery'),
              cellRenderer(params) {
                if (params.data) {
                  if (params.node && params.node?.rowPinned !== 'bottom') {
                    if (!params.data['img_' + i] || params.data['img_' + i] === "") {
                      return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                    } else {
                      return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                    }
                  }
                }
              }
            },
          );
        }
      }

    });

  }

  validateSame(sDate: string, eDate: string) {
    let isSameDate = false;
    if ((sDate == null || eDate == null)) {
      isSameDate = false;
    }

    if ((sDate != null && eDate != null) && (eDate) === (sDate)) {
      isSameDate = true;
    }
    return isSameDate;
  }


  onExpenseCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.expense_gridApi.getSelectedRows();
    if (column.includes('img')) {
      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty(column) && rows[0][column] !== null && rows[0][column] !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0][column]?.store_fname,
              container: 'expense_gallery'
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0][column]?.store_fname,
              container: 'expense_gallery'
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {

      }
    }
  }




  async fetchImages() {
    const gallery = [];
    this.galleryData.forEach(image => {
      gallery.push({
        store_fname: image,
        container: 'meet_gallery'
      });
    });
    const payload = {
      access_token: localStorage.getItem('resfreshToken'),
      data: gallery
    };
    const inThis = this;
    const res = await this.apiService.postPromise('/api/pwa_connects/fetch_image', payload);
    console.log(res);
    if (res.hasOwnProperty('result') && res['result'].length > 0) {
      const domSanitizer = this.domSanitizer;
      let img;
      res['result'].forEach(photo => {
        img = domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + photo.bill_datas);
        inThis.photos.push(
          {
            photo: img,
            type: photo.type
          });
      });
    }
  }

  action_edit() {

  }

  action_confirm_meet() {

    this.userContext['meet_id'] = this.activeObject.id;
    this.userContext['action'] = 'confirm';
    this.apiService.post('/api/partner_meets/meet_status_change', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            if (res.results.hasOwnProperty('data') && res.results.data.length === 1) {
              this.activeObject['status'] = res.results.data[0]['status'];
              // navigate to invoice.

              this.toastr.success('Record Saved! : ' + this.activeID);
            } else {
              this.toastr.success('Record Saved!');
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });

  }

  action_cancel() {

  }

  discard() {
    this.editMode = false;
    if (this.activeObject && (!this.activeObject.hasOwnProperty('id') || this.activeObject.id === 0)) {
      history.back();
    } else {
      this.loadPage();
    }
  }

  quickSearch() {
    if (this.activetab === 'Sale') {
      this.budget_gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
    }  else {
      this.expense_gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
    }
  }

  attendeesOnGridReady(params) {
    this.attendees_gridOptions.api.showLoadingOverlay();

    this.attendees_gridApi = params.api;
    this.attendees_gridColumnApi = params.columnApi;
    params.api.setRowData(this.attendees_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });

    this.attendees_gridApi.sizeColumnsToFit();

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.attendees_gridApi, this.attendees_gridColumnApi);
      this.attendees_gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  saleOnGridReady(params) {
    this.budget_gridOptions.api.showLoadingOverlay();

    this.budget_gridApi = params.api;
    this.budget_gridColumnApi = params.columnApi;
    params.api.setRowData(this.budget_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });

    this.budget_gridColumnApi.autoSizeAllColumns();

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  expenseOnGridReady(params) {
    this.expense_gridOptions.api.showLoadingOverlay();

    this.expense_gridApi = params.api;
    this.expense_gridColumnApi = params.columnApi;
    params.api.setRowData(this.expense_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });

    this.expense_gridApi.sizeColumnsToFit();

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  saleOnCellClicked(event) {
  }

  returnOnCellClicked(event) {
  }

  /* approveCheck() {
     if (this.activeObject.config_json) {
       this.approverDetails = this.activeObject.config_json.app_dtls;
       if (this.approverDetails && this.approverDetails.length > 0) {
         this.approverDetails.forEach(details => {
           details.app_on = this.datePipe.transform((new Date( details.app_on + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
         });
       }
     } else {
       this.approverDetails = [];
     }
     if (this.approverConfig && this.approverConfig.length > 0) {
       let completed = true;
       let rejected = false;
       if (this.approverDetails && this.approverDetails.length > 0) {
         for (let i = 0; i < this.approverConfig.length; i++) {
           const approved = this.approverDetails.find(x => x.role_id === this.approverConfig[i].role_id);
           if (!approved) {
             completed = false;
             if (this.feature_meet_approve && this.user_role_id ===  this.approverConfig[i].role_id) {
               this.show_approve_button = true;
             } else {
               this.show_approve_button = false;
             }
             this.meetStatus = 'Pending Approval from ' + this.approverConfig[i].role_name;
             if (i === this.approverConfig.length - 1) {
               this.finalApprovalStage  = true;
             } else {
               this.finalApprovalStage = false;
             }
             break;
           } else {
             if (approved.status === 'rejected') {
               rejected = true;
               break;
             }
           }
         }
         if (rejected) {
           this.meetStatus = 'rejected';
         } else if (completed) {
           this.meetStatus = 'approved';
         }
       } else {
         if (this.feature_meet_approve && this.user_role_id ===  this.approverConfig[0].role_id) {
           this.show_approve_button = true;
         } else {
           this.show_approve_button = false;
         }
         this.meetStatus = 'Pending Approval from ' + this.approverConfig[0].role_name;
         if (this.approverConfig.length === 1) {
           this.finalApprovalStage  = true;
         } else {
           this.finalApprovalStage = false;
         }
       }
     } else {
       this.meetStatus = 'pending';
     }
   }*/

  // constructSummaryData() {
  //   const inThis = this;
  //   this.attendees_rowData = [];
  //   this.totalInvoices = 0;
  //   this.totalClaimAmount = 0;
  //   this.totalQuantity = 0;
  //   this.totalOutlets = 0;
  //   const summaryMaster = [];
  //   if (this.sale_rowData && this.sale_rowData.length > 0) {
  //     this.sale_rowData.forEach(sale => {
  //       if (sale.active === false) {
  //
  //       } else {
  //         sale.return_amt = 0;
  //         summaryMaster.push(sale);
  //       }
  //
  //     });
  //
  //   }
  //   if (this.return_rowData && this.return_rowData.length > 0) {
  //     this.return_rowData.forEach(returns => {
  //       if (returns.active === false) {
  //
  //       } else {
  //         returns.margin = 0;
  //         returns.discount = 0;
  //         returns.scheme_discount = 0;
  //         returns.return_amt = returns.price_total;
  //         summaryMaster.push(returns);
  //       }
  //     });
  //   }
  //   console.log(summaryMaster);
  //   this.totalInvoices = [...new Set(summaryMaster.map(item => item.invoice_no))].length;
  //   this.totalOutlets = [...new Set(summaryMaster.map(item => item.code))].length;
  //   const result = [...summaryMaster.reduce((r, o) => {
  //     const key = o.channel + '-' + o.product_categ;
  //
  //     const item = r.get(key) || Object.assign({}, o, {
  //       price_total: 0,
  //       discount: 0,
  //       scheme_discount: 0,
  //       margin: 0,
  //       return_amt: 0,
  //       quantity: 0
  //     });
  //     item.price_total += o.price_total;
  //     item.margin += o.margin;
  //     item.discount += o.discount;
  //     item.scheme_discount += o.scheme_discount;
  //     item.return_amt += o.return_amt;
  //     item.quantity += parseInt(o.quantity, 10);
  //
  //     return r.set(key, item);
  //     // tslint:disable-next-line:new-parens
  //   }, new Map).values()];
  //
  //   console.log(result);
  //
  //   if (result && result.length > 0) {
  //     const unique_channels = [...new Set(result.map(item => item.channel))];
  //     if (unique_channels && unique_channels.length > 0) {
  //       let a = 0;
  //       unique_channels.forEach(channel => {
  //         const channelrows = result.filter(x => x.channel === channel);
  //         const tot = channelrows[0].discount + channelrows[0].return_amt;
  //         inThis.totalClaimAmount += tot;
  //         inThis.totalQuantity += channelrows[0].quantity;
  //         inThis.attendees_rowData.push({
  //           a,
  //           channel: channelrows[0].channel,
  //           product_categ: channelrows[0].product_categ,
  //           rowspan: channelrows.length,
  //           sales: channelrows[0].price_total,
  //           claim_pct: ((tot) / (channelrows[0].price_total)) * 100,
  //           claim: tot,
  //           scheme_discount: channelrows[0].scheme_discount,
  //           margin: channelrows[0].margin,
  //           discount: channelrows[0].discount,
  //           return_amt: channelrows[0].return_amt
  //         });
  //         for (let i = 1; i < channelrows.length; i++) {
  //           const tot1 = channelrows[i].discount + channelrows[i].return_amt;
  //           inThis.totalClaimAmount += tot1;
  //           inThis.totalQuantity += channelrows[i].quantity;
  //           inThis.attendees_rowData.push({
  //             a,
  //             product_categ: channelrows[i].product_categ,
  //             sales: channelrows[i].price_total,
  //             claim_pct: ((tot1) / (channelrows[i].price_total)) * 100,
  //             claim: tot1,
  //             scheme_discount: channelrows[i].scheme_discount,
  //             margin: channelrows[i].margin,
  //             discount: channelrows[i].discount,
  //             return_amt: channelrows[i].return_amt
  //           });
  //         }
  //         a++;
  //       });
  //     }
  //   }
  //   if (this.expense_rowData && this.expense_rowData.length > 0) {
  //     const activeExpenses = [];
  //     if (this.expense_rowData && this.expense_rowData.length > 0) {
  //       this.expense_rowData.forEach(expense => {
  //         if (expense.active === false) {
  //
  //         } else {
  //           activeExpenses.push(expense);
  //         }
  //       });
  //     }
  //
  //     if (activeExpenses) {
  //       this.totalExpenses = activeExpenses.length;
  //       const expense_summary = [...activeExpenses.reduce((r, o) => {
  //         const key = o.policy_line;
  //         const item = r.get(key) || Object.assign({}, o, {
  //           price_total: 0,
  //           discount: 0,
  //           scheme_discount: 0,
  //           margin: 0,
  //           return_amt: 0,
  //           quantity: 0,
  //           amount: 0,
  //           app_amount: 0,
  //           rej_amount: 0
  //         });
  //         item.amount += o.amount;
  //         item.app_amount += o.app_amount;
  //         item.rej_amount += o.rej_amount;
  //
  //         return r.set(key, item);
  //         // tslint:disable-next-line:new-parens
  //       }, new Map).values()];
  //
  //       if (expense_summary && expense_summary.length > 0) {
  //         let i = 0;
  //         if (inThis.attendees_rowData && inThis.attendees_rowData.length > 0) {
  //           i = inThis.attendees_rowData.length;
  //         }
  //         expense_summary.forEach(expense => {
  //           inThis.totalClaimAmount += expense.app_amount;
  //           inThis.attendees_rowData.push({
  //             i,
  //             channel: 'Expense',
  //             product_categ: expense.policy_line,
  //             rowspan: 1,
  //             sales: 0,
  //             claim_pct: 0,
  //             claim: expense.app_amount,
  //             scheme_discount: 0,
  //             margin: 0,
  //             discount: 0,
  //             return_amt: 0
  //           });
  //         });
  //       }
  //     }
  //   }
  //   console.log(this.attendees_rowData);
  //   this.dataLoaded = true;
  // }

  openApproveRejectPopup(type) {
    let title = '';
    let message1 = '';
    let message2 = '';
    let buttonText = '';

    if (type === 'rejected') {
      title = "Reject Meet";
      message1 = "Are you sure you want to Reject Meet?";
      message2 = "Please provide a detailed reason for the rejection";
      buttonText = "Reject";
    } else {
      title = "Approve Meet";
      message1 = "Are you sure you want to Approve Meet?";
      message2 = "Please provide Approval Comments";
      buttonText = "Approve";
    }

    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "50%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.approveRejectMeet(type, data);
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "95%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.approveRejectMeet(type, data);
      });
    }
  }

  approveRejectMeet(state, comments) {
    // let status = '';
    // if (state === 'reject') {
    //   status = 'cancelled';
    // } else {
    //   status = 'approved';
    // }
    const expense_approval_object = {
      apr_id: JSON.parse(localStorage.getItem('user')).id,
      apr_by: JSON.parse(localStorage.getItem('user')).user_name,
      apr_role_id: this.user_role_id,
      role: this.user_role_name,
      status: state,
      comments
    };
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      meet_id: this.activeObject.id,
      expense_approval_object,
      expense_status: state
    };
    this.apiService.post('/api/partner_meets/meet_expense_update', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success(state.toUpperCase() + " Successfully");
          this.loadPage();
        } else {
          this.toastr.error("Failed to " + state.toString().toUpperCase() + " Expense");
        }
      });
  }

  showImagePopup(photo) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          photo
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        data: {
          photo
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    }
  }
}
