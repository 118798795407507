import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-widget-grid',
  template: `
    <div class="info-box">
      <div class="row" >
        <span class="heading">{{ widget.data.label }}</span>
        <div style="position: absolute;right: 20px;">
          <img *ngIf="widget.data.redirection" class="redirect"  src="../../../assets/icons/right-arrow.svg"/>
        </div>
      </div>
      <div class="cardgriddash">
        <ng-container *ngFor="let data of widget.data.data; let i = index">
          <div>
            <div style="text-align: center;">
              <span id="{{data.key}}" class="value" style="display: inline-block">{{ widget.data.value[data.key]['value'] }}</span>
              <span *ngIf="widget.data.value[data.key]['showGrowth']" class="growth" style="display: inline-block; margin-left: 5px;"
                    [ngClass]="{'high': widget.data.value[data.key]['growthValue'] > 1,
          'low': widget.data.value[data.key]['growthValue'] <= 1}">
              {{ widget.data.value[data.key]['growthValue'] }}
                %</span>
            </div>

            <span class="gridtext">{{ data.label }}</span>
          </div>
        </ng-container>
      </div>
    </div>`,
  styleUrls: ['./../dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class GridWidgetComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;
  value;
  resizeSub: Subscription;

  ngOnInit(): void {
    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your widget, chart, map , etc.
        console.log(widget);
      }
    });
    this.widget.data.data.forEach(data => {
      if (!this.widget?.data?.value[data.key] || !this.widget?.data?.value[data.key].value) {
        this.widget.data.value[data.key].value = 0;
      } else {
        //TODO code change by Hemanth. Review pending by Nivil.
        if (data.value_type === 'string') {
          this.widget.data.value[data.key].value = this.widget.data.value[data.key].value.toString();

          setTimeout(() => {
            document.getElementById(data.key).style["color"] = data.value_colour;
          }, 50);
        } else {
          if (this.widget.data.value[data.key].hasOwnProperty('prvsValue')) {
            this.widget.data.value[data.key].prvsValue = Number(this.widget.data.value[data.key].prvsValue);
            if (this.widget.data.value[data.key].prvsValue % 1 !== 0) {
              this.widget.data.value[data.key].prvsValue = parseFloat(this.widget.data.value[data.key].prvsValue).toFixed(2);
            }
            this.widget.data.value[data.key].showGrowth = true;
          }
          if (Number(this.widget.data.value[data.key].value) % 1 !== 0 || this.widget.data.value[data.key].value.toString().includes('.')) {
            this.widget.data.value[data.key].value = parseFloat(this.widget.data.value[data.key].value).toFixed(2);
          }
          if (this.widget.data.value[data.key].showGrowth && this.widget.data.value[data.key].prvsValue  > 0) {
            this.widget.data.value[data.key].growthValue = ((this.widget.data.value[data.key].value /  this.widget.data.value[data.key].prvsValue ) * 100).toFixed(2);
          } else {
            this.widget.data.value[data.key].growthValue = this.widget.data.value[data.key].prvsValue;
          }
          this.widget.data.value[data.key].value = this.formatNUmber(this.widget.data.value[data.key].value, data.value_type);
          if (this.widget.data.value[data.key].value === '0.00') {
            this.widget.data.value[data.key].value = 0;
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }

  formatNUmber(value, value_type) {
    if (value) {
      if (value_type && value_type === 'currency') {
        value = parseFloat(value);
        if (value >= 10000000) {
          return (value / 10000000).toFixed(2) + 'Cr';
        } else if (value >= 100000) {
          return (value / 100000).toFixed(2) + 'L';
        } else if (value >= 1000) {
          return (value / 1000).toFixed(2) + 'K';
        } else {
          return value;
        }
      } else if (value_type && value_type === 'string') {
        return value.toString();
      } else {
        return value;
      }
    } else {
      return 0;
    }
  }
}
