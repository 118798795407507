import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../../_services/shared_service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-asset-travel-cost-popup',
  templateUrl: './assign_travel_cost_popup.component.html'
})

export class AssetTravelCostPopupComponent implements OnInit {

  @Output() serviceExeAssignedEvent = new EventEmitter<boolean>();
  assetDetails = {};
  serviceExecutiveDetails: any = {};
  newVendorRep = false;
  vendorList = [];
  assetList;
  totalAssets = 0;
  totalBrands = 0;
  totalQty = 0;
  assetIds = [];
  batchId = 0;
  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public datePipe: DatePipe,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.batchId = dialogdata.batch_id;
    // this.assetList = dialogdata.rows;
    // this.totalAssets = this.assetList.length;
    // this.assetList.forEach(asset => {
    //   this.assetIds.push(asset.id);
    //   if (asset.qty) {
    //     this.totalQty += parseInt(asset.qty, 10);
    //   }
    // });
    // this.serviceExecutiveDetails.ids =  this.assetIds;
    // this.fetchServiceExecutives();
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  changeDate(dt) {
    this.serviceExecutiveDetails.date = this.datePipe.transform(this.serviceExecutiveDetails.date, 'yyyy-MM-dd');
  }

  assignCheckboxClick(event) {
    if (this.newVendorRep) {
      this.dialogRef.updateSize('650px', '600px');
    } else {
      this.dialogRef.updateSize('650px', '450px');
    }
  }

  vendorChange(event) {

  }

  // fetchServiceExecutives() {
  //   const paramObject: any = {};
  //   paramObject.access_token = localStorage.getItem('resfreshToken');
  //   paramObject.data = {
  //     profile_name: 'Service Executive'
  //   };
  //   this.apiService.post('/api/res_users/get_users_from_profile', paramObject)
  //     .subscribe(res => {
  //       console.log(res);
  //       if (res.hasOwnProperty('results') && (res.results.status === 200)) {
  //         this.vendorList = res.results.data;
  //       }
  //     });
  // }
  addTravelCost() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.serviceExecutiveDetails;
    paramObject.data.id = this.batchId;
    this.apiService.post('/api/request_masters/add_travel_cost', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success("Travel and Asset Cost Updated Successfully");
          this.serviceExeAssignedEvent.emit(true);
          this.dialogRef.close();
        } else {
          this.toastr.error("Failed to Update Travel and Asset Cost");
        }
      });
  }
}
