import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {BehaviorSubject, Observable} from 'rxjs';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {getPerformance, trace} from 'firebase/performance';
import {getAnalytics, logEvent} from 'firebase/analytics';
import {environment} from '../../environments/environment';
import {LazyMapsAPILoaderConfigLiteral} from '@agm/core';
import {initializeApp} from "firebase/app";
import {setAnalyticsCollectionEnabled} from "@firebase/analytics";
import {MenuMasterList} from "../components/sidebarv2/menuMaster";
import {RowNode} from "@ag-grid-enterprise/all-modules";

@Injectable({providedIn: 'root'})
export class SharedService {
  result;
  public po_dc_id;
  public new = false;
  public mark_receieve = false;
  public is_receipt = false;
  public is_return = false;
  public new_retailer = false;
  public order_id;
  public mark_dispatch = false;
  public delivered_po = false;
  public confirmed_po = false;
  public order_type = 'sale';
  public flag;
  public invoice = false;
  public dms_retailer;
  public gotoGrv = {active: false, po: ''};
  public gotoGrn = {active: false, po: ''};
  public gotoPO = {active: false, po: ''};
  public logData = {distributor_id: null, product_id: null, territory_id: null};
  public headerTitleSource = new BehaviorSubject<string>('default data');
  public apiKeySource = new BehaviorSubject<string>('default data');
  public dmsParams = {
    new_mode: false,
    invoice: false,
    type: '',
    order_id: null,
    flag: '',
    edit_mode: false,
    dmsType: '',
    cust_supp: null,
    goToInv: {
      active: false,
      ref: null
    },
    goToRef: {
      active: false,
      ref: null
    },
    goToOrd: {
      active: false,
      ref: null
    },
    ret_id: null,
    ret_code: null,
    inv_ids: []
  };
  headerTitle = this.headerTitleSource.asObservable();
  //apiKey = this.apiKeySource.asObservable();
  public mapapiKey = new BehaviorSubject<string>('Default Data');
  yourModal: ModalDirective;
  @Output() filterCancelCLickEvent = new EventEmitter<string>();
  @Output() filterSetEvent = new EventEmitter<boolean>();
  public perf;
  public analytics;
  private modals: any[] = [];
  role_features;
  currListData = {
    url: null,
    userdata: null,
    searchText: null,
    agGridFilter: null
  };
  public statusConfig = {
    success: 'success',
    success1: 'success',
    error: 'fail',
    error1: 'fail',
    warning: 'warning',
    warning1: 'warning'
  };
  menuList = [];
  excelColumns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'Y', 'Z'];

  constructor(private httpClient: HttpClient,
              private datePipe: DatePipe) {

    this.mapapiKey = new BehaviorSubject<string>('Default Data');
    const firebaseConfig = {
      apiKey: "AIzaSyC89xRGREv7-9o0C9IL9Syj2nQRY3jUcz0",
      authDomain: "salesdiary-pwa.firebaseapp.com",
      projectId: "salesdiary-pwa",
      storageBucket: "salesdiary-pwa.appspot.com",
      messagingSenderId: "272841099066",
      appId: "1:272841099066:web:4658a9a37b0a2cd60f3203",
      measurementId: "G-N8GMWKFGT7"
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    this.perf = getPerformance(app);
    this.analytics = analytics;
    this.setRoleFeatures();
    setAnalyticsCollectionEnabled(this.analytics, true);
  }

  public static mapAPIKeySet(googleMapsConfig: LazyMapsAPILoaderConfigLiteral) {
    return () => googleMapsConfig.apiKey = localStorage.getItem('app') !== null ? JSON.parse(localStorage.getItem('app')).map_key : 'nokey';
  }

  filterCancelCLick(data: string) {
    this.filterCancelCLickEvent.emit(data);
  }

  filterSet(data: boolean) {
    this.filterSetEvent.emit(data);
  }

  getHeader() {
    return this.headerTitleSource.getValue();
  }

  setHeader(headerTitle: string) {
    if (localStorage.getItem('company_key')) {
      this.headerTitleSource.next(headerTitle);
      const t = trace(this.perf, 'page:' + headerTitle);
      t.start();
      t.putAttribute('companyKey', localStorage.getItem('company_key'));
      //setCurrentScreen(this.appModule.analytics, headerTitle);
      logEvent(this.analytics, 'screen_view', {
        firebase_screen: headerTitle,
        firebase_screen_class: 'Menu',
        appName: 'Salesdiary PWA',
        appId: window.location.hostname,
        appVersion: environment.appVersion,
        app_version: environment.appVersion
      });
      t.stop();
    }
  }

  setApiKey(apiKey) {
    this.mapapiKey.next(apiKey);
  }

  getApiKey(): Observable<string> {
    return this.mapapiKey.asObservable();
  }


  add(modal: any) {
    this.modals.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string) {
    console.log(this.modals);
    const modal: any = this.modals.filter(x => x.id === id)[0];
    modal.open();
  }

  close(id: string) {
    const modal: any = this.modals.filter(x => x.id === id)[0];
    modal.close();
  }

  setModal(modal: ModalDirective) {

    this.yourModal = modal;

  }

  showModal() {

    this.yourModal.show();

  }


  setDmsParams() {
    this.dmsParams.new_mode = false;
    this.dmsParams.edit_mode = false;
    this.dmsParams.order_id = null;
    this.dmsParams.goToOrd.active = false;
    this.dmsParams.goToOrd.ref = null;
    this.dmsParams.goToInv.active = false;
    this.dmsParams.goToInv.ref = null;
    localStorage.setItem('dms_order_id', this.dmsParams.order_id);
    localStorage.setItem('invoice_order_id', this.dmsParams.order_id);
  }

  fetch_periodID(date) {
    const jcData = JSON.parse(localStorage.getItem('jc_periods'));
    if (jcData) {
      for (let i = 0; i < jcData.length; i++) {
        if (new Date(date) >= new Date(jcData[i].start_date) &&
          new Date(date) <= new Date(jcData[i].end_date)) {
          localStorage.setItem('period_id', jcData[i].id);
          i = jcData.length;
        }
      }
    }
  }

  clearCache() {
    localStorage.clear();
  }

  clearPinned(gridOptions) {
    if (gridOptions !== null && gridOptions.columnApi !== null) {
      gridOptions.columnApi.applyColumnState({defaultState: {pinned: null}});
    }
  }

  resetPinned(gridOptions, params) {
    if (gridOptions !== null && gridOptions.columnApi !== null) {
      gridOptions.columnApi.applyColumnState({
        state: params,
        defaultState: {pinned: null},
      });
    }
  }

  checkLogin() {
    const loggedIn = localStorage.getItem('logged_in');
    return new Promise((resolve, reject) => {
      (function waitForLogin() {
        if (loggedIn === 'true') {
          return resolve();
        }
        setTimeout(waitForLogin, 5000);
      })();
    });
  }

  setRoleFeatures() {
    if (localStorage.getItem('role_features')) {
      this.role_features = [...new Set(JSON.parse(localStorage.getItem('role_features')).web_module_ids.map(obj => obj.name))];
    }
  }

  ACLcheck(featureName) {
    if (this.role_features) {
      return this.role_features.includes(featureName);
    } else {
      return false;
    }
  }

  statusCellRenderer(params) {
    let displayElem = '';
    if (params.value) {
      if (params.value === params.context.componentParent.statusConfig.success || params.value === params.context.componentParent.statusConfig.success1) {
        displayElem = '<span class="ml-1 status2" style="background-color:#E1F0E7; border:#E1F0E7; color: #379862; font-weight: bold;" >' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.error || params.value === params.context.componentParent.statusConfig.error1) {
        displayElem = '<span class="ml-1 status2" style="background-color:#FBE3E7; border:#FBE3E7; color: #E24260; font-weight: bold;">' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.warning || params.value === params.context.componentParent.statusConfig.warning1) {
        displayElem = '<span class="ml-1 status2" style="background-color:#FEF2DF; border:#FEF2DF; color: #F6A82C; font-weight: bold;">' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.new || params.value === params.context.componentParent.statusConfig.new1) {
        displayElem = '<span class="ml-1 status2" style="background-color:#E1ECF5; border:#E1ECF5; color: #0762AD; font-weight: bold;">' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.processed) {
        displayElem = '<span class="ml-1 status2" style="background-color:#EAEBF7; border:#EAEBF7; color: #757BC8; font-weight: bold;">' + params.value + '</span>';
      } else {
        if (params.value) {
          displayElem = '<span class="ml-1 status2" style="background-color:#E3F4F6; border:#E3F4F6; color: #3A6C87; font-weight: bold;">' + params.value + '</span>';
        }
      }
    }
    return displayElem;
  }

  statusIndicatorCellRenderer(params) {
    let displayElem = '';
    if (params.value) {
      if (params.value === params.context.componentParent.statusConfig.success || params.value === params.context.componentParent.statusConfig.success1) {
        displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span style="text-transform: capitalize;">' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.error || params.value === params.context.componentParent.statusConfig.error1) {
        displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span style="text-transform: capitalize;">' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.warning || params.value === params.context.componentParent.statusConfig.warning1) {
        displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #F6A82C;border-radius: 50%; display: inline-block;"></span><span style="text-transform: capitalize;">' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.new || params.value === params.context.componentParent.statusConfig.new1) {
        displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #0762AD;border-radius: 50%; display: inline-block;"></span><span style="text-transform: capitalize;">' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.processed) {
        displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #757BC8;border-radius: 50%; display: inline-block;"></span><span style="text-transform: capitalize;">' + params.value + '</span>';
      } else {
        if (params.value) {
          displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #3A6C87;border-radius: 50%; display: inline-block;"></span><span style="text-transform: capitalize;">' + params.value + '</span>';
        }
      }
    }
    return displayElem;
  }

  convertExcelStringToDate(item) {
    let splitchar = '/';
    if (typeof item === 'number') {
      item = this.ExcelDateToJSDate(item);
      item = this.datePipe.transform(new Date(item), 'yyyy-MM-dd');
      return item;
    } else {
      if (item.includes('/')) {
        splitchar = '/';
      } else if (item.includes('-')) {
        splitchar = '-';
      } else {
        return '';
      }
    }
    const dat = item.split(splitchar);
    let converted_date;
    if (dat && dat.length === 3) {
      /*if (dat[0].length === 1) {
        dat[0] =  (dat[0]).toString().padStart(2, 0);
      }
      if (dat[1].length === 1) {
        dat[1] =  (dat[1]).toString().padStart(2, 0);
      }
      if (dat[2].length === 1) {
        dat[2] =  (dat[2]).toString().padStart(2, 0);
      }*/
      if (dat[0].length > 2) {
        try {
          converted_date = this.datePipe.transform(new Date(dat[0] + '-' + dat[1] + '-' + dat[2]), 'yyyy-MM-dd');
          return converted_date;
        } catch (e) {
          return '';
        }
      } else {
        try {
          converted_date = this.datePipe.transform(new Date(dat[2] + '-' + dat[1] + '-' + dat[0]), 'yyyy-MM-dd');
          return converted_date;
        } catch (e) {
          return '';
        }
      }
    } else {
      return '';
    }
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ExcelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;
    return ((date_info.getMonth() + 1)).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      + '/' + date_info.getDate().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      + '/' + date_info.getFullYear();
  }

  get_mid(object_id) {
    const USER = JSON.parse(localStorage.getItem('user'));
    if (object_id) {
      return USER['id'] + '_' + object_id + '_' + Date.now();
    } else {
      return USER['id'] + '_' + Date.now();
    }
  }

  updateImmutableObject(original: any, newValues: any) {
    // start with new object
    const newObject: any = {};
    // copy in the old values
    Object.keys(original).forEach(key => {
      newObject[key] = original[key];
    });
    // now override with the new values
    Object.keys(newValues).forEach(key => {
      newObject[key] = newValues[key];
    });
    return newObject;
  }

  toLowerCaseProps(obj) {
    return Object.entries(obj).reduce((a, [key, val]) => {
      a[key.toLowerCase().trim().replace(' ', '')] = val;
      return a;
    }, {}) as unknown;
  }

  unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }

  getDaysArray(start, end) {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  checkAndPrepareMenus() {
    const userObj = JSON.parse(localStorage.getItem('user'));
    let roleconfigPresent = false;
    if (userObj.role.hasOwnProperty('role_config') && userObj.role.role_config && userObj.role.role_config.length > 0) {
      const role_config = userObj.role.role_config.find(x => x.type === 'PWA Menu');
      if (role_config?.config_json) {
        roleconfigPresent = true;
        this.prepareMenuList(role_config.config_json);
      } else {
        roleconfigPresent = false;
      }
    }
    if (!roleconfigPresent) {
      const webModuleIds = JSON.parse(localStorage.getItem('role_features')).web_module_ids;
      if (webModuleIds) {
        this.prepareMenuList(MenuMasterList);
      }
    }
  }

  prepareMenuList(menuMaster) {
    const inThis = this;
    const webModuleIds = JSON.parse(localStorage.getItem('role_features')).web_module_ids;
    if (webModuleIds) {
      this.menuList = [];
      const menuList = [];
      menuMaster.forEach(menu => {
        if (menu.feature && menu.feature !== "") {
          if (inThis.ACLcheck(menu.feature)) {
            menuList.push(menu);
          }
        } else {
          menuList.push(menu);
        }
      });
      if (menuList) {
        menuList.forEach(menu => {
          const menuItem: any = JSON.parse(JSON.stringify(menu));
          menuItem.sub_menu = [];
          if (menu.hasOwnProperty('sub_menu') && menu.sub_menu && menu.sub_menu.length > 0) {
            menu.sub_menu.forEach(sub_menu => {
              if (sub_menu.hasOwnProperty("feature") && sub_menu.feature && sub_menu.feature !== "") {
                if (inThis.ACLcheck(sub_menu.feature)) {
                  menuItem.sub_menu.push(sub_menu);
                }
              } else {
                menuItem.sub_menu.push(sub_menu);
              }
            });
          }
          this.menuList.push(menuItem);
        });
      }
    }
  }

  capitalize(s) {
    return s.toString().replace('_', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
  }

  capitalise(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data[field] === null || params.data[field] === "") {
          return '';
        } else {
          return params.data[field].replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
        }
      } else {
        return '';
      }
    };
  }

  removeUnderscoreAndFirstLetterCapital(str) {
    const frags = str.split('_');
    for (let i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  generatePinnedBottomData(gridApi: any, gridColumnApi) {
    // generate a row-data with null values
    const result = {};

    gridColumnApi.getAllGridColumns().forEach(item => {
      result[item.colId] = null;
    });
    return this.calculatePinnedBottomData(result, gridApi, gridColumnApi);
  }

  calculatePinnedBottomData(result, gridApi: any, gridColumnApi) {
    gridColumnApi.getAllGridColumns().forEach(element => {
      gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        if (rowNode.data[element.colId] && typeof rowNode.data[element.colId] === 'number' && element.colDef.aggFunc === 'sum') {
          if (Number(rowNode.data[element.colId]) % 1 !== 0) {
            result[element.colId] += Number(parseFloat(rowNode.data[element.colId]).toFixed(2));
          } else {
            result[element.colId] += Number(rowNode.data[element.colId]);
          }
        }
      });
      if (result[element.colId]) {
        if (result[element.colId] % 1 !== 0) {
          result[element.colId] = `${result[element.colId].toFixed(2)}`;
        }
      }
    });
    if (gridApi.getRenderedNodes() && gridApi.getRenderedNodes().length > 0) {
      result[gridColumnApi.getAllColumns()[0].colDef.field] = 'TOTAL';
    }
    return result;
  }

  applyExcelColumnStyle(ws, row, length) {
    for (let i = 0; i < length; i++) {
      if (ws[this.excelColumns[i] + row]) {
        ws[this.excelColumns[i] + row].s = {
          fill: {
            patternType: 'solid',
            fgColor: {rgb: 'F3FF00'},
            bgColor: {rgb: 'F3FF00'},
          },
          font: {
            bold: true,
            sz: "10"
          },
          alignment: {
            horizontal: "center"
          },
          border: {
            right: {
              style: "thin",
              color: "000000"
            },
            left: {
              style: "thin",
              color: "000000"
            },
            top: {
              style: "thin",
              color: "000000"
            },
            bottom: {
              style: "thin",
              color: "000000"
            }
          }
        };
      }
    }
  }

  titleCase(str) {
    str = str.toString().toLowerCase().replace(/_/g, " ");
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  constructDataFromColumnDefs(columndefs, rowData) {
    const finalData = [];
    if (rowData && rowData.length > 0 && columndefs && columndefs.length > 0) {
      rowData.forEach(row => {
        const indrow = {};
        columndefs.forEach(column => {
          indrow[column.headerName] = row[column.field];
        });
        finalData.push(indrow);
      });
    }
    return finalData;
  }

  formatDateTime(value) {
    if (value) {
      return this.datePipe.transform((new Date(value + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
    } else {
      return '';
    }
  }
}

export function agmConfigFactory(config: LazyMapsAPILoaderConfigLiteral, sharedservice: SharedService) {
  return () => sharedservice.getApiKey().subscribe(value => {
    if (value === 'Default Data') {
      if (localStorage.getItem('map_key') != null && localStorage.getItem('map_key') !== '') {
        config.apiKey = localStorage.getItem('map_key');
      } else {
        config.apiKey = 'initialKey';
      }
    } else {
      config.apiKey = value;
    }
  });
}

