<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
<html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <style type="text/css">

    body {
      margin: 25px;
      background-color: #ffffff;
    }

    #page_1 {
      overflow: hidden;
      padding: 0px;
      border: none;
      width: 100%;
    }

    #page_1 #id1_1 {
      border: none;
      margin: 0px 0px 0px 0px;
      padding: 0px;
      border: 1px solid black;
      width: auto;
      overflow: hidden;
    }

    #page_1 #id1_2 {
      border: none;
      padding: 0px;
      border: none;
      width: auto;
      overflow: hidden;
    }

    #page_1 #p1dimg1 {
      position: absolute;
      top: 17px;
      right: 58px;
      width: 68px;
      height: 67px;
    }

    #page_1 #p1dimg1 #p1img1 {
      width: 68px;
      height: 67px;
    }

    #page_1 #p1dimg2 {
      position: absolute;
      top: 30px;
      left: 30px;
      width: 70px;
      height: 70px;
    }
    .companylogo {
      height: 100px;
    }

    #page_1 #p1dimg2 #p1dimg2 {
      width: 100px;
      height: 100px;
    }


    .ft0 {
      font: 1px 'Arial';
      line-height: 1px;
    }

    .ft1 {
      font: bold 20px 'Arial';
      line-height: 29px;
    }

    .ft2 {
      font: 11px 'Arial';
      line-height: 14px;
    }

    .ft3 {
      font: bold 11px 'Arial';
      line-height: 14px;
    }


    .ft6 {
      font: 1px 'Arial';
      line-height: 2px;
    }

    .ft7 {
      font: 14px 'Calibri';
      line-height: 10px;
    }

    .p0 {
      text-align: left;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p1 {
      text-align: center;
      margin-top: 46px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p2 {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }


    .p5 {
      text-align: left;
      padding: 4px !important;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }


    .p7 {
      text-align: left;
      padding-left: 6px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .p8 {
      text-align: left;
      padding-left: 3px;
      margin-top: 0px;
      margin-bottom: 0px;
    }


    .p11 {
      text-align: right;
      padding-right: 5px;
      margin-top: 4px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .table_style {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      border: 1px;
      table-layout:fixed;
      width: 97%;
      border: 1px solid #dddddd;
      margin: 10px;

    }

    .td9 {
      padding: 5px;
      margin: 0px;
      width: 10%;
      vertical-align: middle;
    }

    .td9s {
      padding: 5px;
      margin: 0px;
      width: 5%;
      vertical-align: middle;
    }

    .td10 {
      padding: 5px;
      margin: 0px;
      width: 25%;
      vertical-align: middle;
    }

    .td11 {
      border-left: #dddddd 1px solid;
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 169px;
      vertical-align: bottom;
    }

    .td12 {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 94px;
      vertical-align: bottom;
    }

    .td14 {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 169px;
      vertical-align: bottom;
    }

    .td15 {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 116px;
      vertical-align: bottom;
    }

    .td16 {
      border-right: #dddddd 1px solid;
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 106px;
      vertical-align: bottom;
    }

    .td19 {
      border-right: #dddddd 1px solid;
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 59px;
      vertical-align: bottom;
    }


  </style>
</head>

<body>
<div id=page_1>
  <div id="p1dimg2">
    <img class="companylogo" [src]="order_detail['logo']">
  </div>
  <div id=id1_1>
    <p  class="p1 ft1"><u>Delivery Challan</u></p>
    <br><br>
    <br><br>
    <table cellpadding=0 cellspacing=0 class=0>
      <tr>
        <td class="r2 "><p class="p5 ft3"><span class="ft2">GST: </span> {{order_detail['partner_gstn']}} </p></td>
        <td class="r2 td9"><p class="p0 ft0"> </p></td>
        <td class="r2 "><p class="p5 ft3"><span class="ft2">Phone Number: </span>   {{order_detail['partner_mobile']}}  </p></td>
        <td class="r2 td9"><p class="p0 ft0"> </p></td>
        <td class="r2"><p class="p5 ft3"><span class="ft2">Email ID: </span>  {{order_detail['partner_email']}}  </p></td>
      </tr>
      <tr>
        <td class="r5 td11"><p class="p0 ft6"> </p></td>
        <td class="r5 td12"><p class="p0 ft6"> </p></td>
        <td class="r5 td19"><p class="p0 ft6"> </p></td>
        <td class="r5 td14"><p class="p0 ft6"> </p></td>
        <td class="r5 td15"><p class="p0 ft6"> </p></td>
        <td class="r5 td16"><p class="p0 ft6"> </p></td>
      </tr>
      <tr>
        <td class="r2"><p class="p5 ft3"><span class="ft2">Bill No:  </span> {{order_detail['bill_no']}}</p></td>
        <td class="r2 td9"><p class="p0 ft0"> </p></td>
        <td class="r2"><p class="p5 ft3"><span class="ft2">Date: </span>  {{order_detail['order_date']}} </p></td>
      </tr>
      <tr>
        <td class="r5 td11"><p class="p0 ft6"> </p></td>
        <td class="r5 td12"><p class="p0 ft6"> </p></td>
        <td class="r5 td19"><p class="p0 ft6"> </p></td>
        <td class="r5 td14"><p class="p0 ft6"> </p></td>
        <td class="r5 td15"><p class="p0 ft6"> </p></td>
        <td class="r5 td16"><p class="p0 ft6"> </p></td>
      </tr>

    </table>
    <br><br>
    <p class="p5 ft3" style="margin-left: 10px;">Address Details </p>
    <table class=table_style>
      <th width="50%"></th>
      <th width="50%"></th>
      <tr>
        <td class="r6"  style="white-space: pre-wrap !important;  padding-top: 5px;"><p class="p7 ft2" ><span class="ft3">From: </span> {{order_detail['supplier']}}</p></td>
        <td *ngIf="order_detail['partner']" class="r6" style="white-space: pre-wrap !important;  padding-top: 5px;"><p class="p7 ft2" ><span class="ft3">To: </span> {{order_detail['partner']}}</p></td>
      </tr>

      <tr>
        <td class="r6" style="white-space: pre-wrap !important;"><p class="p7 ft2" >{{order_detail['dist_address']}}</p></td>
        <td class="r6" style="white-space: pre-wrap !important;"><p class="p7 ft2">{{order_detail['address']}} <br>
        </p></td>
      </tr>
      <tr>
        <td *ngIf="order_detail['van']" class="r6"  style="white-space: pre-wrap !important; "><p class="p7 ft3" style="margin-top: 10px;"><span class="ft2">Van: </span>  : {{order_detail['van']}}</p></td>
        <td *ngIf="order_detail['vehicle_no']" class="r6" style="white-space: pre-wrap !important;  "><p class="p7 ft3" style="margin-top: 10px;"><span class="ft2">Vehicle Number: </span> {{order_detail['vehicle_no']}}</p></td>
      </tr>
      <tr>
        <td *ngIf="order_detail['supplier_mobile']" class="r6"  style="white-space: pre-wrap !important; "><p class="p7 ft3" >Mobile : {{order_detail['supplier_mobile']}}</p></td>
        <td *ngIf="order_detail['driver_name']" class="r6" style="white-space: pre-wrap !important; "><p class="p7 ft3" >Driver: {{order_detail['driver_name']}}</p></td>
      </tr>
      <tr>
        <td *ngIf="order_detail['supplier_email']" class="r6"  style="white-space: pre-wrap !important; "><p class="p7 ft3" >Email : {{order_detail['supplier_email']}}</p></td>
        <td *ngIf="order_detail['driver_contact']" class="r6" style="white-space: pre-wrap !important; "><p class="p7 ft3" >Driver Contact: {{order_detail['driver_contact']}}</p></td>
      </tr>
    </table>
    <br>
    <p class="p5 ft3" style="margin-left: 10px;">Product Details</p>
    <table class=table_style>
      <tr style=" border-bottom: #dddddd 1px solid;">
        <td class="r10 td9s"><p class="p7 ft3">Sl. No.</p></td>
        <td class="r6 td10"><p class="p7 ft3">Particulars</p></td>
        <td class="r6 td9"><p class="p7 ft3">HSN</p></td>
        <td class="r6 td9s"><p class="p8 ft3">Qty In</p></td>
        <td class="r6 td9"><p class="p8 ft3">UOM</p></td>
        <td *ngIf="showReportingUom" class="r6 td9"><p class="p8 ft3">Report UOM</p></td>
        <td class="r6 td9s"><p class="p8 ft3">Unit Rate</p></td>
        <td class="r6 td9"><p class="p8 ft3">Gross<br>Amount</p></td>
      </tr>
      <br>
      <tr *ngFor="let product of order_detail['sale_items']; let i = index">
        <td class="r10 td9s"><p class="p7 ft2">{{i+1}}</p></td>
        <td class="r6 td10"><p class="p7 ft2">{{product['product_name']}}</p></td>
        <td class="r6 td9"><p class="p7 ft2">{{product['hsn']}}</p></td>
        <td class="r6 td9s"><p class="p8 ft2">{{product['abs_qty']}}</p></td>
        <td class="r6 td9"><p class="p8 ft2">{{product['product_uom']}}</p></td>
        <td *ngIf="showReportingUom" class="r6 td9"><p class="p8 ft2">{{product['reporting_uom']}}</p></td>
        <td class="r6 td9s"><p class="p8 ft2">{{product['unit_price']}}</p></td>
        <td class="r6 td9"><p class="p8 ft2">{{math.abs(product['amount_total'])}}</p></td>
      </tr>
    </table>


    <br><br><p class="p11 ft2">Total Qty : <span class="ft3">{{totalQty}}</span></p>
    <p *ngIf="order_detail['amount_subtotal']" class="p11 ft2">Taxable Amount : <span class="ft3">{{order_detail['amount_subtotal'].toFixed(2)}}</span></p>
    <p *ngIf="order_detail['amount_subtotal'] && order_detail['cgst']" class="p11 ft2">CGST : <span class="ft3">{{order_detail['cgst']}}</span></p>
    <p *ngIf="order_detail['amount_subtotal'] && order_detail['sgst']" class="p11 ft2">SGST : <span class="ft3">{{order_detail['sgst']}}</span></p>
    <p *ngIf="order_detail['amount_subtotal'] && order_detail['igst']" class="p11 ft2">IGST : <span class="ft3">{{order_detail['igst']}}</span></p>
    <p *ngIf="order_detail['amount_subtotal'] && order_detail['cess']" class="p11 ft2">CESS : <span class="ft3">{{order_detail['cess']}}</span></p>
    <p class="p11 ft2">Total Amount : <span class="ft3">{{totalValue.toFixed(2)}}</span></p>
    <br>
    <p *ngIf="order_detail['company']" class="p11 ft2">For {{order_detail['company']}}</p>
    <div class="p2 ft1">
      <ngx-barcode [bc-value]="order_detail['ewb_no']" [bc-display-value]="order_detail['ewb_no']" [bc-width]="1" [bc-height]="50" [bc-font-size]="10"></ngx-barcode>
    </div>
  </div>
</div>
</body>
</html>
