<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Create User - Executive</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <form novalidate id="ngForm" #userform="ngForm">
        <div>
          <div class="row">
            <div class="col-md-6 pl-0 mt-0">
              <span class="detaillabel2">Name</span>
              <span class="requiredspan"> *</span>
              <input
                class="forminputwhitebg"
                type="text"
                style="display: block; margin-top: 5px;width: 100%;"
                name="customer_name"
                (keydown)="onUserKeyDown($event)"
                [(ngModel)]="activeObject.user_name"
                #user_name="ngModel"
                placeholder="Enter User Name"
                required
              >
              <div *ngIf="user_name.errors && (user_name.dirty || user_name.touched)">
                <span *ngIf="user_name.errors.required" class="text-danger">User Name is required</span>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0  pr-0">
              <span class="detaillabel2">EMP Code</span>
              <span class="requiredspan"> *</span>
              <div>
                <input
                  class="forminputwhitebg"
                  type="text"
                  style="display: block; margin-top: 5px;width: 100%;"
                  name="emp_code"
                  [(ngModel)]="activeObject.emp_code"
                  #emp_code="ngModel"
                  placeholder="unique Code or alias"
                  required
                >
                <div *ngIf="emp_code.errors && (emp_code.dirty || emp_code.touched)">
                  <span *ngIf="emp_code.errors.required" class="text-danger">User Code is required</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 10px;">
          <div class="row">
            <div class="col-md-6 pl-0 mt-0">
              <span class="detaillabel2">Login Name</span>
              <span class="requiredspan"> *</span>
              <div>
                <input
                  class="forminputwhitebg"
                  type="text"
                  style="display: block; margin-top: 5px;width: 100%;"
                  name="login"
                  [(ngModel)]="activeObject.login"
                  #login="ngModel"
                  placeholder="unique login"
                  required
                >
                <div *ngIf="login.errors && (login.dirty || login.touched)">
                  <span *ngIf="login.errors.required" class="text-danger">Unique Login is required</span>
                </div>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="detaillabel2">Role</span>
              <span class="requiredspan"> *</span>
              <div style="margin-top: 5px;">
                <ng-select [items]="rolesList"
                           bindLabel="itemname"
                           bindValue="id"
                           appendTo="body"
                           class="custom"
                           name="role_id"
                           #role_id="ngModel"
                           [(ngModel)]="activeObject.role_id"
                           required>
                  >
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 10px;" class="row">
          <div class="col-md-6 pl-0 mt-0">
            <span class="detaillabel2">Email</span>
            <span class="requiredspan"> *</span>
            <div>
              <input
                class="forminputwhitebg"
                type="email"
                style="display: block; margin-top: 5px;width: 100%;"
                name="user_email"
                [(ngModel)]="activeObject.user_email"
                #user_email="ngModel"
                placeholder="Enter Email"
                required
              >
              <div *ngIf="user_email.errors && (user_email.dirty || user_email.touched)">
                <span *ngIf="user_email.errors.required" class="text-danger">Email is required</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 pl-0 mt-0 pr-0">
            <span class="detaillabel2">Mobile</span>
            <span class="requiredspan"> *</span>
            <div>
              <input
                class="forminputwhitebg"
                type="number"
                style="display: block; margin-top: 5px;width: 100%;"
                name="mobilenumber"
                [(ngModel)]="activeObject.mobilenumber"
                #mobilenumber="ngModel"
                placeholder="Enter Mobile No"
                required
              >
              <div *ngIf="mobilenumber.errors && (mobilenumber.dirty || mobilenumber.touched)">
                <span *ngIf="mobilenumber.errors.required" class="text-danger">Mobile Number is required</span>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 10px;" class="row">
          <div class="col-md-6 pl-0 mt-0">
            <span class="detaillabel2">Reporting Team</span>
            <span class="requiredspan"> *</span>
            <div style="margin-top: 5px;">
              <ng-select [items]="teamList"
                         bindLabel="itemname"
                         bindValue="id"
                         appendTo="body"
                         class="custom"
                         name="sale_team_id"
                         #sale_team_id="ngModel"
                         (ngModelChange)="onTeamChangeEvent($event)"
                         [(ngModel)]="activeObject.sale_team_id"
                         required>
                >
              </ng-select>
            </div>
          </div>
          <div class="col-md-6 pl-0 mt-0 pr-0">
            <span class="detaillabel2">State</span>
            <span class="requiredspan"> *</span>
            <div style="margin-top: 5px;">
              <ng-select [items]="statesList"
                         bindLabel="itemName"
                         appendTo="body"
                         bindValue="id"
                         class="custom"
                         name="state_id"
                         #state_id="ngModel"
                         [(ngModel)]="activeObject.state_id"
                         required>
                >
              </ng-select>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
        <button
          mat-button
          style=" margin-right:15px;"
          class="btn grey-btn"
          (click)="closeDialog()"
        >
          Cancel
        </button>
        <button
          mat-button
          type="submit"
          [disabled]="userform.form.invalid || !activeObject.role_id || !activeObject.sale_team_id || !activeObject.state_id"
          class="btn confirm-blue-btn"
          (click)="saveUser()"
        >
          Save
        </button>
      </div>
  </div>
</section>
