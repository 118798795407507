<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button class="closebtn" (click)="closeDialog()">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Transfer GT Orders to VAN</h2>
    </div>
    <div class="body-section w-100" style="padding: 15px;background-color: #F5F7F9 ">
      <div style="display: inline-block; margin-top: 10px; width: 100%">
        <form novalidate id="ngForm" #deliveryplanform="ngForm">

          <div style="margin-bottom: 20px;">
            <div style="display: block;" class="cardbox">
              <div class="row">
                <div style="width: 100%;" *ngIf="!deliveryPlanUpdated">
                  <div class="col-md-12 pad0" style="margin-top: 5px;background: white;padding: 10px;">
                    <div class="col-md-2 pl-0 pr-30">
                      <label style="color: #5D5D5D">Delivery Date</label>
                      <div style="margin-top: 5px;">
                        <div *ngIf="!dpEdit" class="date-picker-grey">
                          <input [selectMode]="'single'"
                                 [readonly]="true" [(ngModel)]="deliveryPlanData.date"
                                 [owlDateTimeTrigger]="dt"
                                 [owlDateTime]="dt" placeholder="Date"
                                 name="date"
                                 #date="ngModel"
                                 style="width: 100%;">
                          <div style=" position: absolute;right: 20px;top: 12px;">
                            <span class="datespan" (click)="selectTodaysDate()">Today</span>
                            <span class="datespan" (click)="selectTommorowDate()">Tomorrow</span>
                            <span style="padding-left: 5px;"><i class="far fa-calendar-alt"></i></span>
                          </div>

                          <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                        </div>
                        <p *ngIf="dpEdit" style="font-size: 15px;">{{deliveryPlanData.date}}</p>
                      </div>
                    </div>
                    <div class="col-md-2 pl-0 pr-30">
                      <label style="color: #5D5D5D">Delivered By</label>
                      <div style="margin-top: 5px;">
                        <div *ngIf="!dpEdit">
                          <span id="spanselect" class="spanselect"
                                [matMenuTriggerFor]="moredropwdown">{{deliveryPlanData.van_name}}</span>
                          <mat-menu #moredropwdown="matMenu" id="spanselectmenu">
                            <ng-container *ngFor="let item of usersList; let i = index">
                              <button mat-menu-item style="width: {{menuWidth}}" (click)="onMatMenuSelect(item)">
                                <div class="col-md-12 pad0">
                                  <div class="col-md-10 pl-0 pr-30">
                                    <span *ngIf="!item.dp">{{ item.user_name }}</span>
                                    <div *ngIf="item.dp" style="line-height: normal; margin-top: 9px;">
                                      <span>{{ item.user_name }}</span>
                                      <span style="font-size: 10px; display: block; color: #7D7D7D">{{item.dp}}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-2 pl-0 pt-0 pr-0">
                                    <img *ngIf="item.dp" src="./../../../assets/delplan.svg"/>
                                  </div>
                                </div>
                              </button>
                            </ng-container>
                          </mat-menu>
                        </div>
                        <p *ngIf="dpEdit" style="font-size: 15px;">{{deliveryPlanData.van_name}}</p>
                      </div>
                    </div>
                    <div class="col-md-7 pl-0 pr-30">
                    </div>
                    <div class="col-md-1 pl-0 pr-0">
                      <button
                        mat-button
                        class="btn confirm-blue-btn"
                        style="float: right;"
                        (click)="updateDeliveryPlan()"
                        form="ngForm"
                        [disabled]="deliveryplanform.form.invalid"
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                  <div class="col-md-12 pad0" style="margin-top: 5px;padding: 10px;">
                    <div class="col-md-2 pl-0 pr-30">
                      <label style="color: #5D5D5D">Driver Name</label>
                      <div style="margin-top: 5px;">
                        <div>
                          <input
                            class="forminput"
                            type="text"
                            name="del_person"
                            [(ngModel)]="deliveryPlanData.del_person"
                            #del_person="ngModel"
                            style="width: 100%;"
                            required
                          >
                          <div *ngIf="del_person.errors && (del_person.dirty || del_person.touched)">
                            <span *ngIf="del_person.errors.required" class="text-danger">Drive name is required</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 pl-0 pr-30">
                      <label style="color: #5D5D5D">Driver Contact</label>
                      <div style="margin-top: 5px;">
                        <div>
                          <input
                            class="forminput"
                            type="text"
                            name="del_contact"
                            [(ngModel)]="deliveryPlanData.del_contact"
                            #del_contact="ngModel"
                            style="width: 100%;"
                            required
                          >
                          <div *ngIf="del_contact.errors && (del_contact.dirty || del_contact.touched)">
                            <span *ngIf="del_contact.errors.required"
                                  class="text-danger">Contact info is required</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 pl-0 pr-30">
                      <label class="detaillabel">Vehicle Type</label>
                      <div style="margin-top: 5px;" class="formselect">
                        <select
                          name="vehicle_type"
                          [(ngModel)]="deliveryPlanData.vehicle_type"
                          #vehicle_type="ngModel"
                        >
                          <option value="tempo" selected>Tempo Truck</option>
                          <option value="van">Auto</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2 pl-0 pr-30">
                      <label style="color: #5D5D5D">Vehicle Number</label>
                      <div style="margin-top: 5px;">
                        <div style="margin-top: 5px;">
                          <div>
                            <input
                              class="forminput"
                              type="text"
                              name="vehicle"
                              [(ngModel)]="deliveryPlanData.vehicle"
                              #vehicle="ngModel"
                              style="width: 100%;"
                              required
                            >
                            <div *ngIf="vehicle.errors && (vehicle.dirty || vehicle.touched)">
                               <span *ngIf="vehicle.errors.required"
                                     class="text-danger">Vehicle Number is required</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--<div class="col-md-2 pl-0 pr-30">
                      <label style="color: #5D5D5D">LR Number</label>
                      <div style="margin-top: 5px;">
                        <div style="margin-top: 5px;">
                          <div>
                            <input
                              class="forminput"
                              type="text"
                              name="lr"
                              [(ngModel)]="deliveryPlanData.lr"
                              #lr="ngModel"
                              style="width: 100%;"
                              required
                            >
                            <div *ngIf="lr.errors && (vehicle.dirty || lr.touched)">
                               <span *ngIf="lr.errors.required"
                                     class="text-danger">LR is required</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>-->
                    <div class="col-md-2 pl-0 pr-30"></div>
                  </div>
                </div>

                <div *ngIf="deliveryPlanUpdated" class="col-md-12 pad0"
                     style="margin-top: 5px;background: white;padding: 10px;">
                  <div class="col-md-2 pl-0 pr-30">
                    <label class="detaillabel">Delivery Plan</label>
                    <div style="margin-top: 5px;">
                      <p class="detailvalue1" style="font-weight: 500;font-size: 15px;">{{deliveryPlanData.name}}</p>
                      <p class="detailvalue1">ID: {{deliveryPlanData.id}}</p>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-30">
                    <label class="detaillabel">Date</label>
                    <div style="margin-top: 5px;">
                      <p class="detailvalue1">{{deliveryPlanData.date}}</p>
                      <p class="detailvalue1">{{deliveryPlanData.van_name}}</p>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-30">
                    <label class="detaillabel">Driver info</label>
                    <div style="margin-top: 5px;">
                      <p class="detailvalue1">{{deliveryPlanData.del_person}}</p>
                      <p class="detailvalue1">{{deliveryPlanData.del_contact}}</p>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-30">
                    <label class="detaillabel">Vehicle info</label>
                    <div style="margin-top: 5px;">
                      <p class="detailvalue1">{{deliveryPlanData.vehicle}}</p>
                      <p class="detailvalue1">Tempo truck</p>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-30">
                    <label class="detaillabel">Orders</label>
                    <div style="margin-top: 5px;">
                      <p class="detailvalue1">{{grandtotal.toFixed(2)}} value</p>
                      <p class="detailvalue1">{{ordercount}} Orders</p>
                    </div>
                  </div>
                  <div class="col-md-1 pl-0 pr-30">
                    <label class="detaillabel">Weight | Quantity</label>
                    <div style="margin-top: 5px;">
                      <p class="detailvalue1">{{weight}}</p>
                      <p class="detailvalue1">{{quantity}}</p>
                    </div>
                  </div>
                  <div class="col-md-1 pl-0 pr-0">
                    <button
                      mat-button
                      class="btn grey-btn"
                      style="float: right;"
                      (click)="editDeliveryPlan()"
                      form="ngForm"
                    >
                      EDIT
                    </button>
                  </div>
                </div>

                <div *ngIf="deliveryPlanData.id && !noOrdersToTransfer" class="col-md-12 pad0" style="margin-top: 5px;padding: 10px;">
                  <span style="color: #F6A82C;">You have selected a delivery plan that currently includes {{ordercount}} order(s). By selecting this plan, you are adding {{validOrders}} more order(s).</span>
                </div>
              </div>
            </div>
            <div class="col-md-12 cardbox mt-4">
                <div class="search-list">
                  <div class="col-md-6 col-12 nopadding pl-0" style="display: inline-block">
                    <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
                      <mat-tab label="Orders">
                      </mat-tab>
                      <mat-tab label="Picklist">
                      </mat-tab>
                    </mat-tab-group>
                  </div>
                  <div class="col-md-6 col-12 pad0 text-right">
                    <div class="filters">
                      <ul class="list-unstyled">
                        <li class="margLR" style="margin-left: 10px;">
                          <div class="search" style="display: inline-block;">
                            <span class="search-control"><img src="assets/searchicon.png"/></span>
                            <input id="filter-text-box1" type="text" placeholder="filter" (input)="quickSearch()"
                                   class="search-control-area"
                                   placeholder="Search">
                          </div>
                        </li>
                        <li class="margLR" style="margin-left: 10px;" *ngIf="activetab==='Picklist'">
                          <app-excelicon [gridOption]="gridOptions" [title]="'Product Report'"></app-excelicon>
                        </li>
                        <li class="margLR" style="margin-left: 10px;" *ngIf="activetab==='Orders'">
                          <app-excelicon [gridOption]="grid2Options" [title]="'Orders Report'"></app-excelicon>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div *ngIf="activetab==='Orders'" class="col-md-12 col-12 nopadding pl-0" style="margin-top: 10px;">
                  <div class="col-md-12 pad0">
                    <ag-grid-angular id="myGrid1" style="width: 100%; height: 40vh" *ngIf="dataLoaded"
                                     class="ag-theme-balham"
                                     [columnDefs]="columnDefs"
                                     [gridOptions]="gridOptions"
                                     (gridReady)="onGridReady($event)"
                                     [tooltipShowDelay]="tooltipShowDelay"
                                     [tooltipHideDelay]="tooltipHideDelay"
                                     (filterChanged)="onFilterChanged($event)"
                                     [frameworkComponents]="frameworkComponents"
                                     [getRowId]="getRowId"
                                     [context]="context"
                    >
                    </ag-grid-angular>
                  </div>
                </div>
                <div *ngIf="activetab==='Picklist'" class="col-md-12 col-12 nopadding pl-0" style="margin-top: 10px;">
                  <div class="col-md-12 pad0">
                    <ag-grid-angular id="myGrid2" style="width: 100%; height: 40vh" *ngIf="dataLoaded"
                                     class="ag-theme-balham"
                                     [masterDetail]="true"
                                     [columnDefs]="grid2columnDefs" [gridOptions]="grid2Options"
                                     (gridReady)="onGrid2Ready($event)"
                                     [context]="grid2Context">
                    </ag-grid-angular>
                  </div>
                </div>
                <div class="button-row" style="padding-top: 10px;display: block; background: white;">
                  <div *ngIf="!noOrdersToTransfer" style="float: left;margin-left: 15px;margin-top: 12px;">
                    <button
                      style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
                      class="btn confirm-blue-btn"
                      (click)="onTransfer()"

                      [disabled]="!deliveryPlanUpdated"
                    >
                      TRANSFER TO VAN
                    </button>
                    <span style=" vertical-align:bottom;margin-top: 20px;" (click)="closeDialog()">
                       <a class="btn grey-btn">CLOSE</a>
                     </span>
                  </div>
                  <div *ngIf="noOrdersToTransfer" style="float: left;margin-left: 15px;margin-top: 12px;">
                    <button
                      *ngIf="deliveryPlanData.id"
                      style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
                      mat-button
                      class="icon-btn-border blue-btn"
                      (click)="goToDeliveryPlan()"
                    ><i class="fas fa-print"></i>
                      Go To Delivery Plan
                    </button>
                    <span style=" vertical-align:bottom;margin-top: 20px;" (click)="closeDialog()">
                     <a class="btn grey-btn">CLOSE</a>
                   </span>
                  </div>
                </div>
              </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</section>

<div #PrintingModal class="pol-modal pol-send invoice modal" id="PrintingModal" role="dialog">
  <div class="modal-dialog m1">
    <!-- Modal content-->
    <div class="modal-content m2">
      <div>
        <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      </div>
      <div class="m3">

        <div id="print-section-sales1">
          <div   *ngFor="let order_detail of all_orders">
            <app-picklist-print *ngIf="printType === 'Picklist'" [order_detail]="order_detail"></app-picklist-print>
            <app-print *ngIf="printType === 'Invoice'" [order_detail]="order_detail"></app-print>
          </div>
        </div>
      </div>

      <div style="margin-top:1%;padding-bottom: 3%;text-align: center;">
        <button [useExistingCss]="true"
                printSectionId="print-section-sale1"
                ngxPrint >PRINT</button>
        <button
          #autoprint
          [hidden]="true"
          printSectionId="print-section-sales1"
          ngxPrint>Print</button>
      </div>
    </div>
  </div>
</div>
<button #interPrint id="openModalButton1" [hidden]="true" data-toggle="modal" data-target="#PrintingModal"></button>
