import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ApiService} from '../../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {ApproveRejectCommentsPopupComponent} from "../approve_reject_comments_popup/approve_reject_comments_popup.component";

@Component({
  selector: 'app-grn-popup',
  templateUrl: './grn_popup.component.html',
  styleUrls: ['./grn_popup.component.scss']

})

export class GRNPopupComponent implements OnInit {
  @Output() grnverified: EventEmitter<any> = new EventEmitter<any>();
  data;
  photo = null;
  store_fname;
  container;
  name;
  type;
  API_URL = '';
  date_delivered;
  grn_no;
  rejectComments;
  showApprove = false;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              protected datePipe: DatePipe,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.data = dialogdata.data;
    console.log(this.data);
    this.container = 'sale_order';
    this.grn_no = this.data['GRN'];
    this.store_fname = this.data?.PHOTO?.image;
    if (this.data.state !== 'cancel') {
      this.showApprove = !this.data?.config_json?.grn?.approved;
    }
  }

  ngOnInit(): void {
    this.fetchImage();
    /*this.showApprove = true;
    this.photo='abc';*/
  }

  fetchImage() {
    this.API_URL = '/api/pwa_connects/fetch_file';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.store_fname = this.store_fname;
    paramObject.container = this.container;
    console.log(paramObject);
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res?.result?.status && res?.result?.status === 200 && res?.result?.data) {
            this.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'].data);
          } else {
            this.showApprove = false;
          }
        },
        error => {
          console.log(error);
        });
  }

  verifyGRN() {
    this.API_URL = '/api/sale_orders/verify_grn';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = this.data.ID;
    paramObject.grn = this.grn_no;
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            this.toastr.success("GRN verified succcessfully");
            this.grnverified.emit(true);
          } else {
            this.toastr.error("Failed to verify GRN");
          }
          this.closeDialog();
        },
        error => {
          console.log(error);
        });
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
