<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Change Password</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div>
        <div>
          <span class="detaillabel2">Enter Old Password</span>
          <span class="requiredspan"> *</span>
          <input
            class="forminputwhitebg"
            style="display: block; margin-top: 5px;width: 100%;"
            type="password"
            name="old_pass"
            [(ngModel)]="old_password"
            #old_pass="ngModel"
            autocomplete="new-password"
            required
          >
          <div *ngIf="old_pass.errors && (old_pass.dirty || old_pass.touched)">
            <span *ngIf="old_pass.errors.required" class="text-danger">Old Password is required</span>
          </div>
        </div>
        <div style="margin-top: 20px;">
          <span class="detaillabel2">Enter New Password</span>
          <span class="requiredspan"> *</span>
          <input
            class="forminputwhitebg"
            style="display: block; margin-top: 5px;width: 100%;"
            type="password"
            name="new_pass"
            [(ngModel)]="new_password"
            #new_pass="ngModel"
            autocomplete="new-password"
            required
          >
          <div *ngIf="new_pass.errors && (new_pass.dirty || new_pass.touched)">
            <span *ngIf="new_pass.errors.required" class="text-danger">New Password is required</span>
          </div>
          <div *ngIf="old_password && new_password && old_password === new_password">
            <span class="text-danger">Old Password and New Password are the same</span>
          </div>
        </div>
        <div style="margin-top: 20px;">
          <span class="detaillabel2">Re-Enter New Password</span>
          <span class="requiredspan"> *</span>
          <input
            class="forminputwhitebg"
            style="display: block; margin-top: 5px;width: 100%;"
            type="password"
            name="re_pass"
            [(ngModel)]="rentered_password"
            #re_pass="ngModel"
            autocomplete="new-password"
            required
          >
          <div *ngIf="re_pass.errors && (re_pass.dirty || re_pass.touched)">
            <span *ngIf="re_pass.errors.required" class="text-danger">Re-entered Password is required</span>
          </div>
          <div *ngIf="new_password && rentered_password && new_password !== rentered_password">
            <span class="text-danger">Re-entered Password does not match</span>
          </div>
        </div>
      </div>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="btn confirm-blue-btn"
        (click)="updatePassword()"
        [disabled]="!old_password || !new_password || (old_password === new_password) || (new_password !== rentered_password)"
      >
        Confirm
      </button>
    </div>
  </div>
</section>
