<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div>
        <app-page-loader [show]="!pageReady"></app-page-loader>
      </div>
      <div style="margin-right: 10px;">
        <div class="col-md-12 cardbox" style="width: 100%;">
          <div>
            <div class="search-list" style="margin-top: 0px;">
              <div class="row" style="height: 33px;">
                <div *ngIf="!editMode" class="button-row">
                  <button
                    class="btn confirm-blue-btn"
                    (click)="editClick()"
                  >EDIT
                  </button>
                </div>
                <div *ngIf="editMode" class="button-row">
                  <button
                    style="margin-left:0px !important;"
                    class="btn grey-btn"
                    (click)="discard()"
                  >
                    DISCARD
                  </button>
                </div>
                <div *ngIf="editMode" class="button-row" style="margin-left: 10px;">
                  <button
                    style="margin-right:15px;"
                    class="btn confirm-blue-btn"
                    (click)="saveDeliveryPlan()"
                    form="ngForm"
                  >
                    SAVE
                  </button>
                </div>
                <div *ngIf="!newMode && !editMode && listLength > 0" style="position: absolute;right: 40px;">
                  <div class="button-row">
                    <span (click)="movePrevious()"><i
                      [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                      class="fal fa-arrow-circle-left fa-2x"></i></span>
                    <span
                      style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{ rowIndex + 1 }}
                      /{{ listLength }}</span>
                    <span (click)="moveNext()"><i
                      [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                      class="fal fa-arrow-circle-right fa-2x"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 40px;">
            <div class="col-md-12 pad0">
              <div class="col-md-10 pl-0 pr-30">
                <form novalidate id="ngForm" #dpform="ngForm">
                  <div class="col-md-12 pl-0" style="margin-top: 15px;">
                    <div class="col-md-3 pl-0 pr-30">
                      <label class="detaillabel">Plan Name</label>
                      <div style="margin-top: 5px;">
                        <!-- <div *ngIf="edit">
                           <input
                             class="forminput"
                             style="width: 70%;"
                             type="text"
                             name="name"
                             [(ngModel)]="deliveryPlanData.name"
                             #name="ngModel"
                             required
                           >
                           <div *ngIf="name.errors && (name.dirty || name.touched)">
                             <span *ngIf="name.errors.required" class="text-danger">Plan name is required</span>
                           </div>
                         </div>-->
                        <p class="detailvalue1">{{ activeObject.name }}</p>
                      </div>
                    </div>
                    <div class="col-md-3 pl-0 pr-30">
                      <label class="detaillabel">Delivered By</label>
                      <div style="margin-top: 5px;">
                        <!-- <div *ngIf="edit">
                           <input
                             class="forminput"
                             style="width: 70%;"
                             type="text"
                             name="del_person"
                             [(ngModel)]="deliveryPlanData.del_person"
                             #del_person="ngModel"
                             required
                           >
                           <div *ngIf="del_person.errors && (del_person.dirty || del_person.touched)">
                             <span *ngIf="del_person.errors.required" class="text-danger">Delivered By is required</span>
                           </div>
                         </div>-->
                        <p class="detailvalue1">{{ activeObject.van_name }}</p>
                      </div>
                    </div>
                    <div class="col-md-2 pl-0 pr-20">
                      <label class="detaillabel">Delivery Date</label>
                      <div style="margin-top: 5px;">
                       <!-- <div *ngIf="editMode">
                          <input
                            class="forminput"
                            type="text"
                            name="date"
                            [(ngModel)]="activeObject.date"
                            #date="ngModel"
                            [owlDateTime]="dt1"
                            [owlDateTimeTrigger]="dt1"
                            required
                          >
                          <owl-date-time (afterPickerClosed)="setStartDate()" [pickerType]="'calendar'"
                                         #dt1></owl-date-time>
                          <div *ngIf="date.errors && (date.dirty || date.touched)">
                            <span *ngIf="date.errors.required" class="text-danger">Delivery Date is required</span>
                          </div>
                        </div>-->
                        <p class="detailvalue1">{{ activeObject.date }}</p>
                      </div>
                    </div>
                    <div class="col-md-2 pl-0 pr-30">
                      <label class="detaillabel">Driver Name</label>
                      <div style="margin-top: 5px;">
                        <div *ngIf="editMode">
                          <input
                            class="forminput"
                            style="width: 70%;"
                            type="text"
                            name="del_person"
                            [(ngModel)]="activeObject.del_person"
                            #del_person="ngModel"
                            required
                          >
                          <div *ngIf="del_person.errors && (del_person.dirty || del_person.touched)">
                              <span *ngIf="del_person.errors.required"
                                    class="text-danger">Driver Name is required</span>
                          </div>
                        </div>
                        <p *ngIf="!editMode" class="detailvalue1">{{ activeObject.del_person }}</p>
                      </div>
                    </div>
                    <div class="col-md-2 pl-0 pr-30">
                      <label class="detaillabel">Driver Contact</label>
                      <div style="margin-top: 5px;">
                        <div *ngIf="editMode">
                          <input
                            class="forminput"
                            style="width: 70%;"
                            type="text"
                            name="del_contact"
                            [(ngModel)]="activeObject.del_contact"
                            #del_contact="ngModel"
                            required
                          >
                          <div *ngIf="del_contact.errors && (del_contact.dirty || del_contact.touched)">
                              <span *ngIf="del_contact.errors.required"
                                    class="text-danger">Driver Contact is required</span>
                          </div>
                        </div>
                        <p *ngIf="!editMode" class="detailvalue1">{{ activeObject.del_contact }}</p>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-12 pl-0" style="margin-top: 15px;">
                    <div class="col-md-3 pl-0 pr-30">
                      <label class="detaillabel">Vehicle Number</label>
                      <div style="margin-top: 5px;">
                        <div *ngIf="editMode">
                          <input
                            class="forminput"
                            style="width: 70%;"
                            type="text"
                            name="vehicle"
                            [(ngModel)]="activeObject.vehicle"
                            #vehicle="ngModel"
                            required
                          >
                          <div *ngIf="vehicle.errors && (vehicle.dirty || vehicle.touched)">
                              <span *ngIf="vehicle.errors.required"
                                    class="text-danger">Vehicle Number is required</span>
                          </div>
                        </div>
                        <p *ngIf="!editMode" class="detailvalue1"> {{ activeObject.vehicle }}</p>
                      </div>
                    </div>
                    <div class="col-md-3 pl-0 pr-30">

                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-2 pl-0 pr-30">
                <div class="roundedcard">
                  <div style="padding: 10px;">
                    <div style="margin-bottom: 25px;">
                      <div>
                        <label style="float: left">Order Details</label>
                        <label *ngIf="editMode" (click)="openAddOrderPopup()"
                               style="color: #17A2B8; font-weight: 500; float: right; cursor: pointer">Add
                          Orders</label>
                      </div>
                    </div>
                    <div style="background-color: #F4F6FC;padding: 10px;margin-top: 10px;">
                      <div class="row">
                        <label style="color: #2D2D2D">Orders Selected : </label>
                        <label
                          style="color: #183153; font-weight: 500; padding-left: 3px;"> {{ ordercount }}</label>
                      </div>
                      <div class="row">
                        <label style="color: #2D2D2D">Grand Total : </label>
                        <label
                          style="color: #183153; font-weight: 500; padding-left: 3px;"> {{ grandtotal.toFixed(2) }}</label>
                      </div>
                      <div class="row">
                        <label style="color: #2D2D2D">Weight : </label>
                        <label
                          style="color: #183153; font-weight: 500; padding-left: 3px;"> {{ (weight / 1000).toFixed(2) }}
                          Kg</label>
                      </div>
                      <div class="row">
                        <label style="color: #2D2D2D">Volume : </label>
                        <label
                          style="color: #183153; font-weight: 500; padding-left: 3px;"> {{ (volume / 1000).toFixed(2) }}
                          Ltr</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 cardbox mt-4">

          <div class="search-list">
            <div class="col-md-6 col-12 nopadding pl-0">
              <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
                <mat-tab label="Orders">
                </mat-tab>
                <mat-tab label="Picklist">
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="col-md-6 col-12 pad0 text-right">
              <div class="filters">
                <ul class="list-unstyled">
                  <li class="margLR" style="margin-left: 10px;">
                    <div class="search" style="display: inline-block;">
                      <span class="search-control"><img src="/assets/searchicon.png"/></span>
                      <input id="filter-text-box" type="text" (input)="quickSearch()"
                             class="search-control-area"
                             placeholder="Search">
                    </div>
                  </li>
                  <li class="margLR" style="margin-left: 10px;" *ngIf="activetab==='Picklist'">
                    <app-excelicon [gridOption]="gridOptions" [title]="'Product Report'"></app-excelicon>
                  </li>
                  <li class="margLR" style="margin-left: 10px;" *ngIf="activetab==='Orders'">
                    <app-excelicon [gridOption]="gridOptions1" [title]="'Order Report'"></app-excelicon>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div *ngIf="activetab==='Orders'" class="col-md-12 col-12 nopadding pl-0" style="margin-top: 10px;">
            <ag-grid-angular *ngIf="dataLoaded1" id="myGrid2" style="width: 100%;height:calc(58vh - 160px);" class="ag-theme-balham"
                             [masterDetail]="true"
                             [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"
                             (gridReady)="onGridReady1($event)" [frameworkComponents]="frameworkComponents1"
                             (cellClicked)="onCellClicked($event)"
                             [context]="context1">
            </ag-grid-angular>
          </div>
          <div *ngIf="activetab==='Picklist'" class="col-md-12 col-12 nopadding pl-0" style="margin-top: 10px;">
            <ag-grid-angular *ngIf="dataLoaded" id="myGrid1" style="width: 100%;height:calc(58vh - 160px);" class="ag-theme-balham"
                             [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                             [context]="context">
            </ag-grid-angular>
          </div>

          <div class="button-row" style="padding-top: 10px;display: block; background: white;">
            <div style="float: left;margin-left: 15px;margin-bottom: 12px;">
              <button
                style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
                mat-button
                class="icon-btn-border blue-btn"
                (click)="printOrders()"
              ><i class="fas fa-print"></i>
                PRINT Orders
              </button>
              <button
                style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
                mat-button
                class="icon-btn-border blue-btn"
                (click)="printPicklist()"
              ><i class="fas fa-print"></i>
                PRINT PICKLIST
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div #PrintingModal class="pol-modal pol-send invoice modal" id="PrintingModal" role="dialog">
  <div class="modal-dialog m1">
    <!-- Modal content-->
    <div class="modal-content m2">
      <div>
        <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      </div>
      <div class="m3">

        <div id="print-section-sales">
          <div class="invoice" *ngFor="let order_detail of all_orders">
            <app-picklist-print *ngIf="printType === 'Picklist'" [order_detail]="order_detail"></app-picklist-print>
            <app-print *ngIf="printType === 'Order'" [order_detail]="order_detail"></app-print>
          </div>
        </div>
      </div>

      <div style="margin-top:1%;padding-bottom: 3%;text-align: center;">
        <button [useExistingCss]="true"
                printSectionId="print-section-sales"
                ngxPrint>PRINT
        </button>
        <button
          #autoprint
          [hidden]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print
        </button>
      </div>
    </div>
  </div>
</div>
<button #interPrint id="openModalButton1" [hidden]="true" data-toggle="modal" data-target="#PrintingModal"></button>

