<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div>
        <app-page-loader [show]="!pageReady"></app-page-loader>
      </div>
      <div style="margin-right: 10px;">
        <div class="col-md-12 cardbox" style="width: 100%;">
          <div>
            <div class="search-list">
              <div class="row" style="height: 33px;">
                <div *ngIf="!editMode && activeObject['status'] === 'new'" class="button-row" style="margin-right: 10px;">
                  <button
                    class="btn confirm-blue-btn"
                    (click)="action_confirm_claim()"
                  >CONFIRM CLAIM
                  </button>
                </div>
                <div
                  *ngIf="!editMode && ((activeObject['status'] === 'confirmed' || activeObject['status'] === 'processing') && feature_claim_approve && show_approve_button)"
                  class="button-row">
                  <button
                    class="btnsmall confirm-green-btn"
                    (click)="openApproveRejectPopup('approve')"
                  >APPROVE
                  </button>
                </div>
                <div
                  *ngIf="!editMode && ((activeObject['status'] === 'confirmed' || activeObject['status'] === 'processing') && feature_claim_approve && show_approve_button)"
                  class="button-row" style="margin-left: 10px;">
                  <button
                    class="btnsmall reject-btn"
                    (click)="openApproveRejectPopup('reject')"
                  >REJECT
                  </button>
                </div>
                <div
                  *ngIf="!editMode && (activeObject['status'] === 'new' || activeObject['status'] === 'confirmed')"
                  class="button-row">
                  <button
                    class="btn grey-btn"
                    (click)="action_cancel()"
                  >CANCEL
                  </button>
                </div>


                <div *ngIf="!newMode && !editMode && listLength > 0 && rowIndex && rowIndex >= 0" style="position: absolute;right: 40px;">
                  <div class="button-row centeralign">
                    <div style="display: inline-block; margin-right: 20px; padding: 6px 20px;"
                         class="btnsmall light-blue-btn">
                          <span
                            style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span
                      style="color: #2D2D2D; text-transform: capitalize;">{{ activeObject['status'] }}</span>
                    </div>
                    <div style="display: inline-block; ">
                         <span (click)="movePrevious()"><i
                           [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                           class="fal fa-arrow-circle-left fa-2x"></i></span>
                      <span
                        style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{ rowIndex + 1 }}
                        /{{ listLength }}</span>
                      <span (click)="moveNext()"><i
                        [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                        class="fal fa-arrow-circle-right fa-2x"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-3 pad0" *ngIf="newMode">

          </div>
          <div>
            <div class="col-md-12 pt-3 pad0" *ngIf="!newMode" style="margin-top: 10px;">
              <div class="col-md-12 pt-3 pad0">
                <div class="col-md-2 pl-0 pr-30">
                  <label class="detaillabel2">Period</label>
                  <p class="detailvaluebold">{{ period }}</p>
                </div>

                <div class="col-md-2 pl-0 pr-30">
                  <label class="detaillabel2">Quantity</label>
                  <p class="detailvaluebold">{{ totalQuantity }}</p>
                </div>

                <div class="col-md-2 pl-0 pr-30">
                  <label class="detaillabel2">Invoices</label>
                  <p class="detailvaluebold">{{ totalInvoices }}</p>
                </div>

                <div class="col-md-2 pl-0 pr-30">
                  <label class="detaillabel2">No Of Parties</label>
                  <p class="detailvaluebold">{{ totalOutlets }}</p>
                </div>

                <div class="col-md-2 pl-0 pr-30">
                  <label class="detaillabel2">Expenses</label>
                  <p class="detailvaluebold">{{ totalExpenses }}</p>
                </div>

                <div class="col-md-2 pl-0 pr-30">
                  <label class="detaillabel2">Total</label>
                  <p class="detailvaluebold">{{ totalClaimAmount.toFixed(2) }}</p>
                </div>
              </div>
              <div class="col-md-12 pad0 mt-3">
                <div style="border: 1px solid #D7DDE6; border-radius: 5px;padding: 15px;background: #FFFFFF;">
                  <div class="row centeralign" style="cursor: pointer" (click)="detailsClick()">
                    <span style="color: #2D2D2D; font-size: 14px;font-weight: 600;">Approval Status</span>
                    <span
                      [ngClass]="{'success': claimStatus === 'approved', 'error': claimStatus === 'rejected', 'new': claimStatus !== 'approved' && claimStatus !== 'rejected'}"
                      class="ml-1 status2" style="margin-left: 20px !important;">{{ claimStatus }}</span>
                    <img *ngIf="!showApproverDetails" style="position: absolute; right: 30px;height: 8px;"
                         alt="Expand" src="../../../../assets/images/down_arrow_black.svg"/>
                    <img *ngIf="showApproverDetails" style="position: absolute; right: 30px;height: 8px;"
                         alt="Collapse" src="../../../../assets/images/up_arrow.svg"/>
                  </div>
                  <div class="mt-3" *ngIf="showApproverDetails">
                    <ng-container *ngFor="let item of approverDetails">
                      <div class="row centeralign mt-3">
                        <span class="detaillabel1 mr-2">Approved By</span>
                        <span class="detailvaluebold">{{ item.app_by }}</span>

                        <span class="greyindicator" style="margin-left: 30px;"></span>
                        <span class="detaillabel1 mr-2">Role</span>
                        <span class="detailvaluebold">{{ item.role_name }}</span>

                        <span class="greyindicator" style="margin-left: 30px;"></span>
                        <span class="detaillabel1 mr-2">Approved On</span>
                        <span class="detailvaluebold">{{ item.app_on }}</span>

                        <span class="greyindicator" style="margin-left: 30px;"></span>
                        <span class="detaillabel1 mr-2">Comments</span>
                        <span class="detailvaluebold">{{ item.comments }}</span>

                        <span [ngClass]="{'success': item.status === 'approved', 'error': item.status === 'rejected'}"
                              class="ml-1 status2" style="position: absolute; right: 30px;">{{ item.status }}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-12 cardbox mt-4">
          <div class="search-list">
            <div class="col-md-6 col-12 nopadding pl-0" style="display: inline-block">
              <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
                <mat-tab label="Summary">
                </mat-tab>
                <mat-tab label="Sale">
                </mat-tab>
                <mat-tab label="Sale Return">
                </mat-tab>
                <mat-tab label="Expense">
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="col-md-6 col-12 pad0 text-right">
              <div class="filters">
                <ul class="list-unstyled">
                  <li class="margLR" style="margin-left: 10px;">
                    <div class="search" style="display: inline-block;">
                      <span class="search-control"><img src="assets/searchicon.png" alt="Search"/></span>
                      <input id="filter-text-box1" type="text" (input)="quickSearch()"
                             class="search-control-area"
                             alt="Search"
                             placeholder="Search">
                    </div>
                  </li>
                  <li *ngIf="activetab==='Summary'" class="margLR" style="margin-left: 10px;">
                    <app-excelicon [gridOption]="summary_gridOptions" [title]="'Summary Report'"></app-excelicon>
                  </li>
                  <li *ngIf="activetab==='Sale'" class="margLR" style="margin-left: 10px;">
                    <app-excelicon [gridOption]="sale_gridOptions" [title]="'Sale Report'"></app-excelicon>
                  </li>
                  <li *ngIf="activetab==='Sale Return'" class="margLR" style="margin-left:5px;margin-right:5px">
                    <app-excelicon [gridOption]="return_gridOptions" [title]="'Sale Return Report'"></app-excelicon>
                  </li>
                  <li *ngIf="activetab==='Expense'" class="margLR" style="margin-left: 10px;">
                    <app-excelicon [gridOption]="return_gridOptions" [title]="'Expense Report'"></app-excelicon>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-12 pad0">
              <div *ngIf="activetab==='Summary'" style="margin-top: 10px;">
                <ag-grid-angular style="width: 100%; height:calc(70vh - 160px);" *ngIf="dataLoaded"
                                 id="myGrid2" class="ag-theme-alpine"
                                 [columnDefs]="summary_columnDefs"
                                 [gridOptions]="summary_gridOptions"
                                 (gridReady)="summaryOnGridReady($event)"
                                 [context]="sale_gridContext"
                >
                </ag-grid-angular>
              </div>
              <div *ngIf="activetab==='Sale'"  style="margin-top: 10px;">
                <ag-grid-angular style="width: 100%; height:calc(70vh - 160px);" *ngIf="dataLoaded"
                                 id="myGrid2" class="ag-theme-alpine"
                                 [columnDefs]="sale_columnDefs"
                                 [gridOptions]="sale_gridOptions"
                                 (gridReady)="saleOnGridReady($event)"
                                 (cellClicked)="saleOnCellClicked($event)"
                                 [getRowId]="getRowId"
                                 [context]="sale_gridContext"
                >
                </ag-grid-angular>
              </div>

              <div *ngIf="activetab==='Sale Return'"  style="margin-top: 10px;">
                <ag-grid-angular style="width: 100%; height:calc(70vh - 160px);" *ngIf="dataLoaded"
                                 id="myGrid2" class="ag-theme-alpine"
                                 [columnDefs]="return_columnDefs"
                                 [gridOptions]="return_gridOptions"
                                 (gridReady)="returnOnGridReady($event)"
                                 (cellClicked)="returnOnCellClicked($event)"
                                 [getRowId]="getRowId"
                                 [context]="return_gridContext"
                >
                </ag-grid-angular>
              </div>

              <div *ngIf="activetab==='Expense'" style="margin-top: 10px;">
                <ag-grid-angular style="width: 100%; height:calc(70vh - 160px);" *ngIf="dataLoaded"
                                 id="myGrid2" class="ag-theme-alpine"
                                 [columnDefs]="expense_columnDefs"
                                 [gridOptions]="expense_gridOptions"
                                 (gridReady)="expenseOnGridReady($event)"
                                 [getRowId]="getRowId"
                                 [context]="expense_gridContext"
                >
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

