import {Component, OnInit} from '@angular/core';
import {ReportBase} from "../_common/reportbase";
import {MatDialogConfig} from "@angular/material/dialog";
import {ImageGalleryPopupComponent} from "../../components/image_gallery_popup/image_gallery_popup.component";

@Component({
  selector: 'app-btl-activity-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})
export class BtlActivityReportComponent extends ReportBase implements IReport, OnInit {

  maxImages = 0;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.sharedService.setHeader('BTL Activity Report');
    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.API_URL = '/api/pwa_connects/btl_report';
    this.container = 'res_partner_gallery';
    this.loadServerData();
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [{
      headerName: 'Date', field: 'device_time', width: 100,
      valueGetter(params) {
        if (params.data) {
          if (params.data.device_time) {
            return inThis.datePipe.transform((new Date(params.data.device_time + ' UTC')).toString(), 'yyyy-MM-dd');
          } else {
            return params.data.device_time;
          }
        } else {
          return '';
        }
      }
    },
      {headerName: 'Supplier', field: 'dc', width: 100},
      {headerName: 'Salesman', field: 'van', width: 100},
      {headerName: 'Beat', field: 'beat', width: 100},
      {headerName: 'Outlet', field: 'outlet', width: 100},
      {headerName: 'Outlet Code', field: 'code', width: 100},
      {headerName: 'Outlet Type', field: 'outlet_type', width: 100},
      {headerName: 'Area', field: 'area', width: 100},
      {headerName: 'Activity Time', field: 'device_time', width: 100},
      {headerName: 'Activity Type', field: 'type', width: 100},
      {
        headerName: 'Image1', field: 'image1', width: 100,
        valueGetter: this.generateImageUrl('image1'),
        cellRenderer(params) {
          if (params.data) {
            if (params.data.image1 && params.data.image1 !== '') {
              return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
            } else {
              return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
            }
          }
        }
      }
    ];

    if (this.maxImages > 1) {
      for (let i = 1; i < this.maxImages; i++) {
        this.columnDefs.push({
          headerName: 'Image' + (i + 1), field: 'image' + (i + 1), width: 100,
          valueGetter: this.generateImageUrl('image' + (i + 1)),
          cellRenderer(params) {
            if (params.data) {
              if (params.data['image' + (i + 1)] && params.data['image' + (i + 1)] !== '') {
                return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
              } else {
                return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
              }
            }
          }
        });
      }
    }
    this.columnDefs.push( {headerName: 'Comments', field: 'comments', width: 100},
      {headerName: 'Deviation', field: 'deviation', width: 100});
  }

  postProcessData() {
    this.maxImages = 0;
    this.apiData.forEach((item) => {
      if (item.store_fname && item.store_fname.length > 0) {
        this.maxImages = Math.max(this.maxImages, item.store_fname.length);
        for (let i = 0; i < this.maxImages; i++) {
          item['image' + (i + 1)] = item.store_fname[i];
        }
      }
    });
    this.configureGrid();
    this.rowData = this.apiData;
  }

  onCellClicked(event) {
    if (event.data.store_fname && event.data.store_fname.length > 0) {
      this.showImagePopup(event.data);
    }
  }

  showImagePopup(row) {
    const gallery = [];
    if (row.store_fname && row.store_fname.length > 0) {
      row.store_fname.forEach(fname => {
        gallery.push({
          store_fname: fname,
          container: 'res_partner_gallery'
        });
      });
    }

    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          gallery
        }
      };

      const creatediaeref = this.dialog.open(ImageGalleryPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        data: {
          gallery
        }
      };

      const creatediaeref = this.dialog.open(ImageGalleryPopupComponent, config);
    }
  }
}
