import {Component, ElementRef, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {SharedService} from "../../../_services/shared_service";

@Component({
  selector: 'app-users-kpiscorecard-component',
  templateUrl: './users_kpi_score_card.component.html',
  styleUrls: ['./users_kpi_score_card.component.scss']
})
export class UserKPIScoreCardComponent implements OnInit {

  public collapse_disable;

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private elRef: ElementRef)
  {

  }


  ngOnInit(): void {
    this.sharedService.setHeader('Retail Audit Availability');

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == 'true' ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

}
