import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {DetailPageBase} from "../../../pagebase/DetailPageBase";
import {GetRowIdFunc, GetRowIdParams, GridOptions} from "@ag-grid-enterprise/all-modules";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {MatDialogConfig} from "@angular/material/dialog";
import {DeliveryPlanAddInvoicePopupComponent} from "../../../components/delivery_plan_add_invoice_popup/delivery_plan_add_invoice_popup.component";
import {ConfirmDialogComponent} from "../../../components/confirmdialog/confirmdialog.component";
import {CustomGridToolPanelComponent} from "../../../reports/_common/CustomGridToolPanel.component";

@Component({
  selector: 'app-delivery-plan-detail',
  templateUrl: 'deliveryplan-detail.html',
  styleUrls: ['../../../pagebase/pagebase_detail.scss']
})

export class DeliveryPlanDetailV1Component extends DetailPageBase implements IDetailPage, OnInit {
  @ViewChild('interPrint') public interPrint: ElementRef;

  public gridColumnApi1: any;
  public gridApi1: any;
  public pinnedParams1 = [];
  preloader1: any;
  dataLoaded1: any;
  gridOptions1: GridOptions;
  styleGrid1: any;
  columnDefs1 = [];
  frameworkComponents1: any;
  context1: any;
  rowData1 = [];

  invoicecount = 0;
  weight = 0.00;
  volume = 0.00;
  grandtotal = 0.0;
  userList = [];
  all_orders = [];
  printType = 'Invoice';
  activetab = 'Invoices';

  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/inv_delivery_plans/get_delivery_plan_detail";
    this.context = {componentParent: this};
    this.sharedService.setHeader('Delivery Plan');

    this.configureProductGrid();

    this.configureInvoiceGrid();

    this.readPageParms(params => {

      if (this.activeID > 0) {
        this.newMode = false;
        this.editMode = false;
        //   View Mode
        //   Page Can be ready only after detail Object is fetched
        this.loadPageForView(() => {
          this.pageReady = true;
        });
      } else {
        this.newMode = true;
        this.editMode = true;
        //   Page Can be ready only after detail Object is fetched
        //    Throw Error
      }
    });
  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  };


  configureProductData(productData) {
    if (productData !== null && productData !== undefined) {
      productData.sort((a, b) => a.category.localeCompare(b.category));
      const grouped = Object.values(productData.reduce((acc, {
        category,
        brand,
        product_code,
        product_name,
        uom,
        quantity,
        weight,
        volume,
        total
      }) => {
        acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom] =
          acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom] ||
          {category, brand, product_code, product_name, uom, quantity: 0, weight: 0.00, volume: 0.00, total: 0};
        acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom].quantity += parseInt(quantity, 0);
        acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom].weight = parseFloat(weight) * parseInt(quantity, 0);
        acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom].volume = parseFloat(volume) * parseInt(quantity, 0);
        acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom].total += parseFloat(total);
        this.weight += acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom].weight;
        this.volume += acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom].volume;
        return acc;
      }, {}));
      this.rowData = grouped;
    } else {
      this.rowData = productData;
    }
    //console.log(grouped);
  }

  prepareLineData(lineItems) {
    this.rowData1 = [];
    this.grandtotal = 0;
    const uq_inv_ids = [...new Set(lineItems.map(item => item.id))];
    if (uq_inv_ids && uq_inv_ids.length > 0) {
      for (const invoice_id of uq_inv_ids) {
        const invoice_lines = lineItems.filter(x => x.id === invoice_id);
        let total = 0.00;
        let qty = 0;
        const invoiceRow = {
          id: invoice_lines[0].id,
          inv_no: invoice_lines[0].inv_no,
          order_id: invoice_lines[0].order_id,
          cust_name: invoice_lines[0].cust_name,
          line_count: invoice_lines.length,
          total: 0.00,
          qty: 0,
          d_status: invoice_lines[0].d_status,
          lines: []
        };
        invoice_lines.forEach(invoice => {
          total += parseFloat(invoice.total);
          qty += parseInt(invoice.quantity, 10);
          const lineData = {
            inv_no: invoice.inv_no,
            product_name: invoice.product_name,
            product_code: invoice.product_code,
            category: invoice.category,
            brand: invoice.brand,
            quantity: invoice.quantity,
            volume: invoice.volume,
            weight: invoice.weight,
            total: invoice.total,
            price_unit: invoice.price_unit,
            uom: invoice.uom
          };
          invoiceRow.lines.push(lineData);
        });
        invoiceRow.total = total;
        invoiceRow.qty = qty;
        this.rowData1.push(invoiceRow);

        this.grandtotal += total;
      }
    }
  }

  loadPageForView(callback) {

    this.loadActiveObject(() => {

      this.prepareLineData(this.activeObject.invoices);
      this.invoicecount = this.activeObject.invoices.length;
      this.configureProductData(this.activeObject.invoices);

      this.dataLoaded1 = true;
      this.dataLoaded = true;

      // this.gridOptions1.api.setRowData(this.rowData1);
      // this.gridOptions.api.setRowData(this.rowData);

      this.dataLoaded1 = true;
    });

  }

  loadPage() {
    this.pageReady = false;
    this.dataLoaded = false;
    this.dataLoaded1 = false;
    this.loadPageForView(() => {
      this.pageReady = true;
    });
  }

  discard() {
    this.editMode = false;
    if (this.activeObject && (!this.activeObject.hasOwnProperty('id') || this.activeObject.id === 0)) {
      history.back();
    } else {
      this.loadPage();
    }
  }


  setStartDate() {
    this.activeObject.start_date = this.datePipe.transform(this.activeObject.start_date, 'yyyy-MM-dd');
  }


  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
  }

  openAddInvoicePopup() {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "70%",
        maxHeight: "80%",
        data: {
          dpData: this.activeObject
        },
        panelClass: 'custom-dialog-container'
      };
      creatediaeref = this.dialog.open(DeliveryPlanAddInvoicePopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "95%",
        maxHeight: "90%",
        data: {
          dpData: this.activeObject
        },
        panelClass: 'custom-dialog-container'
      };
      creatediaeref = this.dialog.open(DeliveryPlanAddInvoicePopupComponent, config);
    }
    creatediaeref.componentInstance.updatedDP.subscribe(data => {
      // this.activeObject = data;
      this.dialog.closeAll();
      this.loadPage();
    });
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();

    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    const noOfColumns = this.gridColumnApi1.getAllColumns().length;

    params.api.setRowData(this.rowData1);
    this.preloader1 = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi1.autoSizeAllColumns();
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi1.sizeColumnsToFit();
    } else {
      this.gridColumnApi1.autoSizeAllColumns();
    }

    this.styleGrid1 = this.elRef.nativeElement.querySelector('#myGrid2');
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi1.getSelectedRows();
    if (column === 'delete') {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "650px",
        maxHeight: "250px",
        data: {
          title: "Remove Invoice From Delivery Plan !!",
          message1: rows[0].inv_no,
          message2: "This invoice has " + rows[0].qty + " items with a total value of " + parseFloat(rows[0].total).toFixed(2),
          showNoButton: true,
          yesButton: "REMOVE",
          noButton: "CANCEL",
          deleteButton: true
        }
      };

      const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

      confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

        if (data === 'yes') {
          this.deleteInvoice(rows[0]);
        } else {

        }
      });
    } else {

    }
  }

  async editClick() {
    this.editMode = !this.editMode;
    if (this.gridApi1) {
      this.gridApi1.setRowData(this.rowData1);
    }
  }


  configureProductGrid() {
    this.columnDefs = [
      {headerName: "Category", field: "category", width: 80},
      {headerName: "Brand", field: "brand", width: 80},
      {headerName: "Code", field: "product_code", width: 80},
      {headerName: "Name", field: "product_name", width: 200},
      {headerName: "Quantity", field: "quantity", width: 80},
      {headerName: "UOM", field: "uom", width: 80},
      {
        headerName: "Value", field: "total", width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.total) {
              return params.data.total.toFixed(2);
            }
          }
        }
      },
      {
        headerName: "Delivered Qty", field: "quantity", width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.d_status && params.data.d_status === 'delivered') {
              return params.data.quantity;
            } else {
              return '';
            }
          } else {
            return '';
          }
        }
      },
    ];

    this.gridOptions1 = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      getRowHeight: params => {
        if (params.node && params.node.detail) {
          const offset = 40;
          const allDetailRowHeight =
            params.data.lines.length *
            params.api.getSizesForCurrentTheme().rowHeight;
          const gridSizes = params.api.getSizesForCurrentTheme();
          return (
            allDetailRowHeight +
            ((gridSizes && gridSizes.headerHeight) || 0) +
            offset
          );
        }
      },
      detailCellRendererParams: this.getDetailRendererParams(),
    } as GridOptions;
    this.frameworkComponents1 = {
      CustomToolPanel: CustomGridToolPanelComponent
    };
    this.context1 = {componentParent: this};
  }

  getDetailRendererParams() {
    return (params) => {
      const res: any = {};
      res.detailGridOptions = {
        suppressRowClickSelection: true,
        enableRangeSelection: false,
        pagination: false,
        rowHeight: 40,
        columnDefs: [
          {headerName: "Product code", field: "product_code", width: 80},
          {headerName: "Product name", field: "product_name", width: 200},
          {headerName: "Quantity", field: "quantity", width: 80},
          {headerName: "UOM", field: "uom", width: 80},
          {
            headerName: "Value", field: "total", width: 80,
            valueGetter(params1) {
              if (params1.data) {
                if (params1.data.total) {
                  return parseFloat(params1.data.total).toFixed(2);
                }
              }
            }
          }
        ],
        defaultColDef: {
          headerClass: 'myagheaderblue',
          filter: true,
          sortable: true,
          resizable: true,
          enableRowGroup: true,
          flex: 1,
        },
        context: {
          params
        },
        onGridReady: (params1) => {
          // using auto height to fit the height of the detail grid
          params1.api.setDomLayout('autoHeight');
        }
      };
      res.getDetailRowData = (params1) => {
        params1.successCallback(params1.data.lines);
      };

      return res;
    };
  }

  configureInvoiceGrid() {
    this.columnDefs1 = [
      {headerName: "Invoice number", field: "inv_no", width: 150, cellRenderer: 'agGroupCellRenderer'},
      {headerName: "Customer", field: "cust_name", width: 150},
      {headerName: "Products", field: "line_count", width: 100},
      {headerName: "Quantity", field: "qty", width: 100},
      {
        headerName: "Value", field: "total", width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.total) {
              return parseFloat(params.data.total).toFixed(2);
            }
          }
        }
      },
      {headerName: "Status", field: "d_status", width: 100},
      {
        headerName: "", headerClass: 'myagheader', field: "delete", cellStyle: {textAlign: 'center'}, sortable: true,
        filter: true, width: 50, resizable: true,
        cellRenderer: params => {
          if (params._self.edit) {
            return ' <span title="Remove Invoice" style="cursor: pointer" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          } else {
            return "";
          }
        }, cellRendererParams: {
          _self: this
        }
      }
    ];
  }

  saveDeliveryPlan() {
    // const paramObject: any = {};
    // paramObject.access_token = localStorage.getItem('resfreshToken');
    // paramObject.data = [];
    // paramObject.data.push(this.activeObject);
    // this.apiService.post('/api/inv_delivery_plans/save_delivery_planV2', paramObject)
    //   .subscribe(res => {
    //       console.log(res);
    //       if (res.hasOwnProperty('results') && (res.results.status === 200)) {
    //         this.toastr.success('Delivery Plan updated Successfully');
    //         this.dialog.closeAll();
    //         window.location.reload();
    //       } else if (res.hasOwnProperty('results')) {
    //         this.toastr.error(res.results.msg);
    //       } else {
    //         this.toastr.error("Delivery Plan updation Failed");
    //       }
    //     },
    //     error => {
    //       console.log(error);
    //       this.toastr.error("Delivery Plan updation Failed");
    //     });
    this._Detail_SAVE_URL = "/api/inv_delivery_plans/save_delivery_plan";
    this.saveDetailPage((err, result) => {
      if (err) {
        this.toastr.error(err);
      } else {

        this.editMode = false;
        this.loadPage();
      }
    });
  }

  quickSearch() {
    if (this.activetab === 'Picklist') {
      this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
    } else {
      this.gridApi1.setQuickFilter(document.getElementById('filter-text-box')['value']);
    }
  }

  printInvoices() {
    this.printType = 'Invoice';
    this.getPrintDetails();
   // this.appComponent.printAll(this.rowData1, 'id',  '/api/pwa_dms_connects/get_invoice_print_details', null);
  }

  printPicklist() {
    this.printType = 'Picklist';
    this.getPrintDetails();
  }

  getPrintDetails() {
    const invoice_ids = [];
    this.rowData1.forEach(row => {
      invoice_ids.push(row.id);
    });
    const order_data = {
      access_token: this.userContext.access_token,
      order_id: invoice_ids
    };
    this.apiService.post('/api/pwa_dms_connects/get_invoice_print_details', order_data)
      .subscribe(async res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          let orderdata = res.results.data;
          if (this.printType === 'Picklist') {
            const lines = this.preparePrintPicklistData(res.results.data);
            orderdata = orderdata[0];
            orderdata.sale_items = lines;
            orderdata.invoice_date = this.activeObject.date;
            orderdata.driver_name = this.activeObject.del_person;
            orderdata.driver_contact = this.activeObject.del_contact;
            orderdata = [orderdata];
            this.all_orders = orderdata;
          } else {
            await this.processPrintDetails(orderdata);
          }
          console.log("test");
          const el: HTMLElement = this.interPrint.nativeElement;
          el.click();
        }
      });
  }

  preparePrintPicklistData(ordersList) {
    const pickListData = [];
    const uqProducts = new Set();
    const lineData = [];
    ordersList.forEach(order => {
      if (order.sale_items) {
        order.sale_items.forEach(line => {
          lineData.push(line);
          uqProducts.add(line.product_id);
        });
      }
    });

    if (uqProducts && uqProducts.size > 0) {
      for (const product_id of uqProducts) {
        const lines = lineData.filter(x => x.product_id === product_id);
        if (lines && lines.length > 0) {
          const productData = {
            product_id: lines[0].product_id,
            product_name: lines[0].product_name,
            product_uom: lines[0].product_uom,
            hsn: lines[0].hsn,
            reporting_uom: "",
            unit_price: parseFloat(lines[0].unit_price),
            abs_qty: 0,
            amount_total: 0.00,
          };
          lines.forEach(line => {
            productData.abs_qty += parseInt(line.qty, 10);
            productData.amount_total += parseFloat(line.amount_total);
          });
          productData.amount_total = parseFloat(productData.amount_total.toFixed(this.apiService.decimalPolicy));
          if (lines[0].report_uom && lines[0].report_uom.length > 0) {
            let reportUoms = lines[0].report_uom;
            reportUoms = reportUoms.sort((a, b) => (a.factor_inv > b.factor_inv ? -1 : 1));
            let qty = productData.abs_qty;
            for (let i = 0; i < reportUoms.length; i++) {
              const chunks = qty / reportUoms[i].factor_inv;
              if (chunks >= 1) {
                productData['reporting_uom'] += parseInt(String(chunks), 10) + ' ' + reportUoms[i].name + '(s), ';
                qty = qty % reportUoms[i].factor_inv;
                if (qty === 0) {
                  break;
                }
              } else {
              }
            }
            if (productData['reporting_uom'] && productData['reporting_uom'] !== "") {
              productData['reporting_uom'] = productData['reporting_uom'].substring(0, productData['reporting_uom'].length - 2);
            }
          }
          pickListData.push(productData);
        }
      }
    }
    return pickListData;
  }

  async processPrintDetails(orderdata) {
    const inThis = this;
    let total_qty = 0;
    let total_discount = 0;
    let printDetails = {};
    const all_orders = [];
    for (const data of orderdata) {
      // res['results'].data.forEach(async data => {
      total_qty = 0;
      printDetails = data;
      const tax_breaks = [];
      const taxnotes = [];
      printDetails['label_config'] = {};
      const labels = JSON.parse(localStorage.getItem('custom_labels'));
      if (labels) {
        labels.forEach(lab => {
          printDetails['label_config'][lab.label] = lab.display;
        });
      } else {
        printDetails['label_config']['PrimaryCurrency'] = '';
        printDetails['label_config']['SecondaryCurrency'] = '';
        printDetails['label_config']['SupplierLabel'] = 'Supplier';
        printDetails['label_config']['TaxLabel'] = 'GST';
      }
      if (typeof printDetails['tax_notes'] === 'string') {
        printDetails['tax_notes'] = JSON.parse(printDetails['tax_notes']);
      }
      if (printDetails['tax_notes']) {
        Object.keys(printDetails['tax_notes']).forEach(each_tax => {
          tax_breaks.push({
            name: each_tax,
            tax: printDetails['tax_notes'][each_tax].toFixed(inThis.apiService.decimalPolicy)
          });
          taxnotes.push(each_tax);
        });
      }
      printDetails['tax_breakup'] = tax_breaks;
      if (printDetails['partner_id'] === printDetails['partner_shipping_id']) {
        printDetails['customer_skip'] = true;
      } else {
        printDetails['customer_skip'] = false;
      }
      printDetails['print_text'] = inThis.domSanitizer.bypassSecurityTrustHtml(printDetails['print_text']);
      let rate = 0;
      let spli;
      printDetails['sale_items'].forEach(sale_item => {
        sale_item['label'] = '[' + sale_item.type + ']';
        const breaks = [];
        sale_item['new_tax'] = 0;
        if (sale_item['tax_notes']) {
          sale_item['tax_notes'] = JSON.parse(sale_item['tax_notes']);
          if (typeof sale_item['tax_notes'] === 'string' && sale_item['tax_notes'] !== "") {
            sale_item['tax_notes'] = JSON.parse(sale_item['tax_notes']);
          }
        }
        if (sale_item['tax_notes'] && sale_item['tax_notes'].constructor === Object) {
          Object.keys(sale_item['tax_notes']).forEach(each_tax => {
            if (sale_item['tax_notes'][each_tax].includes('(')) {
              spli = sale_item['tax_notes'][each_tax].split('(');
              rate = spli[1].split('%')[0].split(')')[0];
              breaks.push({name: each_tax, tax: spli[0], rate});
            } else {
              if (sale_item.hasOwnProperty('tax')) {
                spli = sale_item['tax'].split('(');
                rate = spli[1].split('%')[0].split(')')[0];
              } else {
              }
              breaks.push({name: each_tax, tax: sale_item['tax_notes'][each_tax], rate});
            }

          });
        }
        sale_item['tax_notes'] = breaks;

        total_discount += parseFloat(sale_item.vps_dis);
        if (printDetails['d_status'] === 'delivered') {
          sale_item['abs_qty'] = Math.abs(sale_item['delivered_qty']);
          total_qty += parseInt(sale_item.delivered_qty, 0);
          sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.delivered_qty)) -
            (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
        } else {
          sale_item['abs_qty'] = Math.abs(sale_item['qty']);
          total_qty += parseInt(sale_item.qty, 0);
          sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.qty)) -
            (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
        }
        sale_item.unit_price = sale_item.unit_price.toFixed(inThis.apiService.decimalPolicy);
      });
      printDetails['total_qty'] = total_qty;
      printDetails['discount'] = total_discount.toFixed(inThis.apiService.decimalPolicy);
      if (printDetails['date_delivery']) {
        printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivery'] + ' UTC')).toString(), 'dd/MM/yyyy');
      } else if (printDetails['date_delivered'] != null) {
        printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      printDetails['order_date'] = inThis.datePipe.transform((new Date(printDetails['order_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      if (printDetails['date_delivered'] != null) {
        printDetails['date_delivered'] = inThis.datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      if (printDetails['logo']) {
        const resp = await inThis.apiService.fetchImageWait({
          access_token: inThis.userContext.access_token,
          data: [{store_fname: printDetails['logo'], container: "res_partner"}]
        });
        // res_data.subscribe(resp => {
        console.log(resp);
        if (resp['result'].length > 0) {
          printDetails['logo'] = inThis.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + resp['result'][0].bill_datas);
        }
        all_orders.push(printDetails);
      } else {
        all_orders.push(printDetails);
      }
      this.all_orders = all_orders;
      console.log(all_orders);
    }
  }

  deleteInvoice(row) {
    const context_data = {
      access_token: this.userContext.access_token,
      invoice_ids: [row.id],
      action: 'remove'

    };
    this.apiService.post('/api/inv_delivery_plans/update_invoice_with_delivery_plan', context_data)
      .subscribe(async res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.toastr.success('Successfully Removed Invoice');
          this.loadPage();
        } else {
          this.toastr.error('Failed To Remove Invoice');
        }
      });
  }

}
