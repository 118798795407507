<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Add Travel and Asset Cost</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div>
        <div *ngIf="newVendorRep">
          <div  class="mt-4">
            <span class="dialoglabel">Vendor Rep Name</span>
            <input
              class="forminputwhitebg"
              style="display: block; font-size: 14px; margin-top: 5px;width: 100%;"
              type="text"
              name="rep_name"
              [(ngModel)]="serviceExecutiveDetails.name"
              #rep_name="ngModel"
              placeholder="Enter vendor rep name"
              required
            >
            <div *ngIf="rep_name.errors && (rep_name.dirty || rep_name.touched)">
              <span *ngIf="rep_name.errors.required" class="text-danger">Vendor Rep Name is required</span>
            </div>
          </div>
          <div  class="mt-4">
            <span class="dialoglabel">Phone</span>
            <input
              class="forminputwhitebg"
              style="display: block; font-size: 14px; margin-top: 5px;width: 100%;"
              type="text"
              name="mobile"
              [(ngModel)]="serviceExecutiveDetails.mobile"
              #mobile="ngModel"
              placeholder="Enter phone number"
              required
            >
            <div *ngIf="mobile.errors && (mobile.dirty || mobile.touched)">
              <span *ngIf="mobile.errors.required" class="text-danger">Phone Number is required</span>
            </div>
          </div>
          <div  class="mt-4">
            <span class="dialoglabel">Email</span>
            <input
              class="forminputwhitebg"
              style="display: block;  font-size: 14px; margin-top: 5px;width: 100%;"
              type="text"
              name="email"
              [(ngModel)]="serviceExecutiveDetails.email"
              #email="ngModel"
              placeholder="Enter email"
              required
            >
            <div *ngIf="email.errors && (email.dirty || email.touched)">
              <span *ngIf="email.errors.required" class="text-danger">Email is required</span>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <span class="dialoglabel">Travel Cost</span>
          <input
            class="forminputwhitebg"
            style="display: block; color: #7D7D7D; font-size: 14px;margin-top: 5px;width: 100%;"
            type="number"
            name="narration"
            [(ngModel)]="serviceExecutiveDetails.t_cost"
            #narration="ngModel"
            placeholder="Enter Travel Cost"
          >
        </div>
        <div class="mt-4">
          <span class="dialoglabel">Installation Cost</span>
          <input
            class="forminputwhitebg"
            style="display: block; color: #7D7D7D; font-size: 14px;margin-top: 5px;width: 100%;"
            type="number"
            name="narration"
            [(ngModel)]="serviceExecutiveDetails.a_cost"
            #narration="ngModel"
            placeholder="Enter Asset Cost"
          >
        </div>
      </div>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn confirm-blue-btn"
        (click)="addTravelCost()"
      >
        OK
      </button>
    </div>
  </div>
</section>
