<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Add Expected Delivery Date</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
        <div class="date-picker-form">
          <input
                 [selectMode]="'single'"
                 [readonly]="true" [(ngModel)]="serviceExecutiveDetails.delivery_date"
                 [owlDateTimeTrigger]="dt"
                 [owlDateTime]="dt"
                 placeholder="Date"
                 name="Expected Delivery Date"
                 [required]="true"
                 style="margin-top: 5px;width: 100%;">
          <span class="datepicker-icon"><i class="far fa-calendar-alt"></i></span>
          <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
        </div>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn confirm-blue-btn"
        (click)="addExpectedDeliveryDate()"
        [disabled]="!serviceExecutiveDetails.delivery_date"
      >
        Save
      </button>
    </div>
  </div>
</section>
