<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div style="margin-right: 10px;">
        <div class="col-md-12 cardbox" style="width: 100%;">
          <div>
            <div class="search-list" style="margin-top: 0px;">
              <div class="row" style="height: 33px;">
                <div *ngIf="!edit" class="button-row">
                  <button
                    class="btn confirm-blue-btn"
                    (click)="editClick()"
                  >EDIT
                  </button>
                </div>
                <div *ngIf="edit" class="button-row" style="margin-left: 10px;">
                  <button
                    style="margin-left:0px !important;"
                    class="btn grey-btn"
                    (click)="discardClick()"
                  >
                    DISCARD
                  </button>
                </div>
                <div *ngIf="edit" class="button-row" style="margin-left: 10px;">
                  <button
                    class="btn confirm-blue-btn"
                    (click)="updateRetailAudit()"
                    form="ngForm">
                    SAVE
                  </button>
                </div>
                <div *ngIf="!edit && listLength > 0" style="position: absolute;right: 40px;">
                  <div class="button-row">
                  <span style="margin-right: 20px;">
                         <a class="btn teal-border-btn" style="margin-right:15px;"
                            (click)="viewReport()">VIEW REPORT</a>
                        </span>
                    <span (click)="movePrevious()"><i
                      [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                      class="fal fa-arrow-circle-left fa-2x"></i></span>
                    <span
                      style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{ rowIndex + 1 }}
                      /{{ listLength }}</span>
                    <span (click)="moveNext()"><i
                      [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                      class="fal fa-arrow-circle-right fa-2x"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div style="margin-top: 40px;">
              <div class="col-md-12 pad0">
                <form novalidate id="ngForm" #taskform="ngForm">
                  <div class="col-md-2 pl-0 pr-20">
                    <label class="detaillabel">Code</label>
                    <div>
                      <div *ngIf="edit">
                        <input
                          hidden=true
                          class="forminput"
                          style="width: 70%;"
                          type="text"
                          name="code"
                          [(ngModel)]="retailAuditDetails.code"
                          #code="ngModel"
                          required
                        >
                        <div *ngIf="code.errors && (code.dirty || code.touched)">
                          <span *ngIf="code.errors.required" class="text-danger">Retail Audit Code is required</span>
                        </div>
                      </div>
                      <p class="detailvalue1">{{ retailAuditDetails.code }}</p>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-20">
                    <label class="detaillabel">Type</label>
                    <div class="formselect">
                      <select
                        hidden=true
                        name="task_type"
                        [(ngModel)]="retailAuditDetails.type"
                        #task_type="ngModel"
                        style="width: 80%;">
                        <option value="Activation" selected>Activation</option>
                        <option value="BTL Activity">BTL Activity</option>
                        <option value="Scheme">Scheme</option>
                      </select>
                      <p class="detailvalue1">{{ retailAuditDetails.type }}</p>
                    </div>
                  </div>
                  <div class="col-md-4 pl-0 pr-30">
                    <label class="detaillabel">Audit Name</label>
                    <div>
                      <div *ngIf="edit">
                        <input
                          hidden=true
                          class="forminput"
                          style="width: 70%;"
                          type="text"
                          name="taskname"
                          [(ngModel)]="retailAuditDetails.name"
                          #taskname="ngModel"
                          required
                        >
                        <div *ngIf="taskname.errors && (taskname.dirty || taskname.touched)">
                          <span *ngIf="taskname.errors.required"
                                class="text-danger">Retail Audit name is required</span>
                        </div>
                      </div>
                      <p class="detailvalue1">{{ retailAuditDetails.name }}</p>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-20">
                    <label class="detaillabel">Description</label>
                    <div>
                      <div *ngIf="edit">
                        <input
                          hidden=true
                          class="forminput"
                          type="text"
                          name="taskdescription"
                          [(ngModel)]="retailAuditDetails.description"
                          #taskdescription="ngModel"
                          style="width: 80%;"
                          required
                        >
                        <div *ngIf="taskdescription.errors && (taskdescription.dirty || taskdescription.touched)">
                          <span *ngIf="taskdescription.errors.required" class="text-danger">Retail Audit description is required</span>
                        </div>
                      </div>
                      <p  class="detailvalue1" [innerHTML]="retailAuditDetails.description"></p>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0 pr-20">
                    <label class="detaillabel">Last Modified By</label>
                    <div>
                      <div *ngIf="edit">
                        <input
                          hidden=true
                          class="forminput"
                          type="text"
                          name="taskdescription"
                          [(ngModel)]="retailAuditDetails.last_modified"
                          #taskdescription="ngModel"
                          style="width: 80%;"
                          required
                        >
                        <div *ngIf="taskdescription.errors && (taskdescription.dirty || taskdescription.touched)">
                          <span *ngIf="taskdescription.errors.required"
                                class="text-danger">Updated by is required</span>
                        </div>
                      </div>
                      <p class="detailvalue1">{{ retailAuditDetails.last_modified }}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
        <div class="col-md-12 cardbox mt-4">
          <div class="search-list">
            <div class="col-md-6 col-12 nopadding pl-0">
              <!--
                                  <ul >
                                  <li >
                                    SKU List
                                  </li>
                                  <li >
                                    Territory
                                  </li>
                                  <li >
                                    Criteria
                                  </li>
                                </ul>-->

              <div>
                <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
                  <mat-tab label="SKU List">
                  </mat-tab>
                  <mat-tab label="Territory">
                  </mat-tab>
                  <mat-tab label="Criteria">
                  </mat-tab>
                </mat-tab-group>
              </div>

            </div>
            <div class="col-md-6 col-12 pad0 text-right">
              <div class="filters">
                <ul class="list-unstyled">
                  <li class="margLR" style="margin-left: 10px;">
                    <div style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;"
                         (click)="createDialog()" *ngIf="edit && activetab=='SKU List'">
                      <i class="fas fa-plus-circle"
                         style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                      <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                    </div>
                    <div class="search" style="display: inline-block;">
                      <span class="search-control"><img src="assets/searchicon.png"/></span>
                      <input id="filter-text-box" type="text" (input)="quickSearch()"
                             class="search-control-area"
                             placeholder="Search">
                    </div>
                    <div style="display: inline-block;margin-right: 10px;">
                    </div>
                  </li>
                  <li class="margLR" *ngIf="activetab==='SKU List'">
                    <app-excelicon [gridOption]="gridOptions" [title]="'SKU List Report'"></app-excelicon>
                  </li>
                  <li class="margLR" *ngIf="activetab==='Territory'">
                    <app-excelicon [gridOption]="gridOptions1" [title]="'Territory Report'"></app-excelicon>
                  </li>
                  <li class="margLR" *ngIf="activetab==='Criteria'">
                    <app-excelicon [gridOption]="gridOptions2" [title]="'Criteria Report'"></app-excelicon>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-12 pad0">
              <div *ngIf="activetab==='SKU List'" style="margin-top: 10px;">
                <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;height:calc(70vh - 160px);"
                                 class="ag-theme-balham" [defaultColDef]="defaultColDef"
                                 [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                 (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                                 (cellClicked)="onCellClicked($event)"
                                 [context]="context">
                </ag-grid-angular>
                <div *ngIf="preloader">
                  <app-loadingstate></app-loadingstate>
                </div>
                <div *ngIf="empty_state">
                  <app-emptystate></app-emptystate>
                </div>
              </div>

              <div *ngIf="activetab==='Territory'" style="margin-top: 10px;">
                <ag-grid-angular id="myGrid2" *ngIf="loaded1" style="width: 100%;height:calc(70vh - 160px);"
                                 class="ag-theme-balham" [defaultColDef]="defaultColDef"
                                 [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"
                                 (gridReady)="onGridReady1($event)" [frameworkComponents]="frameworkComponents"
                                 (cellClicked)="onCellClicked1($event)"
                                 [context]="context">
                </ag-grid-angular>
                <div *ngIf="preloader1">
                  <app-loadingstate></app-loadingstate>
                </div>
                <div *ngIf="empty_state1">
                  <app-emptystate></app-emptystate>
                </div>
              </div>

              <div *ngIf="activetab==='Criteria'" style="margin-top: 10px;">
                <ag-grid-angular id="myGrid3" *ngIf="loaded2" style="width: 100%;height:calc(70vh - 160px);"
                                 class="ag-theme-balham" [defaultColDef]="defaultColDef"
                                 [columnDefs]="columnDefs2" [gridOptions]="gridOptions2"
                                 (gridReady)="onGridReady2($event)" [frameworkComponents]="frameworkComponents"
                                 (cellClicked)="onCellClicked2($event)"
                                 [context]="context">
                </ag-grid-angular>
                <div *ngIf="preloader2">
                  <app-loadingstate></app-loadingstate>
                </div>
                <div *ngIf="empty_state2">
                  <app-emptystate></app-emptystate>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
