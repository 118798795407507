<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div>
        <div class="search-list row">
          <div class="row" style="margin-bottom: 15px;">
            <div class="greywhitebox row centeralign">
              <div class="menuIcon" style="display: inline-block;margin-left: 5px;">
                <div title="Dashboard" (click)="goToDashboard()">
                    <span style="cursor: pointer;">
                     <img style="height: 14px;" src="/assets/menu/dashboard.svg">
                    </span>
                </div>
              </div>
              <div class="menuIcon" style="display: inline-block;margin-left: 5px;">
                <div title="Reports" (click)="gotoReports()">
                    <span style="cursor: pointer;">
                     <img style="height: 14px;" src="/assets/menu/report.svg">
                    </span>
                </div>
              </div>
            </div>
            <div class="showhide">
              <span *ngIf="!showAll">
                  <a class="btn white-btn" (click)="showDetails(true)" style="margin-right:15px;background-color: #FFFFFF">SHOW ALL</a>
             </span>
              <span *ngIf="showAll">
                  <a class="btn white-btn" (click)="showDetails(false)" style="margin-right:15px;background-color: #FFFFFF">HIDE ALL</a>
             </span>
            </div>
          </div>
          <!--<div class="col-md-6 pad0">
            <img style="height: 14px;" src="/assets/menu/dashboard.svg">
            &lt;!&ndash;<div class="greywhitebox row centeralign">
              <div class="menuIcon" style="display: inline-block;margin-left: 5px;">
                <div title="Dashboard" (click)="goToDashboard()">
                    <span style="cursor: pointer;">
                     <img style="height: 14px;" src="/assets/menu/dashboard.svg">
                    </span>
                </div>
              </div>
              <div class="menuIcon" style="display: inline-block;margin-left: 5px;">
                <div title="Reports" (click)="gotoReports()">
                    <span style="cursor: pointer;">
                     <img style="height: 14px;" src="/assets/menu/report.svg">
                    </span>
                </div>
              </div>
            </div>&ndash;&gt;
          </div>
          <div class="col-md-6 pad0 text-right">
            <span *ngIf="!showAll">
                  <a class="btn white-btn" (click)="showDetails(true)" style="margin-right:15px;background-color: #FFFFFF">SHOW ALL</a>
             </span>
            <span *ngIf="showAll">
                  <a class="btn white-btn" (click)="showDetails(false)" style="margin-right:15px;background-color: #FFFFFF">HIDE ALL</a>
             </span>
          </div>-->
        </div>

        <div class="col-md-12 col-12 nopadding pl-0">
          <div *ngFor="let menu of menuList;">
            <div class="cardbox" style="border-radius: 10px; margin-bottom: 10px;cursor: pointer;">
              <div class="row centeralign" (click)="menuClick(menu)">
                <img class="menuicon" id="img_{{menu.name.toString().toLowerCase().replace(' ', '_')}}_sel"
                     [src]="getIcon(menu.icon)"/>
                <h3 style="margin-left: 30px;">{{ menu.name }}</h3>
                <span *ngIf="!menu.route && menu.show"
                      style="position: absolute;right: 30px;">
                  <img src="assets/up_arrow.svg" width="15px"/>
                </span>
                <span *ngIf="!menu.route && !menu.show"
                      style="position: absolute;right: 30px;">
                  <img src="assets/down_arrow.svg" width="15px"/>
                </span>
              </div>
              <div class="eod-list-carda" *ngIf="menu.show && menu.sub_menu">
                <div style="margin-top: 15px;">
                  <div *ngIf="menu.sub_menu" class="cardgrid">
                    <ng-container *ngFor="let submenu of menu.sub_menu; let i = index" class="col-md-3 eod-width">
                      <div class="cardbox" style="cursor: pointer;" (click)="goToRoute(submenu)">
                        <img class="submenuicon"
                             id="img_{{menu.name.toString().toLowerCase().replace(' ', '_')}}_{{submenu.name.toString().toLowerCase().replace(' ', '_')}}"
                             [src]="getsubMenuIcon(submenu.icon)"/>
                        <span
                          id="a_{{menu.name.toString().toLowerCase().replace(' ', '_')}}_{{submenu.name.toString().toLowerCase().replace(' ', '_')}}"
                          style="display: block;font-size: 1.6em;margin-top: 8px;"> {{ submenu.name }}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
