import {PageBase} from "../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {ErrorHandler} from "../../error/error-handler";
import {ErrortooltipComponent} from "../../errortooltip";
import {SupplierVerticalUploadComponent} from "./supplier_vertical_upload.component";


@Component({
  selector: 'app-supplier-vertical-list',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})

export class SupplierVerticalListComponent extends PageBase implements OnInit, IPage {
  customer_ids = [];

  ngOnInit() {
    this.API_URL = '/api/pwa_admin_connects/getSupplierVerticalList';
    this.sharedService.setHeader('Customer Supplier Vertical List')
    this.showDate = false;
    this.showUpload = true;
    this.checkAndLoadPreviousSate();

    this.frameworkComponents = {
      errortooltipComponent: ErrortooltipComponent
    };

    this.showProgress = true;
    this.loadInBatches(50000, 'csr_id');
    this.loadServerData();
    this.configureGrid();
  }

  configureGrid() {

    this.columnDefs = [
      {headerName: "Customer Code", field: "cust_code", width: 100, cellStyle: {textAlign: 'center'}},
      {headerName: "Customer Name", field: "cust_name", width: 250},
      {headerName: "Supplier Code", field: "supp_code", width: 100, cellStyle: {textAlign: 'center'}},
      {headerName: "Supplier Name", field: "supp_name", width: 250},
      {headerName: "Salesman Code", field: "salesman_code", width: 100, cellStyle: {textAlign: 'center'}},
      {headerName: "Salesman Name", field: "salesman_name", width: 250},
      {headerName: "Vertical Code", field: "vc", width: 100, cellStyle: {textAlign: 'center'}},
      {headerName: "Vertical", field: "vertical", width: 100}
    ]
  }


  showUploadPopup() {
    this.uploadSupplierVertical()
  }

  uploadSupplierVertical() {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "95vh",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
      };
      creatediaeref = this.dialog.open(SupplierVerticalUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
      };
      creatediaeref = this.dialog.open(SupplierVerticalUploadComponent, config);
    }
  }
}
