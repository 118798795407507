
<div class="common-wrapper">
        <div class="common-container">
          <div class="main-wrapper dcvanlist">
            <div class="search-list">
              <div class="col-md-6 pad0">
                  <div class="search" style="display: inline-block;">
                    <span class="search-control"><img src="assets/searchicon.png"/></span>
                    <input type="text" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                    placeholder="Search">
                  </div>
                <div style="display: inline-block; margin-top:10px; float: right; font-size: 182%;">{{van_name}}</div>
              </div>
              <div class="col-md-6 pad0">
                <div class="filters">
                  <ul class="list-unstyled">
                    <li class="margLR">
                        <div class="date-picker">
                            <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                            <input [min]="min" [max]="max" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                            <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                            <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                        </div>
                    </li>

                      <li class="margLR">
                          <app-excelicon [gridOption]="gridOptions" [title]="'MT List'"></app-excelicon>
                      </li>
                    <li style="margin-top: 0%;" class="margLR">
                      <img data-toggle="modal" data-target="#popupModal" src="../../../assets/upload.svg"/>
                    </li>
                    </ul>
                  </div>
              </div>
              <div class="col-md-12 pad0" style="margin-top: 10px;">
                <div class="mt-default">
                  <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;height: calc(92vh - 160px);" class="ag-theme-balham"  [columnDefs]="columnDefs"
                                   [gridOptions]="gridOptions"
                                   (gridReady)="onGridReady($event)"
                                   (rowSelected)="onSelectionChanged($event)"
                                   [modules]="modules" [frameworkComponents]="frameworkComponents"
                                   [context]="context" [tooltipShowDelay]="tooltipShowDelay"
                                   [tooltipHideDelay]="tooltipHideDelay">
                  </ag-grid-angular>
                  <div *ngIf="preloader">
                    <app-loadingstate></app-loadingstate>
                  </div>
                  <div *ngIf="empty_state">
                    <app-emptystate></app-emptystate>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<div style="position:fixed;bottom: 10px;right: 2%;" class="generate-invoice">
  <div id="overlay"></div>
  <span class="sendpopup">
      <ul class="list-unstyled">
          <li *ngIf="showAutoPopulate">
            <a (click)="update_with_stock()" class="btn white-btn"> AUTO POPULATE </a>
          </li>
          <li>
            <a (click)="print_invoice()" class="btn white-btn"> PRINT </a>
          </li>
          <li *ngIf="!generate_loading">
            <a (click)="generate_invoice()" class="btn confirm-blue-btn">GENERATE INVOICE </a>
          </li>
          <li *ngIf="generate_loading">
               <img style="position:relative;right: 6vw;width:40%;" src="assets/images/loader_icon.gif"/>
          </li>
        </ul>
  </span>
</div>

<button #genInvoice id="openModalButton1" [hidden]="true" data-toggle="modal" data-target="#BillingModal"></button>

<div #BillingModal class="pol-modal pol-send invoice modal" id="BillingModal" role="dialog">
  <div style="margin-bottom: 0px !important;" class="modal-dialog" *ngFor="let order_detail of all_orders">
    <!-- Modal content-->
    <div class="modal-content">
          <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      <div class="billing-details">
        <div class="billing-name">
          <ul class="list-unstyled">
            <li>
              <h1>{{order_detail['partner']}}</h1>
              <h1>Code: {{order_detail['code']}}</h1>
            </li>
            <li>
              <label>ROUTE NAME : {{order_detail['beat_name']}}</label>
            </li>
            <li  *ngIf="showBatchConfigfure && confirm_enable && !confirm_loading">
              <div>
                <button (click)="performBatchConfigure(order_detail)" class="btn confirm-blue-btn">Configure Batches</button>
              </div>
            </li>
            <a *ngIf="order_detail['has_einvoice'] && (order_detail['status'] == 'draft' || order_detail['status'] == 'error' || order_detail['status'] == 'processing')"
               style="margin-top: 4%;" class="btn blue-btn" (click)="generate_irn(order_detail['id'])">Generate IRN</a>
            <img *ngIf="irn_loading" style="position:relative;right: 5vw;width:5%;" src="assets/images/loader_icon.gif"/>
          </ul>
        </div>
        <div class="billing-order">
          <ul class="list-unstyled">
            <li>
              <label>INVOICE NO : {{order_detail['invoice_no']}}</label>
            </li>
            <li *ngIf="confirm_enable">
              <label>REF NO : </label>
            </li>
            <li *ngIf="!confirm_enable">
              <label>REF NO : {{order_detail['name']}}</label>
            </li>
            <li *ngIf="confirm_enable">
              <label>ID : </label>
            </li>
            <li *ngIf="!confirm_enable">
              <label>ID : {{order_detail['id']}}</label>
            </li>
            <li>
              <label>GST/TAX ID : {{order_detail['partner_gstn']}}</label>
            </li>
            <li>
              <label>DATE : {{order_detail['invoice_date']}}</label>
            </li>
            <li>
              <label>DELIVERY DATE :  {{order_detail['date_delivered']}}</label>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-body">

        <div class="pol-modal-heading">
          <h1>DETAILS</h1>
        </div>
        <div class="pol-modal-content">
          <ul class="pol-content-heading list-unstyled">
            <li>
              <label>PRODUCT</label>
            </li>
            <li>
              <label>QUANTITY</label>
            </li>
            <li>
              <label>PRICE</label>
            </li>
            <li>
              <label>TAX</label>
            </li>
            <li>
              <label>TAX AMOUNT</label>
            </li>
            <li>
              <label>VALUE</label>
            </li>
            <li>
              <label>TCS/TDS</label>
            </li>
          </ul>
          <div style="margin-bottom: 0px;" class="pol-content-text" *ngFor="let product of order_detail['sale_items']">
            <ul class="list-unstyled" *ngIf="!product['scheme_used'] && product['type'] != 'promo'">
              <li>
                <h2>{{product['product_name']}}</h2>
              </li>
              <li>
                <label>{{product['qty']}}</label>
              </li>
              <li>
                <label>{{parseFloat(product['unit_price']).toFixed(2)}}</label>
              </li>
              <li>
                <label>{{product['tax']}}</label>
              </li>
              <li>
                <label>{{product['price_tax']}}</label>
              </li>
              <li>
                <label> {{product['amount_subtotal']}}</label>
              </li>
              <li>
                <label>{{product['tcs'].toFixed(4)}}</label>
              </li>
            </ul>
            <ul class="list-unstyled" *ngIf="product['type'] == 'promo' && order_detail['invoice_no'] != null">
              <li>
                <h2 style="color: lightgreen;">{{product['product_name']}}</h2>
              </li>
              <li>
                <label style="color: lightgreen;">{{product['qty']}}</label>
              </li>
              <li>
                <label>{{product['unit_price']}}</label>
              </li>
              <li>
                <label style="color: lightgreen;">{{product['tax']}}</label>
              </li>
              <li>
                <label style="color: lightgreen;">{{product['price_tax']}}</label>
              </li>
              <li>
                <label style="color: lightgreen;"> {{product['amount_subtotal']}}</label>
              </li>
              <li>
                <label style="color: lightgreen;">0</label>
              </li>
            </ul>
            <ul class="list-unstyled" *ngIf="product['scheme_used']">
              <li>
                <h2 style="color: lightgreen;">{{product['name']}}</h2>
              </li>
              <li>
                <h2 style="color: lightgreen;">{{product['qty']}}</h2>
              </li>
              <li>
                <h2 style="color: lightgreen;"></h2>
              </li>
              <li>
                <h2 style="color: lightgreen;"></h2>
              </li>
              <li>
                <label style="color: lightgreen;">{{product['scheme_code']}}</label>
              </li>
              <li *ngIf="product['scheme_discount'] != 0">
                <label style="color: lightgreen;">-{{product['scheme_discount']}}</label>
              </li>
              <li *ngIf="product['price_subtotal'] != 0">
                <label style="color: lightgreen;">{{product['price_subtotal']}}</label>
              </li>
            </ul>
          </div>
          <table *ngIf="order_detail['invoice_no'] != null">
            <TR style="font-size: 115%;font-weight: 500;">
              <TD >Tax</TD>
              <TD style="padding-left: 20%;">Amount</TD>
            </TR>
            <TR *ngFor="let tax of order_detail['tax_breakup']">
              <TD style="padding-top: 5%;font-size: 120%;">{{tax.name}}</TD>
              <TD style="padding-left: 20%;padding-top: 5%;font-size: 120%;">{{tax.tax}}</TD>
            </TR>
          </table>
          <table *ngIf="order_detail['invoice_no'] != null" style="margin-left: 70%;margin-top: -10%;">
            <TR>
              <TD style="font-size: 115%;font-weight: 500;">Total Without Taxes</TD>
              <TD style="font-size: 120%;padding-left: 20%;">{{order_detail['amount_untaxed']}}</TD>
            </TR>
            <TR>
              <TD style="font-size: 115%;font-weight: 500;">Taxes</TD>
              <TD style="font-size: 120%;padding-left: 20%;">{{order_detail['amount_tax']}}</TD>
            </TR>
            <TR>
              <TD style="font-size: 115%;font-weight: 500;">Total</TD>
              <TD style="font-size: 120%;padding-left: 20%;">{{order_detail['amount_total']}}</TD>
            </TR>
            <TR>
              <TD style="font-size: 115%;font-weight: 500;">TCS</TD>
              <TD style="font-size: 120%;padding-left: 20%;">{{order_detail['tcs'].toFixed(4)}}</TD>
            </TR>
            <TR>
              <TD style="font-size: 115%;font-weight: 500;">Total Payable</TD>
              <TD style="font-size: 120%;padding-left: 20%;">{{order_detail['amount_pay']}}</TD>
            </TR>
          </table>
        </div>
      </div>
    </div>
  </div>
  <a *ngIf="confirm_enable && !confirm_loading" style="margin-left: 45%;" class="btn blue-btn" (click)="saveMT()" >CONFIRM</a>
  <div *ngIf="confirm_loading" style="margin-left: 45%;">
    <img style="width:5%;margin-top: 10%;" src="assets/images/loader_icon.gif"/>
  </div>
</div>
<div class="approve-modal modal" id="popupModal" role="dialog">
  <div id="overlay"></div>
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <label style="padding-bottom: 5%;">UPLOAD PRE-ORDER</label>
          <input #fileUp type="file" class="form-control" (change)="uploadedFile($event)" placeholder="Upload file" accept=".xlsx">
        </div>
        <p style="font-size: medium" [style.color]="uploadCaption[1] == 0 ? 'green' : 'red'">{{uploadCaption[0]}}</p>
        <div class="approve-modal-content">
          <a *ngIf="!uploading" class="btn blue-btn" (click)="uploadexcel()">Upload</a>
          <div *ngIf="uploadLoader">
            <img style="width:5%;margin-top: 10%;" src="../../../assets/images/loader_icon.gif"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
