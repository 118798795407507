import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class RetailerMasterReportComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };
  apiData: any = [];
  uqTypes;
  uqImageTypes
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Retailer Master Report');

    /*this.showableFilters.salesman = true;
    this.showableFilters.team = true;*/
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;

    this.loadInBatches(10000, 'r_id');
    this.showProgressCustomText = true;



    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Retailer',
      field: 'r_name',
      minWidth: 300,
      cellRenderer: 'agGroupCellRenderer',
      resizable: true,
      pinned: 'left'
    };
    this.API_URL = '/api/pwa_reports/getRetailerMasterReport';
  }

  configureGrid() {


    this.columnDefs = [
      {
        headerName: 'REGION', field: 'region', rowGroup: true, enableRowGroup: true, hide: true,
        valueGetter(params) {
          if (params.data) {
            if (params.data.region) {
              return params.data.region;
            } else {
              return 'NA';
            }
          }
        }
      },
      {
        headerName: 'CLUSTER', field: 'cluster', rowGroup: true, enableRowGroup: true, hide: true,
        valueGetter(params) {
          if (params.data) {
            if (params.data.cluster) {
              return params.data.cluster;
            } else {
              return 'NA';
            }
          }
        }
      },
      {
        headerName: 'DISTRIBUTOR',

        children: [
          {headerName: 'CODE', field: 'dist_code', width: 100},
          {headerName: 'NAME', field: 'dist_name', enableRowGroup: true, width: 100},
          {headerName: 'TYPE', field: 'dist_type', hide: true, width: 100},
          {headerName: 'ADDRESS', field: 'dist_address', hide: true, width: 100},
          {headerName: 'CITY', field: 'dist_city', hide: true, width: 60},
          {headerName: 'ZIP', field: 'dist_zip', hide: true, width: 50},
          {headerName: 'GST', field: 'dist_gst', hide: true, width: 100},
          {headerName: 'PAN', field: 'dist_pan', hide: true, width: 100},
        ]
      },
      {
        headerName: 'CHAIN', hide: true,

        children: [
          {headerName: 'CODE', field: 'parent_code', width: 80},
          {headerName: 'NAME', enableRowGroup: true, field: 'parent_name', width: 100},
          {headerName: 'CLASS', enableRowGroup: true, field: 'parent_class', width: 100},
        ]
      },
      {
        headerName: 'BEAT',

        children: [
          {headerName: 'BEAT ID', field: 'beat_id', width: 80, valueGetter: this.BeatIdGetter, cellStyle: this.beatCountCellStyle},
          {headerName: 'BEAT CODE', field: 'beat_code', hide: true, width: 50, valueGetter: this.BeatCodeGetter, cellStyle: this.beatCountCellStyle},
          {headerName: 'BEAT NAME', field: 'beat_name', enableRowGroup: true, width: 100, valueGetter: this.BeatNameGetter, cellStyle: this.beatCountCellStyle},
          {headerName: 'EMP CODE', field: 'beat_emp_code', enableRowGroup: true, width: 80, valueGetter: this.BeatEmployeeCodeGetter, cellStyle: this.beatCountCellStyle},
          {headerName: 'SALESMAN', field: 'beat_salesman', enableRowGroup: true, width: 80, valueGetter: this.BeatSalesmanGetter, cellStyle: this.beatCountCellStyle},
          {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 80, cellStyle: this.beatCountCellStyle},
          {headerName: 'TCODE', field: 'territory_code', enableRowGroup: true, width: 80, cellStyle: this.beatCountCellStyle},
          {headerName: 'TER MANAGER', field: 'ter_manager', enableRowGroup: true, width: 80, cellStyle: this.beatCountCellStyle},
          {headerName: 'TM CODE', field: 'tm_code', enableRowGroup: true, width: 80, cellStyle: this.beatCountCellStyle},
          {headerName: 'REG MANAGER', field: 'reg_manager', enableRowGroup: true, width: 80, cellStyle: this.beatCountCellStyle},
          {headerName: 'VISIT FREQ', field: 'vf', hide: true, cellStyle: {textAlign: 'center'}, width: 60, valueGetter: this.BeatVFGetter},
          {headerName: 'BEAT COUNT', field: 'beat_count', hide: true, cellStyle: {textAlign: 'center'}, width: 60},
        ]
      },
      {
        headerName: 'RETAILER',

        children: [
          {headerName: 'ID', field: 'r_id', width: 80},
          {headerName: 'CODE', field: 'r_code', width: 80},
          {headerName: 'NAME', field: 'r_name', enableRowGroup: true, width: 100},
          {headerName: 'TYPE', field: 'r_type', enableRowGroup: true, width: 80},
          {headerName: 'PROGRAM', enableRowGroup: true, field: 'program', width: 100},
          {headerName: 'PRICELIST', field: 'pricelist_name', width: 100},
          {
            headerName: 'CLASS', field: 'class_type', enableRowGroup: true, width: 40,
            valueGetter(params) {
              if (params.data) {
                if (params.data.class_type != null) {
                  return params.data.class_type.toUpperCase();
                }
              }
            }
          },
          {headerName: 'CHANNEL', field: 'channel', enableRowGroup: true, width: 80},
          {headerName: 'CITY', field: 'rp_city', enableRowGroup: true, width: 60},
          {headerName: 'ZIP', field: 'rp_zip', enableRowGroup: true, width: 60},
          {headerName: 'AREA', field: 'area', enableRowGroup: true, width: 80},
          {headerName: 'DISTRICT', field: 'zone', enableRowGroup: true, width: 80},
          {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
          {headerName: 'CONTACT ', field: 'contact_name', width: 80},
          {headerName: 'MOBILE NO ', field: 'contact_no', width: 80},
          {headerName: 'PROMOTER CODE', field: 'emp_code', width: 80},
          {headerName: 'PROMOTER', field: 'user_name', enableRowGroup: true, width: 100},
          {headerName: 'LAT', field: 'lat', width: 50},
          {headerName: 'LONG', field: 'lng', width: 50},
          {headerName: 'GST', field: 'gst_id', width: 50},
          {headerName: 'PAN', field: 'pan', width: 50},
          {headerName: 'ACTIVE', field: 'status', enableRowGroup: true, width: 80},
          {headerName: 'CREATE DATE', field: 'create_date', enableRowGroup: true, width: 80},
          {headerName: 'CREATED BY', field: 'create_name', enableRowGroup: true, width: 80},
          {headerName: 'LAST MODIFIED', field: 'write_date', enableRowGroup: true, width: 80},
          {headerName: 'LAST MODIFIED BY', field: 'write_name', enableRowGroup: true, width: 80},
          {headerName: 'LEAD ID', field: 'l_id', hide: true, width: 50},
          {headerName: 'LEAD', field: 'lead', hide: true, width: 60},
          {
            headerName: 'GEO TAGGED', field: 'r_lat', width: 120, cellStyle: {textAlign: 'center'}, enableRowGroup: true,
            aggFunc: this.geoTagAggFunction,
            valueGetter(params) {
              if (params.data) {
                if (params.data.lat != null && params.data.lat !== "0.0" && params.data.lng != null && params.data.lng !== "0.0") {
                  return 'true';
                } else {
                  return 'false';
                }
              }
            }
          }
        ]
      },
    ];

    let headercount = 4;
    if (this.uqTypes) {
      for (const params in this.uqTypes) {
        this.columnDefs.push(
          {
            headerName: params,
            children: []
          }
        );
        for (const key of this.uqTypes[params]) {
          this.columnDefs[headercount].children.push(
            {headerName: key, field: params + '_' + key, width: 80}
          );
        }
        headercount++;
      }
    }
    if (this.uqImageTypes) {
      for (const key of this.uqImageTypes) {
        this.columnDefs.push(
          {
            headerName: key,
            children: [
              {headerName: 'Value', field: 'img_con_' + key + '_val', width: 80},
              {
                headerName: "Image", field: 'img_con_' + key + '_img', width: 80, valueGetter: this.generateImageUrl('img_con_' + key + '_img'),
                cellRenderer(params) {
                  if (params.data) {
                    if (!params.data['img_con_' + key + '_img'] || params.data['img_con_' + key + '_img'] === "") {
                      return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                    } else {
                      return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                    }
                  }
                }
              }
            ]
          }
        );
        headercount++;
      }
    }

    this.columnDefs.push(
      {
        headerName: 'ADDITIONAL',

        children: [
          {headerName: 'KEY OUTLET', field: 'key_outlet', hide: true, width: 50},
          {headerName: 'POTENTIAL', field: 'target', hide: true, width: 50},
          {headerName: 'CREDIT LIMIT', field: 'credit_limit', hide: true, width: 50},
          {headerName: 'CREDIT DAYS', field: 'cl_days', hide: true, width: 50},
          {headerName: 'PAY MODE', field: 'payment_mode', hide: true, width: 50},
          {headerName: 'eINVOICE', field: 'has_einvoice', hide: true, width: 50},
          {headerName: 'PRE ORDER', field: 'has_purchase_order', hide: true, width: 50},
          {headerName: 'OTP', field: 'otp_verified', hide: true, width: 50},
          {headerName: 'VERIFIED', field: 'is_verified', hide: true, width: 50},
          {headerName: 'VERTICAL', field: 'vertical', hide: true, width: 300}
        ]
      }
    );
  }

  beatCountCellStyle(params) {
    let color = '#FFFFFF';
    if (params.data && params.data.beats && params.data.beats.length > 1) {
      color = 'LightGreen';
    }
    return {backgroundColor: color};
  }


  BeatIdGetter(params) {
    let res = '';
    if (params.data && params.data.beats) {
      let i = 0;
      params.data.beats.forEach(obj => {
        res += obj.id;
        i++;
        if (i < params.data.beats.length) {
          res += ',';
        }
      });
    }
    return res;
  }

  BeatNameGetter(params) {
    let res = '';
    if (params.data && params.data.beats) {
      let i = 0;
      params.data.beats.forEach(obj => {
        res += obj.name;
        i++;
        if (i < params.data.beats.length) {
          res += ',';
        }
      });
    }
    return res;
  }

  BeatCodeGetter(params) {
    let res = '';
    if (params.data && params.data.beats) {
      let i = 0;
      params.data.beats.forEach(obj => {
        res += obj.code;
        i++;
        if (i < params.data.beats.length) {
          res += ',';
        }
      });
    }
    return res;
  }

  BeatSalesmanGetter(params) {
    let res = '';
    if (params.data && params.data.beats) {
      let i = 0;
      params.data.beats.forEach(obj => {
        res += obj.salesman;
        i++;
        if (i < params.data.beats.length) {
          res += ',';
        }
      });
    }
    return res;
  }


  BeatEmployeeCodeGetter(params) {
    let res = '';
    if (params.data && params.data.beats) {
      let i = 0;
      params.data.beats.forEach(obj => {
        res += obj.emp_code;
        i++;
        if (i < params.data.beats.length) {
          res += ',';
        }
      });
    }
    return res;
  }

  BeatVFGetter(params) {
    let res = '';
    if (params.data && params.data.beats) {
      let i = 0;
      params.data.beats.forEach(obj => {
        res += obj.vf;
        i++;
        if (i < params.data.beats.length) {
          res += ',';
        }
      });
    }
    return res;
  }

  VerticalGetter(params) {
    let res = '';
    if (params.data && params.data.vertical) {
      const jsonObject = JSON.parse(params.data.vertical);
      if (jsonObject !== null) {
        res= jsonObject;
      }
    }
    return res;
  }

  geoTagAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;
    params.values.forEach(value => {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (value === 'true') {
          sum += 1;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: () => sum,
    };

    return result;

  }

  // loadServerData() {
  //   this.goClicked = true;
  //   //debugger;
  //   /*   const t = trace(this.appModule.perf, this.sharedService.headerTitleSource.value);
  //      t.start();
  //      t.putAttribute('companyKey', localStorage.getItem('company_key'));*/
  //   this.userData.offsetID = 0;
  //   this.userData.offset = 10000;
  //   this.apiData = [];
  //   this.progressText = '';
  //   this.dataLoaded = false;
  //   this.preloader = true;
  //   this.emptyState = false;
  //   this.errorExists = false;
  //
  //   if (this.API_URL === '') {
  //     return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
  //   }
  //
  //   this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
  //   this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
  //
  //   this.fetch('r_id');
  //
  //
  // }

  /*configureData() {
    this.rowData = [];
    let masterRow: any = {};
    masterRow = this.apiData.master;
    const inThis = this;
    for (const key in masterRow) {
      let indRow = {};
      const attRow = [];

      for (const key1 in inThis.attributeTypes) {
        attRow[inThis.attributeTypes[key1]] = this.apiData.retail_attr.filter(api => api.retailer_id === masterRow[key].r_id
          && api.attribute === inThis.attributeTypes[key1]);
        if (attRow[inThis.attributeTypes[key1]] != null && attRow[inThis.attributeTypes[key1]].length > 0) {
          attRow[inThis.attributeTypes[key1]] = attRow[inThis.attributeTypes[key1]][0].value;
        }
      }

      indRow = {
        ...masterRow[key],
        ...attRow
      };
      this.rowData.push(indRow);
    }
  }*/

  postProcessData() {
    this.dataLoaded = false;
    this.preloader = true;
    this.configureData();
  }

  configureData() {
    this.uqTypes = [];
    this.uqImageTypes = new Set();
    this.apiData.forEach(obj => {
      if (obj.param_json) {
        if (obj.param_json.constructor !== Object) {
          try {
            obj.param_json = JSON.parse(obj.param_json);
          } catch (e) {
            console.log(obj);
          }
        }
        if (obj.param_json.hasOwnProperty('params') && obj.param_json.params) {
          const paramList = Array.isArray(obj.param_json.params) ? obj.param_json.params : [];
          for (const params of paramList) {
            if (!params.s) {
              params.s = " ";
            }
            if (!this.uqTypes[params.s]) {
              this.uqTypes[params.s] = new Set();
            }
            this.uqTypes[params.s].add(params.n);
            obj[params.s + '_' + params.n] = params.v;
          }
        }
      }
      if (obj.hasOwnProperty('image_config') && obj.image_config) {
        for (const params of obj.image_config) {
          this.uqImageTypes.add(params['image_type']);
          if (!params['comment'] || params.comment === "") {
            if (params['rel_field'] === 'pan') {
              obj['img_con_' + params['image_type'] + '_val'] = obj['pan'];
            }
          } else {
            obj['img_con_' + params['image_type'] + '_val'] = params['comment'];
          }
          obj['img_con_' + params['image_type'] + '_img'] = params['store_fname'];
        }
      }
    });

    this.rowData = this.apiData;
    this.configureGrid();
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column.includes('img') || column.includes('image')) {
      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty(column) && rows[0][column] !== null && rows[0][column] !== "") {
        let container = 'res_partner_gallery';
        if (column.includes('image')) {
          container = 'res_partner';
        }
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0][column],
              container
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0][column],
              container
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }
      }
    }
  }


}
