import {Component, ElementRef, OnInit, Renderer2} from "@angular/core";
import {environment} from "../../../environments/environment";
import {ApiService} from "../../_services/api.service";
import {Router} from "@angular/router";
import {DeviceDetectorService} from "ngx-device-detector";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-login-companykey-component',
  templateUrl: './login_companykey.component.html',
  styleUrls: ['./login_companykey.component.scss']
})
export class LoginCompanyKeyComponent implements OnInit {

  role = '';
  refresh_token;
  // public url;
  public currentApplicationVersion = environment.appVersion;
  company_name = 'assets/images/sd-logo.png';
  company_bg = 'assets/images/sales.png';
  company_data = {};
  device_info = null;
  result;
  instance;
  enable_loader = true;
  company_key_input: string;
  username: string;
  password: string;
  phone: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  userdata = {
    instance: '',
    ckey: '',
    url: '',
    user_name: null,
    password: null,
    phone: null,
    otp: null,
    device_name: '',
    device_info: null
  };
  public error_msg = '';
  public web_module_ids;
  public loader = false;
  public subdomain: string;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private apiService: ApiService,
              private router: Router,
              private deviceService: DeviceDetectorService,
              private toastr: ToastrService) {
    this.device_info = this.deviceService.getDeviceInfo();
    console.log(this.device_info, this.deviceService.isDesktop());
    localStorage.setItem('company_logo', this.company_name);
  }

  getSubdomain() {
    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 || domain.split('.')[0] === 'app' || domain.split('.')[0] === 'console'
      || domain.split('.')[0] === '0' || domain.split('.')[0] === 'www'
      || domain.split('.')[0] === 'pwauat' || domain.split('.')[0] === 'dmsuat' || domain.split('.')[0] === 'app1'
      || domain.split('.')[0] === 'asplpwa' || domain.split('.')[0] === 'vajra' || domain.split('.')[0] === 'dms') {
      this.subdomain = '';
    } else {
      this.subdomain = domain.split('.')[0];
      localStorage.setItem('company_key', this.subdomain);
    }
    //this.subdomain = 'pwauat';

    console.log(this.subdomain);
  }

  ngOnInit() {

    this.enable_loader = true;

    localStorage.setItem('logindata', null);

    this.getSubdomain();

    this.refresh_token = localStorage.getItem('resfreshToken');

    if ((this.refresh_token !== null) && (this.refresh_token !== 'null')) {
      this.enable_loader = false;
      this.router.navigate([this.apiService.redirect_url + '/dash']);
      //this.router.navigate([this.apiService.redirect_url + '/boards/board_one']);
    } else {
      if (this.subdomain !== '') {
        this.userdata.instance = this.subdomain;
        this.apiService.findCompanyInstance({companyKey: this.subdomain})
          .subscribe(res => {
              if (res.hasOwnProperty('success')) {
                if (res.success.logo_url !== null) {
                  this.company_name = res.success.logo_url;
                }
                if (res.success.background_url !== null) {
                  this.company_bg = res.success.background_url;
                }
                this.userdata.instance = this.subdomain;
                this.userdata.ckey = this.subdomain;
                this.userdata.url = res.success.url;

                if (res.success.hasOwnProperty('config') && res.success.config !== null) {
                  if (res.success.config.hasOwnProperty('login') && res.success.config.login !== null) {
                    localStorage.setItem('logindata', JSON.stringify(res.success.config.login));
                  }
                  if (res.success.config.hasOwnProperty('auth_provider') && res.success.config.auth_provider !== null &&
                    res.success.config.auth_provider.okta_pwa.domain !== null && res.success.config.auth_provider.okta_pwa.clientId !== null) {
                    environment.oktaConfig.issuer = res.success.config.auth_provider.okta_pwa.domain + '/oauth2/default';
                    environment.oktaConfig.clientId = res.success.config.auth_provider.okta_pwa.clientid;
                  }
                }
                localStorage.setItem('company_key', this.subdomain);
                this.perform_login();

              } else {
                this.enable_loader = false;
                this.toastr.warning('Invalid Company Key');
              }
            },
            error => {
              this.enable_loader = false;
              console.log(error);
              this.company_name = 'assets/images/sd-logo.png';
            });
      } else {
        this.enable_loader = false;
      }
    }
  }

  login_user() {

    if (this.company_key_input !== null && this.company_key_input.length === 0) {
      this.toastr.error('Please Enter Company Key');
    } else {
      this.loader = true;
      this.error_msg = '';
      this.userdata.user_name = this.username;
      this.userdata.password = this.password;
      this.userdata.phone = this.phone;
      this.userdata.otp = this.otp1 + this.otp2 + this.otp3 + this.otp4;
      this.userdata.device_info = {
        ip_address: '0.0.0.0',
        name: '12345678',
        browser: this.device_info.browser,
        os: this.device_info.os,
        device_token: JSON.parse(localStorage.getItem(this.userdata.ckey + '_token'))
      };
      if (this.subdomain !== '') {
        this.userdata.ckey = this.subdomain;
        this.userdata.instance = this.subdomain;
        this.perform_login();

      } else {

        this.apiService.findCompanyInstance({companyKey: this.company_key_input})
          .subscribe(res => {
              if (res.hasOwnProperty('success')) {
                console.log(res);
                this.userdata.instance = this.company_key_input;
                this.userdata.ckey = this.company_key_input;
                this.userdata.url = res.success.url;
                if (res.success.hasOwnProperty('config') && res.success.config !== null) {
                  if (res.success.config.hasOwnProperty('login') && res.success.config.login !== null) {
                    localStorage.setItem('logindata', JSON.stringify(res.success.config.login));
                  }
                  if (res.success.config.hasOwnProperty('auth_provider') && res.success.config.auth_provider !== null &&
                    res.success.config.auth_provider.okta_pwa.domain !== null && res.success.config.auth_provider.okta_pwa.clientId !== null) {
                    // localStorage.setItem('domain',res.success.config.auth_provider.okta_pwa.domain);
                    // localStorage.setItem('clientid',res.success.config.auth_provider.okta_pwa.clientid);
                    environment.oktaConfig.issuer = res.success.config.auth_provider.okta_pwa.domain + '/oauth2/default';
                    environment.oktaConfig.clientId = res.success.config.auth_provider.okta_pwa.clientid;
                  }
                }

                localStorage.setItem('company_key', this.company_key_input);
                this.perform_login();
              } else {
                this.toastr.warning('Invalid Company Key');
              }
            },
            error => {
              if (error.error.error === undefined) {
                this.error_msg = 'Connection Error';
                this.toastr.error(this.error_msg);
              } else {
                console.log(error.error.error.message);
                this.error_msg = 'Invalid credentials';
                this.toastr.error(this.error_msg);
              }
              this.loader = false;
            });

      }
    }

  }

  perform_login() {
    this.enable_loader = false;
    this.router.navigate(['/login']);
  }

  loginClick() {
    this.error_msg = '';
    this.renderer.removeClass(this.elRef.nativeElement.querySelector('.getotp'), 'hide');
    this.renderer.addClass(this.elRef.nativeElement.querySelector('#login'), 'hide');
  }

  otpClick() {
    this.userdata.phone = this.phone;
    if (this.phone === undefined) {
      this.error_msg = 'Enter Mobile Number';
    } else {
      this.result = this.apiService.sendotp(this.userdata);
      this.result.subscribe(res => {
          if (res.result.hasOwnProperty('success')) {
            console.log(res.result.otp);
            this.renderer.removeClass(this.elRef.nativeElement.querySelector('.verifyotp'), 'hide');
            this.renderer.addClass(this.elRef.nativeElement.querySelector('.getotp'), 'hide');
          } else {
            this.error_msg = res.result.failed;
          }
        },
        error => {
          console.log(error);
          if (error.error.error.message === undefined) {
            this.error_msg = 'Authentication Error';
          } else {
            this.error_msg = error.error.error.message;
          }
        }
      );
    }
  }
}
