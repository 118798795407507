<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Create Beat Plan</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div>
        <form novalidate  id="ngForm" #beatplanform="ngForm">
          <div class="row">
            <div class="col-md-6 pl-0 mt-0">
              <span class="detaillabel2" >Name</span>
              <div style="margin-top: 5px;">
                <input
                  class="forminputwhitebg"
                  type="text"
                  name="name"
                  [(ngModel)]="beatPlanData.name"
                  #name="ngModel"
                  style="width: 100%;"
                  required
                >
                <div *ngIf="name.errors && (name.dirty || name.touched)">
                  <span *ngIf="name.errors.required" class="text-danger">Name is required</span>
                </div>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="detaillabel2" >Code</span>
              <div style="margin-top: 5px;">
                <input
                  class="forminputwhitebg"
                  type="text"
                  name="code"
                  [(ngModel)]="beatPlanData.code"
                  #code="ngModel"
                  style="width: 100%;"
                  required
                >
                <div *ngIf="code.errors && (code.dirty || code.touched)">
                  <span *ngIf="code.errors.required" class="text-danger">Code is required</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px;">
            <div class="col-md-6 pl-0 mt-0">
              <span class="detaillabel2" >Territory</span>
              <div style="margin-top: 5px;">
                <ng-select [items]="territoriesList"
                           bindLabel="itemname"
                           bindValue="id"
                           class="custom"
                           name="territory_id"
                           #territory_id="ngModel"
                           [(ngModel)]="beatPlanData.territory_id"
                           required>
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="detaillabel2" >Salesman</span>
              <div style="margin-top: 5px;">
                <ng-select [items]="salesmenList"
                           bindLabel="itemname"
                           bindValue="id"
                           class="custom"
                           name="salesman_id"
                           #salesman_id="ngModel"
                           [(ngModel)]="beatPlanData.salesman_id"
                           required>
                  >
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px;">
            <div class="col-md-6 pl-0 mt-0">
              <span class="detaillabel2">Travel Type</span>
              <div style="margin-top: 5px;">
                <ng-select [items]="beatTravelTypes"
                           bindLabel="itemName"
                           bindValue="id"
                           class="custom"
                           name="travel_type_id"
                           #travel_type_id="ngModel"
                           [(ngModel)]="beatPlanData.travel_type_id"
                           required>
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="detaillabel2">Schedule Type</span>
              <div style="margin-top: 5px;">
                <ng-select [items]="schedule_type_list"
                           class="custom"
                           name="schedule_type"
                           #schedule_type="ngModel"
                           [(ngModel)]="beatPlanData.schedule_type"
                           required>
                  >
                </ng-select>
              </div>
            </div>
          </div>
          <div style="margin-top:10px;">
            <div>
              <span class="detaillabel2">Comments</span>
              <div style="height: 70px;">
                    <textarea
                      class="forminputwhitebg"
                      style="height: 70px;width: 100%"
                      type="text"
                      name="comments"
                      #comments="ngModel"
                      [(ngModel)]="beatPlanData.comments"
                    ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        class="btn confirm-blue-btn"
        (click)="createBeatPlan()"
        [disabled]="beatplanform.form.invalid"
      >
        Save
      </button>
    </div>
  </div>
</section>
