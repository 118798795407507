<div class="common-wrapper" xmlns="http://www.w3.org/1999/html">
  <div class="common-container">
    <div class="main-wrapper">
      <div style="padding: 10px;">
        <div class="cardbox" style="width: 100%">
          <div class="row">
            <div style="width: 100%;">
              <h3>{{assetDetails.asset_name}}</h3>
              <div class="row centeralign">
                <span class="detaillabel1 mr-2">Asset Type</span>
                <span class="detailvalue1">{{assetDetails['asset_type']}}</span>

                <span class="greyindicator" style="margin-left: 30px;"></span>
                <span class="detaillabel1 mr-2">Asset Code</span>
                <span class="detailvalue1">{{assetDetails['asset_code']}}</span>

                <span class="greyindicator" style="margin-left: 30px;"></span>
                <span class="detaillabel1 mr-2">QR Code</span>
                <span class="detailvalue1">{{assetDetails['asset_type']}}</span>
              </div>
              <div class="mt-5" style="display: flex">
                <div style="flex: 1">
                  <label class="detaillabel">Requested By</label>
                  <p class="detailvalue1">{{assetDetails['salesman']}}</p>
                  <p class="detailvaluedesc">Requested Date : {{assetDetails['create_date']}}</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">Brand</label>
                  <p class="detailvalue1">{{brand}}</p>
                </div>
                <div *ngIf="showPoDetails" style="flex: 1">
                  <label class="detaillabel">PO Number</label>
                  <p class="detailvalue1">{{assetDetails?.config_json?.po_no}}</p>
                </div>
                <div *ngIf="showPoDetails" style="flex: 1">
                  <label class="detaillabel">PO Date</label>
                  <p class="detailvalue1">{{assetDetails?.config_json?.po_date}}</p>
                  <p class="detailvaluedesc">PO amount : {{assetDetails?.config_json?.po_amount}}</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">Outlet</label>
                  <p class="detailvalue1">{{assetDetails['retailer']}}</p>
                  <p class="detailvaluedesc">Code : {{assetDetails['rcode']}}</p>
                  <p class="detailvaluedesc">Outlet Type : {{assetDetails['out_type']}}</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">Vendor</label>
                  <p class="detailvalue1">{{assetDetails['vendor']}}</p>
                </div>
              </div>
            </div>

            <div style="position: absolute;right: 30px;">
              <div class="button-row centeralign">
                <div style="display: inline-block; margin-right: 20px;">
                  <span class="detaillabel" style="display: block">Estimated Budget</span>
                  <span class="detailvalue1" style="font-size: 18px;float: right;">{{assetDetails['budget']}}</span>
                </div>
                <div style="display: inline-block;margin-left:10px; margin-right: 10px;" class="btn light-blue-btn">
                  <span
                    style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span
                  style="color: #2D2D2D">{{assetDetails['status']}}</span>
                </div>
                <div style="display: inline-block; margin-right: 20px;">
                  <span (click)="fetchPreviousData()"><i
                    [ngClass]="{'disabledspan': rowIndex === 0,
                     'enabledspan': rowIndex >0}"
                    class="fal fa-arrow-circle-left fa-2x"></i></span>
                  <span
                    style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 14px;">{{rowIndex + 1}}
                    /{{asset_ids.length}}</span>
                  <span (click)="fetchNextData()"><i
                    [ngClass]="{'disabledspan': rowIndex === asset_ids.length-1,
                     'enabledspan': rowIndex < asset_ids.length-1}"
                    class="fal fa-arrow-circle-right fa-2x"></i></span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4" style="border: 1px solid #0762AD; border-radius: 5px;padding: 15px;background: #EBF2F8;">
            <div class="row centeralign" style="cursor: pointer" (click)="detailsClick()">
              <span style="color: #2D2D2D; font-size: 14px;font-weight: 600;">Additional Details</span>
              <img *ngIf="!showDetails" style="margin-left: auto;height: 8px;" src="../../../assets/images/down_arrow_black.svg"/>
              <img *ngIf="showDetails" style="margin-left: auto;right: 30px;height: 8px;" src="../../../assets/images/up_arrow.svg"/>
            </div>
            <div *ngIf="showDetails" class="mt-2" style="display: flex;cursor: pointer" (click)="detailsClick()">
              <div style="flex: 1">
                <label class="detaillabel">Deployed By</label>
                <p class="detailvalue1">{{assetDetails['deployed_by']}}</p>
              </div>
              <div style="flex: 1">
                <label class="detaillabel">Deployed On</label>
                <p class="detailvalue1">{{deployed_on}}</p>
              </div>
              <div style="flex: 1">
                <label class="detaillabel">Comments</label>
                <p class="detailvalue1">{{assetDetails.comments}}</p>
              </div>
              <div style="flex: 1">
                <label class="detaillabel">Verified By</label>
                <p class="detailvalue1">{{assetDetails.dv_by}}</p>
              </div>
              <div style="flex: 2">
                <label class="detaillabel">Verified On</label>
                <p class="detailvalue1">{{verified_on}}</p>
              </div>
            </div>
            <div *ngIf="showDetails" class="mt-2">
              <label class="detaillabel">Images</label>
              <div class="gallerycardgrid">
                <ng-container *ngFor="let data of photos; let i = index">
                  <img style="width:150px; cursor: pointer" [src]="data.photo" (click)="showImagePopup(data.photo)"/>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="cardbox mt-4" style="width: 100%">
          <!--          <div class="row">-->
          <p class="detailvaluebold">Activities</p>
          <div class="timeline-container">
            <ul class="tl">
              <li *ngFor="let activity of timelineData" ng-repeat="item in retailer_history">
                <div class="timestamp">{{activity.date}}</div>
                <div class="item-title">{{activity.name}}</div>

                <div class="item-detail row centeralign">
                  <ng-container *ngFor="let item of activity.details; ; let i = index">
                    <span *ngIf="i>0" class="greyindicator" style="margin-left: 30px;"></span>
                    <span class="detaillabel1 mr-2">{{item.key}}</span>
                    <span class="detailvalue1">{{item.value}}</span>
                  </ng-container>
                </div>
              </li>
            </ul>
          </div>
          <!--  <div class="col-md-12 pad0">
              <div class="col-md-2 pl-0 pr-10" style="border-right: 1px solid #D7DDE6;">
                <ol class="steps" style="height: 500px;">
                  <li id="li_feasibility" class="todo" style="height:40px;"><span style="top: 1em;">Feasibility Survey</span></li>
                  <li id="li_designapproval" class="todo" style="height:100px;"><span>Design Approval</span></li>
                  <li id="li_deployment" class="todo" style="height:100px;"><span>Deployment</span></li>
                  <li id="li_verification" class="todo" style="height:100px;"><span>Verification</span></li>
                </ol>
              </div>
              <div class="col-md-10 pl-10 pr-10">
                <div class="mt-4">
                  <div class="row centeralign" style="justify-content: space-between">
                    <div class="centeralign" style="margin-right: auto">
                      <p class="detailvaluebold">Feasibility Survey</p>
                      <img  *ngIf="stage.feasibility" style="margin-left: 5px;height: 12px;" src="./../../../assets/tickgreen.svg"/>
                    </div>
                    <div  *ngIf="stage.feasibility">
                      <p class="detaillabel1" style="display: inline;">Completed On: </p>
                      <p class="detailvalue1" style="display: inline;">20 Feb 2024 10:25 am</p>
                    </div>
                  </div>
                  <div *ngIf="stage.feasibility" class="mt-2" style="border: 1px solid #D7DDE6; border-radius: 10px;padding: 20px;">
                    <div class="row centeralign">
                      <span class="detaillabel1 mr-2">Survey Date</span>
                      <span class="detailvalue1">{{surveyDetails['date']}}</span>

                      <span class="greyindicator" style="margin-left: 30px;"></span>
                      <span class="detaillabel1 mr-2">Survey Status</span>
                      <span class="detailvalue1">{{surveyDetails['status']}}</span>
                    </div>
                  </div>
                  <div *ngIf="!stage.feasibility" class="mt-3">
                    <span class="detaillabel1" style="font-size: 12px;">Click ‘Take Survey’ to take feasibility survey.</span>
                    <div class="mt-2">
                      <button  class="btn confirm-blue-btn" style="margin:5px;" (click)="openTakeSurveyPopup()">Take Survey</button>
                    </div>

                  </div>
                </div>
                <div *ngIf="stage.feasibility" class="mt-4">
                  <div class="row centeralign" style="justify-content: space-between">
                    <div class="centeralign" style="margin-right: auto">
                      <p class="detailvaluebold">Design Approval</p>
                      <img *ngIf="stage.designapproval" style="margin-left: 5px;height: 12px;" src="./../../../assets/tickgreen.svg"/>
                    </div>
                    <div>
                      <p class="detaillabel1" style="display: inline;">Completed On: </p>
                      <p class="detailvalue1" style="display: inline;">20 Feb 2024 10:25 am</p>
                    </div>
                  </div>
                  <div class="mt-2" style="border: 1px solid #D7DDE6; border-radius: 10px;padding: 20px;">
                    <div class="row centeralign">
                      <span class="detaillabel1 mr-2">Design Budget</span>
                      <span class="detailvalue1">{{surveyDetails['vendor_rep']}}</span>

                      <span class="greyindicator" style="margin-left: 30px;"></span>
                      <span class="detaillabel1 mr-2">Estimated Cost</span>
                      <span class="detailvalue1">{{surveyDetails['vendor_rep_contact']}}</span>

                      <span class="greyindicator" style="margin-left: 30px;"></span>
                      <span class="detaillabel1 mr-2">Installation Period</span>
                      <span class="detailvalue1">{{surveyDetails['date']}}</span>
                    </div>
                    <span class="detaillabel1 mr-2">Comments</span>
                    <span class="detailvalue1">Approve design for Asset</span>
                    <div *ngIf="!stage.designapproval" class="row mt-2">
                      <button  class="btn confirm-green-btn" style="margin:5px;" (click)="openFeasibilityApprovePopup()">Approve</button>
                      <button class="btn reject-btn" style="margin:5px;" (click)="openFeasibilityRejectPopup()">Reject</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="stage.deployment" class="mt-4">
                  <div class="row centeralign" style="justify-content: space-between">
                    <div class="centeralign" style="margin-right: auto">
                      <p class="detailvaluebold">Asset Deployment</p>
                      <img style="margin-left: 5px;height: 12px;" src="./../../../assets/tickgreen.svg"/>
                    </div>
                    <div>
                      <p class="detaillabel1" style="display: inline;">Completed On: </p>
                      <p class="detailvalue1" style="display: inline;">20 Feb 2024 10:25 am</p>
                    </div>
                  </div>
                  <div class="mt-2" style="border: 1px solid #D7DDE6; border-radius: 10px;padding: 20px;">
                    <div class="row centeralign">
                      <span class="detaillabel1 mr-2">Deployed By</span>
                      <span class="detailvalue1">{{assetDetails['deployed_by']}}</span>

                      <span class="greyindicator" style="margin-left: 30px;"></span>
                      <span class="detaillabel1 mr-2">Deployed On</span>
                      <span class="detailvalue1">{{assetDetails['deployed_on']}}</span>

                      <span class="greyindicator" style="margin-left: 30px;"></span>
                      <span class="detaillabel1 mr-2">Deployment Status</span>
                      <span class="detailvalue1">{{assetDetails['deploy_status']}}</span>

                      <span class="greyindicator" style="margin-left: 30px;"></span>
                      <span class="detaillabel1 mr-2">Deployed Location</span>
                      <span class="detailvalue1">{{assetDetails['deployed_location']}}</span>
                    </div>
                    <div class="row centeralign mt-2">
                      <span class="detaillabel1 mr-2">Remarks</span>
                      <span class="detailvalue1">{{assetDetails['comments']}}</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="stage.verification" class="mt-4">
                  <div class="row centeralign" style="justify-content: space-between">
                    <div class="centeralign" style="margin-right: auto">
                      <p class="detailvaluebold">Verification</p>
                      <img style="margin-left: 5px;height: 12px;" src="./../../../assets/tickgreen.svg"/>
                    </div>
                    <div>
                      <p class="detaillabel1" style="display: inline;">Completed On: </p>
                      <p class="detailvalue1" style="display: inline;">20 Feb 2024 10:25 am</p>
                    </div>
                  </div>
                  <div class="mt-2" style="border: 1px solid #D7DDE6; border-radius: 10px;padding: 20px;">
                    <div class="row centeralign">
                      <span class="detaillabel1 mr-2">Verified By</span>
                      <span class="detailvalue1">{{assetDetails['deployed_by']}}</span>

                      <span class="greyindicator" style="margin-left: 30px;"></span>
                      <span class="detaillabel1 mr-2">Verified On</span>
                      <span class="detailvalue1">{{assetDetails['deployed_on']}}</span>

                      <span class="greyindicator" style="margin-left: 30px;"></span>
                      <span class="detaillabel1 mr-2">Verification Status</span>
                      <span class="detailvalue1">{{assetDetails['deploy_status']}}</span>
                    </div>
                    <div class="row centeralign mt-2">
                      <span class="detaillabel1 mr-2">Remarks</span>
                      <span class="detailvalue1">{{assetDetails['comments']}}</span>
                    </div>
                  </div>
                  <button  class="btn confirm-blue-btn" style="margin:5px;">Close Ticket</button>
                  <button class="btn grey-btn" style="margin:5px;">Cancel</button>
                </div>
              </div>
            </div>-->
        </div>
      </div>
    </div>
  </div>
</div>
