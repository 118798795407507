import {Component, ElementRef, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ApiService} from "../../../../_services/api.service";
import {SharedService} from "../../../../_services/shared_service";
import {ToastrService} from "ngx-toastr";
import {DatePipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalforageService} from "../../../../_services/localforage.service";
import {DomSanitizer} from "@angular/platform-browser";
import {AppComponent} from "../../../../app.component";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {GridOptions, RowNode, RowSpanParams} from "@ag-grid-enterprise/all-modules";
import {ConfirmDialogComponent} from "../../../../components/confirmdialog/confirmdialog.component";

@Component({
  selector: 'app-claim-generate',
  templateUrl: './claim_generate_popup.component.html',
  styleUrls: ['./claim_generate_popup.component.scss']

})
export class ClaimGeneratePopupComponent implements OnInit {

  activetab = 'Summary';
  dataLoaded = false;

  summary_gridContext: any;
  summary_columnDefs = [];
  summary_gridOptions: GridOptions;
  summary_gridApi: any;
  summary_gridColumnApi: any;
  summary_rowData = [];
  summary_gridStyle: any;

  sale_gridContext: any;
  sale_columnDefs = [];
  sale_gridOptions: GridOptions;
  sale_gridApi: any;
  sale_gridColumnApi: any;
  sale_rowData = [];
  sale_gridStyle: any;

  return_gridContext: any;
  return_columnDefs = [];
  return_gridOptions: GridOptions;
  return_gridApi: any;
  return_gridColumnApi: any;
  return_rowData = [];
  return_gridStyle: any;

  expense_gridContext: any;
  expense_columnDefs = [];
  expense_gridOptions: GridOptions;
  expense_gridApi: any;
  expense_gridColumnApi: any;
  expense_rowData = [];
  expense_gridStyle: any;

  getRowId;

  periodData: any = [];
  selectedPeriod: any = {};
  periodID = 0;
  myDate: any;

  supplierData: any = [];
  selectedSupplier: any;
  supplierID = 0;

  channel_categ_master = [];
  totalQuantity = 0;
  totalClaimAmount = 0;
  totalInvoices = 0;
  totalOutlets = 0;
  totalExpenses = 0;

  constructor(public dialog: MatDialog,
              public apiService: ApiService,
              public sharedService: SharedService,
              public toastr: ToastrService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected route: ActivatedRoute,
              protected _localCacheService: LocalforageService,
              public router: Router,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.periodData = JSON.parse(localStorage.getItem('jc_months'));
    const date = new Date();
    const monthStartDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    const currentperiod = this.periodData.findIndex(x => x.start_date === monthStartDate);
    if (currentperiod) {
      this.periodData.splice(currentperiod, 1);
    }
    this.periodData.reverse();

    const indPeriod = {id: 0, start_date: null, end_date: null, period_name: "No Date"};
    this.periodData.push(indPeriod);
    this.myDate = [];
    if (this.periodData != null && this.periodData.length > 0) {
      this.periodID = this.periodData[0].id;
      this.myDate[0] = this.periodData[0].start_date;
      this.myDate[1] = this.periodData[0].end_date;
    }

    this.summary_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressRowTransform: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        } else {
          if (params.data.a % 2 === 0) {
            return {background: '#F3F6F8'};
          } else {
            return {background: 'white'};
          }
        }
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.sale_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        } else {
          if (params.data && params.data.active === false) {
            if (params.node.isSelected()) {
              params.node.setSelected(false);
            }
            return {background: '#FCF2E4'};
          } else {
            if (!params.node.isSelected()) {
              params.node.setSelected(true);
            }
            return {background: '#FFFFFF'};
          }
        }
      }
    } as GridOptions;

    this.return_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        } else {
          if (params.data && params.data.active === false) {
            if (params.node.isSelected()) {
              params.node.setSelected(false);
            }
            return {background: '#FCF2E4'};
          } else {
            if (!params.node.isSelected()) {
              params.node.setSelected(true);
            }
            return {background: '#FFFFFF'};
          }
        }
      }
    } as GridOptions;

    this.expense_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        } else {
          if (params.data && params.data.active === false) {
            if (params.node.isSelected()) {
              params.node.setSelected(false);
            }
            return {background: '#FCF2E4'};
          } else {
            if (!params.node.isSelected()) {
              params.node.setSelected(true);
            }
            return {background: '#FFFFFF'};
          }
        }
      }
    } as GridOptions;
    this.configureGrid();
    this.fetchSuppliers();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  ngOnInit(): void {
  }

  rowSpan(params: RowSpanParams) {
    /*if (params.data && params.data.rowspan) {
      return params.data.rowspan;
    } else {
      return 1;
    }*/
    if (params.data.rowspan) {
      return params.data.rowspan;
    } else {
      return 1;
    }
  }

  configureGrid() {
    this.getRowId = (params) => params.data.id;
    const inThis = this;

    this.summary_columnDefs = [
      {
        headerName: "Channel", field: "channel", width: 100,
        /*  rowSpan: params => params.data.rowspan ? params.data.rowspan : 1,
         cellClassRules: { "show-cell": "value !== undefined"}*/
      },
      {headerName: "Category", field: "product_categ", width: 100},
      {
        headerName: "Claim %", field: "claim_pct", width: 40, type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.claim_pct) {
            return parseFloat(params.data.claim_pct).toFixed(2);
          }
        }
      },
      {
        headerName: "Sales", field: "sales", width: 40, aggFunc: 'sum', type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.sales) {
            return parseFloat(params.data.sales).toFixed(2);
          }
        }
      },
      {
        headerName: "Claim Amount", field: "claim", width: 40, aggFunc: 'sum', type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.claim) {
            return parseFloat(params.data.claim).toFixed(2);
          }
        }
      },
      {
        headerName: "Scheme", field: "scheme_discount", width: 40, aggFunc: 'sum', type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.scheme_discount) {
            return parseFloat(params.data.scheme_discount).toFixed(2);
          }
        }
      },
      {
        headerName: "Margin", field: "margin", width: 40, aggFunc: 'sum', type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.margin) {
            return parseFloat(params.data.margin).toFixed(2);
          }
        }
      },
      {
        headerName: "Return", field: "return_amt", width: 40, aggFunc: 'sum', type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.return_amt) {
            return parseFloat(params.data.return_amt).toFixed(2);
          }
        }
      },
      /*{
        headerName: "Expense", field: "expense", width: 40, aggFunc: 'sum', type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.expense) {
            return parseFloat(params.data.expense).toFixed(2);
          }
        }
      },*/
    ];

    this.sale_columnDefs = [
      {
        headerName: "ID", field: "id", width: 20, checkboxSelection: true,
        cellRenderer(params) {
          if (params.data) {
            if (params.data.invoice_no === 'TOTAL') {
              const displayElem = '<span style="font-weight: bold">' + params.value + '</span>';
              return displayElem;
            } else {
              return params.value;
            }
          }
        }
      },
      {headerName: "Active", field: "active", width: 30, hide: true},
      {headerName: "Invoice NO", field: "invoice_no", width: 40},
      {headerName: "Date", field: "date_invoice", width: 40},
      {headerName: "Code", field: "code", width: 40},
      {headerName: "Name", field: "name", width: 100},
      {headerName: "Product Code", field: "default_code", width: 40},
      {headerName: "Product Name", field: "product_name", width: 100},
      {headerName: "Scheme ID", field: "scheme_id", width: 30, hide: true},
      {headerName: "Scheme", field: "scheme", width: 120},
      {headerName: "Scheme Start", field: "scheme_start", width: 40, hide: true},
      {headerName: "Scheme End", field: "scheme_end", width: 40, hide: true},
      {headerName: "QTY", field: "quantity", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      {headerName: "Margin", field: "margin", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      {headerName: "Scheme Discount", field: "discount", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      {headerName: "Tot Discount", field: "discount", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      {headerName: "Line Total", field: "price_total", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      {
        headerName: "Type", field: "claim_type", width: 40,
        cellStyle(params) {
          if (params.value) {
            if (params.value === 'Scheme') {
              return {color: '#17A2B8', 'font-weight': 600};
            } else if (params.value === 'Discount') {
              return {color: '#F6A82C', 'font-weight': 600};
            } else {
              return {color: '#757BC8', 'font-weight': 600};
            }
          }
        },
      },
      /* {
         headerName: "", field: "delete", cellStyle: {textAlign: 'right'}, width: 50, show: this.edit,
         cellRenderer: params => {
           if (params.data) {
             if (params.node && params.node.rowPinned === 'bottom') {
               return '';
             } else if (params._self.edit) {
               if (params.data.active !== false) {
                 return ' <span title="Delete" style="color: #E24260; font-size: 12px; font-weight: bold">Delete</span>';
               } else {
                 return ' <span title="Delete" style="color: #0762AD; font-size: 12px; font-weight: bold">Undo</span>';
               }

             } else {
               return "";
             }
           }
         }, cellRendererParams: {
           _self: this
         }

       },*/
    ];

    this.return_columnDefs = [
      {
        headerName: "ID", field: "id", width: 20, checkboxSelection: true,
        cellRenderer(params) {
          if (params.data) {
            if (params.data.invoice_no === 'TOTAL') {
              const displayElem = '<span style="font-weight: bold">' + params.value + '</span>';
              return displayElem;
            } else {
              return params.value;
            }
          }
        }
      },
      {headerName: "Active", field: "active", width: 30, hide: true},
      {headerName: "Invoice NO", field: "invoice_no", width: 40},
      {headerName: "Date", field: "date_invoice", width: 40},
      {headerName: "Beat", field: "beat", width: 40},
      {headerName: "Supp Code", field: "supp_code", width: 40},
      {headerName: "Supp Name", field: "supp_name", width: 100},
      {headerName: "Cust Code", field: "code", width: 40},
      {headerName: "Cust Name", field: "name", width: 100},
      {headerName: "Cust City", field: "city", width: 100},
      {headerName: "Cust PIN", field: "zip", width: 100},
      {headerName: "Product Code", field: "default_code", width: 40},
      {headerName: "Product Name", field: "product_name", width: 100},
      {headerName: "Product Category", field: "product_categ", width: 40},
      {headerName: "QTY", field: "quantity", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      {headerName: "Line Total", field: "price_total", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      /* {
         headerName: "", field: "delete", cellStyle: {textAlign: 'right'}, width: 50, show: this.edit,
         cellRenderer: params => {
           if (params.data) {
             if (params.node && params.node.rowPinned === 'bottom') {
               return '';
             } else if (params._self.edit) {
               if (params.data.active !== false) {
                 return ' <span title="Delete" style="color: #E24260; font-size: 12px; font-weight: bold">Delete</span>';
               } else {
                 return ' <span title="Delete" style="color: #0762AD; font-size: 12px; font-weight: bold">Undo</span>';
               }

             } else {
               return "";
             }
           }
         }, cellRendererParams: {
           _self: this
         }

       }*/
    ];

    this.expense_columnDefs = [
      {
        headerName: "ID", field: "id", width: 20, checkboxSelection: true,
        /*cellRenderer(params) {
          if (params.data) {
            if (params.data.expense_date === 'TOTAL') {
              const displayElem = '<span style="font-weight: bold">' + params.value + '</span>';
              return displayElem;
            } else {
              return params.value;
            }
          }
        }*/
      },
      {headerName: "Active", field: "active", width: 30, hide: true},
      {headerName: 'Date', field: 'expense_date', width: 100},
      {headerName: "Policy", field: 'policy_line', width: 120},
      {headerName: "Expense Type", field: 'exp_type', width: 120},
      {headerName: 'Claimed', field: 'amount', filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'right'}, width: 80, aggFunc: 'sum'},
      {headerName: "Approved", field: 'app_amount', cellStyle: {textAlign: 'right'}, width: 80, aggFunc: 'sum'},
      {headerName: "Rejected", field: 'rej_amount', cellStyle: {textAlign: 'right'}, width: 80, aggFunc: 'sum'},
      {headerName: "Remarks", field: 'note', width: 150}
    ];
  }

  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
  }

  changePeriod(selectedPeriod: any) {
    const period = this.periodData.find(x => x.id === parseInt(selectedPeriod, 10));
    this.myDate[0] = period.start_date;
    this.myDate[1] = period.end_date;
    this.fetchClaimsToRaise();
  }

  changeSupplier(selectedSupplier: any) {
    this.selectedSupplier = this.supplierData.find(x => x.id === parseInt(selectedSupplier, 10));
  }

  summaryOnGridReady(params) {
    this.summary_gridOptions.api.showLoadingOverlay();

    this.summary_gridApi = params.api;
    this.summary_gridColumnApi = params.columnApi;
    params.api.setRowData(this.summary_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });

    this.summary_gridApi.sizeColumnsToFit();

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.summary_gridApi, this.summary_gridColumnApi);
      this.summary_gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  saleOnGridReady(params) {
    this.sale_gridOptions.api.showLoadingOverlay();

    this.sale_gridApi = params.api;
    this.sale_gridColumnApi = params.columnApi;
    params.api.setRowData(this.sale_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });

    this.sale_gridColumnApi.autoSizeAllColumns();

    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  returnOnGridReady(params) {
    this.return_gridOptions.api.showLoadingOverlay();

    this.return_gridApi = params.api;
    this.return_gridColumnApi = params.columnApi;
    params.api.setRowData(this.return_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });

    this.return_gridColumnApi.autoSizeAllColumns();

    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  expenseOnGridReady(params) {
    this.expense_gridOptions.api.showLoadingOverlay();

    this.expense_gridApi = params.api;
    this.expense_gridColumnApi = params.columnApi;
    params.api.setRowData(this.expense_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });

    this.expense_gridApi.sizeColumnsToFit();

    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  generatePinnedBottomData(gridApi: any, gridColumnApi) {
    // generate a row-data with null values
    const result = {};

    gridColumnApi.getAllGridColumns().forEach(item => {
      result[item.colId] = null;
    });
    return this.calculatePinnedBottomData(result, gridApi, gridColumnApi);
  }

  calculatePinnedBottomData(result, gridApi: any, gridColumnApi) {
    gridColumnApi.getAllGridColumns().forEach(element => {
      gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        if (rowNode.data[element.colId] && typeof rowNode.data[element.colId] === 'number' && element.colDef.aggFunc === 'sum' &&
          (!rowNode.data.hasOwnProperty('active') || (rowNode.data.hasOwnProperty('active') && rowNode.data.active))) {
          if (Number(rowNode.data[element.colId]) % 1 !== 0) {
            result[element.colId] += Number(parseFloat(rowNode.data[element.colId]).toFixed(2));
          } else {
            result[element.colId] += Number(rowNode.data[element.colId]);
          }
        }
      });
      if (result[element.colId]) {
        if (result[element.colId] % 1 !== 0) {
          result[element.colId] = `${result[element.colId].toFixed(2)}`;
        }
      }
    });
    if (gridApi.getRenderedNodes() && gridApi.getRenderedNodes().length > 0) {
      result[gridColumnApi.getAllColumns()[0].colDef.field] = 'TOTAL';
    }
    return result;
  }

  saleOnCellClicked(event) {
  }

  selectionChanged(event, api, columnApi) {
    if (event.data.hasOwnProperty('active')) {
      let changeCheck = true;
      if (event.data.active && event.node.isSelected()) {
        changeCheck = false;
      }
      if (!event.data.active && !event.node.isSelected()) {
        changeCheck = false;
      }
      if (changeCheck) {
        event.data.active = !event.data.active;
        api.applyTransactionAsync({update: [event.data]}, () => {
          api.refreshCells({force: true});
          setTimeout(() => {
            const pinnedBottomData = this.generatePinnedBottomData(api, columnApi);
            api.setPinnedBottomRowData([pinnedBottomData]);
          }, 500);
          this.constructSummaryData();
        });
      }
    } else {
      event.data.active = true;
    }
  }

  saleOnSelectionChanged(event) {
    this.selectionChanged(event, this.sale_gridApi, this.sale_gridColumnApi);
  }

  returnOnCellClicked(event) {
  }

  returnOnSelectionChanged(event) {
    this.selectionChanged(event, this.return_gridApi, this.return_gridColumnApi);
  }

  expenseOnSelectionChanged(event) {
    this.selectionChanged(event, this.expense_gridApi, this.expense_gridColumnApi);
  }

  quickSearch() {
    if (this.activetab === 'Sale') {
      this.sale_gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
    } else if (this.activetab === 'Return') {
      this.return_gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
    } else {
      this.expense_gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
    }
  }

  fetchSuppliers() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    this.apiService.post('/api/pwa_dms_connects/get_suppliers_for_dms', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && res.results.status === 200 && res.results.data && res.results.data.length > 0) {
          this.supplierData = res.results.data;
          this.selectedSupplier = this.supplierData[0];
          this.supplierID = this.supplierData[0].id;
          this.fetchClaimsToRaise();
        } else {
          this.toastr.error("Failed to fetch Suppliers");
        }
      });
  }

  fetchClaimsToRaise() {
    this.dataLoaded = false;
    this.sale_rowData = [];
    this.return_rowData = [];
    this.expense_rowData = [];
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      start_date: this.myDate[0],
      end_date: this.myDate[1],
      supplier_id: this.supplierID
    };
    this.apiService.post('/api/pwa_dms_connects/get_claims_to_raise', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {

          this.sale_rowData = res.results.data.sale;
          this.return_rowData = res.results.data.return;
          this.expense_rowData = res.results.data.expense;
          this.constructSummaryData();
        }
      });
  }

  constructSummaryData() {
    const inThis = this;
    this.summary_rowData = [];
    this.totalInvoices = 0;
    this.totalClaimAmount = 0;
    this.totalQuantity = 0;
    this.totalOutlets = 0;
    const summaryMaster = [];
    if (this.sale_rowData && this.sale_rowData.length > 0) {
      this.sale_rowData.forEach(sale => {
        if (sale.active === false) {

        } else {
          sale.return_amt = 0;
          sale.expense_amt = 0;
          summaryMaster.push(sale);
        }

      });

    }
    if (this.return_rowData && this.return_rowData.length > 0) {
      this.return_rowData.forEach(returns => {
        if (returns.active === false) {

        } else {
          returns.margin = 0;
          returns.discount = 0;
          returns.scheme_discount = 0;
          returns.expense_amt = 0;
          returns.return_amt = returns.price_total;
          summaryMaster.push(returns);
        }
      });
    }
    /*if (this.expense_rowData && this.expense_rowData.length > 0) {
      this.expense_rowData.forEach(expense => {
        if (expense.active === false) {

        } else {
          expense.margin = 0;
          expense.discount = 0;
          expense.scheme_discount = 0;
          expense.return_amt = 0;
          expense.expense_amt = expense.amount;
          summaryMaster.push(expense);
        }
      });
    }*/

    console.log(summaryMaster);
    /*if (summaryMaster && summaryMaster.length > 0) {
      const unique_channels = [...new Set(summaryMaster.map(item => item.channel))];
      console.log(unique_channels);
      if (unique_channels && unique_channels.length > 0) {
        unique_channels.forEach(channel => {
          const channelrows = summaryMaster.filter(x => x.channel === channel);
          const channel_categories = this.sharedService.unique(channelrows, ['category']);
          const channel_categ_master = {
            name: channel,
            categories: []
          };
          channel_categories.forEach(categoryRow => {
            channel_categ_master.categories.push({name: categoryRow['product_categ']});
            const indrow: any = {};
            if (categoryRow['claim_type'] === )

          });
        });
      }
    }*/
    this.totalInvoices = [...new Set(summaryMaster.map(item => item.invoice_no))].length;
    this.totalOutlets = [...new Set(summaryMaster.map(item => item.code))].length;
    const result = [...summaryMaster.reduce((r, o) => {
      const key = o.channel + '-' + o.product_categ;

      const item = r.get(key) || Object.assign({}, o, {
        price_total: 0,
        discount: 0,
        scheme_discount: 0,
        margin: 0,
        return_amt: 0,
        quantity: 0
      });
      item.price_total += o.price_total;
      item.margin += o.margin;
      item.discount += o.discount;
      item.scheme_discount += o.scheme_discount;
      item.return_amt += o.return_amt;
      item.quantity += parseInt(o.quantity, 10);

      return r.set(key, item);
    }, new Map).values()];

    console.log(result);

    if (result && result.length > 0) {
      const unique_channels = [...new Set(result.map(item => item.channel))];
      if (unique_channels && unique_channels.length > 0) {
        let a = 0;
        unique_channels.forEach(channel => {
          const channelrows = result.filter(x => x.channel === channel);
          const tot = channelrows[0].discount + channelrows[0].return_amt;
          inThis.totalClaimAmount += tot;
          inThis.totalQuantity += channelrows[0].quantity;
          inThis.summary_rowData.push({
            a,
            channel: channelrows[0].channel,
            product_categ: channelrows[0].product_categ,
            rowspan: channelrows.length,
            sales: channelrows[0].price_total,
            claim_pct: ((tot) / (channelrows[0].price_total)) * 100,
            claim: tot,
            scheme_discount: channelrows[0].scheme_discount,
            margin: channelrows[0].margin,
            discount: channelrows[0].discount,
            return_amt: channelrows[0].return_amt
          });
          for (let i = 1; i < channelrows.length; i++) {
            const tot1 = channelrows[i].discount + channelrows[i].return_amt;
            inThis.totalClaimAmount += tot1;
            inThis.totalQuantity += channelrows[i].quantity;
            inThis.summary_rowData.push({
              a,
              product_categ: channelrows[i].product_categ,
              sales: channelrows[i].price_total,
              claim_pct: ((tot1) / (channelrows[i].price_total)) * 100,
              claim: tot1,
              scheme_discount: channelrows[i].scheme_discount,
              margin: channelrows[i].margin,
              discount: channelrows[i].discount,
              return_amt: channelrows[i].return_amt
            });
          }
          a++;
        });
      }
    }
    if (this.expense_rowData && this.expense_rowData.length > 0) {
      const activeExpenses = [];
      if (this.expense_rowData && this.expense_rowData.length > 0) {
        this.expense_rowData.forEach(expense => {
          if (expense.active === false) {

          } else {
            activeExpenses.push(expense);
          }
        });
      }

      if (activeExpenses) {
        this.totalExpenses = activeExpenses.length;
        const expense_summary = [...activeExpenses.reduce((r, o) => {
          const key = o.policy_line;
          const item = r.get(key) || Object.assign({}, o, {
            price_total: 0,
            discount: 0,
            scheme_discount: 0,
            margin: 0,
            return_amt: 0,
            quantity: 0,
            amount: 0,
            app_amount: 0,
            rej_amount: 0
          });
          item.amount += o.amount;
          item.app_amount += o.app_amount;
          item.rej_amount += o.rej_amount;

          return r.set(key, item);
        }, new Map).values()];

        if (expense_summary && expense_summary.length > 0) {
          let i = 0;
          if (inThis.summary_rowData && inThis.summary_rowData.length > 0) {
            i = inThis.summary_rowData.length;
          }
          expense_summary.forEach(expense => {
            inThis.totalClaimAmount += expense.app_amount;
            inThis.summary_rowData.push({
              i,
              channel: 'Expense',
              product_categ: expense.policy_line,
              rowspan: 1,
              sales: 0,
              claim_pct: 0,
              claim: expense.app_amount,
              scheme_discount: 0,
              margin: 0,
              discount: 0,
              return_amt: 0
            });
          });
        }
      }
    }
    console.log(this.summary_rowData);
    this.dataLoaded = true;
    /* if (this.summary_gridApi) {
       setTimeout(() => {
         const pinnedBottomData = this.generatePinnedBottomData(this.summary_gridApi, this.summary_gridColumnApi);
         this.summary_gridApi.setPinnedBottomRowData([pinnedBottomData]);
       }, 500);
     }*/
  }

  openConfirmDialog() {
    let message1 = "You are about to Generate Claim of " + this.totalClaimAmount.toFixed(2) + " for ";
    if (this.totalInvoices > 0) {
      message1 +=  this.totalInvoices + " Invoice(s)";
      if (this.totalExpenses > 0) {
        message1 += " and " + this.totalExpenses + " Expense(s)";
      }
    } else if (this.totalExpenses > 0) {
      message1 +=  this.totalExpenses + " Expense(s)";
    }

    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: 'Generate Claim',
        message1,
        message2: "",
        showNoButton: true,
        yesButton: "CONFIRM",
        noButton: "CANCEL"
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {
      console.log(data);
      if (data === 'yes') {
        this.generateClaim();
      } else {
        console.log("Discard");
      }
    });
  }

  generateClaim() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');

    const invoice_ids = [];
    if (this.sale_rowData && this.sale_rowData.length > 0) {
      this.sale_rowData.forEach(sale => {
        if (!sale.hasOwnProperty('active') || sale.active) {
          invoice_ids.push(sale.id);
        }
      });
    }
    if (this.return_rowData && this.return_rowData.length > 0) {
      this.return_rowData.forEach(returns => {
        if (!returns.hasOwnProperty('active') || returns.active) {
          invoice_ids.push(returns.id);
        }
      });
    }
    const expense_ids = [];
    if (this.expense_rowData && this.expense_rowData.length > 0) {
      this.expense_rowData.forEach(expense => {
        if (!expense.hasOwnProperty('active') || expense.active) {
          expense_ids.push(expense.id);
        }
      });
    }
    if (invoice_ids.length > 0 || expense_ids.length > 0) {
      paramObject.supplier_id = this.supplierID;
      paramObject.type = 'reimbursement';
      paramObject.amount = this.totalClaimAmount;
      paramObject.invoice_ids = invoice_ids;
      paramObject.expense_ids = expense_ids;
      this.apiService.post('/api/pwa_dms_connects/generate_scheme_claimsv2', paramObject)
        .subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results')) {
              if (res.results.status === 200) {
                if (res.results.data && res.results.data.id) {
                  this.toastr.success('Claim Generated Successfully');
                  this.dialog.closeAll();
                }
              } else {
                this.toastr.error(res.results.msg);
              }
            } else {
              this.toastr.error('Error Generating Claims');
            }
          },
          error => {
            console.log(error);
            this.toastr.error('Error Generating Claims');
          });
    } else {
      this.toastr.error('No Lines To Process');
    }
  }
}
