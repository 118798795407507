import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DaywiseSalesReport extends ReportBase implements IReport, OnInit {
  apiData: any = [];
  showCustomFilter = false;
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.sharedService.setHeader('DSR - Daywise Salesman Report');
    this.showDate = true;
    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.region = true;
    this.showableFilters.cluster = true;
    this.dateSelectMode = 'single';
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.API_URL = '/api/pwa_v2_reports/daywiseSalesReport';
    this.configureGrid();
  }

  configureGrid() {

    this.columnDefs = [

      {headerName: 'STATE', field: 'state', width: 80},
      {headerName: 'REGION', field: 'region', width: 80},
      {headerName: 'CLUSTER', field: 'cluster', width: 80},
      {headerName: 'DATE', field: 'date', width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', width: 80},
      {headerName: 'SALESMAN', field: 'salesman', width: 80},
      {headerName: 'ROLE', field: 'role', width: 80},
      {headerName: 'SAC', field: 'sac', width: 80},
      {headerName: 'MOBILE', field: 'user_mob', width: 80},
      {headerName: 'EMAIL', field: 'user_email', width: 80},
      {headerName: 'TEAM', field: 'team', width: 80},
      {headerName: 'TEAM LEAD', field: 'tl_name', width: 80},
      {headerName: 'TEAM LEAD CODE', field: 'tl_code', width: 80},
      {headerName: 'AGENDA', field: 'agenda', width: 80},
      {headerName: 'SCHEDULED  BEAT', field: 'beat', width: 80},
      {headerName: 'DISTRIBUTOR', field: 'distributors', width: 80},
      {headerName: 'START LOCATION', field: 'location_name', width: 80},

      {
        headerName: "DISCIPLINARY PARAMETER",
        children: [
          {headerName: 'Attendance Time', field: 'attendance_time',  width: 80},
          {headerName: 'EOD Time', field: 'eod_time', width: 80},
          {headerName: "First Activity Time", field: "first_activity_time",  width: 100},
          {headerName: "Last Activity Time", field: "last_activity_time",  width: 100},
          {headerName: "Retail Time", field: "avg_retail_time", width: 100},
          {headerName: "Working Time", field: "working_mins", width: 100, aggFunc: 'sum'},
          {headerName: "Time Spent / OUTLET", field: "avg_time_spent", width: 100, aggFunc: 'sum'}
        ]
      },
      {
        headerName: "COVERAGE PARAMETERS",
        children: [
          {headerName: 'TC', field: 'tc', width: 80, aggFunc: 'sum'},
          {headerName: "VC", field: "visited_count",  width: 100, aggFunc: 'sum'},
          {headerName: "Beat Jump VC", field: "beat_jump_vc", width: 100, aggFunc: 'sum'},
          {headerName: "KO VC", field: "ko_vc", width: 100, aggFunc: 'sum'},
          {headerName: "PC", field: "productive_calls",  width: 100, aggFunc: 'sum'},
          {headerName: "Beat Jump PC", field: "beat_jump_pc", width: 100, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data) {
                if (params.data.beat_jump_pc !== 0) {
                  return (params.data.beat_jump_pc).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: "Beat Jump PC (Val)", field: "us_ord_val", width: 100, aggFunc: 'sum'},
          {headerName: "KO PC", field: "ko_pc", width: 100, aggFunc: 'sum'},
          {headerName: "Productivity% (PC/VC)",  width: 100, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.visited_count !== 0) {
                  return ((params.data.productive_calls / params.data.visited_count) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: "Coverage% (VC/TC)" , width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.tc !== 0) {
                  return ((params.data.visited_count / params.data.tc) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            },  aggFunc: this.avgAggFunction
          }
        ]
      },
      {
        headerName: "SALES DETAILS",
        children: [
          {headerName: 'Secondary Sales', field: 'sec_sales',  width: 80, aggFunc: 'sum'},
          {headerName: 'Scheme Sales', field: 'schm_ords',  width: 80, aggFunc: 'sum'},
          {headerName: 'QTY(In Base UOM)', field: 'ord_qty',  width: 80, aggFunc: 'sum'},
          {headerName: 'QTY(In Reporting UOM)', field: 'report_qty',  width: 80, aggFunc: 'sum'},
          {headerName: 'Order Volume', field: 'ord_qty_volume',  width: 80, aggFunc: 'sum'},

          {headerName: 'Qty Delivered', field: 'dlr_qty',  width: 80, aggFunc: 'sum'},
          {headerName: 'Delivered Volume', field: 'dlr_qty_volume',  width: 80, aggFunc: 'sum'},
          {headerName: 'Fill Rate',   width: 80, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.ord_qty !== 0) {
                  return ((params.data.dlr_qty / params.data.ord_qty) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }},
          {headerName: 'Total Lines', field: 'total_sku',  width: 80, aggFunc: 'sum'},
          {headerName: 'ALS', field: 'als',  width: 80, aggFunc: 'sum'},
          {headerName: 'Avg Order Value', field: 'avg_value_pc',  width: 80, aggFunc: this.avgAggFunction},
        ]
      },
      {
        headerName: "Primary",
        children: [
          {headerName: 'Planned', field: 'supplier_planned_visits',  width: 80, aggFunc: 'sum'},
          {headerName: 'Completed', field: 'supplier_completed_visits',  width: 80, aggFunc: 'sum'},
          {headerName: 'Time Spent', field: 'supp_hours_spent',  width: 80, aggFunc: 'sum'},
          {headerName: 'Primary Sales', field: 'pri_sales',  width: 80, aggFunc: 'sum'},
        ]
      },
      {
        headerName: "TARGETS",
        children: [
          {headerName: 'Monthly Target', field: 'sec_target',  width: 80, aggFunc: this.avgAggFunction},
          {headerName: 'MTD Ach', field: 'sec_ach',  width: 80, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.sec_ach !== 0) {
                  return (params.data.sec_ach).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'MTD Balance',  width: 80, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.sec_target !== 0) {
                  return (params.data.sec_target - params.data.sec_ach).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'MTD Ach%',  width: 80, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.sec_target !== 0) {
                  return  ((params.data.sec_ach / params.data.sec_target ) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'DRR (Daily Required Rate)', field: 'drr',  width: 80, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.drr !== 0) {
                  return (params.data.drr).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'CSR (Current Sales Rate)', field: 'csr',  width: 80, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.csr !== 0) {
                  return (params.data.csr).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'RSR (Required Sales Rate)', field: 'rrr',  width: 80, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.rrr !== 0) {
                  return (params.data.rrr).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'Day Target', field: 'schedule_target',  width: 80, aggFunc: this.avgAggFunction},
          {headerName: 'Day Ach', field: 'beat_revenue',  width: 80, aggFunc: this.avgAggFunction},
          {headerName: 'Day Ach%',  width: 80, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.schedule_target !== 0) {
                  return  ((params.data.beat_revenue / params.data.schedule_target ) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'DBY Target', field: 'dfy_target',  width: 80, aggFunc: this.avgAggFunction},
          {headerName: 'DBY Ach', field: 'dfy_revenue',  width: 80, aggFunc: this.avgAggFunction},
          {headerName: 'DBY Ach%',  width: 80, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.dfy_target !== 0) {
                  return  ((params.data.dfy_revenue / params.data.dfy_target ) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'Yesterday Target', field: 'yesterday_target',  width: 80, aggFunc: this.avgAggFunction},
          {headerName: 'Yesterday Ach', field: 'yesterday_revenue',  width: 80, aggFunc: this.avgAggFunction},
          {headerName: 'Yesterday Ach%',  width: 80, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.yesterday_target !== 0) {
                  return  ((params.data.yesterday_revenue / params.data.yesterday_target ) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'Tomorrow Target', field: 'tomo_target',  width: 80, aggFunc: 'sum'},
          {headerName: 'Schedule Target (MTD)', field: 'sch_mtd_target',  width: 80, aggFunc: 'sum'},
          {headerName: 'Schedule Ach (MTD)', field: 'sch_mtd_ach',  width: 80, aggFunc: this.avgAggFunction},
          {headerName: 'Schedule Balance (MTD)',  width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.sch_mtd_target !== 0) {
                  return (params.data.sch_mtd_target - params.data.sch_mtd_ach).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }, aggFunc: this.sumAggFunction
          },
          {headerName: 'Schedule Ach%',  width: 80, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.sch_mtd_target !== 0) {
                  return  ((params.data.sch_mtd_ach / params.data.sch_mtd_target ) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },

          {headerName: 'LY Schedule Ach (MTD)', field: 'ly_sch_mtd_ach',  width: 80, aggFunc: this.avgAggFunction},
          {
            headerName: "Growth(%)",  field: "ach_growth_pct",
            cellStyle(params) {
              if (!params.data || params.data.ach_growth_pct === undefined || params.data.ach_growth_pct === '') {
                return { textAlign: 'right' };
              } else if (params.data.ach_growth_pct <= -2) {
                return {textAlign: 'right', backgroundColor: '#F8D1C8'};
              } else if (params.data.ach_growth_pct > -2 && params.data.ach_growth_pct <= 0) {
                return {textAlign: 'right', backgroundColor: '#F0E199'};
              } else if (params.data.ach_growth_pct > 0) {
                return {textAlign: 'right', backgroundColor: '#B3DFB0'};
              } else {
                return {textAlign: 'right'};
              }
            },
            width: 50,
            valueGetter: params => {
              if (params.data) {
                if (params.data.ach_growth_pct !== 0) {
                  return (params.data.ach_growth_pct).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            },  aggFunc: this.avgAggFunction
          },
          {headerName: 'Planned Target', field: 'sch_month_target',  width: 80, aggFunc: this.avgAggFunction},

        ]
      },
      {
        headerName: "NEW OUTLETS",
        children: [
          {headerName: 'New Outlets', field: 'new_outlets',  width: 80, aggFunc: 'sum'},
          {headerName: 'New Outlets PC', field: 'new_outlet_pc',  width: 80, aggFunc: 'sum'},
          {headerName: 'New Outlets Sales', field: 'new_outlets_sales',  width: 80, aggFunc: 'sum'}
        ]
      },
      {
        headerName: "ASSETS DETAILS",
        children: [
          {headerName: 'Requested', field: 'created',  width: 80, aggFunc: 'sum'},
          {headerName: 'Approved', field: 'approved',  width: 80, aggFunc: 'sum'},
          {headerName: 'Rejected', field: 'rejected',  width: 80, aggFunc: 'sum'}
        ]
      },
      {
        headerName: "SURVEY",
        children: []
      }
    ];

    let uniqueSurveys = [];
    if (this.apiData.survey && this.apiData.survey.length > 0){
      uniqueSurveys = this.apiData.survey.map(p => p.name)
        .filter((type, index, arr) => arr.indexOf(type) === index);

      for (const key in uniqueSurveys) {
        this.columnDefs[24].children.push(
          {headerName: uniqueSurveys[key], field: uniqueSurveys[key], menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}},
        );
      }
    }
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  avgAggFunction(params) {
    let avg;
    let sum = 0;
    let count = 0;
    params.values.forEach(value => {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // Aggregating groups: Use weighted average approach
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // Ignore non-numeric values
        if (typeof value === 'number') {
          sum += value;
          count++;
        } else if (typeof value === 'string') {
          sum += parseFloat(value);
          count++;
        }
      }
    });

    // Avoid division by zero
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    // Preserve up to 2 decimal places
    avg = avg !== null ? parseFloat(avg.toFixed(2)) : null;

    return {
      count,
      avg,
      toString() {
        return this.avg !== null ? this.avg.toFixed(2) : "0.00"; // Ensure consistent display
      },
    };
  }

  sumAggFunction(params) {
    let sum = 0;
    params.values.forEach(value => {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // Aggregating groups: Use weighted average approach
        sum += value.avg * value.count;
      } else {
        // Ignore non-numeric values
        if (typeof value === 'number') {
          sum += value;
        } else if (typeof value === 'string') {
          sum += parseFloat(value);
        }
      }
    });
    return{
      sum,
      toString() {
        return this.sum !== null ? this.sum.toFixed(2) : "0.00"; // Ensure consistent display
      }
    };
  }

  configureData() {
    this.rowData = [];
    for (const key in this.apiData.main) {
      let indrow = {};
      let mainrow: any = {};
      let surveysrow: any = [];
      const surveyrow: any = [];

      mainrow = this.apiData.main[key];
      if (this.apiData.survey && this.apiData.survey.length > 0){
        surveysrow = this.apiData.survey.filter(survey => survey.user_id === mainrow.salesman_id);
        if (surveysrow != null && surveysrow.length > 0) {
          for (const i in surveysrow) {
            surveyrow[surveysrow[i].name] = surveysrow[i].survey_cnt;
          }
        }
      }
      indrow = {
        ...mainrow,
        ...surveyrow
      };
      this.rowData.push(indrow);

    }
  }
}
