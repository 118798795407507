import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";
import {ErrortooltipComponent} from "../../errortooltip";

@Component({
  selector: 'app-asset-request-master-list',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})

export class AssetRequestMasterListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'Requested',
    values: [],
    show: true
  };

  ngOnInit(): void {


    this.customFilter.values = [
      {id: "", itemName: "All"},
      {id: "Requested", itemName: "Requested"},
      {id: "Approved", itemName: "Approved"},
      {id: "Confirmed", itemName: "Confirmed"},
      {id: "Rejected", itemName: "Rejected"}
    ];

    this.showCustomFilter = true;
    this.showActionButton = false;
    this.showDate = true;

    this.gridOptions.getRowStyle = this.getRowStyleFunc;
    this.frameworkComponents = {
      errortooltipComponent: ErrortooltipComponent
    };
    this.showPinnedFooter = true;
    // this.getAuthorizedActions();
    // this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();

    this.sharedService.setHeader('Batch List');
  }

  getRowStyleFunc(params) {
    if (params.data && params.data.id === 'TOTAL') {
      return {'font-weight': 'bold'};
    }
  }

  configureGrid() {
    this.API_URL = '/api/request_masters/get_asset_request_master_list';

    this.columnDefs = [
      {
        headerName: "ID",
        field: "id",
        width: 20
      }, {
        headerName: "NAME",
        field: "name",
        width: 20
      },
      {
        headerName: "Status", field: "status", width: 40
      }, {
        headerName: "TOTAL",
        field: "asset_ids_count",
        width: 20
      }, {
        headerName: "APPROVED",
        field: "approved_ids_count",
        width: 20
      }, {
        headerName: "REJECTED",
        field: "rejected_ids_count",
        width: 20
      },
      {
        headerName: "REQUEST",
        children: [
          {headerName: "DATE", field: "create_date", width: 80},
          {headerName: "USER", field: "requested_by", width: 80},
        ]
      },
      {
        headerName: "ACTION",
        children: [
          {headerName: "USER", field: "approved_by", width: 40},
          {headerName: "DATE", field: "approved_on", width: 50},
        ]
      }
    ];

    this.getRowId = (params) => params.data.id;
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      this.router.navigate([this.apiService.redirect_url + '/assets/request_master_detail'], {
        relativeTo: this.route,
        queryParams: {id: rows[0].id}
      });
    }
  }

  checkAccessControl() {
    this.showCreate = true;
  }

  onFilterChanged($event) {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }
}
