import {ChangeDetectorRef, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';

// @ts-ignore
import {IReport} from './ireport';

import {ngxCsv} from 'ngx-csv/ngx-csv';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

import {DomSanitizer} from '@angular/platform-browser';
import {MatMenuTrigger} from '@angular/material';
import {GridOptions, RowNode} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../_services/api.service";
import {SharedService} from "../_services/shared_service";
import {ErrorObject} from "../error/error-model";
import {ErrorHandler} from "../error/error-handler";
import {ToastrService} from "ngx-toastr";
import * as XLSX from "xlsx-js-style";
import {LocalforageService} from "../_services/localforage.service";
import {ConfirmDialogComponent} from "../components/confirmdialog/confirmdialog.component";
import {StockUploadComponent} from "../dmsv2/stock/upload_stock/stock_upload.component";
import {AppComponent} from "../app.component";
import {BasePrintComponent} from "../components/base_print/base_print.component";
import {ExcelExportService} from "../_services/ExcelExportService";
import {GridsterConfig, GridsterItem} from "angular-gridster2";

export abstract class PageBase implements IPage, OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('fileUp') fileUp: ElementRef;

  USER;
  apiData: any = [];
  interPrint;
  public tabIndex = 0;
  public prevIndex;
  public showApprove = false;
  public showApproveBtn = true;
  public showRejectBTn = true;
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: [],
      payment_mode: ''
    },
    customFilter: {},
    searchableFilter: 0,
    multiFilter: {}
  };
  showMultiFilter = false;
  showCustomFilter = false;
  showCustomFilter1 = false;
  multiFilter: any = {};
  customFilter: any = {};
  customFilter1: any = {};
  selectedPeriod: any = {};
  periodID: 0;
  showDate = true;
  dateFilterType: any = 'range';
  title = '';
  preloader: any;
  emptyState: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;

  myDate: any;
  searchValue: any;
  gridOptions: GridOptions;
  styleGrid: any;
  columnDefs = [];
  frameworkComponents: any = {};
  context: any;

  filterData: any;

  API_URL = '';

  rowData = [];
  public getRowId;
  public gridColumnApi: any;
  public gridApi: any;
  public pinnedParams = [];
  dateSelectMode = 'range';

  public queryParams = {};

  showableFilters = {
    max_claim: false,
    policy: false,
    salesman: false,
    status: false,
    class: false,
    beat: false,
    trax_outlet: false,
    non_trax_outlet: false,
    exclude_trax_audit: false,
    region: false,
    cluster: false,
    team: false,
    territory: false,
    outlet_type: false,
    payment_mode: false,
    dc: false,
    state: false
  };
  filterSet = false;
  progress = 0;
  progressText = '';
  showProgress = false;
  showProgressBar = false;
  progressMode = 'indeterminate';
  showProgressCustomText = false;
  progressTotal = 0;
  keyColumn = '';
  showCreate = false;
  showUpload = false;
  public collapse_disable;
  uploadTask = false;
  showBaseUpload = false;
  showZipDownload = false;
  showDefaultFilter = false;
  showCustomerFilter = true;
  uploadTaskwithType = false;
  uploadTypeList = [];
  selectedUploadType;
  public uploadCaption = ['', 0];
  public fileUploaded: File;
  filesize = 0;
  public storeData: any;
  public worksheet: any;
  public uploading = false;
  public upload_loader = false;
  public upload_enable = false;
  public upload_task_data;
  public order_data = [];
  public showExcelSample = false;
  public excel_sample = [];
  public excel_sample_title = '';
  matActionMenuItemDeclaration = [];
  matAuthorizedActionMenuItems = [];
  showActionButton = false;
  public subdomain: string;
  public container = '';

  collapse_disabled = false;

  viewGrid = true;
  viewGallery = false;
  viewMap = false;

  showViewSelector = false;
  showGalleryview = false;
  showMapView = false;
  selectedGalleryItem;

  galleryData = {
    pageSize: 50,
    id: 'id',
    image: '',
    name: '',
    field1: '',
    field2: '',
    field3: '',
    status: '',
    statusTypes: [],
    reviewed: '',
    reviewTypes: {
      yes: 'approved',
      no: 'rejected'
    },
    container: '',
    selectionCriteria: []
  };
  MapViewMetaData = {
    id: 'id',
    title: '',
    latitude: 'lat',
    longitude: 'long',
    color: '',
    field1: '',
    field2: ''
  };
  filteredData = [];
  pageData = [];
  goClicked = false;
  columnState = [];
  selectedCount = 0;
  allSelect = false;
  agGridFilter;


  showPinnedFooter = false;
  columnsWithAggregation = [];
  statusConfig = {
    success: 'success',
    success1: 'success',
    error: 'fail',
    error1: 'fail',
    warning: 'warning',
    warning1: 'warning',
    new: 'new',
    new1: 'new',
    processed: 'processed'
  };
  public orderDetails = {label_config: {}};
  public print_details = [];
  public payment_enable: boolean;
  printType = 'Invoice';

  customerList = [];
  supplierList = [];
  mapkey = '';
  showAnalysis = false;
  analysisData: Array<GridsterItem>;
  analysisGridoptions: GridsterConfig;
  analysisGridRows = 1;
  agTextFilterTypes = { contains: 'contains', notContains: 'notContains', equals: 'equals', notEqual: 'notEqual', startsWith: 'startsWith', endsWith: 'endsWith'};
  agNumberFilterTypes = {
    equals: 'equals', notEqual: 'notEqual', greaterThan: 'greaterThan', greaterThanOrEqual: 'greaterThanOrEqual',
    lessThan: 'lessThan', lessThanOrEqual: 'lessThanOrEqual', inRange: 'inRange', blank: 'blank', notBlank: 'notBlank'};

  constructor(public apiService: ApiService,
              protected router: Router,
              protected route: ActivatedRoute,
              public elRef: ElementRef,
              protected renderer: Renderer2,
              public datePipe: DatePipe,
              public sharedService: SharedService,
              public dialog: MatDialog,
              public toastr: ToastrService,
              private componentFactoryResolver: ComponentFactoryResolver,
              protected changeDetector: ChangeDetectorRef,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent,
              protected _localCacheService: LocalforageService,
              protected excelExportService: ExcelExportService) {

    this.USER = JSON.parse(localStorage.getItem('user'));

    const date = new Date();
    if (this.dateSelectMode === 'single') {
      this.myDate = this.datePipe.transform(date, 'yyyy-MM-dd');
      this.userData.start_date = this.myDate;
      this.userData.end_date = this.myDate;
    } else {
      this.myDate = [];
      this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
      this.myDate[1] = this.datePipe.transform(date, 'yyyy-MM-dd');
      this.userData.start_date = this.myDate[0];
      this.userData.end_date = this.myDate[1];
    }


    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];
    this.userData.offset = -1 * (new Date(this.userData.start_date)).getTimezoneOffset();
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.userData.url = localStorage.getItem('private_url');
    this.userData.offsetID = 0;

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;
    this.analysisGridoptions = {
      gridType: "verticalFixed",
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      minCols: 12,
      maxCols: 12,
      minRows: 1,
      maxRows: 60,
      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedRowHeight: 130,
      keepFixedHeightInMobile: true,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true
      },
      resizable: {
        enabled: true
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: {north: true, east: true, south: true, west: true},
      pushResizeItems: false,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false
    };

    /* this.gridOptions.onColumnVisible = event => {
       if (event.visible) {
         console.log(event.column.colId + ' was made visible');
       } else {
         console.log(event.column.colId  + ' was hidden')
       }
     };*/

    // this.getAuthorizedActions();
    this.mapkey = localStorage.getItem('map_key');
    this.getSubdomain();
    this.sharedService.filterSetEvent
      .subscribe((set: boolean) => {
        if (set) {
          this.filterSet = true;
        } else {
          this.filterSet = false;
        }
      });
  }

  ngOnInit() {
    /*  const t = trace(this.appModule.perf, 'pages');
      t.start();
      t.putAttribute('reports', this.API_URL);*/
    this.dateSelectMode = 'range';


  }

  getAnalysisGridHeight() {
    if (this.showAnalysis) {
      return ((this.analysisGridRows * this.analysisGridoptions.fixedRowHeight) + 30) + 'px';
    } else {
      return '0px';
    }
  }

 async analysisGridClick(item) {
    if (item.filter) {
      item.selected = !item.selected;
      item = {...item};
      const currdata = this.analysisData;
      this.analysisData = [];
      setTimeout(() => {
        this.analysisData = [...currdata];
        this.showAnalysis = false;
        this.showAnalysis = true;
        this.analysisGridFilter(item);
      }, 10);
    }
  }

  async analysisGridFilter(item) {

  }

  readPageParms(callback) {
    this.route.queryParamMap
      .subscribe(params => {
        // this.activeID = Number(params.get('id'));
        this.queryParams = params;
        // if (this.active_ids) {
        //   this.rowIndex = this.active_ids.indexOf(this.activeID);
        //   this.listLength = this.active_ids.length;
        // }

        return callback(params);
      });
  }

  getSubdomain() {
    const domain = window.location.hostname;
    this.subdomain = domain.split('.')[0];
  }

  generateImageUrl(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data[field] === null || params.data[field] === "") {
          return '';
        } else {
          const url = 'https://' + this.subdomain + '.salesdiary.in/imagerender?url=' + this.apiService.api_url +
            '&sf=' + params.data[field] + '&cont=' + this.container;
          return url;
        }
      } else {
        return '';
      }
    };
  }

  generateGalleryImageUrl(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data[field] === null || params.data[field] === "") {
          return '';
        } else {
          if (params.data[field].length > 0) {
            let urls = '';
            params.data[field].forEach(item => {
              const image_url = 'https://' + this.subdomain + '.salesdiary.in/imagerender?url=' + this.apiService.api_url +
                '&sf=' + item + '&cont=' + this.container;
              urls += image_url + ', ';
            });
            urls = urls.substring(0, urls.length - 2);
            return urls;
          } else {
            return '';
          }
        }
      }
    };
  }

  getAuthorizedActions() {
    if (this.matActionMenuItemDeclaration && this.matActionMenuItemDeclaration.length > 0) {
      this.matAuthorizedActionMenuItems = [];
      this.matActionMenuItemDeclaration.forEach((actionItem) => {
        if (actionItem.feature === undefined) {
          this.matAuthorizedActionMenuItems.push(actionItem);
        } else if (this.sharedService.ACLcheck(actionItem.feature)) {
          this.matAuthorizedActionMenuItems.push(actionItem);
        }
      });
      if (this.matAuthorizedActionMenuItems && this.matAuthorizedActionMenuItems.length > 0) {
        this.showActionButton = true;
      } else {
        this.showActionButton = false;
      }
    } else {
      this.showActionButton = false;
    }
  }

  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.emptyState = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }


  loadServerData() {
    this.goClicked = true;
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;
    this.pageData = [];
    this.selectedCount = 0;
    this.showApprove = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    if (this.showCustomFilter) {
      if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
        this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
      }
    }
    if (this.showCustomFilter1) {
      if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter1.key)) {
        this.userData.customFilter[this.customFilter1.key] = this.customFilter1.value;
      }
    }
    if (this.dateSelectMode === 'single') {
      this.userData.start_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    } else {
      this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    }

    if (this.showProgress === false) {

      //Load Data in one shot
      this.apiService.post(this.API_URL, this.userData)
        .subscribe(res => {
            console.log(res);
            // t.stop();
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {
              this.apiData = res.results.data; //TODO: check if this line is needed
              this.postProcessData();
              //this.rowData should be ready now
              this.preloader = false;
              if (this.rowData === null || this.rowData === undefined) {
                this.emptyState = true;
              } else if (this.rowData.length === 0) {
                this.emptyState = true;
              } else {
                this.dataLoaded = true;
              }
            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.emptyState = true;
              if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
                console.error(res.results.errmsg);
              }
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.emptyState = true;
              if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
                console.error(res.results.errmsg);
              }
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          // tslint:disable-next-line:no-shadowed-variable
          error => this.handleError(ErrorHandler.getErrorObject(error)));
    } else {
      //Load data in batches
      this.loadInBatches(this.userData.offset, this.keyColumn);
      this.fetch(this.keyColumn);
    }
  }

  loadInBatches(limit, keyColumn) {
    this.showProgress = true;
    this.showProgressBar = true;
    this.progress = 0;
    this.progressText = '';
    this.progressTotal = 0;
    this.userData.offsetID = 0;
    this.userData.offset = limit;
    this.keyColumn = keyColumn;
    this.apiData = [];
  }

  loadInBatchesAll(limit, keyColumn) {
    this.showProgress = true;
    this.showProgressBar = true;
    /* this.progress = 0;
     this.progressText = '';
     this.progressTotal = 0;*/
    this.userData.offsetID = 0;
    this.userData.offset = limit;
    this.keyColumn = keyColumn;
  }

  async fetch(key) {
    const res = await this.apiService.postPromise(this.API_URL, this.userData);
    console.log(res);
    if (res.hasOwnProperty('results') && (res.results.status === 200)) {
      // console.log(res);
      //this.apiData.push(res.results.data);
      for (const item of res.results.data) {
        this.apiData.push(item);
      }
      if (this.apiData === null || this.apiData === undefined) {
        this.postProcessData();
        this.dataLoaded = true;
        this.preloader = false;
        this.emptyState = (this.rowData.length === 0);
      } else if (this.apiData.length === 0) {
        this.postProcessData();
        this.dataLoaded = true;
        this.preloader = false;
        this.emptyState = (this.rowData.length === 0);
      } else {


        if (this.apiData[0].hasOwnProperty('total_prog')) {
          this.progressTotal = this.apiData[0].total_prog;
          this.showProgressBar = true;
          this.progressMode = 'determinate';
        } else {
          this.showProgressBar = false;
          this.progressTotal = 0;
        }
        if (res.results.data.length === this.userData.offset) {
          this.userData.offsetID = this.apiData[this.apiData.length - 1][key];
          if (this.progressTotal !== 0) {
            this.progress = Math.round((this.apiData.length / this.progressTotal) * 100);
            this.progressText = this.apiData.length + '/' + this.progressTotal;
          } else {
            this.progressText = 'Downloaded ' + this.apiData.length + ' records';
          }

          await this.fetch(key);
        } else {
          this.postProcessData();
          // this.rowData = this.postProcessData(this.apiData);
          this.dataLoaded = true;
          this.preloader = false;
          this.emptyState = (this.rowData.length === 0);
        }
      }
    } else if (res.hasOwnProperty('results') && res.results.status === 203) {
      if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
        console.error(res.results.errmsg);
      }
      this.handleError(ErrorHandler.getErrorObject(res.results.msg));
    } else {
      if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
        console.error(res.results.errmsg);
      }
      this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
    }
  }

  postProcessData() {
    this.rowData = this.apiData;
    this.filteredData = this.rowData;
    return;
  }


  removeFilter(displayData: any) {
    this.sharedService.filterCancelCLick(displayData.key);
  }

  setFilter(filterdata) {
    this.userData.filterData.displayData = [...this.userData.filterData.displayData];
    this.userData.filterData = filterdata;
    this.trigger.closeMenu();
    this.loadServerData();
  }

  setMultiFilter(multifilterdata) {
    console.log(multifilterdata);

  }

  setMultiFilterString(multifilterdata) {
    console.log(multifilterdata);
    if (multifilterdata.includes("all")) {
      this.userData.multiFilter = ["all"];
    } else {
      this.userData.multiFilter = multifilterdata;
    }

  }

  setCustomFilter(customfilterdata) {

    for (const [key, value] of Object.entries(customfilterdata)) {
      this.userData.customFilter[key] = value;
    }
  }

  changeActiveTab(event) {
    this.customFilter.value = this.customFilter.values[event.index].id;
    this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
    this.loadServerData();
  }

  changeDate(dt) {
    if (this.dateSelectMode === 'single') {
      this.userData.start_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    } else {
      this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    }
  }

  onColumnVisible(event) {
    if (this.gridColumnApi !== null) {
      const columnState = JSON.stringify(this.gridColumnApi.getColumnState());
      localStorage.setItem('myColumnState', columnState);
    }
  }

  onDragStopped(event) {
    if (this.gridColumnApi !== null) {
      const columnState = JSON.stringify(this.gridColumnApi.getColumnState());
      localStorage.setItem('myColumnState', columnState);
    }

  }

  onFilterChanged(event) {
    this.agGridFilter = this.gridApi.getFilterModel();
    this.sharedService.currListData = {
      url: this.router.url,
      userdata: this.userData,
      searchText: document.getElementById('filter-text-box')['value'],
      agGridFilter: this.agGridFilter
    };
  }

  onFilterModified(event) {
    this.agGridFilter = this.gridApi.getFilterModel();
    this.sharedService.currListData = {
      url: this.router.url,
      userdata: this.userData,
      searchText: document.getElementById('filter-text-box')['value'],
      agGridFilter: this.agGridFilter
    };
  }

  async resetModelFilter(model) {
    //await this.gridApi.setFilterModel(this.agGridFilter);
    await model.setModel(null);
    //await this.gridApi.setColumnFilterModel('pc', null);
    this.gridApi.onFilterChanged();
  }

  async agTextFilter(field, type, text ) {
    this.agGridFilter = this.gridApi.getFilterModel();
    this.agGridFilter[field] = {type, filter: text};
    await this.gridApi.setFilterModel(
      this.agGridFilter
    );
  }

  async agNumberFilter(field, type, text ) {
    //types = ['equals', 'notEqual', 'greaterThan', 'greaterThanOrEqual', 'lessThan', 'lessThanOrEqual', 'inRange', 'blank', 'notBlank']
    this.agGridFilter = this.gridApi.getFilterModel();
    this.agGridFilter[field] = {type, filter: text};
    await this.gridApi.setFilterModel(
      this.agGridFilter
    );
  }

  async setNullModelFilter(field: string ) {
    this.agGridFilter = this.gridApi.getFilterModel();
    this.agGridFilter[field] = {type: 'set', values: [null]};
    await this.gridApi.setFilterModel(
      this.agGridFilter
    );
  }

  async setExistsModelFilter(field: string ) {
    this.agGridFilter = this.gridApi.getFilterModel();
    this.agGridFilter[field] = {type: 'set', values: [true]};
    await this.gridApi.setFilterModel(
      this.agGridFilter
    );
  }

  onCellClicked(event) {

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllDisplayedColumns().length;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    if (this.showPinnedFooter && this.columnsWithAggregation.length > 0) {
      setTimeout(() => {
        const pinnedBottomData = this.generatePinnedBottomData();
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
          if (inThis.pinnedParams.length > 0) {
            inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
          }
        } else {
          params.columnApi.autoSizeAllColumns();
          inThis.sharedService.clearPinned(inThis.gridOptions);
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
      if (inThis.pinnedParams.length > 0) {
        inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
      }
    } else {
      this.gridColumnApi.autoSizeAllColumns();
      inThis.sharedService.clearPinned(inThis.gridOptions);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    /* const columnState = JSON.parse(localStorage.getItem('myColumnState'));
     if (columnState) {
       this.gridColumnApi.applyColumnState(
         {
           state: columnState,
           applyOrder: true,
         }
       );
     }*/

    /*fetch('C:\Users\Nivil\Downloads\pwaconfig.txt').then(response => response.text())
      .then(data => {
        // Do something with your data
        console.log(data);
      });*/

    if (this.sharedService.currListData && this.sharedService.currListData.url === this.router.url) {
      this.agGridFilter = this.sharedService.currListData.agGridFilter;
      this.gridApi.setFilterModel(this.agGridFilter);
    }
    if (this.columnState) {
      this.gridColumnApi.applyColumnState(
        {
          state: this.columnState,
          applyOrder: true,
        }
      );
      this.quickSearch();
    }
  }

  generatePinnedBottomData() {
    // generate a row-data with null values
    const result = {};

    this.gridColumnApi.getAllGridColumns().forEach(item => {
      result[item.colId] = null;
    });
    return this.calculatePinnedBottomData(result);
  }

  calculatePinnedBottomData(target: any) {
    //console.log(target);
    //list of columns fo aggregation

    this.columnsWithAggregation.forEach(element => {
      this.gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        //if(rowNode.index < 10){
        //console.log(rowNode);
        //}
        if (rowNode.data[element]) {
          if (Number(rowNode.data[element]) % 1 !== 0) {
            target[element] += Number(parseFloat(rowNode.data[element]).toFixed(2));
          } else {
            target[element] += Number(rowNode.data[element]);
          }
        }
      });
      if (target[element]) {
        if (target[element] % 1 !== 0) {
          target[element] = `${target[element].toFixed(2)}`;
        }
      }
    });
    //console.log(target);
    // target['athlete'] = 'Total';
    if (this.rowData && this.rowData.length > 0) {
      target[this.columnDefs[0].field] = 'TOTAL';
    }
    return target;
  }


  quickSearch() {
    const searchtext = document.getElementById('filter-text-box')['value'];
    if (this.gridOptions['api']) {
      this.gridApi.setQuickFilter(searchtext);
      //this.filteredData = this.gridApi.rowModel.rowsToDisplay;
      this.sharedService.currListData = {
        url: this.router.url,
        userdata: this.userData,
        searchText: document.getElementById('filter-text-box')['value'],
        agGridFilter: this.agGridFilter
      };
    }
    if (this.rowData) {
      this.filteredData = this.rowData.filter(x =>
        (x[this.galleryData['name']] && x[this.galleryData['name']].toLocaleLowerCase().includes(searchtext.toLocaleLowerCase())) ||
        (x[this.galleryData['field1']] && x[this.galleryData['field1'].toLocaleLowerCase()].includes(searchtext.toLocaleLowerCase())) ||
        (x[this.galleryData['field2']] && x[this.galleryData['field2'].toLocaleLowerCase()].includes(searchtext.toLocaleLowerCase())) ||
        (x[this.galleryData['field3']] && x[this.galleryData['field3'].toLocaleLowerCase()].includes(searchtext.toLocaleLowerCase())) ||
        (x[this.galleryData['status']] && x[this.galleryData['status'].toLocaleLowerCase()].includes(searchtext.toLocaleLowerCase())));
    }
  }

  setPeriod(selectedPeriod: any) {
    this.selectedPeriod = selectedPeriod;
    this.myDate = [];
    this.myDate[0] = selectedPeriod.start_date;
    this.myDate[1] = selectedPeriod.end_date;
    this.userData.start_date = selectedPeriod.start_date;
    this.userData.end_date = selectedPeriod.end_date;
    this.periodID = selectedPeriod.id;
    this.userData.period_id = this.periodID;

  }

  onBtExport() {
    const header = this.sharedService.headerTitleSource.getValue();
    const params = {
      fontSize: undefined,
      rowHeight: undefined,
      headerRowHeight: undefined,
      fileName: header + '.csv'
    };
    this.gridApi.exportDataAsExcel(params);
  }

  onBtnClick(gridOption, title) {
    const exportData = [];
    const headers = [];
    const fields = [];
    gridOption.columnDefs.forEach(header => {
      if (header.hasOwnProperty('children')) {
        header.children.forEach(each_child => {
          if (each_child.hide === undefined || each_child.hide === false) {
            headers.push(each_child.headerName);
            fields.push(each_child.field);
          }
        });
      } else {
        if (header.hide === undefined || header.hide === false) {
          headers.push(header.headerName);
          fields.push(header.field);
        }
      }
    });

    let passObject = {};

    gridOption.api.forEachNode(node => {
      passObject = {};
      fields.forEach(key => {
        if ((node.data[key] != null) && (typeof (node.data[key]) === 'object')) {
          passObject[key] = node.data[key][Object.keys(node.data[key])[0]];
        } else {
          passObject[key] = node.data[key];
        }
      });
      exportData.push(passObject);
    });

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'report',
      useBom: true,
      noDownload: false,
      headers
    };

    // tslint:disable-next-line:no-unused-expression
    new ngxCsv(exportData, 'report', options);
  }


  gridSearch(searchText) {
    this.gridApi.setQuickFilter(searchText);
  }

  configureGrid() {
  }

  setTitle() {
  }

  openCreateDialog() {
  }

  showUploadPopup() {
  }

  uploadedFile(event) {

    this.uploadCaption = ['', 0];
    this.fileUploaded = event.target.files[0];
    this.filesize = Math.round(this.fileUploaded.size / 1024);
    this.readExcel();
  }

  zipDownload() {
  }

  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];

      this.upload_task_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  uploadexcel() {
  }

  bulkUpdateProgress(state) {
  }

  onSelectionChanged(event) {
  }

  onMenuItemSelected(menuitem) {

  }

  unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }

  checkAccessControl() {
  }

  viewSelector(selectedview) {
    /*  if (this.gridOptions.api) {
        this.filteredData = this.gridApi.rowModel.rowsToDisplay;
      }*/
    if (this.gridOptions.columnApi) {
      this.columnState = this.gridColumnApi.getColumnState();
      this.gridColumnApi.setRowGroupColumns([]);
    }
    if (this.gridOptions.api) {
      const filter = this.gridApi.rowModel.rowsToDisplay;
      this.filteredData = [];
      for (const key in filter) {
        if (filter[key].hasOwnProperty('data')) {
          this.filteredData.push(filter[key].data);
        }
        /*  if (filter[key].group === true) {
            for (const key1 in filter[key].childrenAfterFilter) {

            }
          } else {
            this.filteredData.push(filter[key].data);
          }*/
      }
    } else {
      this.showApprove = false;
      this.filteredData = this.rowData;
    }
    if (selectedview === 'gallery') {
      this.viewGallery = true;

      this.viewGrid = false;
      this.viewMap = false;

    } else if (selectedview === 'map') {
      this.viewMap = true;

      this.viewGrid = false;
      this.viewGallery = false;

    } else {
      this.viewGrid = true;

      this.viewGallery = false;
      this.viewMap = false;
      if (this.showGalleryview) {
        this.showApprove = false;
      }

    }
  }

  cardClick(clickObject) {
    if (clickObject.hasOwnProperty('item') && clickObject.item && clickObject.item.click === 'double') {
      this.selectedGalleryItem = clickObject.item;
      this.showDetailPopup();
    } else {
      this.pageData = clickObject.data;
      const itemSelected = this.pageData.filter(x => x['item_selected'] === true);
      if (itemSelected && itemSelected.length > 0) {
        this.showApprove = true;
        this.selectedCount = itemSelected.length;
      } else {
        this.showApprove = false;
        this.selectedCount = 0;
      }
    }
  }

  checkIfSelectAllowed(selectedItem, selectionCriteria) {
    if (selectionCriteria.matches) {
      if (!selectedItem[selectionCriteria.field]) {
        return true;
      } else if (selectedItem[selectionCriteria.field].includes(selectionCriteria.values)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!selectedItem[selectionCriteria.field].includes(selectionCriteria.values)) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkSelectionCriteria(selectedItem) {
    if (this.galleryData.selectionCriteria) {
      let matches = true;
      for (const criteria of this.galleryData.selectionCriteria) {
        if (!this.checkIfSelectAllowed(selectedItem, criteria)) {
          matches = false;
          return false;
        }
      }
      return matches;
    } else {
      return true;
    }
  }

  selectAll() {
    let item_selected = false;
    let selectedCount = 0;
    const currentIndex = parseInt(localStorage.getItem('currentgalleryIndex'), 10);
    const currentPage = parseInt(localStorage.getItem('currentgalleryPage'), 10);

    let range = ((currentPage * this.galleryData.pageSize) + 1);
    if ((currentIndex + this.galleryData.pageSize) > this.filteredData.length) {
      range = this.filteredData.length + 1;
    }

    if (this.allSelect) {
      item_selected = true;
    } else {
      item_selected = false;
    }
    this.pageData = [];
    for (let i = currentIndex; i < range; i++) {
      this.pageData.push(this.filteredData[i - 1]);
      if (item_selected) {
        if (this.checkSelectionCriteria(this.filteredData[i - 1])) {
          selectedCount += 1;
          this.filteredData[i - 1]['item_selected'] = true;
        } else {
          this.filteredData[i - 1]['item_selected'] = false;
        }
      } else {
        if (item_selected) {
          selectedCount += 1;
        }
        this.filteredData[i - 1]['item_selected'] = item_selected;
      }
    }
    if (this.allSelect) {
      this.selectedCount = selectedCount;
    } else {
      this.selectedCount = 0;
    }

    if (this.selectedCount > 0) {
      this.showApprove = true;
    } else {
      this.showApprove = false;
    }
    const clickObject = {
      item: "all",
      data: this.pageData
    };
    this.cardClick(clickObject);
  }


  showDetailPopup() {
  }

  performAction(action) {

  }

  openConfirmationDialog(row, state: string) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: state + " Retailer",
        message1: "Do you wish to " + state + "?",
        message2: "",
        showNoButton: true,
        yesButton: state,
        noButton: "CANCEL"
      }
    };

    if (state === 'REJECT') {
      config.data.deleteButton = true;
    }

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      let status = '';
      if (state === 'REJECT') {
        status = 'rejected';
      } else {
        status = 'approved';
      }
      if (data === 'yes') {
      } else {
      }
    });
  }

  checkAndLoadPreviousSate() {
    if (this.sharedService.currListData && this.sharedService.currListData.url === this.router.url) {
      this.userData = this.sharedService.currListData.userdata;
      if (this.dateSelectMode === 'single') {
        this.myDate = this.userData.start_date;
      } else {
        this.myDate[0] = this.userData.start_date;
        this.myDate[1] = this.userData.end_date;
      }

      if (this.userData.customFilter != null && this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
        this.customFilter.value = this.userData.customFilter[this.customFilter.key];
      }

      if (this.userData.customFilter != null && this.userData.customFilter.hasOwnProperty(this.customFilter1.key)) {
        this.customFilter1.value = this.userData.customFilter[this.customFilter1.key];
      }
      if (this.sharedService.currListData.searchText) {
        document.getElementById('filter-text-box')['value'] = this.sharedService.currListData.searchText;
      }
      if (this.sharedService.currListData.agGridFilter) {
        this.agGridFilter = this.sharedService.currListData.agGridFilter;
      }
    }
  }

  updateImmutableObject(original: any, newValues: any) {
    // start with new object
    const newObject: any = {};
    // copy in the old values
    Object.keys(original).forEach(key => {
      newObject[key] = original[key];
    });
    // now override with the new values
    Object.keys(newValues).forEach(key => {
      newObject[key] = newValues[key];
    });
    return newObject;
  }

  formatNumber(number) {
    if (number) {
      number = parseFloat(number).toFixed(this.apiService.decimalPolicy);
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return '';
    }
  }

  downloadSampleExcel() {
    const headers = Object.keys(this.excel_sample[0]);
    const fields = [];
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: this.excel_sample_title,
      useBom: true,
      noDownload: false,
      headers
    };

    return new ngxCsv(this.excel_sample, this.excel_sample_title, options);
  }

  findIndex(): number {
    return this.customFilter.values.findIndex(x => x.id === this.customFilter.value);
  }

  async loadPartnersForSales(userData, callback) {
    const res = await this.apiService.postPromise('/api/pwa_dms_connects/get_partners_for_sales', userData);
    if (res) {
      this.customerList = this.parseServerResponse('customers', null, true, res);
      this.supplierList = this.parseServerResponse('suppliers', null, true, res);

      return callback(null, null);
    } else {
      return callback('Error', null);
    }

    callback(null, null);

  }

  parseServerResponse(key, cache, validate, res) {
    if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
      if (res['results'].data) {

        if (res['results'].data[key] && cache) {
          cache.set(key, res['results'].data[key]).then();
        } else if (res['results'].data && cache) {
          cache.set(key, res['results'].data).then();
        }
        //
        // if (validate && !(res['results'].data[key] || res['results'].data) ) {
        //   this.handleError(ErrorHandler.getErrorObject('Records not found!'));
        //   return null;
        // } else {

        if (res['results'].data[key]) {
          return res['results'].data[key];
        } else {
          return res['results'].data;
        }
        // }
      }
    } else {
      this.handleError(ErrorHandler.getErrorObject(res.results.msg));
      return null;
    }
  }

  formatTime(dataTimeField) {
    if (dataTimeField) {
      return this.datePipe.transform((new Date(dataTimeField + ' UTC')).toString(), 'HH:mm:ss');
    } else {
      return dataTimeField;
    }
  }

  configValueGetter(config_field, value_field) {
    return (params) => {
      if (params.data && params.data[config_field]) {
        try {
          let config_json;
          if (params.data[config_field].constructor !== Object) {
            config_json = JSON.parse(params.data[config_field]);
          } else {
            config_json = params.data[config_field];
          }
          if (config_json && config_json.hasOwnProperty(value_field)) {
            return config_json[value_field];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

}
