import {AfterViewInit, Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {GridOptions} from "../../../node_modules/@ag-grid-enterprise/all-modules";
import {ActivatedRoute} from '@angular/router';
import {ApiService} from "../_services/api.service";
import {ApproveClickRenderer} from "../approveClick.component";
import {ConvertCellRenderer} from "../convertCell.component";
import {HeaderClickComponent} from "../headerClick.component";
import {ConvertComponent} from "../convert.component";
import {ConvertPopUpComponent} from "../convert.popup.component";
import {PoEditRenderer} from "../poEdit.component";
import {DamageEditRenderer} from "../damageEdit.component";
import {GrnEditRenderer} from "../grnEdit.component";
import {InvoiceEditRenderer} from "../invoiceEdit.component";
import {ImageClickRenderer} from "../imageClick.component";
import {AgmMap, LatLngBounds, MapsAPILoader} from "@agm/core";
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {ReuseRenderer} from "../reuse.component";
import {ReplaceRenderer} from "../replacesku.component";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import {ToastrService} from "ngx-toastr";
import {RegenerateComponent} from "../regenerate.component";
import {RegenerateHeaderComponent} from "../regenerateHeader.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ReturnApprovePopupComponent} from "../components/return_approve_popup/return_approve_popup.component";
import {PODPopupComponent} from "../components/pod_popup/pod_popup.component";
import {SharedService} from "../_services/shared_service";
import {AppComponent} from "../app.component";
import {GRNPopupComponent} from "../components/grn_popup/grn_popup.component";

declare var google: any;
declare let $: any;

@Component({
  selector: 'app-eodsettlement',
  templateUrl: './eodsettlement_new.component.html',
  styleUrls: ['./eodsettlement.component.scss']
})
export class EodsettlementNewComponent implements AfterViewInit {
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  @ViewChild('devDiv') devDiv: ElementRef<HTMLElement>;
  @ViewChild('printSection') printSection: ElementRef<HTMLElement>;
  @ViewChild('imageClick') imageClick: ElementRef<HTMLElement>;
  @ViewChild('podClick') podClick: ElementRef<HTMLElement>;
  @ViewChild('convertClick') convertClick: ElementRef<HTMLElement>;
  @ViewChild('editDiv') editDiv: ElementRef<HTMLElement>;
  @ViewChild('revertStatus') revertStatus: ElementRef<HTMLElement>;
  @ViewChild('AgmMap') agmMap: AgmMap;
  @ViewChild('BillingModal') public BillingModal: ModalDirective;

  public country_id = 0;
  public modules = AllModules;
  public tax_perc;
  public tcs;
  public amount_in_words;
  public invoice_date;
  public converted_products = [];
  public assetcolumnDefs = [];
  public columnDefs = [];
  public convert_products;
  public columnDefsPrev = [];
  public columnDefs3 = [];
  public replacecolumnDefs = [];
  public dispatchcolumnDefs = [];
  public returncolumnDefs = [];
  public mismatchcolumnDefs = [];
  public logcolumnDefs = [];
  public samplecolumnDefs = [];
  public unappcolumnDefs = [];
  public convertDefs = [];
  public pEinvoiceDefs = [];
  public pEpodDefs = [];
  public sapOrderDefs = [];
  public orderLogsDefs = [];
  public listData = [];
  public src_url;
  public agm_map;
  public convertData;
  public block_settle = false;
  public settle_wait = false;
  public next_started = false;
  public is_return_approved = false;
  public openedWindow = 0;
  public searchValue;
  public context;
  public frameworkComponents;
  public defaultColDef;
  public sideBar;
  public rowSelection;
  public gridOptions: GridOptions;
  public gridOptionsPrev: GridOptions;
  public gridOptions3: GridOptions;
  public gridOptionsReplace: GridOptions;
  public gridOptionsDispatch: GridOptions;
  public gridOptionsReturn: GridOptions;
  public gridOptionsMismatch: GridOptions;
  public gridOptionsLog: GridOptions;
  public gridOptionsSample: GridOptions;
  public gridOptionsConvert: GridOptions;
  public gridOptionsUnapp: GridOptions;
  public gridOptionsList: GridOptions;
  public stockOptions: GridOptions;
  public assetOptions: GridOptions;
  public gridOptionsPEinvoice: GridOptions;
  public gridOptionsPEpod: GridOptions;
  public gridOptionsSapOrder: GridOptions;
  public gridOptionsOrderLogs: GridOptions;
  public datavalue;
  public data = [];
  public data1 = [];
  public data2 = [];
  public dataReplace = [];
  public dataDispatch = [];
  public dataReturn = [];
  public dataMismatch = [];
  public dataLogs = [];
  public dataSample = [];
  public dataUnapp = [];
  public dataPEinvoice = [];
  public dataPEpod = [];
  public dataSapOrder = [];
  public dev_data;
  public logsData = [];
  public user_data = {
    date: null,
    salesman_id: null,
    access_token: null,
    vansale_id: null,
    van_id: null,
    dc_id: null,
    url: null,
    status: null,
    dev_product: null,
    order_ids: null,
    convert_products: null,
    offset: null,
    prod_ids: null,
    crnt_date: null,
    nxt_date: null
  };
  res_data;
  public math = Math;
  public summary = {};
  public return_order;
  public settlement;
  public track = [];
  public mapCenter = {lat: 0, lng: 0};
  public activity = [];
  public assetData = [];
  public stockData = [];
  public stockcolumnDefs = [];
  public asset_loaded = false;
  public stock_loaded = false;
  public convert_loaded = false;
  public assetpreloader = false;
  public preloader1 = false;
  public orderData;
  public order1_loaded = false;
  public preloader2 = false;
  public order2_loaded = false;
  public preloader3 = false;
  public order3_loaded = false;
  public preloader4 = false;
  public replace_loaded = false;
  public replace_preloader = false;
  public dispatch_loaded = false;
  public dispatch_preloader = false;
  public return_loaded = false;
  public return_preloader = false;
  public mismatch_loaded = false;
  public mismatch_preloader = false;
  public list_loaded = false;
  public list_preloader = false;
  public list_empty_state = false;
  public logs_loaded = false;
  public logs_preloader = false;
  public sample_loaded = false;
  public sample_preloader = false;
  public unapp_loaded = false;
  public unapp_preloader = false;
  public pEinvoice_loaded = false;
  public pEinvoice_preloader = false;
  public pEpod_loaded = false;
  public pEpod_preloader = false;
  public sapOrder_loaded = false;
  public orderLogs_loaded = false;
  public sapOrder_preloader = false;
  public orderLogs_preloader = false;
  public distance_travelled = "0";
  public approve_return = false;
  public comments = "";
  public get_user_data;
  public collapse_disable;
  public today_count = 0;
  public today_enable = false;
  public prvs_count = 0;
  public prvs_enable = false;
  public cash_count = 0;
  public cash_enable = false;
  public replace_count = 0;
  public replace_enable = false;
  public dispatch_count = 0;
  public dispatch_enable = false;
  public return_count = 0;
  public return_enable = false;
  public mismatch_count = 0;
  public mismatch_enable = false;
  public logs_count = 0;
  public logs_enable = false;
  public pending_einvoice_count = 0;
  public pending_einvoice_enable = false;
  public pending_saporder_count = 0;
  public pending_saporder_enable = false;
  public pending_pod_count = 0;
  public pending_pod_enable = false;
  public sample_count = 0;
  public sample_enable = false;
  public unapp_count = 0;
  public unapp_enable = false;
  public total_count = 0;
  public total_return = 0.00;
  public map_loader = true;
  public asset_empty_state = false;
  public empty_state1 = false;
  public empty_state2 = false;
  public empty_state3 = false;
  public empty_state4 = false;
  public empty_state_replace = false;
  public empty_state_dispatch = false;
  public empty_state_return = false;
  public empty_state_mismatch = false;
  public empty_state_logs = false;
  public empty_state_sample = false;
  public unapp_empty_state = false;
  public peinvoice_empty_state = false;
  public pepod_empty_state = false;
  public sapOrder_empty_state = false;
  public orderLogs_empty_state = false;
  public orderDetails = {label_config: {}};
  public cancel_tab = false;
  public edit_tab = false;
  public return_approve = false;
  public gridApiList;
  public gridColumnApiList;
  public tabIndex;
  public stock_neg = false;
  public collection_mismatch = false;
  public icon_url = {
    url: '../../assets/retailer.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public start_url = {
    url: '../../assets/start.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public end_url = {
    url: '../../assets/end.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public config = {
    data: [],
    index: 0,
    next: true,
    prvs: true
  };
  public settled = false;
  public photo;
  public clickedRow;
  public start_act;
  public end_act;
  public is_act = false;
  public payment_enable = true;
  public hide = true;
  public expense_hide = true;
  public role_features;
  public cancel_button = true;
  public eod_status;
  public nxt_eod_status = false;
  public print_enable = false;
  public status_enable = true;
  public van_name = "";
  public all_products = {};
  public return_value = "0";
  public updated_value = "0";
  public reason = "";
  public cancel_hide = true;
  public return_hide = true;
  public replace_hide = true;
  public return_product;
  public factor_inv;
  public top_cards = false;
  public selected_order;
  public cancel_reason = "";
  public settle_enable = false;
  public settle_dev = false;
  public settle_live_dev = false;
  public payment_mismatch = false;
  public payment_logs = false;
  public status_edit = false;
  public myDate;
  public mark_approve = false;
  public convert_enable = false;
  public allow_edit = false;
  public invoice_edit = false;
  public invoice_num_edit = false;
  public showOld = false;
  public itemList = [
    {Id: 1, Name: "same"},
    {Id: 2, Name: "other"},
  ];
  public forDropDown;
  public convert_log = ["", 0];
  public editIN;
  public editPO;
  public editGRN;
  public editOrderID;
  public editType;
  public editComments;
  public editOrdMode;
  public updateText = '';
  public updatedParams;
  public updatedNode;
  public columnDefsList;
  public label_config = {};
  public template;
  public templ_config;
  public sapEnable = false;
  public sapConfig: any = {};
  public showProgress = false;
  public progress = 0;
  public show_outstanding: boolean;
  public all_orders = [];
  public disableSettleApprove = false;
  public podApproveRequired = false;
  public selectedTemplate = null;
  public sapSync = false;
  public partial_approve = false;
  private assetgridApi;
  private gridApi1;
  private gridApi2;
  private gridApi3;
  private gridApi4;
  private gridApi_replace;
  private gridApi_dispatch;
  private gridApi_return;
  private gridApi_mismatch;
  private gridApi_log;
  private gridApi_sample;
  private gridApi_unapp;
  private gridApi_convert;
  private gridApi_peinvoice;
  private gridApi_pepod;
  private gridApi_saporder;
  private gridApi_orderlogs;
  private gridColumnApiReplace;
  private gridColumnApiDispatch;
  private gridColumnApiReturn;
  private gridColumnApiMismatch;
  private gridColumnApiLog;
  private gridColumnApiSample;
  private gridColumnApiUnapp;
  private gridColumnApiConvert;
  private gridColumnApiPEinvoice;
  private gridColumnApiPEpod;
  private gridColumnApiSapOrder;
  private gridColumnApiOrderLogs;
  private assetgridColumnApi;
  private gridColumnApi;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  private rowData = [];
  private rowDataPrev = [];
  private rowData3 = [];
  private rowReplace = [];
  private rowDispatch = [];
  private rowReturn = [];
  private rowMismatch = [];
  private rowLogs = [];
  private rowSample = [];
  private rowUnapp = [];
  private rowPEinvoice = [];
  private rowPEpod = [];
  private rowSapOrder = [];
  private rowOrderLogs = [];
  private rowHeight;
  private rowSelection1;
  private a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ',
    'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
  private b = ['', '', 'Twenty ', 'Thirty ', 'Forty ', 'Fifty ', 'Sixty ', 'Seventy ', 'Eighty ', 'Ninety '];
  private latitude: any;
  private longitude: any;
  private bonus = 0;
  private penalty = 0;
  generate_credit_note = false;
  feature_van_asset = false;
  add_return = false;
  timezone = null;
  countryMissing = false;
  countryMissingRetailers = new Set();
  pod_config ;
  pod_payment_mode;
  pod_outlet_type ;
  stock_and_sales1Loaded = false;
  stock_and_sales2Loaded = false;
  approvalOrdersLoaded = false;
  settlementSummaryLoaded = false;
  totalDiscount = 0;
  discountLines = 0;
  constructor(private apiService: ApiService,
              private sharedService: SharedService,
              private elRef: ElementRef,
              private renderer: Renderer2,
              public domSanitizer: DomSanitizer,
              public toastr: ToastrService,
              public dialog: MatDialog,
              private datePipe: DatePipe,
              public appComponent: AppComponent) {
    this.get_user_data = JSON.parse(localStorage.getItem('user_data'));
    this.user_data.date = this.get_user_data.date;
    this.myDate = this.user_data.date;
    this.sharedService.setHeader('EOD Settlement');
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.salesman_id = JSON.parse(localStorage.getItem('eod_params')).van_id;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.vansale_id = JSON.parse(localStorage.getItem('eod_params')).vansale_id;
    this.user_data.van_id = JSON.parse(localStorage.getItem('eod_params')).true_van_id;
    this.user_data.dc_id = JSON.parse(localStorage.getItem('eod_params')).dc_id;
    this.van_name = JSON.parse(localStorage.getItem('eod_params'))['VAN'];
    this.user_data.url = localStorage.getItem('private_url');
    if (this.sharedService.role_features) {
      if (this.sharedService.ACLcheck('feature_expense')) {
        this.expense_hide = true;
      }
      if (this.sharedService.ACLcheck('feature_secondary_order_cancel')) {
        this.cancel_hide = false;
      }
      if (this.sharedService.ACLcheck('feature_secondary_damaged_edit')) {
        this.replace_hide = false;
      }
      if (this.sharedService.ACLcheck('feature_return_edit')) {
        this.return_hide = false;
      }
      if (this.sharedService.ACLcheck('feature_show_outstanding')) {
        this.show_outstanding = false;
      }
      if (this.sharedService.ACLcheck('feature_settlement_summary')) {
        this.top_cards = true;
      }
      if (this.sharedService.ACLcheck('feature_mark_approve')) {
        this.mark_approve = true;
      }
      if (this.sharedService.ACLcheck('feature_allow_marksettled')) {
        this.settle_enable = true;
      }
      if (this.sharedService.ACLcheck('feature_eod_status_edit')) {
        this.status_edit = true;
      }
      if (this.sharedService.ACLcheck('feature_convert_products')) {
        this.convert_enable = true;
      }
      if (this.sharedService.ACLcheck('feature_block_settle')) {
        this.block_settle = true;
      }
      if (this.sharedService.ACLcheck('feature_allow_secondary_order_edit')) {
        this.allow_edit = true;
      }
      if (this.sharedService.ACLcheck('feature_invoice_edit')) {
        this.invoice_edit = true;
      }
      if (this.sharedService.ACLcheck('feature_invoice_num_edit')) {
        this.invoice_num_edit = true;
      }
      if (this.sharedService.ACLcheck('feature_old_payment')) {
        this.showOld = true;
      }
      if (this.sharedService.ACLcheck('feature_disable_settle_approve')) {
        this.disableSettleApprove = true;
      }
      if (this.sharedService.ACLcheck('feature_eod_pod_approve')) {
        this.podApproveRequired = true;
      }
      if (this.sharedService.ACLcheck('feature_eod_sap_push')) {
        this.sapSync = true;
      }
      if (this.sharedService.ACLcheck('feature_partial_return_approve')) {
        this.partial_approve = true;
      }
      if (this.sharedService.ACLcheck('feature_van_asset')) {
        this.feature_van_asset = true;
      }
      if (this.sharedService.ACLcheck('feature_disable_grv_stock_update')) {
        this.add_return = false;
      } else {
        this.add_return = true;
      }
    }
    this.assetcolumnDefs = [
      {headerName: "Name", field: 'name',  filter: true, resizable: true,
        cellRenderer(params) {
          if (params.data.name === 'TOTAL') {
            const displayElem = '<span style="font-weight: bold">' + params.value + '</span>';
            return displayElem;
          } else {
            return params.value;
          }
        }
        },
      {headerName: "Opening", field: 'opening',  filter: true, resizable: true},
      {headerName: "Closing", field: 'closing',  filter: true, resizable: true}
    ];

    const custom_templates = JSON.parse(localStorage.getItem('custom_templates'));
    const updated = custom_templates.filter(elem => {
      return elem.type === 'Secondary Print PDF';
    });
    if (updated.length > 0) {
      this.templ_config = [{name: 'Default'}].concat(updated);
      this.template = true;
    }
    const appConfig = JSON.parse(localStorage.getItem('app')).config_json;
    if (appConfig && appConfig.hasOwnProperty('pod_config')) {
      this.pod_config = appConfig.pod_config;
    }
    this.loadPage();
  }

  loadPage() {
    this.appComponent.pageReady = false;
    this.initialiseValues();
    this.apiService.post('/api/pwa_connects/get_vansale_for_date', this.user_data)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          this.user_data.vansale_id = res.results.data[0].id;
          this.apiEod();
        } else {
          this.appComponent.pageReady = true;
          this.toastr.error("Van Not Started");
        }
      }, error => {
        this.appComponent.pageReady = true;
        this.toastr.error("Error fetching Van Record");
      });
  }

  async getAllProducts() {
    const all_prod = {};
    const resp = await this.apiService.getAllProducts(this.user_data);
    console.log(resp);
    if (resp.hasOwnProperty('results') && (resp['results'].status === 200)) {
      resp['results'].data.forEach(data => {
        all_prod['p_' + data.id] = data.name;
      });
      this.all_products = all_prod;
      this.all_products['TOTAL'] = 'TOTAL';
    }
  }


  inWords(num) {
    if ((num = num.toString()).length > 9) {
      return 'overflow';
    }
    const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) {
      return;
    }
    let str = '';

    str += (n[1] !== '00') ? (this.a[Number(n[1])] || this.b[n[1][0]] + this.a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] !== '00') ? (this.a[Number(n[2])] || this.b[n[2][0]] + this.a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] !== '00') ? (this.a[Number(n[3])] || this.b[n[3][0]] + this.a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] !== '0') ? (this.a[Number(n[4])] || this.b[n[4][0]]  + this.a[n[4][1]]) + 'Hundred ' : '';
    if (this.a[Number(n[5])]) {
      str += this.a[Number(n[5])];
    } else {
      if (this.a[n[5][1]] !== '') {
        str += this.b[n[5][0]] + ' ' + this.a[n[5][1]];
      } else {
        str += this.b[n[5][0]];
      }
    }
    return str;
  }

 initialiseValues() {
   this.order1_loaded = false;
   this.preloader2 = false;
   this.order2_loaded = false;
   this.preloader3 = false;
   this.order3_loaded = false;
   this.preloader4 = false;
   this.list_loaded = false;
   this.list_empty_state = false;
   this.list_preloader = false;
   this.replace_loaded = false;
   this.dispatch_loaded = false;
   this.return_loaded = false;
   this.mismatch_loaded = false;
   this.logs_loaded = false;
   this.sample_loaded = false;
   this.unapp_loaded = false;
   this.replace_preloader = false;
   this.dispatch_preloader = false;
   this.return_preloader = false;
   this.mismatch_preloader = false;
   this.logs_preloader = false;
   this.sample_preloader = false;
   this.unapp_preloader = false;
   this.stock_loaded = false;
   this.preloader1 = false;
   this.map_loader = true;
   this.empty_state1 = false;
   this.empty_state2 = false;
   this.empty_state3 = false;
   this.empty_state4 = false;
   this.columnDefs = [];
   this.columnDefsPrev = [];
   this.columnDefs3 = [];
   this.columnDefsList = [];
   this.replacecolumnDefs = [];
   this.dispatchcolumnDefs = [];
   this.returncolumnDefs = [];
   this.mismatchcolumnDefs = [];
   this.logcolumnDefs = [];
   this.samplecolumnDefs = [];
   this.unappcolumnDefs = [];
   this.stockcolumnDefs = [];
   this.pEinvoiceDefs = [];
   this.pEpodDefs = [];
   this.sapOrderDefs = [];
   this.rowData = [];
   this.rowDataPrev = [];
   this.rowData3 = [];
   this.rowReplace = [];
   this.rowDispatch = [];
   this.rowReturn = [];
   this.rowMismatch = [];
   this.rowLogs = [];
   this.rowSample = [];
   this.rowUnapp = [];
   this.rowPEinvoice = [];
   this.rowPEpod = [];
   this.rowSapOrder = [];
   this.rowOrderLogs = [];
   this.settlement = {};
   this.today_count = 0;
   this.prvs_count = 0;
   this.cash_count = 0;
   this.replace_count = 0;
   this.dispatch_count = 0;
   this.return_count = 0;
   this.mismatch_count = 0;
   this.logs_count = 0;
   this.sample_count = 0;
   this.unapp_count = 0;
   this.total_count = 0;
   this.pending_einvoice_count = 0;
   this.pending_pod_count = 0;
   this.pending_saporder_count = 0;
   this.today_enable = false;
   this.prvs_enable = false;
   this.cash_enable = false;
   this.replace_enable = false;
   this.dispatch_enable = false;
   this.return_enable = false;
   this.mismatch_enable = false;
   this.logs_enable = false;
   this.sample_enable = false;
   this.unapp_enable = false;
   this.pending_einvoice_enable = false;
   this.pending_pod_enable = false;
   this.pending_saporder_enable = false;
   this.bonus = 0;
   this.penalty = 0;
   this.total_return = 0;
   this.totalDiscount = 0;
   this.discountLines = 0;
   this.appComponent.pageReady = false;
   this.stock_and_sales1Loaded = false;
   this.stock_and_sales2Loaded = false;
   this.approvalOrdersLoaded = false;
   this.settlementSummaryLoaded = false;
 }

  async apiEod() {

    let pushData = {};
    const datePipe = this.datePipe;
    await this.getAllProducts();

    // feature based top cards
    if (this.top_cards) {
      const userData = {
        date: this.user_data.date,
        salesman_id: this.user_data.salesman_id
      };
      const sendData = {};
      sendData['access_token'] = this.user_data.access_token;
      sendData['url'] = this.user_data.url;
      sendData['data'] = userData;
      const listdata = [];
      let must_visit = false;
      // van visit details table
      this.res_data = this.apiService.vanList(sendData);
      this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(data => {
            if (data.visit_type === 'daily') {
              must_visit = true;
            } else if (data.visit_type === 'monthly') {
              const dates = data.visit_config.split(',');
              const today = (new Date(data.schedule_date)).getDate();
              dates.forEach(num => {
                if (parseInt(num, 10) === today) {
                  must_visit = true;
                }
              });
            } else if (data.visit_type === 'weekly') {
              const dates = data.visit_config.split(',');
              const today = (new Date(data.schedule_date)).getDay() + 1;
              dates.forEach(num => {
                if (parseInt(num, 10) === today) {
                  must_visit = true;
                }
              });
            } else if (data.visit_type === 'visitbased') {
              const dat = parseInt(data.visit_config, 10);
              const dt1 = new Date(data.last_visited);
              const dt2 = new Date(data.schedule_date);
              const diff = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
              if (diff >= dat) {
                must_visit = true;
              }
            }
            if (!must_visit && (data.visit_details !== 'Not Visited')) {
              data.visit_details = 'Beat Jump';
            } else if (!must_visit && (data.visit_details === 'Not Visited')) {
              data.visit_details = 'Not Planned';
            }
            must_visit = false;
            if (data.check_in !== null) {
              data.check_in = datePipe.transform((new Date(data.check_in + ' UTC')).toString(), 'dd/MM/yy HH:mm:ss');
            }
            if (data.check_out !== null) {
              data.check_out = datePipe.transform((new Date(data.check_out + ' UTC')).toString(), 'dd/MM/yy HH:mm:ss');
            }
            if (data.schedule_date !== null) {
              data.schedule_date = datePipe.transform(data.schedule_date, 'dd/MM/yy');
            }

            const listData = {
              Code: data.code,
              Outlet: data.name,
              Type: data.type,
              Class: data.class_type,
              "Visit Details": data.visit_details,
              "Check IN": data.check_in,
              "Check Out": data.check_out,
              Sale: data.sale
            };

            listdata.push(listData);
          });

          this.listData = listdata;
          const list_data = listdata[0];
          this.columnDefsList = [
            {headerName: 'Code', field: 'Code',   width : 100},
            {headerName: 'Outlet', field: 'Outlet',   width : 150},
            {headerName: 'Type', field: 'Type',   width : 100},
            {headerName: 'Class', field: 'Class',   width : 100},
            {headerName: 'Visit Details', field: 'Visit Details',   width : 100,
              cellRenderer(params) {
                if (params.value !== null && params.value !== undefined) {
                  if (params.value === 'Productive') {
                    return `<span class="ml-1 status2" style="background-color:#E1F0E7; border:#E1F0E7; color: #379862; font-weight: bold;" >` + params.value + `</span>`;
                  } else if (params.value === 'Non Productive') {
                    return `<span class="ml-1 status2" style="background-color:#EAEBF7; border:#EAEBF7; color: #757BC8; font-weight: bold;">` + params.value + `</span>`;
                  } else if (params.value === 'Not Visited') {
                    return `<span class="ml-1 status2" style="background-color:#FBE3E7; border:#FBE3E7; color: #E24260; font-weight: bold;">` + params.value + `</span>`;
                  } else if (params.value === 'Not Planned') {
                    return `<span class="ml-1 status2" style="background-color:#E1ECF5; border:#E1ECF5; color: #0762AD; font-weight: bold;">` + params.value + `</span>`;
                  } else if (params.value === 'Beat Jump') {
                    return `<span class="ml-1 status2" style="background-color:#FEF2DF; border:#FEF2DF; color: #F6A82C; font-weight: bold;">` + params.value + `</span>`;
                  }
                }
              }
            },
            {headerName: 'Check IN', field: 'Check IN',   width : 100},
            {headerName: 'Check Out', field: 'Check Out',   width : 100},
            {headerName: 'Sale', field: 'Sale',   width : 100}
          ];
        } else {
          this.list_empty_state = true;
        }
      }, error => {
        console.log(error);
      });
      // Top cards details
      this.res_data = this.apiService.getSummary(this.user_data);

      this.res_data.subscribe(res => {
        console.log(res);
        const inthis = this;
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.eod_status = res['results'].data[0].status;
          res['results'].data.forEach(data => {
            let daily_perc = ((parseInt(data.sales, 10) / parseInt(data.target, 10)) * 100).toFixed(inthis.apiService.decimalPolicy);
            let monthly_perc = (((parseInt(data.month_ach, 10) - parseInt(data.last_month_ach, 10)) / parseInt(data.last_month_ach, 10)) * 100).toFixed(inthis.apiService.decimalPolicy);
            let nv = parseInt(data.total_ret, 10) - parseInt(data.vc, 10);
            let zs = parseInt(data.total_ret, 10) - parseInt(data.pc, 10);

            if (nv < 0) {
              nv = 0;
            }
            if (zs < 0) {
              zs = 0;
            }

            if (parseFloat(daily_perc) > 0 || isNaN(parseFloat(daily_perc)) || (daily_perc === "-Infinity") || (daily_perc === "Infinity")) {
              daily_perc = "0";
            }
            if (parseFloat(monthly_perc) > 0 || isNaN(parseFloat(monthly_perc)) || (monthly_perc === "-Infinity") || (monthly_perc === "Infinity")) {
              monthly_perc = "0";
            }

            pushData = {
              Target: data.target,
              Achievement: parseFloat(data.sales).toFixed(inthis.apiService.decimalPolicy),
              MonthTarget: data.month_target,
              MonthAchv: parseFloat(data.month_ach).toFixed(inthis.apiService.decimalPolicy),
              PrvsAchv: parseFloat(data.yest_sales).toFixed(inthis.apiService.decimalPolicy),
              LastmonthAchv: parseFloat(data.last_month_ach).toFixed(inthis.apiService.decimalPolicy),
              TC: data.tc,
              PC: data.pc,
              VC: data.vc,
              DC: data.dc,
              GC: data.gc,
              daily_perc,
              monthly_perc,
              NV: nv,
              ZS: zs

            };
          });
        }
        this.summary = pushData;
      });

      const trackData = [];
      let distance_travelled = 0;

      // van track list
      this.res_data = this.apiService.getTrack(this.user_data);
      let org_lat = null;
      let org_lng = null;
      let dest_lat;
      let dest_lng;

      const calculate_distance = (lat1, lon1, lat2, lon2, unit) => {
        if ((lat1 === lat2) && (lon1 === lon2)) {
          return 0;
        }
        if ((lat1 === null) || (lat1 === 0) || (lon1 === 0)) {
          return 0;
        } else {
          const radlat1 = Math.PI * lat1 / 180;
          const radlat2 = Math.PI * lat2 / 180;
          const theta = lon1 - lon2;
          const radtheta = Math.PI * theta / 180;
          let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
          if (dist > 1) {
            dist = 1;
          }
          dist = Math.acos(dist);
          dist = dist * 180 / Math.PI;
          dist = dist * 60 * 1.1515;
          if (unit === "K") {
            dist = dist * 1.609344;
          }
          if (unit === "N") {
            dist = dist * 0.8684;
          }
          return dist;
        }
      };

      this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          res['results'].data.forEach(data => {
            if (data.log !== null) {
              data.log.forEach(tracklog => {
                if (parseFloat(tracklog.lat) > 0 && parseFloat(tracklog.lng) > 0) {
                  trackData.push({
                    lat: tracklog.lat,
                    lng: tracklog.lng
                  });

                  dest_lat = parseFloat(tracklog.lat);
                  dest_lng = parseFloat(tracklog.lng);
                  distance_travelled += calculate_distance(org_lat, org_lng, dest_lat, dest_lng, 'K');
                  org_lat = dest_lat;
                  org_lng = dest_lng;
                }
              });
            }
          });
        }
        if (dest_lat !== undefined) {
          this.mapCenter.lat = dest_lat;
          this.mapCenter.lng = dest_lng;
        }
        this.distance_travelled = distance_travelled.toFixed(this.apiService.decimalPolicy);

        this.track = trackData;
        this.map_loader = false;
      });

      const actData = [];

      // van Activity details
      this.res_data = this.apiService.getActivity(this.user_data);

      this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(data => {
            if (parseInt(data.lat, 10) > 0 && parseInt(data.long, 10) > 0) {
              actData.push({
                id: data.retailer_id,
                lat: data.lat,
                lng: data.long,
                retailer: data.retailer_name,
                activity: data.actions,
                device_time: datePipe.transform((new Date(data.device_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss'),
                comments: data.comments
              });
            }
          });
          if (parseFloat(actData[0].lat) > 0) {
            this.mapCenter.lat = parseFloat(actData[0].lat);
            this.mapCenter.lng = parseFloat(actData[0].lng);
          }
        }

        if (actData.length > 0) {
          this.start_act = actData[0];
          this.end_act = actData[actData.length - 1];
          actData.splice(0, 1);
          actData.splice(actData.length - 1, 1);
          this.is_act = true;
        }
        this.activity = actData;
      });
    }

    // Common details
    let stockData = [];
    this.stockData = [];
    this.preloader1 = true;
    this.empty_state1 = false;
    this.user_data.status = this.eod_status;
    this.settle_wait = false;
    this.stock_neg = false;
    let stock_label = '';
    const prod_ids = [];
    const sumProd = {
      loadin: 0,
      loadout: 0,
      opening: 0,
      sale: 0,
      sample: 0,
      promo: 0,
      return: 0,
      damage: 0
    };
    this.assetpreloader = true;
    // stock and sales table
    const API_URL = '/api/pwa_connects/get_stock_and_sales1';
    this.apiService.post(API_URL, this.user_data)
      .subscribe(res => {
          this.stock_and_sales1Loaded = true;
          if (this.stock_and_sales2Loaded && this.approvalOrdersLoaded && this.settlementSummaryLoaded) {
            this.appComponent.pageReady = true;
          }
          console.log(res);
          this.assetpreloader = false;
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.user_data.crnt_date = res.results.crnt_date;
            this.user_data.nxt_date = res.results.nxt_date;
            this.eod_status = res.results.state;
            this.user_data.status = this.eod_status;
            if (res.results.hasOwnProperty('config_json') && res.results.config_json !== null && res.results.config_json !== undefined ) {
              if (res.results.config_json.hasOwnProperty("assets") && res.results.config_json.assets !== null && res.results.config_json.assets !== undefined) {
                this.assetData =  res.results.config_json['assets'];
              }
              if (!this.assetData || this.assetData.length === 0) {
                 this.asset_empty_state = true;
              } else {
                this.asset_loaded = true;
                let assetopening = 0;
                let assetclosing = 0;
                this.assetData.forEach(data => {
                  assetopening += parseFloat(data.opening);
                  assetclosing += parseFloat(data.closing);
                });
                const pinnedTotalAssetData = [
                  { name: "TOTAL",
                    opening: assetopening,
                    closing: assetclosing
                  }
                  ];
                this.assetOptions.pinnedBottomRowData = pinnedTotalAssetData;
              }
              if (res.results.config_json.hasOwnProperty("bonus") && res.results.config_json.bonus !== null && res.results.config_json.bonus !== "") {
                this.bonus = parseFloat(res.results.config_json.bonus);
              } else {
                this.bonus = 0;
              }
            } else {
              this.bonus = 0;
            }
            if (res.results.hasOwnProperty('config_json') && res.results.config_json !== null && res.results.config_json !== undefined ) {
              if (res.results.config_json.hasOwnProperty("penalty") && res.results.config_json.penalty !== null && res.results.config_json.penalty !== "") {
                this.penalty = parseFloat(res.results.config_json.penalty);
              } else {
                this.penalty = 0;
              }
            } else {
              this.penalty = 0;
            }
            stockData = [
              {LABEL: 'Loadin'},
              {LABEL: 'Loadout'},
              {LABEL: 'Opening'},
              {LABEL: 'Sale'},
              {LABEL: 'Sample'},
              {LABEL: 'Promo'},
              {LABEL: 'Return'},
              {LABEL: 'Damage'},
              {LABEL: 'Closing'},
              {LABEL: 'calc_closing'},
              {LABEL: 'incentive'},
              {LABEL: 'lst_price'},
              {LABEL: 'Bonus'},
              {LABEL: 'Penalty'},
              {LABEL: 'Rejected'},
              {LABEL: 'rej_reason'},
              {LABEL: 'ret_incentive'},
            ];
            let stock_neg = false;
            res.results.data.forEach(prod => {
              let closing = 0;
              if (res.results.state === 'complete' || res.results.state === 'settled') {
                closing = parseInt(prod.vanstock_closing, 10);
                stock_label = 'van';
              } else {
                closing = parseInt(prod.live_closing, 10);
                stock_label = 'live';
              }
              if (parseInt(prod.live_closing, 10) < 0) {
                stock_neg = true;
              }
              if (parseInt(prod.loadin, 10) !== 0 || parseInt(prod.loadout_val, 10) !== 0 || parseInt(prod.sale, 10) !== 0 ||
                closing !== 0 || parseInt(prod.return, 10) !== 0) {
                prod_ids.push(prod.product_id);
                stockData[0]['p_' + prod.product_id] = prod.loadin;
                stockData[1]['p_' + prod.product_id] = prod.loadout_val;
                stockData[2]['p_' + prod.product_id] = parseInt(prod.loadin, 10) + parseInt(prod.loadout_val, 10);
                stockData[3]['p_' + prod.product_id] = parseInt(prod.sale, 10);
                stockData[4]['p_' + prod.product_id] = parseInt(prod.sample, 10);
                stockData[5]['p_' + prod.product_id] = parseInt(prod.promo, 10);
                if (prod.return) {
                  stockData[6]['p_' + prod.product_id] = parseInt(prod.return, 10);
                } else {
                  stockData[6]['p_' + prod.product_id] = 0;
                }
                stockData[7]['p_' + prod.product_id] = parseInt(prod.damage, 10);
                stockData[8]['p_' + prod.product_id] = closing;
                stockData[9]['p_' + prod.product_id] = stockData[2]['p_' + prod.product_id] - (stockData[3]['p_' + prod.product_id] +
                  stockData[4]['p_' + prod.product_id] + stockData[5]['p_' + prod.product_id] + stockData[7]['p_' + prod.product_id]);
                if (this.add_return) {
                  stockData[9]['p_' + prod.product_id] += stockData[6]['p_' + prod.product_id];
                }
                stockData[10]['p_' + prod.product_id] = parseInt(prod.incentive, 10);
                stockData[11]['p_' + prod.product_id] = parseFloat(prod.lst_price);
                if (prod.hasOwnProperty('config_json') && prod.config_json !== null && prod.config_json !== undefined ) {
                      if (prod.config_json.hasOwnProperty("bonus") && prod.config_json.bonus !== null && prod.config_json.bonus !== "") {
                        stockData[12]['p_' + prod.product_id] = parseFloat(prod.config_json.bonus);
                      } else {
                        stockData[12]['p_' + prod.product_id] = 0;
                      }
                      if (prod.config_json.hasOwnProperty("penalty") && prod.config_json.penalty !== null && prod.config_json.penalty !== "") {
                        stockData[13]['p_' + prod.product_id] = parseFloat(prod.config_json.penalty);
                      } else {
                        stockData[13]['p_' + prod.product_id] = 0;
                      }
                      if (prod.config_json.hasOwnProperty("rejected") && prod.config_json.rejected !== null && prod.config_json.rejected !== "") {
                        stockData[14]['p_' + prod.product_id] = parseInt(prod.config_json.rejected, 10);
                      } else {
                        stockData[14]['p_' + prod.product_id] = 0;
                      }
                      if (prod.config_json.hasOwnProperty("rej_reason") && prod.config_json.rej_reason !== null && prod.config_json.rej_reason !== "") {
                        stockData[15]['p_' + prod.product_id] = prod.config_json.rej_reason;
                      } else {
                        stockData[15]['p_' + prod.product_id] = "";
                      }
                } else {
                  stockData[12]['p_' + prod.product_id] = 0;
                  stockData[13]['p_' + prod.product_id] = 0;
                  stockData[14]['p_' + prod.product_id] = 0;
                  stockData[15]['p_' + prod.product_id] = "";
                }
                stockData[16]['p_' + prod.product_id] = parseInt(prod.ret_incentive, 10);
                sumProd.loadin = sumProd.loadin + parseInt(prod.loadin, 10);
                sumProd.loadout = sumProd.loadout + parseInt(prod.loadout_val, 10);
                sumProd.opening = sumProd.opening + parseInt(stockData[2]['p_' + prod.product_id], 10);
                sumProd.sale = sumProd.sale + parseInt(prod.sale, 10);
                sumProd.sample = sumProd.sample + parseInt(prod.sample, 10);
                sumProd.promo = sumProd.promo + parseInt(prod.promo, 10);
                sumProd.return = sumProd.return + parseInt(prod.return, 10);
                sumProd.damage = sumProd.damage + parseInt(prod.damage, 10);
              }
            });
            this.stock_neg = stock_neg;
            let index;
            if (sumProd.loadin === 0) {
              stockData.splice(0, 1);
            }
            if (sumProd.loadout === 0) {
              index = stockData.findIndex(x => x.LABEL === 'Loadout');
              stockData.splice(index, 1);
            }
            if (sumProd.sale === 0) {
              index = stockData.findIndex(x => x.LABEL === 'Sale');
              stockData.splice(index, 1);
            }
            if (sumProd.sample === 0) {
              index = stockData.findIndex(x => x.LABEL === 'Sample');
              stockData.splice(index, 1);
            }
            if (sumProd.promo === 0) {
              index = stockData.findIndex(x => x.LABEL === 'Promo');
              stockData.splice(index, 1);
            }
            if (sumProd.return === 0) {
              index = stockData.findIndex(x => x.LABEL === 'Return');
              stockData.splice(index, 1);
            }
            if (sumProd.damage === 0) {
              index = stockData.findIndex(x => x.LABEL === 'Damage');
              stockData.splice(index, 1);
            }
            this.stockData = stockData;
            this.data = stockData[0];
            const vanstock = 0;
            const eod_stat = this.eod_status;
            for (const key in this.data) {
                if (this.data.hasOwnProperty(key)) {
                  if (key === "LABEL") {
                    this.stockcolumnDefs.push({
                      headerName: key,
                      field: key,
                      width: 250,
                      pinned: 'left'
                    });
                  } else {
                    this.stockcolumnDefs.push({
                      headerName: this.all_products[key],
                      field: key,
                      cellRenderer(params) {
                        if (params.data.LABEL === 'Closing') {
                          if (params.value.toString().split('/').length === 2) {
                            const diff = params.value.split('/')[1];
                            if (parseInt(diff, 10) !== 0) {
                              return '<span style="color: red;">' + params.value.split('/')[0] + '(' + diff + ')</span>';
                            } else {
                              return '<span>' + params.value.split('/')[0] + '</span>';
                            }
                          } else {
                            return '<span>' + params.value + '</span>';
                          }
                        }
                        return '<span>' + params.value + '</span>';
                      }
                    });
                  }
                }
              }
            this.stock_loaded = true;
            this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
            this.user_data.prod_ids = prod_ids;
            const sumProd1 = {
              stk_ret: 0,
              dmg: 0,
              canc: 0,
              dev: 0,
              ret: 0
            };
            if (this.user_data.prod_ids.length > 0) {
              // tslint:disable-next-line:no-shadowed-variable
              const API_URL = '/api/pwa_connects/get_stock_and_sales2';
              let deviation;
              this.apiService.post(API_URL, this.user_data)
                .subscribe(res => {
                    console.log(res);
                    this.stock_and_sales2Loaded = true;
                    if (this.stock_and_sales1Loaded && this.approvalOrdersLoaded && this.settlementSummaryLoaded) {
                      this.appComponent.pageReady = true;
                    }
                    if (res.hasOwnProperty('results') && (res.results.status === 200)) {
                      const len = stockData.length;
                      stockData.push({LABEL: 'Stock Return'});
                      stockData.push({LABEL: 'Amount'});
                      stockData.push({LABEL: 'Return Amount'});
                      stockData.push({LABEL: 'Damage Edit'});
                      stockData.push({LABEL: 'Cancellation'});
                      stockData.push({LABEL: 'Deviation'});
                      let sett_dev = false;
                      res.results.data.forEach(prod => {
                        const indx = stockData.findIndex(x => x.LABEL === 'Closing');
                        deviation = stockData[indx]['p_' + prod.product_id];
                        stockData[indx]['p_' + prod.product_id] = stockData[indx + 1]['p_' + prod.product_id] + parseInt(prod.reusable, 10) +
                          parseInt(prod.deviation, 10) - parseInt(prod.cancelled, 10) + parseInt(prod.stock_return, 10);
                      /*  stockData[indx]['p_' + prod.product_id] = stockData[indx + 1]['p_' + prod.product_id] + parseInt(prod.reusable, 10)
                        + parseInt(prod.stock_return, 10);*/
                        if ((stockData[indx]['p_' + prod.product_id] - deviation) !== 0) {
                          sett_dev = true;
                        }
                        stockData[indx]['p_' + prod.product_id] = stockData[indx]['p_' + prod.product_id] + '/' + (stockData[indx]['p_' + prod.product_id] - deviation).toString();
                        stockData[len]['p_' + prod.product_id] = prod.stock_return;
                        stockData[len + 1]['p_' + prod.product_id] = prod.amount;
                        stockData[len + 2]['p_' + prod.product_id] = prod.ret_amount;
                        stockData[len + 3]['p_' + prod.product_id] = prod.damage_edit;
                        stockData[len + 4]['p_' + prod.product_id] = prod.cancelled;
                        stockData[len + 5]['p_' + prod.product_id] = prod.deviation;
                        sumProd1.stk_ret = sumProd1.stk_ret + parseInt(prod.stock_return, 10);
                        sumProd1.dmg = sumProd1.dmg + parseInt(prod.damage_edit, 10);
                        sumProd1.canc = sumProd1.canc + parseInt(prod.cancelled, 10);
                        sumProd1.dev = sumProd1.dev + Math.abs((parseInt(prod.deviation, 10)));
                        try {
                          if (prod.ret_amount !== null && prod.ret_amount !== undefined) {
                            this.total_return += parseFloat(prod.ret_amount);
                          }
                        } catch (e) {
                        }

                      });
                      if (sett_dev) {
                        if (stock_label === 'van') {
                          this.settle_dev = true;
                        } else {
                          this.settle_live_dev = true;
                        }
                      }
                      if (sumProd1.stk_ret === 0) {
                        index = stockData.findIndex(x => x.LABEL === 'Stock Return');
                        stockData.splice(index, 1);
                      }
                      if (sumProd1.dmg === 0) {
                        index = stockData.findIndex(x => x.LABEL === 'Damage Edit');
                        stockData.splice(index, 1);
                      }
                      if (sumProd1.canc === 0) {
                        index = stockData.findIndex(x => x.LABEL === 'Cancellation');
                        stockData.splice(index, 1);
                      }
                      if (sumProd1.dev === 0) {
                        index = stockData.findIndex(x => x.LABEL === 'Deviation');
                        stockData.splice(index, 1);
                      }
                      this.gridApi3.setRowData(stockData);
                      this.settle_wait = true;
                    }
                  },
                  error => console.log(error));
            } else {
              this.stock_and_sales2Loaded = true;
              if (this.stock_and_sales1Loaded && this.approvalOrdersLoaded && this.settlementSummaryLoaded) {
                this.appComponent.pageReady = true;
              }
              this.settle_wait = true;
            }
          } else {
            this.settle_wait = true;
            this.preloader1 = false;
            this.empty_state1 = true;
            this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
          }
        },
        error => console.log(error));
    // All approving orders details
    const orderData1 = [];
    const orderData2 = [];
    const orderData3 = [];
    const cashDr = [];
    const logData = [];
    const replaceData = [];
    const dispatchData = [];
    const returnData = [];
    const sampleData = [];
    const unappData = [];
    const pEinvoiceData = [];
    const pEpodData = [];
    const sapOrderData = [];
    let src_url = "../../assets/images/bill-disable.png";
    let  pod_url = "../../assets/tick.svg";
    this.preloader2 = true;
    this.preloader3 = true;
    this.preloader4 = true;
    this.replace_preloader = true;
    this.dispatch_preloader = true;
    this.return_preloader = true;
    this.mismatch_preloader = true;
    this.logs_preloader = true;
    this.sample_preloader = true;
    this.unapp_preloader = true;
    this.pEinvoice_preloader = true;
    this.pEpod_preloader = true;
    this.empty_state2 = false;
    this.empty_state3 = false;
    this.empty_state4 = false;
    this.empty_state_replace = false;
    this.empty_state_dispatch = false;
    this.empty_state_return = false;
    this.empty_state_mismatch = false;
    this.empty_state_logs = false;
    this.empty_state_sample = false;
    this.unapp_empty_state = false;
    this.peinvoice_empty_state = false;
    this.pepod_empty_state = false;
    this.countryMissing = false;
    this.countryMissingRetailers = new Set();
    let approve_state = 'Approve';
    let approved_returns = 0;
    const inthis = this;
    this.res_data = this.apiService.getApproval(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        this.approvalOrdersLoaded = true;
        if (this.stock_and_sales1Loaded && this.stock_and_sales2Loaded && this.settlementSummaryLoaded) {
          this.appComponent.pageReady = true;
        }
        this.orderData = res;
        if (res.hasOwnProperty('results') && res['results'].status === 200) {
          // loop needs to be removed
          if (res['results']?.data?.payments) {
            res['results'].data.payments.forEach(data => {
              if (data.store_fname === null) {
                src_url = "../../assets/images/bill-disable.png";
              } else {
                src_url = "../../assets/images/bill.png";
              }
              if (data.pod_store_fname && data.pod_store_fname !== "") {
                pod_url = "../../assets/tick.svg";
              } else {
                pod_url = "../../assets/cross.svg";
              }
              const cashData = {
                ID: data.id,
                TIME: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'HH:mm:ss'),
                'RETAILER NAME': data.partner_name,
                'RETAILER CODE': data.retailer_code,
                TYPE: data.payment_mode,
                approved: data.approved,
                state: data.state,
                MODE: data.payment_mode,
                order_type: data.order_type,
                write_date: data.write_date,
                write_uid: data.write_uid,
                QTY: data.total_qty,
                VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                'INVOICE NO': data.invoice_no,
                COMMENTS: data.note,
                PO: data.po,
                GRN: data.grn,
                PHOTO: {po: src_url, image: data.store_fname}
              };
              cashDr.push(cashData);
            });
          }

          // loop needs to be removed
          if (res['results']?.data?.logs) {
            res['results'].data.logs.forEach(data => {
              if (data.store_fname === null) {
                src_url = "../../assets/images/bill-disable.png";
              } else {
                src_url = "../../assets/images/bill.png";
              }
              if (data.pod_store_fname && data.pod_store_fname !== "") {
                pod_url = "../../assets/tick.svg";
              } else {
                pod_url = "../../assets/cross.svg";
              }
              const cashData = {
                ID: data.id,
                TIME: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'HH:mm:ss'),
                'RETAILER NAME': data.partner_name,
                'RETAILER CODE': data.retailer_code,
                TYPE: data.payment_mode,
                approved: data.approved,
                state: data.state,
                MODE: data.payment_mode,
                order_type: data.order_type,
                write_date: data.write_date,
                write_uid: data.write_uid,
                QTY: data.total_qty,
                VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                'INVOICE NO': data.invoice_no,
                COMMENTS: data.note,
                PO: data.po,
                GRN: data.grn,
                PHOTO: {po: src_url, image: data.store_fname}
              };
              logData.push(cashData);
            });
          }
          if (res['results']?.data?.todays_data) {
            res['results'].data.todays_data.forEach(data => {
              if (data.store_fname === null) {
                src_url = "../../assets/images/bill-disable.png";
              } else {
                src_url = "../../assets/images/bill.png";
              }
              if (data.pod_store_fname && data.pod_store_fname !== "") {
                pod_url = "../../assets/tick.svg";
              } else {
                pod_url = "../../assets/cross.svg";
              }

              if (data.state === 'cancel') {
                approve_state = 'Cancelled';
              } else if (data.approved === true) {
                approve_state = 'Approved';
              } else {
                approve_state = 'Approve';
              }
              if (data.d_status === 'delivered') {
                if (data.state !== 'cancel' && data.has_einvoice && (data.einv_status === null || (data.einv_status !== 'completed' && data.einv_status !== 'cancelled'))) {
                  const peData = {
                    ID: data.id,
                    TIME: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'HH:mm:ss'),
                    'RETAILER NAME': data.partner_name,
                    'RETAILER CODE': data.retailer_code,
                    TYPE: data.payment_mode,
                    approved: data.approved,
                    state: data.state,
                    'IRN Status': data.einv_status,
                    payment_mode: data.payment_mode,
                    order_type: data.order_type,
                    write_date: data.write_date,
                    write_uid: data.write_uid,
                    QTY: data.total_qty,
                    VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                    'INVOICE NO': data.invoice_no,
                    COMMENTS: data.note,
                    PO: data.po,
                    GRN: data.grn,
                    PHOTO: {po: src_url, image: data.store_fname},
                    ACTION: ''
                  };
                  pEinvoiceData.push(peData);
                }
                if (data.state !== 'cancel' && data.tid === null) {
                  const soData = {
                    ID: data.id,
                    name: data.name,
                    /*  TIME: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'HH:mm:ss'),*/
                    /*  'RETAILER NAME': data.partner_name,
                      'RETAILER CODE': data.retailer_code,*/
                    TYPE: data.payment_mode,
                    /*    approved: data.approved,*/
                    state: data.state,
                    country: data.country_id,
                    payment_mode: data.payment_mode,
                    order_type: data.order_type,
                    write_date: data.write_date,
                    write_uid: data.write_uid,
                    /* QTY: data.total_qty,
                     VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),*/
                    'INVOICE NO': data.invoice_no,
                    COMMENTS: data.note,
                    PO: data.po,
                    GRN: data.grn,
                    LOGS: 'logs'
                    /*   PHOTO: {po: src_url, image: data.store_fname},
                       APPROVE: approve_state*/
                  };
                  sapOrderData.push(soData);
                  if (data.country_id) {
                    inthis.country_id = data.country_id;
                  } else if (this.sapSync) {
                    this.countryMissing = true;
                    this.countryMissingRetailers.add(data.retailer_code);
                  }
                }
                if ((data.payment_mode === 'cash') && (data.order_type === 'sale')) {
                  const cashData = {
                    ID: data.id,
                    TIME: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'HH:mm:ss'),
                    'RETAILER NAME': data.partner_name,
                    'RETAILER CODE': data.retailer_code,
                    TYPE: data.payment_mode,
                    approved: data.approved,
                    state: data.state,
                    payment_mode: data.payment_mode,
                    order_type: data.order_type,
                    write_date: data.write_date,
                    write_uid: data.write_uid,
                    QTY: data.total_qty,
                    VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                    'INVOICE NO': data.invoice_no,
                    COMMENTS: data.note,
                    PO: data.po,
                    GRN: data.grn,
                    PHOTO: {po: src_url, image: data.store_fname},
                    POD: {po: pod_url, image: data.pod_store_fname, type: data.pod_type},
                    APPROVE: approve_state,
                    config_json: data.config_json,
                    contact_name: data.ref,
                    mobile: data.mobile,
                    date_delivered: data.date_delivered
                  };
                  orderData3.push(cashData);
                  if (data.state !== 'cancel' && data.config_json.hasOwnProperty('totalDiscount') && data.config_json.totalDiscount && data.config_json.totalDiscount !== "") {
                    this.totalDiscount += parseFloat(data.config_json.totalDiscount);
                    this.discountLines++;
                  }
                  if (data.state !== 'cancel' && this.podCheck(data.partner_type, data.payment_mode)) {
                    if (!data.pod_store_fname || (data.pod_store_fname && data.pod_type === 'qr_validated'
                      && (!data?.config_json?.pod || (data?.config_json?.pod && data?.config_json?.pod.hasOwnProperty('image_verified') && data?.config_json?.pod?.image_verified === false)))) {
                      const podData = {
                        ID: data.id,
                        DATE: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss'),
                        'RETAILER NAME': data.partner_name,
                        'RETAILER CODE': data.retailer_code,
                        TYPE: data.payment_mode,
                        approved: data.approved,
                        state: data.state,
                        payment_mode: data.payment_mode,
                        order_type: data.order_type,
                        write_date: data.write_date,
                        write_uid: data.write_uid,
                        QTY: data.total_qty,
                        VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                        'INVOICE NO': data.invoice_no,
                        COMMENTS: data.note,
                        PO: data.po,
                        GRN: data.grn,
                        PHOTO: {po: src_url, image: data.store_fname},
                        POD: {po: pod_url, image: data.pod_store_fname, type: data.pod_type},
                        APPROVE: approve_state,
                        config_json: data.config_json,
                        contact_name: data.ref,
                        mobile: data.mobile,
                        date_delivered: data.date_delivered
                      };
                      pEpodData.push(podData);
                    }
                  }
                } else if (data.order_type === 'sale') {
                  const creditData = {
                    ID: data.id,
                    TIME: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'HH:mm:ss'),
                    'RETAILER NAME': data.partner_name,
                    'RETAILER CODE': data.retailer_code,
                    TYPE: data.payment_mode,
                    approved: data.approved,
                    state: data.state,
                    payment_mode: data.payment_mode,
                    order_type: data.order_type,
                    write_date: data.write_date,
                    write_uid: data.write_uid,
                    QTY: data.total_qty,
                    VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                    'INVOICE NO': data.invoice_no,
                    COMMENTS: data.note,
                    PO: data.po,
                    GRN: data.grn,
                    PHOTO: {po: src_url, image: data.store_fname},
                    POD: {po: pod_url, image: data.pod_store_fname, type: data.pod_type},
                    APPROVE: approve_state,
                    config_json: data.config_json,
                    contact_name: data.ref,
                    mobile: data.mobile,
                    date_delivered: data.date_delivered,
                  };
                  orderData1.push(creditData);
                  if (data.state !== 'cancel' && data.config_json.hasOwnProperty('totalDiscount') && data.config_json.totalDiscount && data.config_json.totalDiscount !== "") {
                    this.totalDiscount += parseFloat(data.config_json.totalDiscount);
                    this.discountLines++;
                  }
                  if (data.state !== 'cancel' && this.podCheck(data.partner_type, data.payment_mode)) {
                    if (!data.pod_store_fname || (data.pod_store_fname && data.pod_type === 'qr_validated'
                      && (!data?.config_json?.pod || (data?.config_json?.pod && data?.config_json?.pod.hasOwnProperty('image_verified') && data?.config_json?.pod?.image_verified === false)))) {
                      const podData = {
                        ID: data.id,
                        DATE: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss'),
                        'RETAILER NAME': data.partner_name,
                        'RETAILER CODE': data.retailer_code,
                        TYPE: data.payment_mode,
                        approved: data.approved,
                        state: data.state,
                        payment_mode: data.payment_mode,
                        order_type: data.order_type,
                        write_date: data.write_date,
                        write_uid: data.write_uid,
                        QTY: data.total_qty,
                        VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                        'INVOICE NO': data.invoice_no,
                        COMMENTS: data.note,
                        PO: data.po,
                        GRN: data.grn,
                        PHOTO: {po: src_url, image: data.store_fname},
                        POD: {po: pod_url, image: data.pod_store_fname, type: data.pod_type},
                        APPROVE: approve_state,
                        config_json: data.config_json,
                        contact_name: data.ref,
                        mobile: data.mobile,
                        date_delivered: data.date_delivered
                      };
                      pEpodData.push(podData);
                    }
                  }
                } else if (data.order_type === 'replace') {
                  const damageData = {
                    ID: data.id,
                    TIME: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'HH:mm:ss'),
                    'RETAILER NAME': data.partner_name,
                    'RETAILER CODE': data.retailer_code,
                    TYPE: data.payment_mode,
                    approved: data.approved,
                    state: data.state,
                    QTY: data.total_qty,
                    VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                    'INVOICE NO': data.invoice_no,
                    COMMENTS: data.note,
                    PO: data.po,
                    GRN: data.grn,
                    PHOTO: {po: src_url, image: data.store_fname},
                    CONVERT: 'convert',
                    APPROVE: approve_state,
                    config_json: data.config_json,
                    contact_name: data.ref,
                    mobile: data.mobile,
                    date_delivered: data.date_delivered,
                    order_type: data.order_type,
                    payment_mode: data.payment_mode,
                    dc_id: data.supplier_id,
                    territory_id: data.territory_id,
                    partner_id: data.partner_id,
                    sale: data.sale,
                    cid: data.cid,
                    write_date: data.write_date,
                    write_uid: data.write_uid
                  };
                  replaceData.push(damageData);
                } else if (data.order_type === 'return') {
                  if (data.return_store_fname === null) {
                    src_url = "../../assets/images/bill-disable.png";
                  } else {
                    src_url = "../../assets/images/bill.png";
                  }
                  if (data.approved || data.state === 'cancel') {
                    approved_returns += 1;
                  }
                  const retData = {
                    ID: data.id,
                    TIME: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'HH:mm:ss'),
                    'RETAILER NAME': data.partner_name,
                    'RETAILER CODE': data.retailer_code,
                    TYPE: data.payment_mode,
                    approved: data.approved,
                    state: data.state,
                    QTY: data.total_qty,
                    VALUE: -1 * parseFloat(data.sale.toFixed(inthis.apiService.decimalPolicy)),
                    'INVOICE NO': data.invoice_no,
                    COMMENTS: data.note,
                    PO: data.po,
                    GRV: data.grn,
                    PHOTO: {po: src_url, image: data.return_store_fname},
                    CONVERT: 'convert',
                    APPROVE: approve_state,
                    config_json: data.config_json,
                    contact_name: data.ref,
                    mobile: data.mobile,
                    date_delivered: data.date_delivered,
                    order_type: data.order_type,
                    payment_mode: data.payment_mode,
                    dc_id: data.supplier_id,
                    territory_id: data.territory_id,
                    partner_id: data.partner_id,
                    sale: data.sale,
                    cid: data.cid,
                    write_date: data.write_date,
                    write_uid: data.write_uid
                  };
                  returnData.push(retData);
                } else if (data.order_type === 'sample') {
                  const sampData = {
                    ID: data.id,
                    TIME: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'HH:mm:ss'),
                    'RETAILER NAME': data.partner_name,
                    'RETAILER CODE': data.retailer_code,
                    TYPE: data.payment_mode,
                    approved: data.approved,
                    state: data.state,
                    payment_mode: data.payment_mode,
                    order_type: data.order_type,
                    write_date: data.write_date,
                    write_uid: data.write_uid,
                    QTY: data.total_qty,
                    VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                    'INVOICE NO': data.invoice_no,
                    COMMENTS: data.note,
                    PO: data.po,
                    GRN: data.grn,
                    PHOTO: {po: src_url, image: data.return_store_fname},
                    APPROVE: approve_state,
                    config_json: data.config_json,
                    contact_name: data.ref,
                    mobile: data.mobile,
                    date_delivered: data.date_delivered,
                  };
                  sampleData.push(sampData);
                }
              } else if (data.d_status === 'dispatched') {
                if (data.state === 'cancel') {
                  approve_state = 'Cancelled';
                } else {
                  approve_state = '';
                }
                if (data.store_fname === null) {
                  src_url = "../../assets/images/bill-disable.png";
                } else {
                  src_url = "../../assets/images/bill.png";
                }
                const dispData = {
                  ID: data.id,
                  DATE: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss'),
                  'RETAILER NAME': data.partner_name,
                  'RETAILER CODE': data.retailer_code,
                  TYPE: data.payment_mode,
                  approved: data.approved,
                  state: data.state,
                  payment_mode: data.payment_mode,
                  order_type: data.order_type,
                  d_status: data.d_status,
                  write_date: data.write_date,
                  write_uid: data.write_uid,
                  QTY: data.qty,
                  VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                  PO: data.po,
                  'INVOICE NO': data.invoice_no,
                  COMMENTS: data.note,
                  GRN: data.grn,
                  PHOTO: {po: src_url, image: data.store_fname},
                  APPROVE: approve_state,
                  config_json: data.config_json,
                  contact_name: data.ref,
                  mobile: data.mobile,
                  date_delivered: data.date_delivered,
                };
                dispatchData.push(dispData);
              }
            });
          }
          if (res['results']?.data?.previous_data) {
            res['results'].data.previous_data.forEach(data => {
              if (data.store_fname === null) {
                src_url = "../../assets/images/bill-disable.png";
              } else {
                src_url = "../../assets/images/bill.png";
              }
              if (data.pod_store_fname && data.pod_store_fname !== "") {
                pod_url = "../../assets/tick.svg";
              } else {
                pod_url = "../../assets/cross.svg";
              }
              if (data.d_status === 'dispatched') {
                if (data.state === 'cancel') {
                  approve_state = 'Canceled';
                } else {
                  approve_state = '';
                }
                const dispData = {
                  ID: data.id,
                  DATE: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss'),
                  'RETAILER NAME': data.partner_name,
                  'RETAILER CODE': data.retailer_code,
                  TYPE: data.payment_mode,
                  approved: data.approved,
                  state: data.state,
                  payment_mode: data.payment_mode,
                  order_type: data.order_type,
                  d_status: data.d_status,
                  write_date: data.write_date,
                  write_uid: data.write_uid,
                  QTY: data.qty,
                  VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                  PO: data.po,
                  'INVOICE NO': data.invoice_no,
                  COMMENTS: data.note,
                  GRN: data.grn,
                  PHOTO: {po: src_url, image: data.store_fname},
                  APPROVE: approve_state,
                  config_json: data.config_json,
                  contact_name: data.ref,
                  mobile: data.mobile,
                  date_delivered: data.date_delivered,
                };
                dispatchData.push(dispData);
              } else if (data.d_status === 'delivered') {
                if (data.state === 'cancel') {
                  approve_state = 'Canceled';
                } else {
                  approve_state = 'Approve';
                }
                if (data.payment_mode === 'cash') {
                  // tslint:disable-next-line:no-shadowed-variable
                  const pushData = {
                    ID: data.id,
                    DATE: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss'),
                    'RETAILER NAME': data.partner_name,
                    'RETAILER CODE': data.retailer_code,
                    TYPE: data.payment_mode,
                    approved: data.approved,
                    state: data.state,
                    payment_mode: data.payment_mode,
                    order_type: data.order_type,
                    write_date: data.write_date,
                    write_uid: data.write_uid,
                    QTY: data.total_qty,
                    VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                    'INVOICE NO': data.invoice_no,
                    COMMENTS: data.note,
                    PO: data.po,
                    GRN: data.grn,
                    PHOTO: {po: src_url, image: data.store_fname},
                    POD: {po: pod_url, image: data.pod_store_fname, type: data.pod_type},
                    APPROVE: approve_state,
                    config_json: data.config_json,
                    contact_name: data.ref,
                    mobile: data.mobile,
                    date_delivered: data.date_delivered
                  };
                  unappData.push(pushData);
                } else {
                  const cashData = {
                    ID: data.id,
                    DATE: datePipe.transform((new Date(data.date_order + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss'),
                    'RETAILER NAME': data.partner_name,
                    'RETAILER CODE': data.retailer_code,
                    TYPE: data.payment_mode,
                    approved: data.approved,
                    state: data.state,
                    payment_mode: data.payment_mode,
                    order_type: data.order_type,
                    write_date: data.write_date,
                    write_uid: data.write_uid,
                    QTY: data.total_qty,
                    VALUE: data.sale.toFixed(inthis.apiService.decimalPolicy),
                    'INVOICE NO': data.invoice_no,
                    COMMENTS: data.note,
                    PO: data.po,
                    GRN: data.grn,
                    PHOTO: {po: src_url, image: data.store_fname},
                    POD: {po: pod_url, image: data.pod_store_fname, type: data.pod_type},
                    APPROVE: approve_state,
                    config_json: data.config_json,
                    contact_name: data.ref,
                    mobile: data.mobile,
                    date_delivered: data.date_delivered
                  };
                  orderData2.push(cashData);
                }
              }
            });
          }

          if (returnData.length === approved_returns) {
            this.is_return_approved = true;
          }
          this.data = orderData1[0];
          this.rowData = orderData1;
          this.rowDataPrev = orderData2;
          this.rowData3 = orderData3;
          this.rowReplace = replaceData;
          this.rowDispatch = dispatchData;
          this.rowReturn = returnData;
          this.rowMismatch = cashDr;
          this.rowLogs = logData;
          this.rowSample = sampleData;
          this.rowUnapp = unappData;
          this.rowPEinvoice = pEinvoiceData;
          this.rowPEpod = pEpodData;
          this.rowSapOrder = sapOrderData;
          this.today_count = orderData1.length;
          this.prvs_count = orderData2.length;
          this.cash_count = orderData3.length;
          this.replace_count = replaceData.length;
          this.dispatch_count = dispatchData.length;
          this.return_count = returnData.length;
          this.mismatch_count = cashDr.length;
          this.logs_count = logData.length;
          this.sample_count = sampleData.length;
          this.unapp_count = unappData.length;
          this.pending_einvoice_count = pEinvoiceData.length;
          this.pending_pod_count = pEpodData.length;
          this.pending_saporder_count = sapOrderData.length;

          this.total_count = this.today_count + this.prvs_count + this.cash_count + this.replace_count +
            this.dispatch_count + this.return_count + this.sample_count + this.unapp_count +
            this.pending_einvoice_count + this.pending_pod_count + this.pending_saporder_count;
          const eod_status = this.eod_status;
          // loop needs to be removed
          for (const key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.columnDefs.push({
                  headerName: key, field: key,    resizable: true
                }),
                  this.rowSelection = "multiple";
              } else if (key === 'GRN') {
                this.columnDefs.push({
                  headerName: key, field: key,    resizable: true
                });
                  this.rowSelection = "multiple";
              } else if (key === 'PHOTO') {
                this.columnDefs.push({
                  headerName: key, field: key,    resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                  this.rowSelection = "multiple";
              } else if (key === 'POD') {
                this.columnDefs.push({
                  headerName: key, field: key,    resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                  this.rowSelection = "multiple";
              } else if (key === "INVOICE NO") {
                this.columnDefs.push({
                  headerName: key, field: key,    resizable: true
                });

              } else if (key === "APPROVE") {
                this.columnDefs.push({
                  headerName: key, field: key,  resizable: true,
                  headerComponent: "headerApproveAllcomponent",
                  cellRenderer: "approveClickRenderer",
                });
              } else if (key === "VALUE") {
                this.columnDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              } else if (key === "QTY") {
                this.columnDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              } else if (key === "RETAILER NAME") {
                this.columnDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              } else if (key === "TYPE" || key === "approved" || key === "state" || key === 'payment_mode' || key === 'order_type' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered') {
                this.columnDefs.push({
                  headerName: key, field: key,    hide: true,
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.columnDefs.push({
                  headerName: key, field: key,   resizable: true,
                  headerCheckboxSelection(params) {
                    return (mark_approve);
                  },
                  headerCheckboxSelectionFilteredOnly: true,
                  checkboxSelection(params) {
                    return (mark_approve && params.data['APPROVE'] === 'Approve');
                  },
                  cellRenderer(params) {
                    return '<span>' + params.value + '</span>';
                  }
                });
              } else {
                this.columnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              }

            }
          }
          this.data1 = orderData2[0];
          for (const key in this.data1) {
            if (this.data1.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.columnDefsPrev.push({
                  headerName: key, field: key,  resizable: true
                }),
                  this.rowSelection = "multiple";
              } else if (key === 'GRN') {
                this.columnDefsPrev.push({
                  headerName: key, field: key,  resizable: true
                }),
                  this.rowSelection = "multiple";
              } else if (key === 'PHOTO') {
                this.columnDefsPrev.push({
                  headerName: key, field: key,  resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                  this.rowSelection = "multiple";
              } else if (key === 'POD') {
                this.columnDefsPrev.push({
                  headerName: key, field: key,    resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                  this.rowSelection = "multiple";
              } else if (key === "INVOICE NO") {
                this.columnDefsPrev.push({
                  headerName: key, field: key,  resizable: true
                });
              } else if (key === "APPROVE") {
                this.columnDefsPrev.push({
                  headerName: key, field: key,  resizable: true,
                  headerComponent: "headerApproveAllcomponent",
                  cellRenderer: "approveClickRenderer",
                });
              } else if (key === "VALUE") {
                this.columnDefsPrev.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "QTY") {
                this.columnDefsPrev.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "RETAILER NAME") {
                this.columnDefsPrev.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "TYPE" || key === "approved" || key === "state" || key === 'payment_mode' || key === 'order_type' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered') {
                this.columnDefsPrev.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: true,
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.columnDefsPrev.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: false,
                  headerCheckboxSelection(params) {
                    return (mark_approve);
                  },
                  headerCheckboxSelectionFilteredOnly: true,
                  checkboxSelection(params) {
                    return (mark_approve && params.data['APPROVE'] === 'Approve');
                  },
                  cellRenderer(params) {
                    return '<span>' + params.value + '</span>';
                  }
                });
              } else {
                this.columnDefsPrev.push({
                  headerName: key, field: key,  resizable: true,
                });
              }
            }
          }
          this.data2 = orderData3[0];
          for (const key in this.data2) {
            if (this.data2.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.columnDefs3.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: true
                });
                  this.rowSelection = "multiple";
              } else if (key === 'GRN') {
                this.columnDefs3.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'PHOTO') {
                this.columnDefs3.push({
                  headerName: key, field: key,  resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                this.rowSelection = "multiple";
              } else if (key === 'POD') {
                this.columnDefs3.push({
                  headerName: key, field: key,    resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                this.rowSelection = "multiple";
              } else if (key === "INVOICE NO") {
                this.columnDefs3.push({
                  headerName: key, field: key,  resizable: true
                });
              } else if (key === "VALUE") {
                this.columnDefs3.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "QTY") {
                this.columnDefs3.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "RETAILER NAME") {
                this.columnDefs3.push({
                  headerName: key, field: key,  resizable: true
                });
              } else if (key === "APPROVE") {
                this.columnDefs3.push({
                  headerName: key, field: key,
                  headerComponent: "headerApproveAllcomponent",
                  cellRenderer: "approveClickRenderer",
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.columnDefs3.push({
                  headerName: key, field: key,  resizable: true,
                  headerCheckboxSelection(params) {
                    return (mark_approve);
                  },
                  headerCheckboxSelectionFilteredOnly: true,
                  checkboxSelection(params) {
                    return (mark_approve && (params.data['APPROVE'] === 'Approve'));
                  },
                  cellRenderer(params) {
                    return '<span>' + params.value + '</span>';
                  }
                });
              } else if (key === "TYPE" || key === "approved" || key === "state" || key === 'payment_mode' || key === 'order_type' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' ||
                key === 'date_delivered' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered') {
                this.columnDefs3.push({
                  headerName: key, field: key,    hide: true,
                });
              } else {
                this.columnDefs3.push({
                  headerName: key, field: key,  resizable: true,
                });
              }
            }
          }
          this.dataReplace = replaceData[0];
          const convert_enable = this.convert_enable;
          for (const key in this.dataReplace) {
            if (this.dataReplace.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.replacecolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                }),
                  this.rowSelection = "multiple";
              } else if (key === 'GRN') {
                this.replacecolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                }),
                  this.rowSelection = "multiple";
              } else if (key === 'PHOTO') {
                this.replacecolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                }),
                  this.rowSelection = "multiple";
              } else if (key === "INVOICE NO") {
                this.replacecolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });

              } else if (key === "APPROVE") {
                this.replacecolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                  headerComponent: "headerApproveAllcomponent",
                  cellRenderer: "approveClickRenderer",
                });
              } else if (key === "CONVERT") {
                this.replacecolumnDefs.push({
                  headerName: key, field: key,
                  headerComponent: "convertcomponent",
                  cellRenderer: "convertClickRenderer",
                  hide: ((eod_status !== 'complete') || (!convert_enable))
                });
              } else if (key === "VALUE") {
                this.replacecolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "QTY") {
                this.replacecolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "RETAILER NAME") {
                this.replacecolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "TYPE" || key === "approved" || key === "state" || key === 'payment_mode' || key === 'order_type'
                || key === 'dc_id' || key === 'territory_id' || key === 'partner_id' || key === 'sale' || key === 'cid' || key === 'COMMENTS' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered') {
                this.replacecolumnDefs.push({
                  headerName: key, field: key,    hide: true,
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.replacecolumnDefs.push({
                  headerName: key, field: key,    resizable: true,
                  headerCheckboxSelection(params) {
                    return (mark_approve);
                  },
                  headerCheckboxSelectionFilteredOnly: true,
                  checkboxSelection(params) {
                    return (mark_approve && (params.data['APPROVE'] === 'Approve'));
                  },
                  cellRenderer(params) {
                    return '<span>' + params.value + '</span>';
                  }
                });
              } else {
                this.replacecolumnDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              }
            }
          }
          this.dataReturn = returnData[0];
          for (const key in this.dataReturn) {
            if (this.dataReturn.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.returncolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                }),
                  this.rowSelection = "multiple";
              } else if (key === 'GRV') {
                this.returncolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'PHOTO') {
                this.returncolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                this.rowSelection = "multiple";
              } else if (key === "INVOICE NO") {
                this.returncolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });
              } else if (key === "APPROVE") {
                this.returncolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                  headerComponent: "headerApproveAllcomponent",
                  cellRenderer: "approveClickRenderer",
                });
              } else if (key === "CONVERT") {
                this.returncolumnDefs.push({
                  headerName: key, field: key,
                  headerComponent: "convertcomponent",
                  cellRenderer: "convertClickRenderer",
                  hide: ((eod_status !== 'complete') || (!convert_enable))
                });
              } else if (key === "VALUE") {
                this.returncolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "QTY") {
                this.returncolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "RETAILER NAME") {
                this.returncolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "TYPE" || key === "approved" || key === "state" || key === 'payment_mode' || key === 'order_type'
                || key === 'dc_id' || key === 'territory_id' || key === 'partner_id' || key === 'sale' || key === 'cid' || key === 'COMMENTS' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered') {
                this.returncolumnDefs.push({
                  headerName: key, field: key,    hide: true,
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.returncolumnDefs.push({
                  headerName: key, field: key,    resizable: true,
                  headerCheckboxSelection(params) {
                    return (mark_approve);
                  },
                  headerCheckboxSelectionFilteredOnly: true,
                  checkboxSelection(params) {
                    return (mark_approve && (params.data['APPROVE'] === 'Approve'));
                  },
                  cellRenderer(params) {
                    return '<span>' + params.value + '</span>';
                  }
                });
              } else {
                this.returncolumnDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              }
            }
          }
          this.dataMismatch = cashDr[0];
          for (const key in this.dataMismatch) {
            if (this.dataMismatch.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.mismatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'GRN') {
                this.mismatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'PHOTO') {
                this.mismatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                this.rowSelection = "multiple";
              } else if (key === "INVOICE NO") {
                this.mismatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });

              } else if (key === "VALUE") {
                this.mismatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "QTY") {
                this.mismatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "RETAILER NAME") {
                this.mismatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "TYPE" || key === "approved" || key === "state" || key === 'order_type'
                || key === 'dc_id' || key === 'territory_id' || key === 'partner_id' || key === 'sale' || key === 'cid' || key === 'COMMENTS' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered') {
                this.mismatchcolumnDefs.push({
                  headerName: key, field: key,    hide: true,
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.mismatchcolumnDefs.push({
                  headerName: key, field: key,    resizable: true
                });
              } else {
                this.mismatchcolumnDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              }
            }
          }
          this.dataLogs = logData[0];
          for (const key in this.dataLogs) {
            if (this.dataLogs.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.logcolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'GRN') {
                this.logcolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'PHOTO') {
                this.logcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                this.rowSelection = "multiple";
              } else if (key === "INVOICE NO") {
                this.logcolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });
              } else if (key === "VALUE") {
                this.logcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "QTY") {
                this.logcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "RETAILER NAME") {
                this.logcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "TYPE" || key === "approved" || key === "state" || key === 'order_type'
                || key === 'dc_id' || key === 'territory_id' || key === 'partner_id' || key === 'sale' || key === 'cid' || key === 'COMMENTS' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered') {
                this.logcolumnDefs.push({
                  headerName: key, field: key,    hide: true,
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.logcolumnDefs.push({
                  headerName: key, field: key,    resizable: true
                });
              } else {
                this.logcolumnDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              }
            }
          }
          this.dataSample = sampleData[0];
          for (const key in this.dataSample) {
            if (this.dataSample.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.samplecolumnDefs.push({
                  headerName: key, field: key,   resizable: true, lockPosition: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'GRN') {
                this.samplecolumnDefs.push({
                  headerName: key, field: key,   resizable: true, lockPosition: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'PHOTO') {
                this.samplecolumnDefs.push({
                  headerName: key, field: key,   resizable: true,
                  cellRenderer: "imageClickRenderer"
                });
              } else if (key === "INVOICE NO") {
                this.samplecolumnDefs.push({
                  headerName: key, field: key,   resizable: true, lockPosition: true
                });
              } else if (key === "APPROVE") {
                this.samplecolumnDefs.push({
                  headerName: key, field: key,
                  headerComponent: "headerApproveAllcomponent",
                  cellRenderer: "approveClickRenderer",
                });
              } else if (key === "VALUE") {
                this.samplecolumnDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              } else if (key === "QTY") {
                this.samplecolumnDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              } else if (key === "RETAILER NAME") {
                this.samplecolumnDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              } else if (key === "TYPE" || key === "approved" || key === "state" || key === 'payment_mode' || key === 'COMMENTS' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered') {
                this.samplecolumnDefs.push({
                  headerName: key, field: key,    hide: true,
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.samplecolumnDefs.push({
                  headerName: key, field: key,   resizable: true,
                  headerCheckboxSelection(params) {
                    return (mark_approve);
                  },
                  headerCheckboxSelectionFilteredOnly: true,
                  checkboxSelection(params) {
                    return (mark_approve && (params.data['APPROVE'] === 'Approve'));
                  },
                  cellRenderer(params) {
                    return '<span>' + params.value + '</span>';
                  }
                });
              } else {
                this.samplecolumnDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              }
            }
          }
          this.dataDispatch = dispatchData[0];
          for (const key in this.dataDispatch) {
            if (this.dataDispatch.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.dispatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'GRN') {
                this.dispatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'PHOTO') {
                this.dispatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                this.rowSelection = "multiple";
              } else if (key === "INVOICE NO") {
                this.dispatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });
              } else if (key === "APPROVE") {
                this.dispatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                  cellRenderer: "approveClickRenderer",
                });
              } else if (key === "VALUE") {
                this.dispatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "QTY") {
                this.dispatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "RETAILER NAME") {
                this.dispatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "TYPE" || key === "approved" || key === "state" || key === 'payment_mode' || key === 'order_type' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered' || key === 'd_status') {
                this.dispatchcolumnDefs.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: true,
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.dispatchcolumnDefs.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: false,
                  headerCheckboxSelection(params) {
                    return (mark_approve);
                  },
                  headerCheckboxSelectionFilteredOnly: true,
                  checkboxSelection(params) {
                    return (mark_approve && params.data['APPROVE'] === 'Approve');
                  },
                  cellRenderer(params) {
                    return '<span>' + params.value + '</span>';
                  }
                });
              } else {
                this.dispatchcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              }
            }
          }
          this.dataUnapp = unappData[0];
          for (const key in this.dataUnapp) {
            if (this.dataUnapp.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.unappcolumnDefs.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'GRN') {
                this.unappcolumnDefs.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'PHOTO') {
                this.unappcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                this.rowSelection = "multiple";
              } else if (key === 'POD') {
                this.unappcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                this.rowSelection = "multiple";
              } else if (key === "INVOICE NO") {
                this.unappcolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });
              } else if (key === "VALUE") {
                this.unappcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "QTY") {
                this.unappcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "RETAILER NAME") {
                this.unappcolumnDefs.push({
                  headerName: key, field: key,  resizable: true
                });
              } else if (key === "APPROVE") {
                this.unappcolumnDefs.push({
                  headerName: key, field: key,
                  headerComponent: "headerApproveAllcomponent",
                  cellRenderer: "approveClickRenderer",
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.unappcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                  headerCheckboxSelection(params) {
                    return (mark_approve);
                  },
                  headerCheckboxSelectionFilteredOnly: true,
                  checkboxSelection(params) {
                    return (mark_approve && (params.data['APPROVE'] === 'Approve'));
                  },
                  cellRenderer(params) {
                    return '<span>' + params.value + '</span>';
                  }
                });
              } else if (key === "TYPE" || key === "approved" || key === "state" || key === 'payment_mode' || key === 'order_type' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered') {
                this.unappcolumnDefs.push({
                  headerName: key, field: key,    hide: true,
                });
              } else {
                this.unappcolumnDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              }
            }
          }
          this.dataPEinvoice = pEinvoiceData[0];
          for (const key in this.dataPEinvoice) {
            if (this.dataPEinvoice.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.pEinvoiceDefs.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'IRN Status') {
                this.pEinvoiceDefs.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'GRN') {
                this.pEinvoiceDefs.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'PHOTO') {
                this.pEinvoiceDefs.push({
                  headerName: key, field: key,  resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                this.rowSelection = "multiple";
              } else if (key === "INVOICE NO") {
                this.pEinvoiceDefs.push({
                  headerName: key, field: key,  resizable: true
                });
              } else if (key === "VALUE") {
                this.pEinvoiceDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "QTY") {
                this.pEinvoiceDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              } else if (key === "RETAILER NAME") {
                this.pEinvoiceDefs.push({
                  headerName: key, field: key,  resizable: true
                });
              } else if (key === "ACTION") {
                this.pEinvoiceDefs.push({
                  headerName: key, field: key,  filter: true,  resizable: true,
                  headerComponent: "regenerateHeadercomponent",
                  cellRenderer: "regenerateRenderer", pinned: 'right', width: 120
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.pEinvoiceDefs.push({
                  headerName: key, field: key,  resizable: true,
                  headerCheckboxSelection: true,
                  checkboxSelection: true,
                  cellRenderer(params) {
                    return '<span>' + params.value + '</span>';
                  }
                });
              } else if (key === "TYPE" || key === "approved" || key === "state" || key === 'payment_mode' || key === 'order_type' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered') {
                this.pEinvoiceDefs.push({
                  headerName: key, field: key,    hide: true,
                });
              } else {
                this.pEinvoiceDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              }
            }
          }
          this.dataPEpod = pEpodData[0];
          for (const key in this.dataPEpod) {
            if (this.dataPEpod.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.pEpodDefs.push({
                  headerName: key, field: key,    resizable: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'GRN') {
                this.pEpodDefs.push({
                  headerName: key, field: key,    resizable: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'PHOTO') {
                this.pEpodDefs.push({
                  headerName: key, field: key,    resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                this.rowSelection = "multiple";
              } else if (key === 'POD') {
                this.pEpodDefs.push({
                  headerName: key, field: key,    resizable: true,
                  cellRenderer: "imageClickRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
                this.rowSelection = "multiple";
              } else if (key === "INVOICE NO") {
                this.pEpodDefs.push({
                  headerName: key, field: key,    resizable: true
                });
              } else if (key === "VALUE") {
                this.pEpodDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              } else if (key === "QTY") {
                this.pEpodDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              } else if (key === "RETAILER NAME") {
                this.pEpodDefs.push({
                  headerName: key, field: key,   resizable: true,
                });
              } else if (key === "TYPE" || key === "approved" || key === "state" || key === 'payment_mode' || key === 'order_type' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered') {
                this.pEpodDefs.push({
                  headerName: key, field: key,    hide: true,
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.pEpodDefs.push({
                  headerName: key, field: key,   resizable: true,
                  headerCheckboxSelection(params) {
                    return (mark_approve);
                  },
                  headerCheckboxSelectionFilteredOnly: true,
                  checkboxSelection(params) {
                    return (mark_approve && params.data['APPROVE'] === 'Approve');
                  },
                  cellRenderer(params) {
                    return '<span>' + params.value + '</span>';
                  }
                });
              } else if (key !== "APPROVE"){
                this.pEpodDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              }
            }
          }
          this.dataSapOrder = sapOrderData[0];
          for (const key in this.dataSapOrder) {
            if (this.dataSapOrder.hasOwnProperty(key)) {
              if (key === 'PO') {
                this.sapOrderDefs.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: true
                });
                this.rowSelection = "multiple";
              } else if (key === 'GRN') {
                this.sapOrderDefs.push({
                  headerName: key,
                  field: key,
                  resizable: true,
                  hide: true
                });
                this.rowSelection = "multiple";
              } else if (key === "INVOICE NO") {
                this.sapOrderDefs.push({
                  headerName: key, field: key,  resizable: true
                });
              } else if (key === "ID") {
                const mark_approve = this.mark_approve;
                this.sapOrderDefs.push({
                  headerName: key, field: key,  resizable: true,
                  headerCheckboxSelection(params) {
                    return (mark_approve);
                  },
                  headerCheckboxSelectionFilteredOnly: true,
                  checkboxSelection(params) {
                    return (mark_approve && (params.data['APPROVE'] === 'Approve'));
                  },
                  cellRenderer(params) {
                    return '<span>' + params.value + '</span>';
                  }
                });
              } else if (key === "TYPE") {
                this.sapOrderDefs.push({
                  headerName: key, field: key,   lockPosition: true
                });
              } else if (key === "approved" || key === "state" || key === "country" || key === 'payment_mode' || key === 'order_type' ||
                key === 'write_date' || key === 'write_uid' || key === 'config_json' || key === 'contact_name' || key === 'mobile' || key === 'date_delivered') {
                this.sapOrderDefs.push({
                  headerName: key, field: key,    hide: true,
                });
              } else if (key === "LOGS") {
                this.sapOrderDefs.push({
                  headerName: 'LOGS', field: 'logs',  resizable: true,
                  cellRenderer(params) {
                    return ' <span title="View Logs" ><i class="fas fa-eye view" data-action-type="view"></i></span>';
                  },
                });
              } else {
                this.sapOrderDefs.push({
                  headerName: key, field: key,  resizable: true,
                });
              }
            }
          }
          this.context = {componentParent: this};
          this.frameworkComponents = {
            approveClickRenderer: ApproveClickRenderer,
            convertClickRenderer: ConvertCellRenderer,
            headerApproveAllcomponent: HeaderClickComponent,
            convertcomponent: ConvertComponent,
            convertpopupcomponent: ConvertPopUpComponent,
            poEditRenderer: PoEditRenderer,
            damageEditRenderer: DamageEditRenderer,
            reuseRenderer: ReuseRenderer,
            replaceRenderer: ReplaceRenderer,
            grnEditRenderer: GrnEditRenderer,
            imageClickRenderer: ImageClickRenderer,
            invoiceEditRenderer: InvoiceEditRenderer,
            regenerateRenderer: RegenerateComponent,
            regenerateHeadercomponent: RegenerateHeaderComponent
          };

          this.order1_loaded = true;
          this.order2_loaded = true;
          this.order3_loaded = true;
          this.replace_loaded = true;
          this.dispatch_loaded = true;
          this.return_loaded = true;
          this.mismatch_loaded = true;
          this.logs_loaded = true;
          this.sample_loaded = true;
          this.unapp_loaded = true;
          this.pEinvoice_loaded = true;
          this.pEpod_loaded = true;
          this.sapOrder_loaded = true;

          if (this.today_count === 0) {
            this.empty_state2 = true;
            this.order1_loaded = false;
            this.preloader2 = false;
          } else {
            this.today_enable = true;
          }
          if (this.prvs_count === 0) {
            this.empty_state3 = true;
            this.order2_loaded = false;
            this.preloader3 = false;
          } else {
            this.prvs_enable = true;
          }
          if (this.cash_count === 0) {
            this.empty_state4 = true;
            this.order3_loaded = false;
            this.preloader4 = false;
          } else {
            this.cash_enable = true;
          }
          if (this.replace_count === 0) {
            this.empty_state_replace = true;
            this.replace_loaded = false;
            this.replace_preloader = false;
          } else {
            this.replace_enable = true;
          }
          if (this.dispatch_count === 0) {
            this.empty_state_dispatch = true;
            this.dispatch_loaded = false;
            this.dispatch_preloader = false;
          } else {
            this.dispatch_enable = true;
          }
          if (this.return_count === 0) {
            this.empty_state_return = true;
            this.return_loaded = false;
            this.return_preloader = false;
          } else {
            this.return_enable = true;
          }
          if (this.mismatch_count === 0) {
            this.empty_state_mismatch = true;
            this.mismatch_loaded = false;
            this.mismatch_preloader = false;
          } else {
            this.payment_mismatch = true;
            this.mismatch_enable = true;
          }
          if (this.logs_count === 0) {
            this.empty_state_logs = true;
            this.logs_loaded = false;
            this.logs_preloader = false;
          } else {
            this.payment_logs = true;
            this.logs_enable = true;
          }
          if (this.sample_count === 0) {
            this.empty_state_sample = true;
            this.sample_loaded = false;
            this.sample_preloader = false;
          } else {
            this.sample_enable = true;
          }
          if (this.unapp_count === 0) {
            this.unapp_empty_state = true;
            this.unapp_loaded = false;
            this.unapp_preloader = false;
          } else {
            this.unapp_enable = true;
          }
          if (this.pending_einvoice_count === 0) {
            this.peinvoice_empty_state = true;
            this.pEinvoice_loaded = false;
            this.pEinvoice_preloader = false;
          } else {
            this.pending_einvoice_enable = true;
          }
          if (this.pending_pod_count === 0) {
            this.pepod_empty_state = true;
            this.pEpod_loaded = false;
            this.pEpod_preloader = false;
          } else {
            this.pending_pod_enable = true;
          }
          if (this.pending_saporder_count === 0) {
            this.sapOrder_empty_state = true;
            this.sapOrder_loaded = false;
            this.sapOrder_preloader = false;
          } else {
            this.pending_saporder_enable = true;
          }
        } else {
          this.preloader2 = false;
          this.preloader3 = false;
          this.preloader4 = false;
          this.replace_preloader = false;
          this.dispatch_preloader = false;
          this.return_preloader = false;
          this.mismatch_preloader = false;
          this.logs_preloader = false;
          this.sample_preloader = false;
          this.unapp_preloader = false;
          this.pEinvoice_preloader = false;
          this.pEpod_preloader = false;
          this.sapOrder_preloader = false;
          this.empty_state2 = true;
          this.empty_state3 = true;
          this.empty_state4 = true;
          this.empty_state_replace = true;
          this.empty_state_dispatch = true;
          this.empty_state_return = true;
          this.empty_state_mismatch = true;
          this.empty_state_logs = true;
          this.empty_state_sample = true;
          this.unapp_empty_state = true;
          this.peinvoice_empty_state = true;
          this.pepod_empty_state = true;
          this.sapOrder_empty_state = true;
        }
      },
      error => console.log(error));
    // Settlement details
    let expenseData;
    this.settled = false;
    this.res_data = this.apiService.getSettlement(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        this.settlementSummaryLoaded = true;
        if (this.stock_and_sales1Loaded && this.stock_and_sales2Loaded && this.approvalOrdersLoaded) {
          this.appComponent.pageReady = true;
        }
        const oldPaymentObj = [];
        const paymentObj = [];
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          const data = res['results'].data[0];
          let total = 0;
          if (data.cash_sales !== null) {
            total = data.cash_sales - data.expense;
          }
          if (data.hasOwnProperty('config_json') && data.config_json) {
            if (data.config_json.constructor !== Object) {
              data.config_json = JSON.parse(data.config_json);
            }
            for (let [key, value] of Object.entries(data.config_json)) {
              if (parseFloat(value.toString()) > 0.0) {
                key = key.replace(/(?:_| |\b)(\w)/g, function($1){return $1.toUpperCase().replace('_',' ');});
                if (key.toString().toLowerCase().includes('old')) {
                  oldPaymentObj.push({name: key, value: parseFloat(value.toString())});
                } else {
                  paymentObj.push({name: key, value: parseFloat(value.toString())});
                }
              }
            }
          }
          expenseData = {
            expense: data.expense,
            credit: data.credit_sales.toFixed(this.apiService.decimalPolicy),
            cash: data.cash_sales.toFixed(this.apiService.decimalPolicy),
            unapproved_sale: data.unapproved_sale.toFixed(this.apiService.decimalPolicy),
            loadout_value: parseFloat(data.loadout).toFixed(this.apiService.decimalPolicy),
            opening_value: parseFloat(data.opening_value).toFixed(this.apiService.decimalPolicy),
            todays_payment: (parseFloat(data.pay_cash) + parseFloat(data.pay_cheque)).toFixed(this.apiService.decimalPolicy),
            total_out: parseFloat(data.total_out).toFixed(this.apiService.decimalPolicy),
            credits: parseFloat(data.credit_used).toFixed(this.apiService.decimalPolicy),
            pay_cash: data.pay_cash.toFixed(this.apiService.decimalPolicy),
            pay_cheque: data.pay_cheque.toFixed(this.apiService.decimalPolicy),
            old_payment_cash:  parseFloat(data.old_payment_cash).toFixed(this.apiService.decimalPolicy),
            old_payment_cheque: parseFloat(data.old_payment_cheque).toFixed(this.apiService.decimalPolicy),
            oldPaymentObj,
            paymentObj
          };
          expenseData['old_payment'] = 0;
          expenseData['total'] = expenseData['todays_payment'];
          if (this.showOld) {
            //expenseData['old_payment'] = (parseFloat(data.total_pay.toFixed(this.apiService.decimalPolicy)) - parseFloat(expenseData['todays_payment'])).toFixed(this.apiService.decimalPolicy);
            /*     expenseData['old_payment_cash'] = parseFloat(data.old_payment_cash).toFixed(this.apiService.decimalPolicy);
                 expenseData['old_payment_cheque'] = parseFloat(data.old_payment_cheque).toFixed(this.apiService.decimalPolicy),*/
            expenseData['total'] = data.total_pay.toFixed(this.apiService.decimalPolicy);
          }
          expenseData['all_total'] = (parseFloat(data.credit_sales) + parseFloat(data.cash_sales)).toFixed(this.apiService.decimalPolicy);
          this.eod_status = data.state;
          if (data.nxt_vansale === 'draft' || data.nxt_vansale === 'not_started') {
            this.nxt_eod_status = true;
          }
          if (this.eod_status === null) {
            this.eod_status = 'Not Started';
          }
          if (data.state === 'settled') {
            this.settled = true;
            this.comments = data.apr_comments;
          }
        } else {
          expenseData = {
            expense: 0,
            credit: 0,
            cash: 0,
            unapproved_sale: 0,
            loadout_value: 0,
            opening_value: 0,
            todays_payment: 0,
            total_out: 0,
            credits: 0,
            old_payment: 0,
            total: 0,
            all_total: 0,
            pay_cash: 0,
            pay_cheque: 0,
            old_payment_cash: 0,
            old_payment_cheque: 0,
            oldPaymentObj: [],
            paymentObj: []
          };
        }
        this.settlement = expenseData;
        if (!this.showOld && this.settlement.cash !== null && this.settlement.pay_cash !== null && this.settlement.cash !== this.settlement.pay_cash) {
          this.collection_mismatch = true;
        }
      },
      error => console.log(error));
    this.gridOptions = {
      rowHeight: 50,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      isRowSelectable(rowNode) {
        return ((rowNode.data['APPROVE'] === 'Approve'));
      },
      getRowStyle(params) {
        if (params.data['APPROVE'] === 'Cancelled') {
          return {'background-color': 'lightGrey'};
        }
      }
    } as GridOptions;

    this.gridOptionsConvert = {
      rowHeight: 32,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left'},
      getRowNode(data) {
        return data.field;
      },
    } as GridOptions;
    this.gridOptionsReplace = {
      rowHeight: 50,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      isRowSelectable(rowNode) {
        return ((rowNode.data['APPROVE'] === 'Approve'));
      },
      getRowStyle(params) {
        if (params.data['APPROVE'] === 'Cancelled') {
          return {'background-color': 'lightGrey'};
        }
      }
    } as GridOptions;

    this.gridOptionsDispatch = {
      rowHeight: 50,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      isRowSelectable(rowNode) {
        return ((rowNode.data['APPROVE'] === 'Approve'));
      },
      getRowStyle(params) {
        if (params.data['APPROVE'] === 'Cancelled') {
          return {'background-color': 'lightGrey'};
        }
      }
    } as GridOptions;

    this.gridOptionsReturn = {
      rowHeight: 50,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      isRowSelectable(rowNode) {
        return ((rowNode.data['APPROVE'] === 'Approve'));
      },
      getRowStyle(params) {
        if (params.data['APPROVE'] === 'Cancelled') {
          return {'background-color': 'lightGrey'};
        }
      }
    } as GridOptions;

    this.gridOptionsMismatch = {
      rowHeight: 50,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      lockPosition: true
    } as GridOptions;

    this.gridOptionsLog = {
      rowHeight: 50,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      lockPosition: true
    } as GridOptions;

    this.gridOptionsSample = {
      rowHeight: 50,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      isRowSelectable(rowNode) {
        return ((rowNode.data['APPROVE'] === 'Approve'));
      },
      getRowStyle(params) {
        if (params.data['APPROVE'] === 'Cancelled') {
          return {'background-color': 'lightGrey'};
        }
      }
    } as GridOptions;


    this.gridOptionsPrev = {
      rowHeight: 50,
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowSelection: 'multiple',
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      isRowSelectable(rowNode) {
        return ((rowNode.data['APPROVE'] === 'Approve'));
      }
    } as GridOptions;

    const eod_status = this.eod_status;
    this.gridOptions3 = {
      rowHeight: 50,
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowSelection: 'multiple',
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      isRowSelectable(rowNode) {
        return ((rowNode.data['APPROVE'] === 'Approve'));
      },
      getRowStyle(params) {
        if (params.data['APPROVE'] === 'Cancelled') {
          return {'background-color': 'lightGrey'};
        }
      }
    } as GridOptions;
    this.gridOptionsUnapp = {
      rowHeight: 50,
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowSelection: 'multiple',
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      isRowSelectable(rowNode) {
        return ((rowNode.data['APPROVE'] === 'Approve'));
      }
    } as GridOptions;

    this.gridOptionsList = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressHorizontalScroll: false,
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
    } as GridOptions;

    this.assetOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressHorizontalScroll: false,
    } as GridOptions;

    if (this.partial_approve) {
      this.stockOptions = {
        rowHeight: 40,
        headerHeight: 60,
        rowSelection: 'multiple',
        suppressRowClickSelection: false,
        rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
        isExternalFilterPresent: () => true,
        // return true if label=unit_price
        doesExternalFilterPass: rowNode => rowNode.data["LABEL"] !== 'lst_price'
          && rowNode.data["LABEL"] !== 'rej_reason' && rowNode.data["LABEL"] !== 'incentive'
          && rowNode.data["LABEL"] !== 'ret_incentive'
      } as GridOptions;
    } else {
      this.stockOptions = {
        rowHeight: 40,
        headerHeight: 60,
        rowSelection: 'multiple',
        suppressRowClickSelection: false,
        rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left'},
        isExternalFilterPresent: () => true,
        // return true if label=unit_price
        doesExternalFilterPass: rowNode => rowNode.data["LABEL"] !== 'lst_price'
          && (rowNode.data["LABEL"] !== 'Bonus') && rowNode.data["LABEL"] !== 'Penalty'
          && rowNode.data["LABEL"] !== 'Rejected' && rowNode.data["LABEL"] !== 'rej_reason'
          && rowNode.data["LABEL"] !== 'incentive' && rowNode.data["LABEL"] !== 'ret_incentive'
          && rowNode.data["LABEL"] !== 'vanstock_closing' && rowNode.data["LABEL"] !== 'live_closing'
          && rowNode.data["LABEL"] !== 'reusable'
      } as GridOptions;
    }

    this.stockOptions.getRowHeight = params => {
      const row_ht = 43;
      if (params.data['LABEL'] === 'vanstock_closing') {
        return 0;
      }
      if (params.data['LABEL'] === 'live_closing') {
        return 0;
      }
      if (params.data['LABEL'] === 'reusable') {
        return 0;
      }
      if (params.data['LABEL'] === 'calc_closing') {
        return 0;
      }

    };

    this.stockOptions.getRowStyle = params => {
      if (params.data['LABEL'] === 'Damage Edit' || params.data['LABEL'] === 'Cancellation' || params.data['LABEL'] === 'Deviation') {
        return {'background-color': 'lightGrey'};
      }
    };

    this.gridOptionsPEinvoice = {
      rowHeight: 50,
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowSelection: 'multiple',
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      isRowSelectable(rowNode) {
        return ((rowNode.data['APPROVE'] === 'Approve'));
      }
    } as GridOptions;

    this.gridOptionsPEpod = {
      rowHeight: 50,
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      rowSelection: 'multiple',
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      isRowSelectable(rowNode) {
        return ((rowNode.data['APPROVE'] === 'Approve'));
      },
      getRowStyle(params) {
        if (params?.data?.POD?.image && params?.data?.POD?.type  === 'qr_validated' && params?.data?.config_json?.pod_reject_comments) {
          return {background: '#F8D1C8'};
        }
      }
    } as GridOptions;

    this.gridOptionsSapOrder = {
      rowHeight: 50,
      suppressHorizontalScroll: true,
      rowSelection: 'multiple',
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
      lockPosition: true
    } as GridOptions;

    this.gridOptionsOrderLogs = {
      suppressHorizontalScroll: true,
      rowSelection: 'multiple',
      rowStyle: {'border-bottom': '#f3f3f3 10px solid', 'text-align': 'left', padding: '20px 0px'},
    } as GridOptions;

    this.gridOptionsOrderLogs.getRowHeight = function(params) {
      return 20 * (Math.floor(params.data.body.length / 45) + 1);
    };
  }

  podCheck(outlet_type, payment_mode) {
    if (this.pod_config) {
      let pod_mode;
      if (this.pod_config[outlet_type]) {
        pod_mode = this.pod_config[outlet_type];
      } else if (this.pod_config['default']) {
        pod_mode = this.pod_config['default'];
      } else {
        return false;
      }
      if (pod_mode.payment_mode) {
        const payment_modes_list = pod_mode.payment_mode.split(',');
        if (payment_modes_list.includes(payment_mode)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  quickSearch() {
    if (this.gridApi1 !== undefined && this.gridOptions.api !== null) {
      this.gridApi1.setQuickFilter(this.searchValue);
      this.today_count = this.gridOptions.api.getModel().getRowCount();
    }
    if (this.gridApi4 !== undefined && this.gridOptions3.api !== null) {
      this.gridApi4.setQuickFilter(this.searchValue);
      this.cash_count = this.gridOptions3.api.getModel().getRowCount();
    }
    if (this.gridApi2 !== undefined && this.gridOptionsPrev.api !== null) {
      this.gridApi2.setQuickFilter(this.searchValue);
      this.prvs_count = this.gridOptionsPrev.api.getModel().getRowCount();
    }
    if (this.gridApi_replace !== undefined && this.gridOptionsReplace.api !== null) {
      this.gridApi_replace.setQuickFilter(this.searchValue);
      this.replace_count = this.gridOptionsReplace.api.getModel().getRowCount();
    }
    if (this.gridApi_dispatch !== undefined && this.gridOptionsDispatch.api !== null) {
      this.gridApi_dispatch.setQuickFilter(this.searchValue);
      this.dispatch_count = this.gridOptionsDispatch.api.getModel().getRowCount();
    }
    if (this.gridApi_return !== undefined && this.gridOptionsReturn.api !== null) {
      this.gridApi_return.setQuickFilter(this.searchValue);
      this.return_count = this.gridOptionsReturn.api.getModel().getRowCount();
    }
    if (this.gridApi_mismatch !== undefined && this.gridOptionsMismatch.api !== null) {
      this.gridApi_mismatch.setQuickFilter(this.searchValue);
      this.mismatch_count = this.gridOptionsMismatch.api.getModel().getRowCount();
    }
    if (this.gridApi_log !== undefined && this.gridOptionsLog.api !== null) {
      this.gridApi_log.setQuickFilter(this.searchValue);
      this.logs_count = this.gridOptionsLog.api.getModel().getRowCount();
    }
    if (this.gridApi_sample !== undefined && this.gridOptionsSample.api !== null) {
      this.gridApi_sample.setQuickFilter(this.searchValue);
      this.sample_count = this.gridOptionsSample.api.getModel().getRowCount();
    }
    if (this.gridApi_unapp !== undefined && this.gridOptionsUnapp.api !== null) {
      this.gridApi_unapp.setQuickFilter(this.searchValue);
      this.unapp_count = this.gridOptionsUnapp.api.getModel().getRowCount();
    }
    if (this.gridApi_peinvoice !== undefined && this.gridOptionsPEinvoice.api !== null) {
      this.gridApi_peinvoice.setQuickFilter(this.searchValue);
      this.pending_einvoice_count = this.gridOptionsPEinvoice.api.getModel().getRowCount();
    }
    if (this.gridApi_pepod !== undefined && this.gridOptionsPEpod.api !== null) {
      this.gridApi_pepod.setQuickFilter(this.searchValue);
      this.pending_pod_count = this.gridOptionsPEpod.api.getModel().getRowCount();
    }

    if (this.gridApi_saporder !== undefined && this.gridOptionsSapOrder.api !== null) {
      this.gridApi_saporder.setQuickFilter(this.searchValue);
      this.pending_saporder_count = this.gridOptionsSapOrder.api.getModel().getRowCount();
    }
  }

  onGridReadyList(params) {
    this.gridOptionsList.api.showLoadingOverlay();
    this.gridApiList = params.api;
    this.gridColumnApiList = params.columnApi;
    params.api.setRowData(this.listData);
    this.list_preloader = false;
    if (screen.width > 991) {
      this.gridOptionsList.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiList.autoSizeColumns();
    }
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApiList) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi1 = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader2 = false;
    this.tabToNextCell = this.tabToNextCell.bind(this);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi1) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
  }

  onGridReadyReplace(params) {
    this.gridOptionsReplace.api.showLoadingOverlay();
    this.gridApi_replace = params.api;
    this.gridColumnApiReplace = params.columnApi;
    params.api.setRowData(this.rowReplace);
    this.replace_preloader = false;
    this.tabToNextCell = this.tabToNextCell.bind(this);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi_replace) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiReplace.autoSizeColumns();
    }
  }

  onGridReadyDispatch(params) {
    this.gridOptionsDispatch.api.showLoadingOverlay();
    this.gridApi_dispatch = params.api;
    this.gridColumnApiDispatch = params.columnApi;
    params.api.setRowData(this.rowDispatch);
    this.dispatch_preloader = false;
    this.tabToNextCell = this.tabToNextCell.bind(this);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi_dispatch) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiDispatch.autoSizeColumns();
    }
  }

  onGridReadyReturn(params) {
    this.gridOptionsReturn.api.showLoadingOverlay();
    this.gridApi_return = params.api;
    this.gridColumnApiReturn = params.columnApi;
    params.api.setRowData(this.rowReturn);
    this.return_preloader = false;
    this.tabToNextCell = this.tabToNextCell.bind(this);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi_return) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiReturn.autoSizeColumns();
    }
  }

  onGridReadyMismatch(params) {
    this.gridOptionsMismatch.api.showLoadingOverlay();
    this.gridApi_mismatch = params.api;
    this.gridColumnApiMismatch = params.columnApi;
    params.api.setRowData(this.rowMismatch);
    this.mismatch_preloader = false;
    this.tabToNextCell = this.tabToNextCell.bind(this);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi_mismatch) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiMismatch.autoSizeColumns();
    }
  }

  onGridReadyLogs(params) {
    this.gridOptionsLog.api.showLoadingOverlay();
    this.gridApi_log = params.api;
    this.gridColumnApiLog = params.columnApi;
    params.api.setRowData(this.rowLogs);
    this.logs_preloader = false;
    this.tabToNextCell = this.tabToNextCell.bind(this);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi_log) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiLog.autoSizeColumns();
    }
  }

  onGridReadySample(params) {
    this.gridOptionsSample.api.showLoadingOverlay();
    this.gridApi_sample = params.api;
    this.gridColumnApiSample = params.columnApi;
    params.api.setRowData(this.rowSample);
    this.sample_preloader = false;
    this.tabToNextCell = this.tabToNextCell.bind(this);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi_sample) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiSample.autoSizeColumns();
    }
  }

  onGridReadyPrev(params) {
    this.gridOptionsPrev.api.showLoadingOverlay();
    this.gridApi2 = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowDataPrev);
    this.preloader3 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi2) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
  }

  onGridReadyUnapp(params) {
    this.gridOptionsUnapp.api.showLoadingOverlay();
    this.gridApi_unapp = params.api;
    this.gridColumnApiUnapp = params.columnApi;
    params.api.setRowData(this.rowUnapp);
    this.unapp_preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi_unapp) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiUnapp.autoSizeColumns();
    }
  }

  onGridReady3(params) {
    this.gridOptions3.api.showLoadingOverlay();
    this.gridApi4 = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData3);
    this.preloader4 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi4) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
  }

  onassetGridReady(params) {
    this.assetOptions.api.showLoadingOverlay();
    this.assetgridApi = params.api;
    this.assetgridColumnApi = params.columnApi;
    params.api.setRowData(this.assetData);
    this.assetpreloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.assetgridApi) {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    this.gridColumnApiList.autoSizeColumns();
  }
  onstockGridReady(params) {
    this.stockOptions.api.showLoadingOverlay();
    this.gridApi3 = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.stockData);
    const prods = Object.keys(this.stockData[0]).length;
    this.preloader1 = false;
    this.gridApi3.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if ( this.gridApi3) {
          if (screen.width > 991) {
            if (prods < 22) {
              params.api.sizeColumnsToFit();
            }
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      if (prods < 21) {
        params.api.sizeColumnsToFit();
      }
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
  }

  onGridReadyPEinvoice(params) {
    this.gridOptionsPEinvoice.api.showLoadingOverlay();
    this.gridApi_peinvoice = params.api;
    this.gridColumnApiPEinvoice = params.columnApi;
    params.api.setRowData(this.rowPEinvoice);
    this.pEinvoice_preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi_peinvoice) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiPEinvoice.autoSizeColumns();
    }
  }

  onGridReadyPEpod(params) {
    this.gridOptionsPEpod.api.showLoadingOverlay();
    this.gridApi_pepod = params.api;
    this.gridColumnApiPEpod = params.columnApi;
    params.api.setRowData(this.rowPEpod);
    this.pEpod_preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi_pepod) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiPEpod.autoSizeColumns();
    }
  }

  onGridReadySapOrder(params) {
    this.gridOptionsSapOrder.api.showLoadingOverlay();
    this.gridApi_saporder = params.api;
    this.gridColumnApiSapOrder = params.columnApi;
    params.api.setRowData(this.rowSapOrder);
    this.sapOrder_preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi_saporder) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiSapOrder.autoSizeColumns();
    }
  }

  onGridReadyOrderLogs(params) {
    this.gridOptionsOrderLogs.api.showLoadingOverlay();
    this.gridApi_orderlogs = params.api;
    this.gridColumnApiOrderLogs = params.columnApi;
    params.api.setRowData(this.rowOrderLogs);
    this.sapOrder_preloader = false;
    this.gridApi_orderlogs.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi_orderlogs) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiOrderLogs.autoSizeColumns();
    }
  }

  onGridConvertReady(params) {
    this.gridApi_convert = params.api;
    this.gridColumnApiConvert = params.columnApi;
    params.api.setRowData(this.convertData);
    this.tabToNextCell = this.tabToNextCell.bind(this);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi_convert) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiConvert.autoSizeColumns();
    }
  }

  tabToNextCell() {

  }

  // Individual approve click
  approveClick(params, nodes) {

    const approveData = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      data: {
        order_id: params['ID'],
        grn: params['GRN'],
        po: params['PO'],
        invoice_no: params['INVOICE NO']
      }
    };
    let result = false;
    const gridApi = nodes[0].beans.gridApi;
    this.res_data = this.apiService.markApprove(approveData);
    this.res_data.subscribe(res => {
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          result = true;
          window.location.reload();
          let instances = gridApi.getCellRendererInstances({columns: ['PO'], rowNodes: nodes});
          instances.forEach(instance => {
            instance.disable = true;
          });
          instances = gridApi.getCellRendererInstances({columns: ['GRN'], rowNodes: nodes});
          instances.forEach(instance => {
            instance.disable = true;
          });
          instances = gridApi.getCellRendererInstances({columns: ['INVOICE NO'], rowNodes: nodes});
          instances.forEach(instance => {
            instance.disable = true;
          });
        } else {
          result = false;
        }
      },
      error => console.log(error)
    );

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve([result]);
      }, 2000);
    });
  }


  convert(params, nodes) {
    this.convert_log = ["", 0];
    const el: HTMLElement = this.convertClick.nativeElement;
    el.click();
    if (nodes.length > 0) {
      const order_ids = [];

      params.forEach(data => {
        order_ids.push(data['ID']);
      });
      this.convert_loaded = false;
      this.convertDefs = [];
      const convert_data = [];
      this.user_data.order_ids = order_ids;
      this.res_data = this.apiService.getConvertProduct(this.user_data);
      this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].statusCode === 200)) {
            res['results'].data.forEach(data => {
              const pushData = {
                Product: data.product,
                QTY: data.damage,
                "Damaged Qty": data.damage,
                "Replace Sku": 'same',
                "Reusable Qty": 0,
                "Convert All": '',
                product_id: data.product_id,
                replace_sku_id: data.replace_sku_id,
                replace_name: data.replace_sku,
                dc_id: data.dc_id,
                territory_id: res['results'].territory_id
              };
              convert_data.push(pushData);
            });
            this.data = convert_data[0];
            this.convertData = convert_data;
            const forDropDown = this.itemList.map(x => x.Name);
            for (const key in this.data) {
              if (this.data.hasOwnProperty(key)) {
                if (key === "Product") {
                  this.convertDefs.push({
                    headerName: key, field: key,    width: 420,
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true,
                    cellRenderer(params1) {
                      return '<span>' + params1.value + '</span>';
                    }
                  });
                } else if (key === 'Damaged Qty') {
                  this.convertDefs.push({
                    headerName: key, field: key,
                    cellRenderer: "damageEditRenderer",
                    pinnedRowCellRenderer(params1) {
                      return params1.value;
                    }
                  });
                } else if (key === 'Reusable Qty') {
                  this.convertDefs.push({
                    headerName: key, field: key,
                    resizable: true,
                    cellRenderer: "reuseRenderer",
                    pinnedRowCellRenderer(params1) {
                      return params1.value;
                    }
                  });
                } else if (key === "Convert All") {
                  this.convertDefs.push({
                    headerName: key, field: key,
                    headerComponent: "convertpopupcomponent"
                  });
                } else if (key === "Replace Sku") {

                  this.convertDefs.push({
                    headerName: key,
                    field: key,
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    width: 380,
                    cellEditorParams: {
                      values: forDropDown
                    },
                    cellRenderer(params1) {
                      if (params1.value === 'same') {
                        return params1.data['Product'];
                      }
                      if (params1.value === 'other') {
                        return params1.data.replace_name;
                      }
                    }
                  });
                } else if (key === 'product_id' || key === 'territory_id' || key === 'dc_id' || key === 'replace_sku_id' ||
                  key === 'replace_name') {
                  this.convertDefs.push({
                    headerName: key, field: key,    hide: true
                  });
                } else {
                  this.convertDefs.push({
                    headerName: key, field: key,
                  });
                }
              }
            }
            this.convert_loaded = true;
          }
        },
        error => {
          console.log(error);
        });
    }
  }

  changeReuseValue(new_value, node) {
    const gridApi = node.beans.gridApi;
    const instances = gridApi.getCellRendererInstances({columns: ['Reusable Qty'], rowNodes: [node]});
    instances.forEach(instance => {
      instance.reuseValue = new_value;

    });
  }

  changeDamageValue(new_value, node) {
    const gridApi = node.beans.gridApi;
    const instances = gridApi.getCellRendererInstances({columns: ['Damaged Qty'], rowNodes: [node]});
    instances.forEach(instance => {
      instance.damageValue = new_value;

    });
  }

  convertAll(params, nodes) {
    this.elRef.nativeElement.querySelector('#overlay1').style.display = "block";
    let success_flag = true;
    if (params.length > 0) {
      const user_id = this.user_data.salesman_id;
      const converted_products = [];
      params.forEach(product => {
        if ((parseInt(product['Damaged Qty'], 10) < 0) || (parseInt(product['Reusable Qty'], 10) < 0)) {
          success_flag = false;
        } else {
          converted_products.push({
            product_id: product.product_id,
            replace_sku: product.replace_sku_id,
            dc_id: product.dc_id,
            territory_id: product.territory_id,
            damage_qty: product['Damaged Qty'],
            reusable_qty: product['Reusable Qty'],
            convert_flag: product['Replace Sku'],
            comment: "converted to " + product.replace_name
          });
        }
      });
      if (success_flag) {
        const convertData = {
          access_token: this.user_data.access_token,
          url: this.user_data.url,
          prod_data: converted_products,
          date: this.user_data.date,
          salesman_id: this.user_data.salesman_id
        };
        this.res_data = this.apiService.convertAll(convertData);
        this.res_data.subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
              this.convert_log = [res['results'].msg, 0];
              this.loadPage();
            } else {
              this.convert_log = ["Convert Failed", 1];
            }
            this.elRef.nativeElement.querySelector('#overlay1').style.display = "none";
          },
          error => {
            console.log(error);
            this.convert_log = ["Convert Failed", 1];
            this.elRef.nativeElement.querySelector('#overlay1').style.display = "none";
          });
      } else {
        this.convert_log = ["Adjusted Qty is wrong", 1];
        this.elRef.nativeElement.querySelector('#overlay1').style.display = "none";
      }
    } else {
      this.convert_log = ["Select Atleast One Product", 1];
      this.elRef.nativeElement.querySelector('#overlay1').style.display = "none";
    }
  }

  openPartialApprovePopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "50%",
        maxHeight: "95%",
        panelClass: 'custom-dialog-container',
        data: {
          stockData: this.stockData,
          productData: this.all_products,
          vansale_id: this.user_data.vansale_id
        }
      }
      const creatediaeref = this.dialog.open(ReturnApprovePopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        if (creatediaeref.componentInstance.refreshDlg) {
          this.loadPage();
        }
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "95%%",
        data: {
          stockData: this.stockData,
          productData: this.all_products,
          vansale_id: this.user_data.vansale_id
        }
      };
      const creatediaeref = this.dialog.open(ReturnApprovePopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        if (creatediaeref.componentInstance.refreshDlg) {
          this.loadPage();
        }
      });
    }

  }

  // Edit invoiceno,po and grn
  editOrder(params, nodes) {
    const el: HTMLElement = this.editDiv.nativeElement;
    el.click();
    this.updateText = '';
    this.updatedParams = params;
    this.updatedNode = nodes;
    this.editIN = params['INVOICE NO'];
    this.editPO = params['PO'];
    this.editOrderID = params['ID'];
    this.editType = params['payment_mode'];
    this.editOrdMode = params['order_type'];
    this.editComments = params['COMMENTS'];
    if (this.editOrdMode === 'return') {
      this.editGRN = params['GRV'];
    } else {
      this.editGRN = params['GRN'];
    }
    if (params.date_order !== params.write_date) {
      this.updateText = `This Invoice got updated on '` + this.datePipe.transform((new Date(params.write_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss') + `' and updated by ` + params.write_uid;
    }
  }

  // Swap invoice comment and no
  updateInvoiceComment(inv, comm, order_id) {
    if (comm === 'null' || comm === null) {
      window.alert('Invoice Cant Be Null');
    } else {
      const real_in = comm.split(' ');
      if (real_in.length > 1) {
        real_in.forEach(val => {
          if (val !== '') {
            comm = val;
          }
        });
      }
      const updateData = {
        access_token: this.user_data.access_token,
        url: this.user_data.url,
        data: {
          order_id,
          comments: comm,
          invoice: inv
        }
      };
      const params = this.updatedParams;
      const rowNode = this.updatedNode;
      this.res_data = this.apiService.updateInvoice(updateData);
      this.res_data.subscribe(res => {
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.editIN = comm;
          this.editComments = inv;
          params['INVOICE NO'] = comm;
          params['COMMENTS'] = inv;
          params.write_date = res['results'].data[0];
          params.write_uid = res['results'].data[1];
          this.updateText = `This Invoice got updated on '` + this.datePipe.transform((new Date(params.write_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss') + `' and updated by ` + params.write_uid;
          rowNode.setData(params);
        } else if (res['results'].status === 203 && res['results'].msg === 'invoice already exist') {
          const msg = 'Invoice Already exist For order - ' + res['results'].exist_order;
          window.alert(msg);
        }
      }, error => {
        console.log(error);
      });
    }
  }
  // Updating inv,po,grn
  updateOrder(inv, po, grn, comm) {
    const real_in = inv.split(' ');
    if (real_in.length > 1) {
      real_in.forEach(val => {
        if (val !== '') {
          inv = val;
        }
      });
    }
    this.editIN = inv;
    const approveData = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      data: {
        order_id: this.editOrderID,
        grn: this.editGRN,
        po: this.editPO,
        invoice_no: this.editIN,
        note: this.editComments,
        order_type: this.editOrdMode
      }
    };
    const params = this.updatedParams;
    const rowNode = this.updatedNode;
    this.res_data = this.apiService.markApprove(approveData);
    this.res_data.subscribe(res => {
      if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
        params['INVOICE NO'] = inv;
        params['PO'] = po;
        if (params.hasOwnProperty('GRN')) {
          params['GRN'] = grn;
        } else {
          params['GRV'] = grn;
        }
        params['COMMENTS'] = comm;
        params.write_date = res['results'].data[0];
        params.write_uid = res['results'].data[1];

        rowNode.setData(params);
      } else if (res['results'].status === 203 && res['results'].msg === 'invoice already exist') {
        const msg = 'Invoice Already exist For order - ' + res['results'].exist_order;
        window.alert(msg);
      }
    }, error => {
      console.log(error);
    });
  }

  // Approve bulk orders
  approveAll(params, nodes) {
    if (this.mark_approve && nodes.length > 0) {
      const gridApi = nodes[0].beans.gridApi;
      const instances = gridApi.getCellRendererInstances({columns: ['APPROVE'], rowNodes: nodes});
      instances.forEach(instance => {
        if (instance.cancel === false && instance.approved_after === false) {
          instance.loader = true;
          instance.approv = false;
        }
      });
      if (params.length !== 0) {
        const approveData = {
          access_token: this.user_data.access_token,
          url: this.user_data.url,
          data: [],
          salesman_id: this.user_data.salesman_id,
          date: this.user_data.date
        };
        params.forEach(data => {
          if (data['APPROVE'] === 'Approve') {
            if (data['order_type'] === 'return') {
              approveData.data.push({
                order_id: data['ID'],
                grn: data['GRV'],
                po: data['PO'],
                invoice_no: data['INVOICE NO'],
                order_type: data['order_type'],
                dc_id: data['dc_id'],
                territory_id: data['territory_id'],
                partner_id: data.partner_id,
                payment_type: data.payment_mode,
                state: 'draft',
                amount: data.sale,
                cid: data.cid,
                cd: 'Cr'
              });
            } else {
              approveData.data.push({
                order_id: data['ID'],
                grn: data['GRN'],
                po: data['PO'],
                invoice_no: data['INVOICE NO'],
                note: data['COMMENTS'],
                order_type: data['order_type']
              });
            }
          }
        });
        this.res_data = this.apiService.approveAll(approveData);
        this.res_data.subscribe(res => {
            let instances;
            console.log(res);
            if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
              instances = gridApi.getCellRendererInstances({columns: ['APPROVE'], rowNodes: nodes});
              instances.forEach(instance => {
                if (instance.cancel === false && instance.approved_after === false) {
                  instance.value = 'Approved';
                  instance.loader = false;
                  instance.approved_after = true;
                }
              });
              instances = gridApi.getCellRendererInstances({columns: ['PO'], rowNodes: nodes});
              instances.forEach(instance => {
                instance.disable = true;
              });
              instances = gridApi.getCellRendererInstances({columns: ['GRN'], rowNodes: nodes});
              instances.forEach(instance => {
                instance.disable = true;
              });
              instances = gridApi.getCellRendererInstances({columns: ['GRV'], rowNodes: nodes});
              instances.forEach(instance => {
                instance.disable = true;
              });
              instances = gridApi.getCellRendererInstances({columns: ['INVOICE NO'], rowNodes: nodes});
              instances.forEach(instance => {
                instance.disable = true;
              });
            } else {
              instances = gridApi.getCellRendererInstances({columns: ['APPROVE'], rowNodes: nodes});
              instances.forEach(instance => {
                if (instance.cancel === false && instance.approved_after === false) {
                  instance.value = 'Approve';
                  instance.loader = false;
                  instance.approv = false;
                }
              });
            }
            if (params.length > 0 && params[0].order_type === 'return') {
              this.return_approve = true;
            }
          },
          error => console.log(error)
        );
      }
    }
  }

  checkEInvoice() {

  }

  verify() {
    //check for any other sync
    this.sapEnable = false;
    if (this.sapSync && this.rowSapOrder !== null && this.rowSapOrder.length > 0) {
      //let countryid = this.rowSapOrder.filter(c => c.country !== null);
      if (this.country_id !== null && this.country_id !== 0) {
        //countryid = countryid[0];
        const integrs = JSON.parse(localStorage.getItem('integrations'));
        const updated = integrs.filter(existed => existed.integration_type === 'sap' && existed.country_id === this.country_id);
        if (updated.length > 0 && updated[0].config_json && this.sapSync) {
          this.sapEnable = true;
          const sapConf = updated[0].config_json;
          this.sapConfig = {
            access_token: localStorage.getItem('resfreshToken'),
            url: sapConf.AuthUrl,
            DB: sapConf.CompanyDB,
            tz: sapConf.tz,
            session: null,
            Password: sapConf.Password,
            UserName: sapConf.UserName,
            get_invoice_url: sapConf.GetInvoice,
            get_credit_note_url: sapConf.GetCreditNote,
            credit_note_url: sapConf.PushCreditNote,
            payurl: sapConf.PushPayment,
            get_payment_url: sapConf.GetPayment,
            push_invoice_url: sapConf.PushInvoice,
            UID: JSON.parse(localStorage.getItem('user')).id,
            CID: JSON.parse(localStorage.getItem('user')).cid,
            order_ids: [],
            payload: {}
          };
          this.sapConfig.payload = {
            CompanyDB: sapConf.CompanyDB,
            Password: sapConf.Password,
            UserName: sapConf.UserName,
          };
        } else {
          //this.sapEnable = false;
          this.toastr.error("Missing SAP Configuration for Country with id : " + this.country_id);
          return;
        }
      } else {
        //this.sapEnable = false;
        this.toastr.error("Country not set");
        return;
      }
    } else {
      this.sapEnable = false;
    }
    if (this.sapEnable && this.sapSync && this.sapConfig !== null) {
      // this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
      this.showProgress = true;
      this.progress = 5;
      const settleData = {
        access_token: this.user_data.access_token,
        url: this.user_data.url,
        data: {
          salesman_id: this.user_data.salesman_id,
          date: this.user_data.date,
          sapConfig: this.sapConfig
        }
      };
      this.apiService.post('/api/pwa_connects/getsapOrderIDS', settleData)
        .subscribe(res => {
          console.log(res);
          this.progress = 5;
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            if (res.results.data && res.results.data.length > 0 && res.results.data[0].order_ids && res.results.data[0].order_ids.length > 0) {
              const orderIDS = res.results.data[0].order_ids;
              const paramObject: any = {};
              paramObject.access_token = this.user_data.access_token;
              paramObject.data = settleData.data.sapConfig;
              this.apiService.post('/api/sap_connects/sapLogin', paramObject)
                .subscribe(resp => {
                  console.log(resp);
                  if (resp.hasOwnProperty('results') && resp.results.body) {
                    const session = JSON.parse(resp.results.body)['SessionId'].toString();
                    this.sapConfig.session = session;
                    this.sapConfig.url = this.sapConfig.push_invoice_url;
                    this.sapSave(orderIDS);
                  } else {
                    // this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
                    this.showProgress = false;
                    this.toastr.error('Authentication Error ');
                  }
                }, error => {
                  //this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
                  this.showProgress = false;
                  this.toastr.error('Authentication Error ', error.error.error.message);
                });
            } else {
              this.showProgress = false;
              this.markSettlement();
            }
          } else {
            this.showProgress = false;
            this.toastr.error('Error fetching SAP Orders');
          }
        });
    } else {
      this.markSettlement();
    }
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async callRegenerate(orders) {
    const batch_count = orders.length / 10;
    let rem_orders;
    let success_count = 0;
    let failure_count = 0;
    do {
      rem_orders = orders.splice(10);
      const order_data = {
        access_token: this.user_data.access_token,
        url: this.user_data.url,
        orders
      };
      const resp = await this.apiService.regenerateIrn(order_data);
      if (resp['results'] && resp['results'].statusCode === 200) {
        let fail_count = 0;
        if (resp['results'].failures) {
          fail_count = resp['results'].failures.length;
          failure_count += fail_count;
        }
        success_count += orders.length - fail_count;
      } else {
        failure_count += orders.length;
      }
      orders = rem_orders;
    } while (rem_orders.length > 0);
    return {success: success_count, failure: failure_count};
  }

  async regenerateAll(rows, nodes) {
    if (rows.length > 0) {
      this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
      const instances = nodes[0].beans.gridApi.getCellRendererInstances({columns: ['Action'], rowNodes: nodes});
      instances.forEach(instance => {
        instance.loader = true;
        instance.enable_regenerate = false;
      });
      const orders = [];
      if (rows.length > 200) {
        window.alert('First 200 records only will get generated');
        rows.splice(200);
      }
      rows.forEach(van_row => {
        orders.push({
          id: van_row['ID'],
          order_type: van_row['TYPE'],
          invoice_no: van_row['INVOICE NO']
        });
      });
      const resp = await this.callRegenerate(orders);
      window.alert("IRN Regeneration Status:- Success - " + resp.success + " Failure - " + resp.failure);
      instances.forEach(instance => {
        instance.loader = false;
        instance.enable_regenerate = true;
      });
      window.location.reload();
    } else {
      window.alert('Select Atleast one record');
    }
    this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
  }

  async regenerate(params, node) {
    const instances = node.beans.gridApi.getCellRendererInstances({columns: ['ACTION'], rowNodes: [node]});
    const order_data = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      orders: [{
        id: params['ID'],
        order_type: params['TYPE'],
        invoice_no: params['INVOICE NO']
      }]
    };

    this.res_data = await this.apiService.regenerateIrn(order_data);
    if (this.res_data.hasOwnProperty('results') && (this.res_data['results'].statusCode === 200)) {
      if (this.res_data['results'].failures.length === 0) {
        window.alert('IRN Regeneration Success');
        //this.einvoiceReport();
        window.location.reload();
      } else {
        window.alert(this.res_data['results'].msg);
      }
      instances.forEach(instance => {
        instance.loader = false;
        instance.enable_regenerate = true;
      });
    } else {
      window.alert('IRN Regeneration Failed');
      instances.forEach(instance => {
        instance.loader = false;
        instance.enable_regenerate = true;
      });
    }
  }

  async sapSave(orderIDS) {
    let sapError = false;
    let batch = [];
    this.progress = 10;
    await this.timeout(500);
    const total_count = orderIDS.length;
    let i = 0;
    const initial = this.progress;
    const diff = 100 - this.progress;
    do {
      batch = orderIDS.splice(10);
      i += 10;
      this.sapConfig.order_ids = orderIDS;
      const paramObject: any = {};
      paramObject.access_token = this.user_data.access_token;
      paramObject.data = this.sapConfig;
      const resp = await this.apiService.sapSaveInvoice(paramObject);
      this.progress = Math.round(initial + ((i / total_count) * diff));
      await this.timeout(500);
      if (resp.hasOwnProperty('results') && resp['results'].length > 0) {
        for (const result of resp['results']) {
          if (result['status'] !== 200) {
            sapError = true;
            console.log(result['msg']);
          } else {
            for (let j = 0; j < this.dataSapOrder.length - 1; j++) {
              if (result.msg.includes(this.dataSapOrder[j].name)) {
                this.dataSapOrder.splice(j, 1);
                break;
              }
            }
          }
        }
      } else {
        sapError = true;
      }
      orderIDS = batch;
    } while (orderIDS.length > 0);
    this.showProgress = false;
    sapError = false;
    if (!sapError) {
      const sapOrderData = {
        access_token: this.user_data.access_token,
        url: this.user_data.url,
        data: {
          salesman_id: this.user_data.salesman_id,
          date: this.user_data.date,
        }
      };
      this.apiService.post('/api/pwa_connects/getsapOrderIDS', sapOrderData)
        .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            if (res.results.data && res.results.data.length > 0 && res.results.data[0].order_ids && res.results.data[0].order_ids.length > 0) {
              this.toastr.error('Error in SAP Sync');
              window.location.reload();
            } else {
              this.markSettlement();
            }
          } else {
            this.showProgress = false;
            this.toastr.error('Error fetching SAP Orders');
          }
        });
    } else {
      this.toastr.error('Error in SAP Sync');
      window.location.reload();
    }
  }
  // Settlement Api
  markSettlement() {
    this.showProgress = false;
    this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
    const settleData = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      data: {
        comments: this.comments,
        amt: this.settlement['total'],
        salesman_id: this.user_data.salesman_id,
        date: this.user_data.date,
        van_id: this.user_data.van_id,
        mark_approve_disbale: this.disableSettleApprove,
        sapConfig: this.sapConfig
      }
    };
    this.res_data = this.apiService.markSettlement(settleData);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.settled = true;
          //this.loadPage();
          window.location.reload();
        } else {
          this.toastr.error('Failed mark settlement');
        }
        this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
      },
      error => {
        console.log(error);
        this.toastr.error('Failed mark settlement');
        this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
      });
    this.comments = "";
  }
  // GoogleMap Initialization
  foo(event) {
    console.log(event);
    const bounds: LatLngBounds = new google.maps.LatLngBounds();
    this.agm_map = event;
    for (const mm of this.activity) {
      bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
    }
    this.agm_map.fitBounds(bounds);
  }

  ngAfterViewInit() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
    this.config.index = parseInt(localStorage.getItem('row_index'), 10);
    this.config.data = JSON.parse(localStorage.getItem('navigation_params'));
    if (this.config.index === 0) {
      this.config.prvs = false;
    }
    if (this.config.index === this.config.data.length - 1) {
      this.config.next = false;
    }
    this.showProgress = false;
    this.progress = 0;
  }

  // Pop generation in stock and sales table
  onStockCellClicked(event) {
    const column = event.column.getColId();
    if (column === 'LABEL' && event.data['LABEL'] === 'Deviation') {
      this.res_data = this.apiService.getDeviationDetails(this.user_data);
      this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].statusCode === 200) && (res['results'].data.length > 0)) {
            this.dev_data = res['results'].data;
            this.triggerFalseClick2();
          } else {
            this.user_data.dev_product = null;
          }
        },
        error => {
          console.log(error);
          this.user_data.dev_product = null;
        });

    } else if (event.data['LABEL'] === 'Deviation') {
      this.user_data.dev_product = parseInt(column.split('_')[1], 10);
      this.res_data = this.apiService.getDeviationDetails(this.user_data);
      this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].statusCode === 200) && (res['results'].data.length > 0)) {
            this.dev_data = res['results'].data;
            this.triggerFalseClick2();
            this.user_data.dev_product = null;
          } else {
            this.user_data.dev_product = null;
          }
        },
        error => {
          console.log(error);
          this.user_data.dev_product = null;
        });
    }
  }

  // Order details Pop up generation
  onCellClicked(event) {
    const column = event.column.getColId();
    if (column === 'logs') {
      const datepipe = this.datePipe;
      this.orderLogsDefs = [
        {
          headerName: 'DATE', field: 'create_date', width: 120, filter: true,  resizable: true,
          valueGetter: params => {
            if (params.data) {
              if (params.data.create_date) {
                return datepipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd H:mm');
              }
            }
          }
        },
        {headerName: 'USER', field: 'user_name', width: 150, filter: true,  resizable: true},
        {headerName: 'COMMENTS', field: 'body', width: 350, filter: true,  resizable: true,  wrapText: true}
      ];
      this.orderLogs_loaded = false;
      this.orderLogs_preloader = true;
      this.orderLogs_empty_state = false;
      const rows = this.gridApi_saporder.getSelectedRows();
      $('#LogsModal').modal('show');
      this.logsData = [];
      const paramObject: any = {};
      paramObject.access_token = this.user_data.access_token;
      paramObject.res_id = rows[0].ID;
      this.apiService.post('/api/mail_messages/GetLogs', paramObject)
        .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.logsData = res.results.data;
            this.rowOrderLogs = res.results.data;
            /*this.rowOrderLogs[0].body =  this.rowOrderLogs[0].body + 'aaaaaaaaaaaaaabbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';*/
            if (this.rowOrderLogs === null || this.rowOrderLogs === undefined) {
              this.orderLogs_empty_state = true;
            } else if (this.rowOrderLogs.length === 0) {
              this.orderLogs_empty_state = true;
            } else {
              this.orderLogs_loaded = true;
            }
            this.orderLogs_preloader = false;
          } else {
            // this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            this.orderLogs_empty_state = true;
            this.toastr.error('Error fetching logs');
          }
        }, error => {
          //this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error('Error fetching logs', error.error.error.message);
          this.orderLogs_preloader = false;
        });

    } else if (column === 'ID' || column === 'TIME' || column === 'DATE' || column === 'RETAILER NAME' || column === 'QTY' || column === 'VALUE' || column === 'TYPE') {
      this.photo = null;
      this.src_url = event.data['PHOTO'].po;
      this.all_orders = [];
      this.selectedTemplate = null;
      const order_data = {
        access_token: this.user_data.access_token,
        url: this.user_data.url,
        order_id: [event.data['ID']]
      };
      let total_qty = 0;
      let total_discount = 0;
      this.res_data = this.apiService.getOrderDetail(order_data);
      this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
            this.orderDetails = res['results'].data[0];
            this.templ_config = null;
            this.template = false;
            if (localStorage.getItem('custom_templates')) {
              const custom_templates = JSON.parse(localStorage.getItem('custom_templates'));
              const updated = custom_templates.filter(elem => {
                return elem.type === 'Secondary Print PDF';
              });
              if (updated.length > 0) {
                this.templ_config = [{name: 'Default'}].concat(updated);
                this.template = true;
              }
            }
            this.orderDetails['tax_display'] = false;
            this.orderDetails['new_tax'] = 0;
            const tax_breaks = [];
            const taxnotes = [];
            const inthis = this;
            this.orderDetails['label_config'] = {};
            const labels = this.orderDetails['labels'];
            if (labels) {
              labels.forEach(lab => {
                inthis.orderDetails['label_config'][lab.label] = lab.display;
              });
            } else {
              inthis.orderDetails['label_config']['PrimaryCurrency'] = '';
              inthis.orderDetails['label_config']['SecondaryCurrency'] = '';
              inthis.orderDetails['label_config']['SupplierLabel'] = 'Supplier';
              inthis.orderDetails['label_config']['TaxLabel'] = 'GST';
            }
            this.orderDetails['tax_breakup'].forEach(each_tax => {
              if (each_tax.name !== 'CGST' && each_tax.name !== 'SGST' && each_tax.name !== 'IGST' &&
                each_tax.name !== 'VAT' && each_tax.name !== 'CESS' && each_tax.name !== 'SCESS' && each_tax.name !== 'GST') {
                inthis.orderDetails['tax_display'] = true;
                inthis.orderDetails['new_tax'] += parseFloat(each_tax.tax);
                tax_breaks.push(each_tax);
                taxnotes.push(each_tax.name);
              } else {
                tax_breaks.push(each_tax);
                taxnotes.push(each_tax.name);
              }
            });
            this.orderDetails['tax_breakup'] = tax_breaks;
            this.orderDetails['logo'] = this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64, " + this.orderDetails['logo']);
            if (this.orderDetails['partner_id'] === this.orderDetails['partner_shipping_id']) {
              this.orderDetails['customer_skip'] = true;
            } else {
              this.orderDetails['customer_skip'] = false;
            }
            this.orderDetails['print_text'] = this.domSanitizer.bypassSecurityTrustHtml(this.orderDetails['print_text']);
            this.orderDetails['tcs'] = parseFloat(this.orderDetails['tcs']).toFixed(5);
            const d_status = this.orderDetails['d_status'];
            this.orderDetails['sale_items'].forEach(sale_item => {
              sale_item['label'] = '[' + sale_item.type + ']';
              const breaks = [];
              sale_item['new_tax'] = 0;
              sale_item['tax_notes'].forEach(each_tax => {
                if (each_tax.name !== 'CGST' && each_tax.name !== 'SGST' && each_tax.name !== 'IGST' &&
                  each_tax.name !== 'VAT' && each_tax.name !== 'CESS' && each_tax.name !== 'SCESS' && each_tax.name !== 'GST') {
                  sale_item['new_tax'] += parseFloat(each_tax.tax);
                  breaks.push(each_tax);
                } else {
                  breaks.push(each_tax);
                }
              });
              taxnotes.forEach(order_tax => {
                let flag = false;
                breaks.forEach(line_tax => {
                  if (line_tax.name === order_tax) {
                    flag = true;
                  }
                });
                if (!flag) {
                  breaks.push({name: order_tax, rate: 0, tax: 0});
                }
              });
              sale_item['tax_notes'] = breaks;
              total_discount += parseFloat(sale_item.vps_dis);
              if (d_status === 'delivered') {
                sale_item['abs_qty'] = Math.abs(sale_item['delivered_qty']);
                total_qty += parseInt(sale_item.delivered_qty, 0);
                sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.delivered_qty)) -
                  (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
              } else {
                sale_item['abs_qty'] = Math.abs(sale_item['qty']);
                total_qty += parseInt(sale_item.qty, 0);
                sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.qty)) -
                  (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
              }
              sale_item.unit_price = sale_item.unit_price.toFixed(inthis.apiService.decimalPolicy);
            });
            this.orderDetails['total_qty'] = Math.abs(total_qty);
            this.orderDetails['discount'] = total_discount.toFixed(this.apiService.decimalPolicy);
            this.invoice_date = this.datePipe.transform((new Date(this.orderDetails['date_delivery'] + ' UTC')).toString(), 'dd/MM/yyyy');
            this.orderDetails['invoice_date'] = this.invoice_date;
            this.orderDetails['order_date'] = this.datePipe.transform((new Date(this.orderDetails['order_date'] + ' UTC')).toString(),
              'yyyy-MM-dd HH:mm:ss');
            if (this.orderDetails['date_delivered'] !== null) {
              this.orderDetails['date_delivered'] = this.datePipe.transform((new Date(this.orderDetails['date_delivered'] + ' UTC')).toString(),
                'yyyy-MM-dd HH:mm:ss');
            }
            if (this.orderDetails['pay_items'].length === 0) {
              this.payment_enable = false;
            } else {
              const datePipe = this.datePipe;
              this.orderDetails['pay_items'].forEach(pay_item => {
                if (pay_item['payment_type'] === 'Cr Used') {
                  pay_item['payment_type'] = 'Cr Note';
                } else if (pay_item['payment_type'] === 'cash_and_credit') {
                  pay_item['payment_type'] = 'Credit With Payment';
                }
                pay_item['payment_date'] = datePipe.transform((new Date(pay_item['payment_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
              });
              this.payment_enable = true;
            }
            this.all_orders = [this.orderDetails];
          } else {
            this.orderDetails = {label_config: {}};
            this.payment_enable = false;
          }
          this.clickedRow = event.data['PHOTO'].image;
          this.triggerFalseClick();
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  // Photo pop up generate
  clickPhoto(image) {
    this.res_data = this.apiService.fetchImage({
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      data: [{store_fname: image, container: "sale_order"}]
    });
    this.res_data.subscribe(res => {
        console.log(res);
        this.photo = this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64, " + res['result'][0].bill_datas);
        const el: HTMLElement = this.imageClick.nativeElement;
        el.click();
      },
      error => console.log(error)
    );
  }

  clickPhotoPOD(data) {
    if (data != null) {
      const type = data.pod_type;
      let height = "94%";
      if (screen.width > 991) {
        if (type === 'OTP') {
          height = "60%";
        }
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "28%",
          maxHeight: height,
          data: {
            data
          },
          panelClass: 'custom-dialog-container'
        };
        const creatediaeref = this.dialog.open(PODPopupComponent, config);
        creatediaeref.componentInstance.podverified.subscribe(data => {
          this.loadPage();
        });
      } else {
        if (type === 'OTP') {
          height = "65%";
        }
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "95%",
          maxHeight: height,
          data: {
            data
          },
          panelClass: 'custom-dialog-container'

        };
        const creatediaeref = this.dialog.open(PODPopupComponent, config);
        creatediaeref.componentInstance.podverified.subscribe(data => {
          this.loadPage();
        });
      }
    } else {
    }
  }

  openGRNpopup(data) {
    if (data != null) {
      const type = data.pod_type;
      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: "100vw",
          height: 'auto',
          maxWidth: "28%",
          maxHeight: "100%",
          panelClass: 'custom-dialog-container',
          data: {
            data
          },
        };
        const creatediaeref = this.dialog.open(GRNPopupComponent, config);
        creatediaeref.componentInstance.grnverified.subscribe(data => {
          this.loadPage();
        });
      } else {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "auto",
          maxWidth: "95%",
          maxHeight: "100%",
          panelClass: 'custom-dialog-container',
          data: {
            data
          },
        };
        const creatediaeref = this.dialog.open(GRNPopupComponent, config);
        creatediaeref.componentInstance.grnverified.subscribe(data => {
          this.loadPage();
        });
      }
    } else {
    }
  }

  printCustom(data) {
    this.appComponent.printAll([data], 'id', '/api/pwa_connects/get_order_print_details', null);
  }

  clickPhoto2(event) {
    if (this.clickedRow !== null) {
      this.clickPhoto(this.clickedRow);
    }
  }

  triggerFalseClick() {
    const el: HTMLElement = this.myDiv.nativeElement;
    el.click();
  }

  triggerFalseClick2() {
    const el: HTMLElement = this.devDiv.nativeElement;
    el.click();
  }

  openWindow(id) {
    // this.openedWindow = id; // alternative: push to array of numbers
  }

  isInfoWindowOpen(id) {
    return this.openedWindow === id; // alternative: check if id is in array
  }

  // Changing approval table tabs
  tabClick(event) {
    if (event.index < 0) {
      this.tabIndex = localStorage.getItem('tabIndex');
    } else if (this.cancel_tab || this.edit_tab || this.return_approve) {
      this.tabIndex = localStorage.getItem('tabIndex');
      this.cancel_tab = false;
      this.edit_tab = false;
      this.return_approve = false;
    } else {
      this.tabIndex = event.index;
      localStorage.setItem('tabIndex', event.index);
    }
    if (screen.width > 991) {
      if (this.cash_count > 0) {
        this.gridOptions3.api.sizeColumnsToFit();
      }
      if (this.today_count > 0) {
        this.gridOptions.api.sizeColumnsToFit();
      }
      if (this.replace_count > 0) {
        this.gridOptionsReplace.api.sizeColumnsToFit();
      }
      if (this.dispatch_count > 0) {
        this.gridOptionsDispatch.api.sizeColumnsToFit();
      }
      if (this.return_count > 0) {
        this.gridOptionsReturn.api.sizeColumnsToFit();
      }
      if (this.mismatch_count > 0) {
        this.gridOptionsMismatch.api.sizeColumnsToFit();
      }
      if (this.logs_count > 0) {
        this.gridOptionsLog.api.sizeColumnsToFit();
      }
      if (this.sample_count > 0) {
        this.gridOptionsSample.api.sizeColumnsToFit();
      }
      if (this.unapp_count > 0) {
        this.gridOptionsUnapp.api.sizeColumnsToFit();
      }
      if (this.prvs_count > 0) {
        this.gridOptionsPrev.api.sizeColumnsToFit();
      }
      if (this.pending_einvoice_count > 0) {
        this.gridOptionsPEinvoice.api.sizeColumnsToFit();
      }
      if (this.pending_pod_count > 0) {
        this.gridOptionsPEpod.api.sizeColumnsToFit();
      }
      if (this.pending_saporder_count > 0 &&  this.gridOptionsSapOrder !== null
        &&  this.gridOptionsSapOrder['api'] !== null  &&  this.gridOptionsSapOrder['api'] !== undefined) {
        this.gridOptionsSapOrder.api.sizeColumnsToFit();
      }
    } else {
      if (this.cash_count > 0) {
        this.gridOptions3.columnApi.autoSizeAllColumns();
      }
      if (this.today_count > 0) {
        this.gridOptions.columnApi.autoSizeAllColumns();
      }
      if (this.replace_count > 0) {
        this.gridOptionsReplace.columnApi.autoSizeAllColumns();
      }
      if (this.dispatch_count > 0) {
        this.gridOptionsDispatch.columnApi.autoSizeAllColumns();
      }
      if (this.return_count > 0) {
        this.gridOptionsReturn.columnApi.autoSizeAllColumns();
      }
      if (this.mismatch_count > 0) {
        this.gridOptionsMismatch.columnApi.autoSizeAllColumns();
      }
      if (this.logs_count > 0) {
        this.gridOptionsLog.columnApi.autoSizeAllColumns();
      }
      if (this.sample_count > 0) {
        this.gridOptionsSample.columnApi.autoSizeAllColumns();
      }
      if (this.unapp_count > 0) {
        this.gridOptionsUnapp.columnApi.autoSizeAllColumns();
      }
      if (this.prvs_count > 0) {
        this.gridOptionsPrev.columnApi.autoSizeAllColumns();
      }
      if (this.pending_einvoice_count > 0) {
        this.gridOptionsPEinvoice.columnApi.autoSizeAllColumns();
      }
      if (this.pending_saporder_count > 0) {
        this.gridOptionsSapOrder.columnApi.autoSizeAllColumns();
      }
    }
  }
  // Redirecting to next page with next van
  pageChanged(status) {
    this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
    this.settle_dev = false;
    this.settle_live_dev = false;
    this.payment_mismatch = false;
    this.payment_logs = false;
    this.next_started = false;
    this.status_enable = false;
    this.nxt_eod_status = false;
    this.is_return_approved = false;
    if (status === 'next') {
      this.config.index += 1;
      if (this.config.index === this.config.data.length - 1) {
        this.config.next = false;
      }
    } else {
      this.config.index -= 1;
      if (this.config.index === 0) {
        this.config.prvs = false;
      }
    }
    if (this.config.index < this.config.data.length - 1) {
      this.config.next = true;
    }
    if (this.config.index > 0) {
      this.config.prvs = true;
    }
    this.user_data.salesman_id = this.config.data[this.config.index].van_id;
    this.user_data.vansale_id = this.config.data[this.config.index].vansale_id;
    this.user_data.van_id = this.config.data[this.config.index].true_van_id;
    this.van_name = this.config.data[this.config.index]['VAN'];
    this.eod_status = "Not Started";
    this.status_enable = true;
    this.loadPage();
  }

  expandMap() {
    this.hide = !(this.hide);
  }

  expandList() {
    this.list_loaded = !(this.list_loaded);
  }

  // Edit replace or return values
  updateReturnValue(order, product) {
    this.edit_tab = true;
    this.updated_value = product['delivered_qty'];
    this.return_value = product['delivered_qty'];
    this.return_order = order;
    this.return_product = product;
    this.factor_inv = product['factor_inv'];
  }

  updateReturn(event) {
    console.log(event, this.updated_value);
    if (this.return_order.order_type === 'return') {
      this.return_value = (-1 * parseInt(this.return_value, 10)).toString();
      if (parseInt(this.updated_value, 10) < 0) {
        this.updated_value = (-1 * parseInt(this.updated_value, 10)).toString();
      }
    }
    if ((parseInt(this.updated_value, 10) < parseInt(this.return_value, 10)) && (this.reason !== "")) {
      if (this.return_order.order_type === 'return') {
        this.updated_value = (-1 * parseInt(this.updated_value, 10)).toString();
      }
      const dev = (parseInt(this.return_value, 10) * parseInt(this.factor_inv, 10)) - (parseInt(this.updated_value, 10) * parseInt(this.factor_inv, 10));
      const stock_data = [{
        distributor_id: this.return_order.supplier_id,
        product_id: this.return_product.product_id,
        stock_date: this.user_data.date,
        quantity: this.updated_value,
        prvs_qty: this.return_value,
        cid: this.return_order.cid,
        territory_id: this.return_order.territory_id,
        type: 'd',
        comments: this.reason,
        order_id: this.return_order.id,
        line_id: this.return_product.id,
        status: this.eod_status,
        salesman_id: this.user_data.salesman_id,
        vansale_id: this.user_data.vansale_id,
        deviation: dev
      }];
      this.res_data = this.apiService.addVansaleStock({
        access_token: this.user_data.access_token,
        url: this.user_data.url,
        data: stock_data
      });
      this.res_data.subscribe(res => {
          console.log(res);
          const return_product = this.return_product;
          const updated_data = this.orderDetails['sale_items'].filter(existed => return_product.product_id === existed.product_id);
          updated_data[0].delivered_qty = this.updated_value;
          this.return_value = "0";
          this.updated_value = "0";
          this.reason = "";
          this.loadPage();
        },
        error => {
          console.log(error);
        });
    } else {
      this.return_value = "0";
      this.updated_value = "0";
      this.reason = "";
    }
  }
  // Revert vansale status
  editVanStatus() {
    const el: HTMLElement = this.revertStatus.nativeElement;
    el.click();
  }

  updateVanStatus() {
    if (this.eod_status === 'complete') {
      this.res_data = this.apiService.revertStatus({
        access_token: this.user_data.access_token,
        url: this.user_data.url,
        data: {
          status: this.user_data.status,
          vansale_id: this.user_data.vansale_id,
          salesman_id: this.user_data.salesman_id,
          date: this.user_data.date
        }
      });
      this.res_data.subscribe(res => {
          console.log(res);
          if (res && res.results) {
              if (res.results.status && res.results.status === 200) {
                this.toastr.success("Vansale Reverted");
                this.eod_status = 'started';
                this.loadPage();
              } else if (res.results && res.results.msg) {
                this.toastr.error(res.results.msg);
              } else {
                this.toastr.error("Failed to revert status");
              }
          } else if (res && res.error && res.error.msg) {
            this.toastr.error(res.error.msg);
          } else {
            this.toastr.error("Failed to revert status");
          }
        },
        error => {
          console.log(error);
        });

    } else {
      this.toastr.error("failed to revert status");
    }
  }

  changeDate(dt) {
    this.eod_status = "Not Started";
    this.settle_dev = false;
    this.settle_live_dev = false;
    this.settle_wait = false;
    this.payment_mismatch = false;
    this.payment_logs = false;
    this.next_started = false;
    this.nxt_eod_status = false;
    this.is_return_approved = false;
    this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.user_data.vansale_id = null;
    localStorage.setItem('my_date', this.user_data.date);
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.loadPage();
  }
  // Cancel order
  cancelPopup(order_details) {
    this.selected_order = order_details;
  }
  cancelOrder() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
    this.cancel_tab = true;
    const cancel_order = {};
    const eod_status = this.eod_status;
    const selected_order = this.selected_order;
    const reason = this.cancel_reason;
    const salesman_id = this.user_data.salesman_id;
    const datePipe = this.datePipe;
    cancel_order['order'] = [];
    cancel_order['schedule_data'] = [];
    cancel_order['order'].push({
      state: 'cancel',
      id: this.selected_order.id,
      has_einvoice: this.selected_order.has_einvoice,
      schedule_id: this.selected_order.schedule_id,
      amount_untaxed: 0,
      amount_tax: 0,
      amount_total: 0,
      firebase_token: this.selected_order.firebase_token,
      name: this.selected_order.name,
      type: this.selected_order.order_type,
      user_id: this.selected_order.user_id,
      d_status: this.selected_order.d_status,
      generate_credit_note: this.generate_credit_note
    });
    cancel_order['schedule_data'].push({
      schedule_id: this.selected_order.schedule_id,
      amount: this.selected_order.amount_untaxed
    });
    cancel_order['order_line'] = [];
    cancel_order['supplier_stock'] = [];
    cancel_order['supplier_stock_log'] = [];
    cancel_order['customer_stock'] = [];
    cancel_order['payment'] = [];
    cancel_order['status'] = this.eod_status;
    cancel_order['vansale_id'] = this.user_data.vansale_id;

    this.selected_order.sale_items.forEach(order_line => {
      cancel_order['order_line'].push({
        quantity: 0,
        price_subtotal: 0,
        id: order_line.id,
        price_tax: 0,
        price_total: 0
      });
      order_line.delivered_qty = parseInt(order_line.delivered_qty, 10) * parseInt(order_line.factor_inv, 10);
      cancel_order['supplier_stock_log'].push({
        quantity: order_line.delivered_qty,
        distributor_id: selected_order.supplier_id,
        product_id: order_line.product_id,
        territory_id: selected_order.territory_id,
        stock_date: datePipe.transform((new Date(selected_order.order_date)).toString(), 'yyyy-MM-dd'),
        cid: selected_order.cid,
        type: selected_order.type,
        comments: reason,
        line_id: order_line.id
      });
      const updated_order_line = cancel_order['supplier_stock'].filter(existed => parseInt(order_line.product_id, 10) === parseInt(existed.product_id, 10));
      if (updated_order_line.length > 0) {
        updated_order_line[0].quantity = parseInt(updated_order_line[0].quantity, 10) + parseInt(order_line.delivered_qty, 10);
      } else {
        cancel_order['supplier_stock'].push({
          quantity: order_line.delivered_qty,
          distributor_id: selected_order.supplier_id,
          product_id: order_line.product_id,
          territory_id: selected_order.territory_id,
          stock_date: datePipe.transform((new Date(selected_order.order_date)).toString(), 'yyyy-MM-dd'),
          cid: selected_order.cid,
          type: selected_order.type,
          comments: reason,
          line_id: order_line.id
        });
      }
      //supplier stock only
      let sale_qty = 0;
      let damage_qty = 0;
      let sample_qty = 0;
      let promo_qty = 0;
      const updated_order_line_cust = cancel_order['customer_stock'].filter(existed => parseInt(order_line.product_id, 10) === parseInt(existed.product_id, 10));
      if (updated_order_line_cust.length > 0) {
        updated_order_line_cust[0].quantity = parseInt(updated_order_line_cust[0].quantity, 10) + parseInt(order_line.delivered_qty, 10);
        if (order_line.type === 'damaged') {
          updated_order_line_cust[0].damage_qty = parseInt(updated_order_line_cust[0].damage_qty, 10) + parseInt(order_line.delivered_qty, 10);
        } else if (order_line.type === 'sale') {
          updated_order_line_cust[0].sale_qty = parseInt(updated_order_line_cust[0].sale_qty, 10) + parseInt(order_line.delivered_qty, 10);
        } else if (order_line.type === 'sample') {
          updated_order_line_cust[0].sample_qty = parseInt(updated_order_line_cust[0].sample_qty, 10) + parseInt(order_line.delivered_qty, 10);
        } else if (order_line.type === 'promo') {
          updated_order_line_cust[0].promo_qty = parseInt(updated_order_line_cust[0].promo_qty, 10) + parseInt(order_line.delivered_qty, 10);
        }
      } else {
        if (order_line.type === 'damaged') {
          damage_qty += parseInt(order_line.delivered_qty, 10);
        } else if (order_line.type === 'sale') {
          sale_qty += parseInt(order_line.delivered_qty, 10);
        } else if (order_line.type === 'sample') {
          sample_qty += parseInt(order_line.delivered_qty, 10);
        } else if (order_line.type === 'promo') {
          promo_qty += parseInt(order_line.delivered_qty, 10);
        }
        cancel_order['customer_stock'].push({
          quantity: order_line.delivered_qty,
          distributor_id: selected_order.supplier_id,
          product_id: order_line.product_id,
          territory_id: selected_order.customer_territory_id,
          stock_date: datePipe.transform((new Date(selected_order.order_date)).toString(), 'yyyy-MM-dd'),
          cid: selected_order.cid,
          type: selected_order.type,
          sale_qty,
          damage_qty,
          sample_qty,
          promo_qty,
          salesman_id
        });
      }
    });
    selected_order.pay_items.forEach(pay_line => {
      cancel_order['payment'].push({
        partner_id: pay_line.partner_id,
        order_id: pay_line.order_id,
        payment_date: datePipe.transform((new Date(pay_line.payment_date)).toString(), 'yyyy-MM-dd'),
        payment_type: pay_line.payment_type,
        state: 'draft',
        user_id: pay_line.user_id,
        amount: pay_line.amount,
        cid: pay_line.cid,
        order_type: selected_order.order_type,
        cd: 'Cr',
        id: pay_line.id
      });
    });
    console.log(selected_order);
    this.res_data = this.apiService.cancelOrder({
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      data: cancel_order,
      offset: this.user_data.offset
    });
    this.res_data.subscribe(res => {
        console.log(res);
        if (res['results'] && res['results'].status !== 200) {
          if (res['results'].msg) {
            window.alert('Failed:- ' + res['results'].msg);
          }
        }
        this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
        this.loadPage();
        this.cancel_reason = "";
      },
      error => {
        console.log(error);
        if (error.error && error.error.error) {
          window.alert("cancel failed");
        } else {
          window.alert("cancel failed: " + error.error.error.message);
        }
        this.cancel_reason = "";
        this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
      });
  }

  checkEodSettleShow() {
    if ((!this.block_settle ||
        (this.pending_einvoice_count === 0 && !this.settle_dev && !this.settle_live_dev && this.is_return_approved))
      && this.settle_wait && !this.payment_mismatch && !this.payment_logs && this.eod_status === 'complete' && !this.stock_neg
      && !this.collection_mismatch && this.settle_enable && !this.settled && ((this.pending_pod_count === 0 && this.podApproveRequired)
        || !this.podApproveRequired)) {
      if (this.sapSync && this.countryMissing) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getSettleMainErrorMessage() {
    if (this.block_settle && (this.settle_dev || this.settle_live_dev) && !this.settled) {
      return "Cannot settle due to stock mismatch";
    } else if (this.block_settle && this.payment_mismatch && !this.settled) {
      return "Cannot settle due to payment mismatch";
    } else if (this.block_settle && this.payment_logs && !this.settled) {
      return "Cannot settle due to Stock Log mismatch";
    } else if (this.block_settle && !this.is_return_approved && this.eod_status === 'complete') {
      return "Cannot settle due to pending return approval";
    } else if (this.block_settle && this.collection_mismatch && !this.settled) {
      return "Cannot settle due to Collection payment mismatch";
    } else if (!this.settled && this.stock_neg) {
      return "Cannot settle due to Negative stock";
    } else if (this.block_settle && this.pending_pod_count > 0 && this.podApproveRequired && this.eod_status === 'complete') {
      return "Cannot settle due to Pending POD";
    } else if (this.block_settle && this.pending_einvoice_count > 0 && this.eod_status === 'complete') {
      return "Cannot settle due to Pending E-Invoice";
    } else if (this.sapSync && this.countryMissing) {
      let missingListString = '';
      for (const retailer of this.countryMissingRetailers) {
        if (missingListString === '') {
          missingListString = retailer.toString();
        } else {
          missingListString = missingListString + ', ' + retailer;
        }
      }
      return "State mapping missing for " + this.countryMissingRetailers.size + " Retailers - " + missingListString;
    }
  }

  getSettleSupportErrorMessage() {
     if (this.block_settle && !this.settled && this.stock_neg) {
      return "*contact Admin/support team";
    } else if (!this.block_settle && (this.settle_dev || this.settle_live_dev) && !this.settled) {
      return "Kindly validate the stock mismatch";
    } else if (this.settle_dev && !this.settled) {
      return "*Try reversing the EoD status and Marking EoD again from mobile.If that doesnt help contact Admin/support team";
    } else if (this.settle_live_dev && !this.settled) {
      return "*Live Stock is not correct.contact Admin/support team";
    } else if (!this.block_settle && this.payment_mismatch && !this.settled) {
      return "Kindly validate the payment mismatch";
    } else if (this.payment_mismatch && !this.settled) {
      return "*contact Admin/support team";
    } else if (!this.block_settle && this.payment_logs && !this.settled) {
      return "Kindly validate the stocklog mismatch";
    } else if (this.payment_logs && !this.settled) {
      return "*contact Admin/support team";
    } else if (this.block_settle && this.collection_mismatch && !this.settled) {
      return "*contact Admin/support team";
    } else if (this.pending_einvoice_count > 0 && this.eod_status === 'complete') {
      return "Regenerate Invoice for Pending E-Invoices";
    } else if (this.sapSync && this.countryMissing) {
       return "Kindly map States for above Retailers";
     }
  }

  protected readonly parseFloat = parseFloat;
}
