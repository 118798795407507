import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from "@angular/core";
import {GridsterComponent, GridsterConfig, GridsterItem} from "angular-gridster2";
import {MatMenuTrigger} from "@angular/material";
import {ApiService} from "../_services/api.service";
import {SharedService} from "../_services/shared_service";
import {DatePipe} from "@angular/common";
import {LocalforageService} from "../_services/localforage.service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {forkJoin} from "rxjs";
import {MatTabChangeEvent} from "@angular/material/tabs";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.scss']
})

// tslint:disable-next-line:component-class-suffix
export class Dashboard implements OnInit {
  @ViewChild('gridster') gridster: GridsterComponent;
  options: GridsterConfig;
  dashboard: Array<GridsterItem>;
  resizeEvent: EventEmitter<GridsterItem> = new EventEmitter<GridsterItem>();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  tabList = [];
  showableFilters = {
    salesman: true,
    region: true,
    cluster: true,
    team: true,
    territory: true,
    state: true
  };

  filterData = {
    period: 'TM',
    region_id: 0,
    cluster_id: 0,
    team_id: 0,
    territory_id: 0,
    salesman_id: 0
  };

  public tab_config = {};
  public analysis_config = {};
  public USER;
  public apiList;
  access_token;
  myDate = [];
  activetab = '';
  height = 0;
  width = 0;
  jcData: any = [
    {
      name: "Today"
    },
    {
      name: "Yesterday"
    },
    {
      name: "This Week"
    },
    {
      name: "Last Week"
    },
    {
      name: "This Month"
    },
    {
      name: "Last Month"
    },
    {
      name: "This Quarter"
    },
    {
      name: "Previous Quarter"
    },
    {
      name: "This Year"
    },
    {
      name: "Previous Year"
    }
  ];
  periodData;
  apiDataMap;
  dataLoaded = false;
  apiLoaded = false;
  progressMode = 'determinate';
  progress = 0;
  progressText = '';
  daylist = [];

  constructor(protected apiService: ApiService,
              public sharedService: SharedService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected _localConfigCache: LocalforageService,
              protected toastr: ToastrService,
              protected route: ActivatedRoute,
              private router: Router) {
    this.USER = localStorage.getItem('user');
    this.access_token = localStorage.getItem('resfreshToken');
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    //this.myDate[0] = '2022-01-01';
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.periodData = JSON.parse(localStorage.getItem('jc_periods'));
    this.filterData.period = 'This Month';
    this.configurePeriodData();
  }

  ngOnInit() {
    this.sharedService.setHeader('Dashboard');
    this.dashboard = [];
    this.options = {
      gridType: "verticalFixed",
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      minCols: 12,
      maxCols: 12,
      minRows: 6,
      maxRows: 60,
      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedRowHeight: 130,
      keepFixedHeightInMobile: true,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true
      },
      resizable: {
        enabled: true
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: {north: true, east: true, south: true, west: true},
      pushResizeItems: false,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false
    };
    this.configureDash();
  }

  configureDash() {
    this.dashboard = [];
    this.tabList = [];
    const userObj = JSON.parse(localStorage.getItem('user'));
    let roleconfigPresent = false;
    if (userObj.role.hasOwnProperty('role_config') && userObj.role.role_config && userObj.role.role_config.length > 0) {
      this.analysis_config = [];
      const role_config = userObj.role.role_config.filter(x => x.type === 'PWA Dashboard');
      if (role_config && role_config.length > 0) {
        roleconfigPresent = true;
        let sequence = 0;
        role_config.forEach(item => {
          this.analysis_config[item.name] = item.config_json;
          if (item.sequence) {
            sequence = item.sequence;
          }
          this.tabList.push({
            sequence,
            key: item.name,
            name: item.name
          });
        });
        this.tabList = this.tabList.sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
        this.activetab = this.tabList[0].key;
        this.loadTabData(this.activetab);
      } else {
        roleconfigPresent = false;
      }
    }
    if (!roleconfigPresent) {
      this._localConfigCache.getAppConfig().then(async app_config => {
        if (app_config && app_config.hasOwnProperty('config_json') && app_config['config_json'].hasOwnProperty('analysis_pwa')) {
          this.analysis_config = app_config['config_json']['analysis_pwa'];
          for (const key of Object.keys(app_config['config_json']['analysis_pwa'])) {
            if (app_config['config_json']['analysis_pwa'][key]['page_name']) {
              this.tabList.push({
                key,
                name: app_config['config_json']['analysis_pwa'][key]['page_name']
              });
            } else {
              this.tabList.push({
                key,
                name: "No Name"
              });
            }
          }
          this.activetab = this.tabList[0].name;
          this.loadTabData(this.activetab);
        } else {

        }
      });
    }
    if (screen.width > 991) {
      this.options.minCols = 12;
    } else {
      this.options.minCols = 1;
    }
  }

  changeActiveTab(event: MatTabChangeEvent) {
    this.activetab = event.tab.textLabel;
    this.dataLoaded = false;
    this.loadTabData(this.activetab);
  }

  async loadTabData(tabName) {
    const inThis = this;
    let xIndex = 0;
    let yindex = 0;
    let value = 1;
    let rowspan = 1;
    let colspan = 1;
    this.dashboard = [];
    const dash = [];
    this.apiList = new Set();
    const tabData = await this.tabList.find(x => x.name === tabName);
    this.tab_config = this.analysis_config[tabData['key']];
    let api = null;
    if (this.filterData.period === 'This Month') {
      const date = new Date();
      const end_date = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
      this.daylist = this.getDaysArray(new Date(this.myDate[0]), new Date(end_date));
    } else {
      this.daylist = this.getDaysArray(new Date(this.myDate[0]), new Date(this.myDate[1]));
    }
    console.log(this.daylist);
    for (const key of Object.keys(this.tab_config)) {
      if (inThis.tab_config[key]['api']) {
        inThis.apiList.add(inThis.tab_config[key]['api']);
      } else if (inThis.tab_config[key]['data'] && inThis.tab_config[key]['data'].length > 0) {
        inThis.tab_config[key]['data'].forEach(data => {
          if (data.api) {
            inThis.apiList.add(data.api);
          }
        });
      }
    }
    await this.loadDataFromApi();
    await this.timeout(50);
    this.height = (document.getElementById("gridelement")).offsetHeight;
    this.width = (document.getElementById("gridelement")).offsetWidth;
    console.log(this.height);
    for (const key of Object.keys(this.tab_config)) {
      if (key !== 'page_name') {
        console.log('Key : ' + key + ', Value : ' + inThis.tab_config[key]);
        rowspan = (xIndex % 2) === 0 ? 1 : 2;
        colspan = (xIndex % 2) === 0 ? 1 : 2;
        let icon = "";
        if (inThis.tab_config[key]['icon_url']) {
          icon = "https://drive.google.com/uc?export=view&" + inThis.tab_config[key]['icon_url'].substring(inThis.tab_config[key]['icon_url'].indexOf('id'), inThis.tab_config[key]['icon_url'].indexOf('&export'));
        }
        let apivalue = [];
        api = null;
        if (inThis.tab_config[key]['api']) {
          api = inThis.tab_config[key]['api'];
        } else if (inThis.tab_config[key]['data'] && inThis.tab_config[key]['data'].length > 0) {
          api = [...new Set(inThis.tab_config[key]['data'].map(item => item.api).filter(Boolean))];
        }
        if (api) {
          let resList = [];
          if (Array.isArray(api)) {
            resList = api.map(apiName => inThis.apiDataMap[apiName]);
          } else {
            resList = [inThis.apiDataMap[api]];
          }
          console.log(`result for ${api} : ` + resList);
          resList.forEach(res => {
            if (res && res.result && res.result.custom_analysis && res.result.custom_analysis.length > 0) {
              console.log(res.result.custom_analysis);
              if (inThis.tab_config[key]['key']) {
                const val = res.result.custom_analysis[0][inThis.tab_config[key]['key']];
                if (inThis.tab_config[key]['growth_key']) {
                  const growth_val = res.result.custom_analysis[0][inThis.tab_config[key]['growth_key']];
                  apivalue['value'] = val;
                  apivalue['prvsValue'] = growth_val;
                } else {
                  apivalue['value'] = val;
                }
              } else if (inThis.tab_config[key]['data'] && inThis.tab_config[key]['data'].length > 0) {
                if (inThis.tab_config[key]['view_mode'] === 'multigrid') {
                  apivalue = res.result.custom_analysis;
                  let headingObj = '';
                  apivalue.forEach(singledata => {
                    headingObj = inThis.tab_config[key]['data'].find(x => x.mapping_key === 'heading');
                    if (headingObj) {
                      singledata.heading = singledata[headingObj['key']];
                    }
                    for (const key1 of Object.keys(singledata)) {
                      if (singledata[key1]) {
                      } else {
                        singledata[key1] = 0;
                      }
                    }
                  });
                } else if (inThis.tab_config[key]['view_mode'] === 'bar_graph') {
                  let headingObj = '';
                  headingObj = inThis.tab_config[key]['data'].find(x => x.mapping_key === 'heading');
                  let apiDat = res.result.custom_analysis;
                  if (inThis.tab_config[key]['source_key']) {
                    apiDat = res.result.custom_analysis[0][inThis.tab_config[key]['source_key']];
                  } else if (res.result.custom_analysis && res.result.custom_analysis.length > 0) {
                    apiDat = res.result.custom_analysis[0];
                  }
                  if (inThis.tab_config[key]['display_mode'] === 'single') {
                    if (headingObj && apiDat && apiDat.length && apiDat.length > 0) {
                      inThis.tab_config[key]['data'].forEach(singledata => {
                        if (!singledata['mapping_key']) {
                          if (apiDat) {
                            apiDat.forEach(apidata => {
                              let val = apidata[singledata['key']];
                              if (val) {
                                val = parseFloat(val);
                              } else {
                                val = 0;
                              }
                              apivalue.push({
                                name: apidata[headingObj['key']],
                                value: val,
                                indicator_colour: singledata['indicator_colour']
                              });
                            });
                          }
                        }
                      });
                    } else {
                      inThis.tab_config[key]['data'].forEach(singledata => {
                        if (!singledata['mapping_key']) {
                          if (apiDat) {
                            let val = apiDat[singledata['key']];
                            if (val) {
                              val = parseFloat(val);
                            } else {
                              val = 0;
                            }
                            apivalue.push({
                              name: singledata['label'],
                              value: val,
                              indicator_colour: singledata['indicator_colour']
                            });
                          }
                        }
                      });
                    }
                  } else {
                    if (apiDat) {
                      apiDat.forEach(apidata => {
                        const ind_obj: any = {};
                        ind_obj.name = apidata[headingObj['key']];
                        ind_obj.series = [];
                        inThis.tab_config[key]['data'].forEach(singledata => {
                          if (!singledata['mapping_key']) {
                            let val = apidata[singledata['key']];
                            if (val) {
                              val = parseFloat(val);
                            } else {
                              val = 0;
                            }
                            ind_obj.series.push({
                              name: singledata['label'],
                              value: val,
                              indicator_colour: singledata['indicator_colour']
                            });
                          }
                        });
                        apivalue.push(ind_obj);
                      });
                    }
                  }
                } else if (inThis.tab_config[key]['view_mode'] === 'donut_graph') {
                  if (inThis.tab_config[key]['data'] && inThis.tab_config[key]['data'].length > 0 && (res.result.custom_analysis[0]['data'])) {
                    inThis.tab_config[key]['data'].forEach(singledata => {
                      apivalue[singledata['key']] = 0;
                      let val = res.result.custom_analysis[0]['data'][singledata['key']];
                      if (val) {
                        val = parseFloat(val);
                      } else {
                        val = 0;
                      }
                      apivalue.push({
                        name: singledata['label'],
                        value: val,
                        indicator_colour: singledata['indicator_colour']
                      });
                    });
                  } else {
                    let apiDat = res.result.custom_analysis;
                    if (inThis.tab_config[key]['source_key']) {
                      apiDat = res.result.custom_analysis[0][inThis.tab_config[key]['source_key']];
                    }
                    apiDat.forEach(apidata => {
                      apivalue.push({
                        name: apidata['name'],
                        value: apidata['value']
                      });
                    });
                  }
                } else if (inThis.tab_config[key]['view_mode'] === 'multi_donut_graph') {
                  if (inThis.tab_config[key]['data'] && inThis.tab_config[key]['data'].length > 0) {
                    res.result.custom_analysis.forEach(apidata => {
                      inThis.tab_config[key]['data'].forEach(singledata => {
                        if (!apivalue[apidata['name']]) {
                          apivalue[apidata['name']] = [];
                        }
                        if (singledata['label'] !== 'Name') {
                          let val = apidata[singledata['key']];
                          if (val) {
                            val = parseFloat(val);
                          } else {
                            val = 0;
                          }
                          apivalue[apidata['name']].push({
                            name: singledata['label'],
                            value: val,
                            indicator_colour: singledata['indicator_colour']
                          });
                        }
                      });
                    });
                  }
                } else if (inThis.tab_config[key]['view_mode'] === 'line_chart') {
                  let apiDat = res.result.custom_analysis;
                  if (inThis.tab_config[key]['source_key']) {
                    apiDat = res.result.custom_analysis[0][inThis.tab_config[key]['source_key']];
                  } else if (res.result.custom_analysis && res.result.custom_analysis.length > 0) {
                    apiDat = res.result.custom_analysis;
                  }
                  if (inThis.tab_config[key]['data'] && inThis.tab_config[key]['data'].length > 0) {
                    inThis.tab_config[key]['data'].forEach(singledata => {
                      const ind_obj: any = {};
                      ind_obj.name = singledata['label'];
                      ind_obj.indicator_colour = singledata['indicator_colour'];
                      ind_obj.series = [];
                      let cumulative = false;
                      let forecast = false;
                      let single_value = false;
                      if (singledata['cumulative']) {
                        cumulative = true;
                      }
                      if (singledata['forecast']) {
                        forecast = true;
                      }
                      if (singledata['single_value']) {
                        single_value = true;
                      }
                      let prev_value = 0;
                      if (inThis.tab_config[key]['type'] && inThis.tab_config[key]['type'] === 'daywise') {
                        inThis.daylist.forEach(day => {
                          if (new Date(day) <= new Date() || single_value) {
                            let val;
                            if (single_value) {
                              val = res.result.custom_analysis[0][singledata['y_key']];
                              if (!val) {
                                val = 0;
                              }
                            } else {
                              let foundRow;
                              if (apiDat) {
                                foundRow = apiDat.find(x => x[singledata['x_key']] === day);
                              }
                              if (foundRow) {
                                val = foundRow[singledata['y_key']];
                                if (val) {
                                  val = parseFloat(val);
                                  prev_value += val;
                                } else {
                                  val = 0;
                                }
                              } else {
                                val = 0;
                              }
                              if (cumulative) {
                                val = prev_value;
                              }
                            }
                            ind_obj.series.push({
                              name: day,
                              value: val
                            });
                          }
                        });
                        apivalue.push(ind_obj);
                        if (forecast && (new Date(inThis.daylist[inThis.daylist.length - 1]) > new Date())) {
                          const lastElement = ind_obj.series[ind_obj.series.length - 1];
                          const index = inThis.daylist.indexOf(lastElement.name);
                          const avg = prev_value / (index + 1);
                          const ind_data: any = {};
                          ind_data.name = singledata['label'] + " Forecast";
                          ind_data.indicator_colour = "#000000";
                          ind_data.series = [];
                          ind_data.series.push({
                            name: lastElement.name,
                            value: lastElement.value
                          });
                          prev_value = lastElement.value;
                          for (let i = (index + 1); i < inThis.daylist.length; i++) {
                            prev_value += avg;
                            ind_data.series.push({
                              name: inThis.daylist[i],
                              value: prev_value
                            });
                          }
                          apivalue.push(ind_data);
                        }
                      } else {
                        if (apiDat) {
                          apiDat.forEach(apidata => {
                            let val = apidata[singledata['y_key']];
                            if (val) {
                              val = parseFloat(val);
                            } else {
                              val = 0;
                            }
                            ind_obj.series.push({
                              name: apidata[singledata['x_key']],
                              value: val,
                            });
                          });
                          apivalue.push(ind_obj);
                        }
                      }
                    });
                  }
                } else if (inThis.tab_config[key]['view_mode'] === 'table') {
                  apivalue = res.result.custom_analysis;
                } else if (inThis.tab_config[key]['view_mode'] === 'grid') {
                  for (const key1 of Object.keys(res.result.custom_analysis[0])) {
                    let val = 0;
                    if (res.result.custom_analysis[0][key1]) {
                      val = res.result.custom_analysis[0][key1];
                    }
                    if (inThis.tab_config[key]['growth_key']) {
                      const growth_val = res.result.custom_analysis[0][inThis.tab_config[key]['growth_key']];
                      apivalue[key1] = {
                        value: val,
                        prvsValue: growth_val
                      };
                    } else {
                      apivalue[key1] = {
                        value: val
                      };
                    }
                  }
                  inThis.tab_config[key]['data'].forEach(singledata => {
                    if (singledata.api) {
                      const res1 = inThis.apiDataMap[singledata.api];
                      if (res1 && res1.result && res1.result.custom_analysis && res1.result.custom_analysis.length > 0) {
                        let val = 0;
                        if (res1.result.custom_analysis[0][singledata.key]) {
                          val = res1.result.custom_analysis[0][singledata.key];
                        }
                        if (singledata['growth_key']) {
                          const growth_val = res.result.custom_analysis[0][singledata['growth_key']];
                          apivalue[singledata.key] = {
                            value: val,
                            prvsValue: growth_val
                          };
                        } else {
                          apivalue[singledata.key] = {
                            value: val
                          };
                        }
                      }
                    }
                  });
                } else {
                  for (const key1 of Object.keys(res.result.custom_analysis[0])) {
                    if (res.result.custom_analysis[0][key1]) {
                      apivalue[key1] = res.result.custom_analysis[0][key1];
                    } else {
                      apivalue[key1] = 0;
                    }
                  }
                  inThis.tab_config[key]['data'].forEach(singledata => {
                    if (singledata.api && singledata.api !== api) {
                      const res1 = inThis.apiDataMap[singledata.api];
                      if (res1 && res1.result && res1.result.custom_analysis && res1.result.custom_analysis.length > 0) {
                        apivalue[singledata.key] = res1.result.custom_analysis[0][singledata.key];
                      }
                    }
                  });
                }
              } else {
                if (inThis.tab_config[key]['view_mode'] === 'bar_graph' && inThis.tab_config[key]['display_mode'] === 'single') {
                  let apiDat = res.result.custom_analysis;
                  if (inThis.tab_config[key]['source_key']) {
                    apiDat = res.result.custom_analysis[0][inThis.tab_config[key]['source_key']];
                  } else if (res.result.custom_analysis && res.result.custom_analysis.length > 0) {
                    apiDat = res.result.custom_analysis;
                  }
                  if (apiDat && apiDat.length > 0) {
                    apiDat.forEach(apidata => {
                      for (const key2 of Object.keys(apiDat[0])) {
                        let val = 0;
                        if (apidata[key2]) {
                          val = parseFloat(String((apidata[key2])));
                        }
                        apivalue.push({
                          name: inThis.sharedService.removeUnderscoreAndFirstLetterCapital(key2),
                          value: val
                        });
                      }
                    });
                  }
                } else if (inThis.tab_config[key]['view_mode'] === 'line_chart') {
                  res.result.custom_analysis.forEach(apidata => {
                    const ind_obj: any = {};
                    ind_obj.name = apidata[inThis.tab_config[key]['data']['heading']];
                    ind_obj.series = [];
                    if (apidata['lines_data']) {
                      apidata['lines_data'].forEach(lineData => {
                        let val = lineData[inThis.tab_config[key]['data']['y_key']];
                        if (val) {
                          val = parseFloat(val);
                        } else {
                          val = 0;
                        }
                        ind_obj.series.push({
                          name: lineData[inThis.tab_config[key]['data']['x_key']],
                          value: val,
                        });
                      });
                    }
                    apivalue.push(ind_obj);
                  });
                  inThis.tab_config[key]['cumulative'] = true;
                  if (inThis.tab_config[key]['cumulative']) {
                    apivalue.forEach(obj => {
                      let prev_value = 0;
                      obj.series.forEach(indvalue => {
                        prev_value += indvalue.value;
                        indvalue.value = prev_value;
                      });
                    });
                  }
                } else if (inThis.tab_config[key]['view_mode'] === 'donut_graph') {
                  let apiDat = res.result.custom_analysis;
                  if (inThis.tab_config[key]['source_key']) {
                    apiDat = res.result.custom_analysis[0][inThis.tab_config[key]['source_key']];
                  } else if (res.result.custom_analysis && res.result.custom_analysis.length > 0) {
                    apiDat = res.result.custom_analysis;
                  }
                  if (apiDat) {
                    apiDat.forEach(apidata => {
                      let value = apidata['value'];
                      let name = apidata['name'];
                      if (inThis.tab_config[key]['data']['value_key']) {
                        value = apidata[inThis.tab_config[key]['data']['value_key']];
                      }
                      if (value) {
                        value = parseFloat(value);
                      } else {
                        value = 0;
                      }

                      if (inThis.tab_config[key]['data']['name_key']) {
                        name = apidata[inThis.tab_config[key]['data']['name_key']];
                      }
                      if (name) {
                        apivalue.push({
                          name,
                          value,
                          //tooltipText: inThis.formatNUmber(value, inThis.tab_config[key]['value_type'])
                        });
                      }
                    });
                  }
                }
              }
            } else {
              if (inThis.tab_config[key]['data'] && inThis.tab_config[key]['data'].length > 0) {
                inThis.tab_config[key]['data'].forEach(singledata => {
                  apivalue[singledata['key']] = 0;
                });
              }
            }
          });
          if (inThis.tab_config[key]['size']) {
            /* if (screen.width > 991) {
               rowspan = inThis.tab_config[key]['size']['h'];
               colspan = inThis.tab_config[key]['size']['w'];
             } else {
               rowspan = inThis.tab_config[key]['size']['w'];
               colspan = inThis.tab_config[key]['size']['h'];
             }*/
            rowspan = inThis.tab_config[key]['size']['h'];
            colspan = inThis.tab_config[key]['size']['w'];
          } else {
            rowspan = 1;
            colspan = 1;
          }
          if (inThis.tab_config[key]['pos']) {
            xIndex = inThis.tab_config[key]['pos']['x'];
            yindex = inThis.tab_config[key]['pos']['y'];
          } else {
          }
          dash.push({
            cols: colspan, rows: rowspan, y: yindex, x: xIndex, type: inThis.tab_config[key]['view_mode'],
            data: {
              label: inThis.tab_config[key]['label'],
              desc: inThis.tab_config[key]['desc'],
              url: inThis.tab_config[key]['value'],
              data: inThis.tab_config[key]['data'],
              x_label: inThis.tab_config[key]['x_label'],
              y_label: inThis.tab_config[key]['y_label'],
              value_type: inThis.tab_config[key]['value_type'],
              value_colour: inThis.tab_config[key]['value_type'],
              value: apivalue,
              height: inThis.height / (inThis.options.minRows / rowspan),
              width: inThis.width / (inThis.options.minCols / colspan),
              display_mode: inThis.tab_config[key]['display_mode'],
              orientation: inThis.tab_config[key]['orientation'],
              redirection: inThis.tab_config[key]['redirection'],
              icon
            }
          });
          /*xIndex += 1;
          if (xIndex % 4 === 0) {
            xIndex = 0;
            yindex += 1;
          }*/
          value += 10;
        }
      }
    }
    this.dashboard = dash;
    console.log(this.dashboard);
    console.log(this.activetab);
    this.dataLoaded = true;
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async loadDataFromApi() {
    const inThis = this;
    this.apiDataMap = {};
    this.apiLoaded = false;
    let counter = 0;
    this.progress = 0;
    const totalCount = inThis.apiList.size;
    for (const api of this.apiList) {
      const res = await this.apiService.postPromise('/api/mobile_reports/custom_analysis_pwa', {
        api_name: api,
        start_date: inThis.myDate[0],
        end_date: inThis.myDate[1],
        access_token: inThis.access_token
      });
      inThis.apiDataMap[api] = res;
      counter++;
      await inThis.timeout(50);
      this.progress = Math.round((counter / totalCount) * 100);
    }
    this.apiLoaded = true;
    return this.apiDataMap;
  }

  loadData() {
    forkJoin(this.apiList).subscribe(res => {
      console.log(res);
    });
  }

  changedOptions() {
    this.options.api.optionsChanged();
  }

  removeItem(item) {
    this.dashboard.splice(this.dashboard.indexOf(item), 1);
  }

  addItem() {
    const item: GridsterItem = {cols: 2, rows: 2, y: 0, x: 2};
    this.dashboard.push(item);
  }

  refreshBoard() {
    //this.configureDash();
    this.loadTabData(this.activetab);
  }


  setFilter(filteredValues) {

    this.filterData.region_id = 0;
    this.filterData.cluster_id = 0;
    this.filterData.team_id = 0;
    this.filterData.territory_id = 0;
    this.filterData.salesman_id = 0;
    if (filteredValues) {
      if (filteredValues.hasOwnProperty('region_id')) {
        this.filterData.region_id = parseInt(filteredValues.region_id, 10);
      }
      if (filteredValues.hasOwnProperty('cluster_id')) {
        this.filterData.cluster_id = parseInt(filteredValues.cluster_id, 10);
      }
      if (filteredValues.hasOwnProperty('team_id')) {
        this.filterData.team_id = parseInt(filteredValues.team_id, 10);
      }
      if (filteredValues.hasOwnProperty('territory_id')) {
        this.filterData.territory_id = parseInt(filteredValues.territory_id, 10);
      }
      if (filteredValues.hasOwnProperty('salesman_id')) {
        this.filterData.salesman_id = parseInt(filteredValues.salesman_id, 10);
      }
    }
    // this.dashboardFilterService.sendMessage(this.filterData);
    this.trigger.closeMenu();
  }

  changePeriod(value) {
    this.filterData.period = value;
    const period = this.jcData.find(x => x.name === value);
    this.myDate[0] = period.start_date;
    this.myDate[1] = period.end_date;
    //this.configureDash();
    this.loadTabData(this.activetab);
    // this.dashboardFilterService.sendMessage(this.filterData);
  }

  getCustomConfig() {

  }

  configurePeriodData() {
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    //this.myDate[0] = '2022-01-01';
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    //today
    this.jcData[0]['start_date'] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.jcData[0]['end_date'] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    //yesterday
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.jcData[1]['start_date'] = this.datePipe.transform(yesterday, 'yyyy-MM-dd');
    this.jcData[1]['end_date'] = this.datePipe.transform(new Date(yesterday), 'yyyy-MM-dd');

    //this week
    const thisweek = new Date();
    thisweek.setDate(thisweek.getDate() - thisweek.getDay() + (thisweek.getDay() === 0 ? -6 : 1));
    this.jcData[2]['start_date'] = this.datePipe.transform(thisweek, 'yyyy-MM-dd');
    this.jcData[2]['end_date'] = this.datePipe.transform(new Date(thisweek.setDate(thisweek.getDate() - thisweek.getDay() + 7)), 'yyyy-MM-dd');

    //last week
    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    this.jcData[3]['start_date'] = this.datePipe.transform(new Date(lastWeek.setDate(lastWeek.getDate() - lastWeek.getDay() + 1)), 'yyyy-MM-dd');
    this.jcData[3]['end_date'] = this.datePipe.transform(new Date(lastWeek.setDate(lastWeek.getDate() - lastWeek.getDay() + 7)), 'yyyy-MM-dd');

    //this month
    this.jcData[4]['start_date'] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.jcData[4]['end_date'] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    //last month
    this.jcData[5]['start_date'] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() - 1, 1), 'yyyy-MM-dd');
    this.jcData[5]['end_date'] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 0), 'yyyy-MM-dd');

    const quarter = Math.floor((date.getMonth() / 3));
    let startFullQuarter = new Date(date.getFullYear(), quarter * 3, 1);

    //this quarter
    this.jcData[6]['start_date'] = this.datePipe.transform(new Date(date.getFullYear(), quarter * 3, 1), 'yyyy-MM-dd');
    this.jcData[6]['end_date'] = this.datePipe.transform(new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0), 'yyyy-MM-dd');

    startFullQuarter = new Date(date.getFullYear(), quarter * 3 - 3, 1);

    //previous quarter
    this.jcData[7]['start_date'] = this.datePipe.transform(new Date(date.getFullYear(), quarter * 3 - 3, 1), 'yyyy-MM-dd');
    this.jcData[7]['end_date'] = this.datePipe.transform(new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0), 'yyyy-MM-dd');

    //this year
    this.jcData[8]['start_date'] = this.datePipe.transform(new Date(date.getFullYear(), 0, 1), 'yyyy-MM-dd');
    this.jcData[8]['end_date'] = this.datePipe.transform(new Date(date.getFullYear(), 11, 31), 'yyyy-MM-dd');

    //previous year
    this.jcData[9]['start_date'] = this.datePipe.transform(new Date(date.getFullYear() - 1, 0, 1), 'yyyy-MM-dd');
    this.jcData[9]['end_date'] = this.datePipe.transform(new Date(date.getFullYear() - 1, 11, 31), 'yyyy-MM-dd');

    this.myDate[0] = this.jcData[4]['start_date'];
    this.myDate[1] = this.jcData[4]['end_date'];
  }

  getDaysArray(start, end) {
    const arr = [];
    for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(this.datePipe.transform((new Date(dt)).toString(), 'yyyy-MM-dd'));
    }
    return arr;
  }

  formatNUmber(value, value_type) {
    if (value) {
      if (value_type && value_type === 'currency') {
        value = parseFloat(value);
        if (value >= 10000000) {
          return (value / 10000000).toFixed(2) + 'Cr';
        } else if (value >= 100000) {
          return (value / 100000).toFixed(2) + 'L';
        } else if (value >= 1000) {
          return (value / 1000).toFixed(2) + 'K';
        } else {
          return value;
        }
      } else {
        return value;
      }
    } else {
      return 0;
    }
  }

  redirectToUrl(item) {
    if (item?.data?.redirection) {
      localStorage.setItem('start_date', this.myDate[0]);
      localStorage.setItem('end_date', this.myDate[1]);
      this.router.navigate([this.apiService.redirect_url + item.data.redirection], {
        relativeTo: this.route
      }).then(data => {
        console.log('Route exists, redirection is done');
      })
        .catch(e => {
          this.toastr.error("Invalid Redirect URL Configuration");
        });
    }
  }
}
