<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>GRN Details</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div>
        <span class="detaillabel2">GRN</span>
        <span *ngIf="showApprove" class="requiredspan"> *</span>
        <div *ngIf="showApprove">
          <input
            class="forminputwhitebg"
            type="text"
            style="display: block; margin-top: 5px;width: 100%;"
            name="grn"
            [(ngModel)]="grn_no"
            #grn="ngModel"
            placeholder="Enter GRN"
            required
          >
          <div *ngIf="grn.errors && (grn.dirty || grn.touched)">
            <span *ngIf="grn.errors.required" class="text-danger">GRN is required</span>
          </div>
        </div>
        <p *ngIf="!showApprove" class="detailvalue1">{{grn_no}}</p>
      </div>
      <div style="margin-top: 25px;text-align: center;">
        <img *ngIf="photo" [src]="photo" style="width:70%;height:70%;">
        <img *ngIf="!photo" [src]="'/assets/no-image-icon.png'" style="width:180px;height:180px; ">
      </div>
    </div>
    <div *ngIf="showApprove" class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Close
      </button>
      <button
        style=" margin-right:15px;"
        class="btn confirm-blue-btn"
        (click)="verifyGRN()"
        [disabled]="!grn_no || !photo"
      >
        Verify
      </button>
    </div>
  </div>
</section>
