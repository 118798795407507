import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-outlet-stock-ageing-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class OutletStockAgeingReportComponent extends ReportBase implements IReport, OnInit {


  showCustomFilter = true;
  customFilter: any = {
    name: 'Partner Type',
    key: 'partner_type',
    value: '',
    values: [],
    show: true
  };
  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Outlet Stock Ageing Report');

    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.state = true;
    this.showableFilters.outlet_type = true;
    // this.showableFilters.cluster = true;
    // this.showableFilters.region = true;


    this.showProgress = true;
    this.showProgressCustomText = true;

    /*   this.userData.offsetID = 0;
       this.userData.offset = 5000;*/
    this.loadInBatches(5000, 'id');
    this.customFilter.values = [
      {id: "", itemName: "All"},
      {id: "supplier", itemName: "Supplier"},
      {id: "retailer", itemName: "Retailer"}
    ];

    this.API_URL = '/api/pwa_v2_reports/getOutletStockAgeing';
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureGrid() {

    this.columnDefs = [
      {headerName: 'AUDIT ID', field: 'audit_id',  width: 50, hide: true},
      {headerName: 'AUDIT NAME', field: 'audit_name',  width: 50, hide: true},
      {headerName: 'SESSION ID', field: 'session_id',  width: 50, hide: true},
      {headerName: 'LINE ID', field: 'id',  width: 50, hide: true},
      {headerName: 'REGION', field: 'region', width: 80},
      {headerName: 'CLUSTER', field: 'cluster', width: 80,  hide: true},
      {headerName: 'TERRITORY', field: 'territory', width: 80},
      {headerName: 'TEAM', field: 'team', width: 80},
      {headerName: 'SALESMAN', field: 'salesman',  width: 80},
      {headerName: 'CHAIN CODE', field: 'p_code',  width: 50, hide: true},
      {headerName: 'CHAIN NAME', field: 'p_name', resizable: true,  width: 80, hide: true},
      {headerName: 'OUTLET ID', field: 'retailer_id',  width: 50, hide: true},
      {headerName: 'OUTLET CODE', field: 'o_code',  width: 50, hide: true},
      {headerName: 'OUTLET', field: 'o_name',  width: 120},
      {headerName: 'STREET', field: 'street',  width: 120, hide: true},
      {headerName: 'CITY', field: 'city',  width: 120},
      {headerName: 'STATE', field: 'state',  width: 120, hide: true},
      {headerName: 'ZIP', field: 'zip',  width: 120, hide: true},
      {headerName: 'CHANNEL', field: 'channel',  width: 80, hide: true},
      {headerName: 'PROGRAM', field: 'program',  width: 80, hide: true},
      {headerName: 'CLASS', field: 'class',  width: 80, hide: true},
      {headerName: 'DATE', field: 'date',  width: 80},
      {headerName: 'CATEGORY', field: 'category', width: 80,  hide: true},
      {headerName: 'BRAND', field: 'brand', width: 80,  hide: true},
      {headerName: 'FAMILY', field: 'family', width: 80,  hide: true},
      {headerName: 'CODE', field: 'prd_code', width: 80},
      {headerName: 'PRODUCT', field: 'product', width: 120},
      {headerName: 'SCORE', field: 'score', width: 120,  hide: true},
      {headerName: 'EXPIRY DAYS', field: 'expiry_days', width: 120, hide: true},
      {headerName: 'LOD', field: 'lod', width: 50},
      {headerName: 'LOD %', field: 'lod_perc', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: this.avgAggFunction},
      {headerName: 'MBQ', field: 'mbq', width: 50, cellStyle: {textAlign: 'left'},
        valueGetter(params) {
          if (params.data) {
            if (params.data.mbq === 0) {
              return "NA";
            } else {
              return params.data.mbq;
            }
          }
        }},
      {headerName: 'LOW STOCK', field: 'low_stock', width: 50, cellStyle: {textAlign: 'left'}},
      {headerName: 'REPLENISH', field: 'replenish', width: 50, cellStyle: {textAlign: 'left'}},
      {
        headerName: 'Availability %', field: 'avail', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: this.avgAggFunction,
        valueGetter(params) {
          if (params.data) {
            if (params.data.avail === 100) {
              return 100;
            } else {
              return "Out of Stock";
            }
          }
        }
      },
      {headerName: 'FACING', field: 'f_qty', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: this.avgAggFunction},
      {headerName: 'OPENING', field: 'o_qty', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {headerName: 'INWARD', field: 'i_qty', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {
        headerName: 'OFFTAKE', field: 'o_qty', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data) {
            if (params.data.o_qty && params.data.c_qty) {
              const opening_stock = params.data.o_qty + params.data.i_qty;
              if (opening_stock > params.data.c_qty) {
                return opening_stock - params.data.c_qty;
              } else {
                return 0;
              }

            } else {
              return 0;
            }
          }
        }
      },
      {headerName: 'CLOSING', field: 'c_qty', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {headerName: 'EXPIRED', field: 'already_expired', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {headerName: 'WITHIN 15 DAYS', field: 'below_15_days', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {headerName: '15 - 30 DAYS', field: 'days_15_30', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {headerName: '31 - 60 DAYS', field: 'days_31_60', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'},
      {headerName: 'AFTER 60 DAYS', field: 'above_60_days', width: 50, cellStyle: {textAlign: 'left'}, aggFunc: 'sum'}
    ];
  }

  configureData() {
    this.rowData = [];
    this.apiData.forEach(obj => {
      if (!obj.expiry_days) {
        obj.expiry_days = 0;
      }
      obj.already_expired = 0;
      obj.below_15_days = 0;
      obj.days_15_30 = 0;
      obj.days_31_60 = 0;
      obj.above_60_days = 0;
      if (obj.batch_json && obj.batch_json.length > 0) {
        for (let i = 0; i < obj.batch_json.length; i++) {
          const batch_obj = obj.batch_json[i];
          // console.log(batch_obj);
          const batch_expiry_date = new Date(batch_obj.batch_date);
          batch_expiry_date.setDate(batch_expiry_date.getDate() + obj.expiry_days);
          console.log(batch_expiry_date);
          console.log(obj.expiry_days);
          const days_to_expiry = Math.round((batch_expiry_date.getTime() - new Date().getTime()) / (1000 * 3600 * 24));
          // console.log(days_to_expiry);
          if (days_to_expiry <= 0) {
            obj.already_expired += batch_obj.qty;
          } else if (days_to_expiry > 0 && days_to_expiry <= 15) {
            obj.below_15_days += batch_obj.qty;
          } else if (days_to_expiry > 15 && days_to_expiry <= 30) {
            obj.days_15_30 += batch_obj.qty;
          } else if (days_to_expiry > 30 && days_to_expiry <= 60) {
            obj.days_31_60 += batch_obj.qty;
          } else if (days_to_expiry > 60) {
            obj.above_60_days += batch_obj.qty;
          }
        }
      }
      if (obj.lod && obj.lod_json) {
        let lodJson = [];
        if (obj.lod_json.constructor !== Object) {
          try {
            lodJson = JSON.parse(obj.lod_json);
          } catch (e) {
          }
        } else {
          lodJson = obj.lod_json;
        }
        if (lodJson && lodJson.length > 0) {
          let lodArray = [];
          Object.keys(lodJson[0]).forEach(key => {
            lodArray.push({
              name: key,
              val: lodJson[0][key]
            });
          });
          lodArray = lodArray.sort((a, b) => a.val - b.val);
          if (lodJson[0][obj['lod']]) {
            obj['lod_perc'] = (lodJson[0][obj['lod']] / lodArray[lodArray.length - 1].val) * 100;
          } else {
            obj['lod_perc'] = 0;
          }
        } else {
          obj['lod_perc'] = 0;
        }
      } else {
        obj['lod_perc'] = 0;
      }
      this.rowData.push(obj);
    });
  }
}
