import {PageBase} from "../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {ErrortooltipComponent} from "../../errortooltip";


@Component({
  selector: 'app-meet-list',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})

export class DSRListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Filters',
    key: 'status',
    value: 'completed',
    values: [],
    show: true
  };

  unique_partner_types = [];
  unique_verticals = [];

  matActionMenuItemDeclaration = []; //, feature: 'action_invoice_create'

  ngOnInit(): void {
    this.API_URL = '/api/pwa_manager_connects/getDailySalesReview';

    this.sharedService.setHeader('Daily Sales Review (Beta)');
    this.showUpload = false;
    this.showAnalysis = true;
    this.analysisGridRows = 1;

    this.MapViewMetaData = {
      id: 'id',
      title: 'name',
      latitude: 'lat',
      longitude: 'lng',
      color: '',
      field1: 'salesman',
      field2: 'type'
    };

    this.checkAndLoadPreviousSate();

    this.showCustomFilter = false;
    this.showActionButton = true;

    this.showDate = true;

    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.region = true;
    this.showableFilters.cluster = true;
    this.dateSelectMode = 'single';
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');


    this.showViewSelector = true;
    this.showMapView = true;

    this.gridOptions.getRowStyle = this.getRowStyleFunc;
    this.frameworkComponents = {
      errortooltipComponent: ErrortooltipComponent
    };

    this.gridOptions.isRowSelectable = params => {
      if (params.data) {
        if (params.data.state === 'cancel') {
          return false;
        } else {
          return true;
        }
      }
    };
    this.getAuthorizedActions();
    this.checkAccessControl();
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  getRowStyleFunc(params) {
    if (params.node.rowPinned === 'bottom') {
      return {'font-weight': 'bold'};
    }
  }

  configureGrid() {
    const inThis = this;

    const OrderStatusCellClassRules = {
      'rag-green-outer': (params) => params.value === 'success',
      'rag-amber-outer': (params) => params.value === 'deviation',
      'rag-red-outer': (params) => params.value === 'alert',
    };

    this.columnDefs = [
      {
        headerName: "",
        children: [
          {headerName: 'Salesman', field: 'user_name', width: 100},
          {
            headerName: "Status", field: "status", width: 80, filter: 'agTextColumnFilter',
            cellClassRules: OrderStatusCellClassRules,
            cellRenderer(params) {
              let displayElem = '';
              if (params.data) {
                if (params.value === 'Success') {
                  displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
                } else if (params.value === 'Absent') {
                  displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
                } else if (params.value === 'PJP Not Planned') {
                  displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #fa8e1b;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
                } else {
                  if (params.value) {
                    displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #fa8e1b;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
                  }
                }
                return displayElem;
              }
            }
          },
          {headerName: 'Emp Code', field: 'emp_code', width: 80, hide: true},
          {headerName: 'Mo', field: 'mobile', width: 80},
          {headerName: 'State', field: 'state', width: 80, hide: true},
          {headerName: 'Region', field: 'region', width: 80, hide: true},
          {headerName: 'Date', field: 'date', width: 80, hide: true},
          {headerName: 'Role', field: 'role', width: 80, hide: true},
          {headerName: 'SAC', field: 'sac', width: 80, hide: true},
          {headerName: 'Email', field: 'user_email', width: 80, hide: true},
          {headerName: 'Team', field: 'team', width: 80, hide: true},
          {headerName: 'Team Lead', field: 'tl_name', width: 80, hide: true},
          {headerName: 'Team Lead Code', field: 'tl_code', width: 80, hide: true},


        ]
      },
      {
        headerName: "KPIS",
        children: [
          {headerName: 'Agenda', field: 'agenda', width: 40},
          {
            headerName: 'Scheduled  Beat', field: 'beat', width: 80,
            cellStyle: params => {
              if (params.data['status'] === 'PJP Not Planned') {
                return {backgroundColor: '#FBE3E7'};
              }
            }
          },
          {
            headerName: 'Attend', field: 'check_in', width: 40, valueGetter: params => this.formatTime(params?.data?.check_in),
            cellStyle: params => {
              if (params.data['status'] === 'Absent') {
                return {backgroundColor: '#FBE3E7'};
              }
            }
          },
          {
            headerName: 'EOD', field: 'check_out', width: 40, valueGetter: params => this.formatTime(params?.data?.check_out),
            cellStyle: params => {
              if (params.data['status'] === 'EOD Not Marked') {
                return {backgroundColor: '#FBE3E7'};
              }
            }
          },
          {headerName: "First Act", field: "fat", width: 40, hide: true},
          {headerName: "Last Act", field: "lat", width: 40, hide: true},
          {headerName: "Work Mins", field: "work_mins", width: 40,  filter: "agNumberColumnFilter"},
          {headerName: 'TC', field: 'tc', width: 40, aggFunc: 'sum'},
          {
            headerName: "VC", field: "vc", width: 40, aggFunc: 'sum', filter: "agNumberColumnFilter",
            cellStyle: params => {
              if (params.data['status'] === 'Days Target Not Met') {
                return {backgroundColor: '#FCEFDE', color: '#E89221', fontWeight: 600};
              }
            }
          },
          {headerName: "PC", field: "pc", width: 40, aggFunc: 'sum', filter: "agNumberColumnFilter"},
          {headerName: 'Day Target', field: 'day_target', width: 40, aggFunc: 'sum'},
          {
            headerName: 'Day Ach', field: 'day_ach', width: 40, aggFunc: 'sum',
            cellStyle: params => {
              if (params.data['status'] === 'Days Target Not Met') {
                return {backgroundColor: '#FCEFDE', color: '#E89221', fontWeight: 600};
              }
            }
          },
          {headerName: "Beat Jump VC", field: "beat_jump_vc", width: 40, hide: true},
          {headerName: "Key Outlet", field: "ko_vc", width: 40, hide: true},
          {headerName: "Beat Jump PC", field: "beat_jump_pc", width: 40, hide: true},
          {headerName: "Key Outlet PC", field: "ko_pc", width: 40, hide: true},
          {
            headerName: "Coverage%", width: 40, hide: true,
            valueGetter: params => {
              if (params.data) {
                if (params.data.tc !== 0) {
                  return ((params.data.visited_count / params.data.tc) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {
            headerName: "PC%", width: 40, hide: true,
            valueGetter: params => {
              if (params.data) {
                if (params.data.visited_count !== 0) {
                  return ((params.data.productive_calls / params.data.visited_count) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'Total Lines', field: 'line', width: 40, hide: true},
          {headerName: 'Avg Lines', field: 'avg_line', width: 40, aggFunc: 'sum'},
          {headerName: 'Avg Value', field: 'avg_amount', width: 40, aggFunc: 'sum',  filter: "agNumberColumnFilter"},
        ]
      },
      {
        headerName: "Sales Details",
        children: []
      },
      {
        headerName: "Secondary Sales",
        hide: true,
        children: [
          {headerName: 'Target', field: 'sec_target', width: 40},
          {headerName: 'Achievement ', field: 'sec_ach', width: 40},
          {
            headerName: 'Ach%', width: 40,
            valueGetter: params => {
              if (params.data) {
                if (params.data.sec_ach > 0 && params.data.sec_target > 0) {
                  return ((params.data.sec_ach / params.data.sec_target) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'Cur Run Rate', field: 'sec_csr', width: 40, hide: true},
          {headerName: 'Req Run Rate', field: 'sec_rrr', width: 40, hide: true},
        ]
      },
      {
        headerName: "Primary Sales",
        hide: true,
        children: [
          {headerName: 'Target', field: 'pri_target', width: 40, hide: true},
          {headerName: 'Achievement ', field: 'pri_ach', width: 40, hide: true},
          {
            headerName: 'Ach%', width: 40, hide: true,
            valueGetter: params => {
              if (params.data) {
                if (params.data.sec_target !== 0) {
                  return ((params.data.pri_ach / params.data.pri_target) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          }
        ]
      },
      {
        headerName: "Partner Visits",
        children: []
      },
      {
        headerName: "New Business",
        children: [
          {headerName: 'Created', field: 'new_outlets', width: 40},
          {headerName: 'Productive', field: 'new_pc', width: 40},
          {headerName: 'Sales', field: 'new_sales', width: 40}
        ]
      },
      {
        headerName: "Vertical",
        hide: true,
        children: []
      },
    ];

    this.unique_partner_types.forEach(type => {
      this.columnDefs[5].children.push(
        {headerName: type, field: type, menuTabs: [], width: 30, cellStyle: {textAlign: 'center'}},
      );
    });

    this.unique_verticals.forEach(vertical => {
      this.columnDefs[7].children.push(
        {headerName: vertical, field: vertical + '_qty', menuTabs: [], width: 30, cellStyle: {textAlign: 'center'}},
      );
    });

    this.getRowId = (params) => params.data.user_id;
  }


  configureData() {
    this.rowData = [];
    this.analysisData = [
      {
        cols: 2,
        rows: 1,
        y: 0,
        x: 0,
        type: "single",
        selected: false,
        filter: true,
        data: {
          label: "Absent Users",
          value_type: "sale",
          value_colour: "sale",
          value: {
            value: 0
          },
          icon: ""
        }
      },
      {
        cols: 2,
        rows: 1,
        y: 0,
        x: 2,
        type: "single",
        selected: false,
        filter: true,
        data: {
          label: "PJP Deviation",
          value_type: "sale",
          value_colour: "sale",
          value: {
            value: 0
          },
          icon: ""
        }
      },
      {
        cols: 2,
        rows: 1,
        y: 0,
        x: 4,
        type: "single",
        selected: false,
        filter: true,
        data: {
          label: "Low Visits",
          value_type: "sale",
          value_colour: "sale",
          value: {
            value: 0
          },
          icon: ""
        }
      },
      {
        cols: 2,
        rows: 1,
        y: 0,
        x: 6,
        type: "single",
        selected: false,
        filter: true,
        data: {
          label: "Low Productivity",
          value_type: "sale",
          value_colour: "sale",
          value: {
            value: 0
          },
          icon: ""
        }
      },
      {
        cols: 2,
        rows: 1,
        y: 0,
        x: 8,
        type: "single",
        selected: false,
        filter: true,
        data: {
          label: "Low Sales",
          value_type: "sale",
          value_colour: "sale",
          value: {
            value: 0
          },
          icon: ""
        }
      },
      {
        cols: 2,
        rows: 1,
        y: 0,
        x: 10,
        type: "single",
        selected: false,
        filter: true,
        data: {
          label: "Low Work Minutes",
          value_type: "sale",
          value_colour: "sale",
          value: {
            value: 0
          },
          icon: ""
        }
      }
    ];
    this.apiData.forEach(dr => {

      dr['supplier'] = this.getPartnerVisitCount('supplier', dr.visits);
      dr['project'] = this.getPartnerVisitCount('project', dr.visits);
      dr['lead'] = this.getPartnerVisitCount('lead', dr.visits);
      dr['influencer'] = this.getPartnerVisitCount('influencer', dr.visits);

      this.setVerticalBusiness(dr);

      this.setDayStatus(dr);
      this.setAnalysisData(dr);
      this.rowData.push(dr);
    });
  }

  setVerticalBusiness(row) {
    if (row.sales !== undefined && row.sales !== null) {
      const user_verticals = [...new Set(row.sales.map(obj => obj.v))];

      //Create unique vertical global array
      user_verticals.forEach(uv => {
        if (!this.unique_verticals.find(i => i === uv)) {
          this.unique_verticals.push(uv);
        }
      });

      if (user_verticals && user_verticals.length > 0) {
        user_verticals.forEach(vertical => {
          row[vertical + '_qty'] = 0;
          const vertical_array = row.sales.filter(i => i.v === vertical);
          vertical_array.forEach(data => {
            row[vertical + '_qty'] = row[vertical + '_qty'] + data.qty;
          });
        });
      }
    }
  }

  getPartnerVisitCount(partner_type, visits_data) {
    let visitscount = 0;
    if (visits_data && visits_data.length > 0) {
      const visit_array = visits_data.filter(i => i.partner_type === partner_type);
      visitscount = [...new Set(visit_array.map((item) => item.partner_id))].length;
      if (visitscount > 0 && !this.unique_partner_types.includes(partner_type)) {
        this.unique_partner_types.push(partner_type);
      }
    }
    return visitscount;
  }

  async analysisGridFilter(item) {
    const status_filterComponent = await this.gridApi.getFilterInstance("status");
    if (item.data.label === 'Absent Users') {
      if (item.selected) {
        this.agTextFilter('status', this.agTextFilterTypes.equals, 'Absent');
      } else {
        this.resetModelFilter(status_filterComponent);
      }
    } else if (item.data.label === 'PJP Deviation') {
      const beat_filterComponent = await this.gridApi.getFilterInstance("beat");
      if (item.selected) {
        this.setNullModelFilter('beat');
      } else {
        this.resetModelFilter(beat_filterComponent);
      }
    } else if (item.data.label === 'Low Visits') {
      const vc_filterComponent = await this.gridApi.getFilterInstance("vc");
      if (item.selected) {
        this.agTextFilter('status', this.agTextFilterTypes.notEqual, 'Absent');
        this.agNumberFilter('vc', this.agNumberFilterTypes.lessThan, 10);
      } else {
        this.resetModelFilter(vc_filterComponent);
        this.resetModelFilter(status_filterComponent);
      }
    } else if (item.data.label === 'Low Productivity') {
      const vc_filterComponent = await this.gridApi.getFilterInstance("pc");
      if (item.selected) {
        this.agTextFilter('status', this.agTextFilterTypes.notEqual, 'Absent');
        this.agNumberFilter('pc', this.agNumberFilterTypes.lessThan, 5);
      } else {
        this.resetModelFilter(vc_filterComponent);
        this.resetModelFilter(status_filterComponent);
      }
    } else if (item.data.label === 'Low Sales') {
      const vc_filterComponent = await this.gridApi.getFilterInstance("avg_amount");
      if (item.selected) {
        this.agTextFilter('status', this.agTextFilterTypes.notEqual, 'Absent');
        this.agNumberFilter('avg_amount', this.agNumberFilterTypes.lessThan, 1000);
      } else {
        this.resetModelFilter(vc_filterComponent);
        this.resetModelFilter(status_filterComponent);
      }
    } else if (item.data.label === 'Low Work Minutes') {
      const vc_filterComponent = await this.gridApi.getFilterInstance("work_mins");
      if (item.selected) {
        this.agTextFilter('status', this.agTextFilterTypes.notEqual, 'Absent');
        this.agNumberFilter('work_mins', this.agNumberFilterTypes.lessThan, 60);
      } else {
        this.resetModelFilter(vc_filterComponent);
        this.resetModelFilter(status_filterComponent);
      }
    }
  }

  setAnalysisData(day) {
    if (!day.hasOwnProperty('check_in') || day.check_in === null || day.check_in.length === 0) {
      this.analysisData[0].data.value.value++;
    } else {
      if (!day.hasOwnProperty('beat') || day.beat === null || day.beat.length === 0) {
        this.analysisData[1].data.value.value++;
      }
      if (!day.vc) {
        day.vc = 0;
      }
      if (!day.pc) {
        day.pc = 0;
      }
      if (!day.avg_amount) {
        day.avg_amount = 0;
      }
      if (!day.work_mins) {
        day.work_mins = 0;
      }
      if (day.vc < 10) {
        this.analysisData[2].data.value.value++;
      }
      if (day.pc < 5) {
        this.analysisData[3].data.value.value++;
      }
      if (parseFloat(day.avg_amount) < 1000) {
        this.analysisData[4].data.value.value++;
      }
      if (parseFloat(day.work_mins) <= 60) {
        this.analysisData[5].data.value.value++;
      }
    }
  }

  setDayStatus(day) {
    if (!day.hasOwnProperty('check_in') || day.check_in === null || day.check_in.length === 0) {
      day['status'] = 'Absent';
      //TODO:leave
    } else if (!day.hasOwnProperty('beat') || day.beat === null || day.beat.length === 0) {
      day['status'] = 'PJP Not Planned';
    } else {

      if (day.day_target && day.day_ach > 0 && (day.day_ach * 100 / day.day_target) < 80) {
        day['status'] = 'Days Target Not Met';
      } else if (day.vc && day.pc > 0 && day.pc < day.vc / 2) {
        day['status'] = 'PC Norm Not Met';
      } else if (day.vc && day.pc > 0 && day.pc < day.vc / 2) {
        day['status'] = 'PC Norm Not Met';
      } else if (!day.hasOwnProperty('check_in') || day.check_in === null || day.check_in.length === 0) {
        day['status'] = 'EOD Not marked';
      } else {
        day['status'] = 'Success';
      }

    }
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;

    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      this.router.navigate([this.apiService.redirect_url + '/sfa/dsr_detail'], {
        relativeTo: this.route,
        queryParams: {id: rows[0].id}
      });
    }
  }

  checkAccessControl() {
    this.showCreate = true;
  }


  onMenuItemSelected(menuitem) {

  }

  configValueGetter(field) {
    return (params) => {
      if (params.data && params.data.config_json) {
        try {
          let config_json;
          if (params.data.config_json.constructor !== Object) {
            config_json = JSON.parse(params.data.config_json);
          } else {
            config_json = params.data.config_json;
          }
          if (config_json && config_json.hasOwnProperty(field)) {
            return config_json[field];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setColumnDefs(this.columnDefs);
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  onFilterChanged($event) {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }
}
