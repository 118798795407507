<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div *ngIf="!tabList || tabList.length === 0">
        <app-dashboard-empty></app-dashboard-empty>
      </div>
      <div class="col-md-12 pad0" *ngIf="tabList && tabList.length > 0">
        <div class="col-md-12 pad0">
          <div class="search-list row" style="display: block; margin-bottom: 15px;">
            <div class="col-md-5 pad0">
              <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
                <ng-container *ngFor="let tab of tabList; let i = index">
                  <mat-tab [disabled]="!dataLoaded" label="{{tab.name}}">
                  </mat-tab>
                </ng-container>
              </mat-tab-group>
            </div>
            <div class="col-md-7 pad0">
              <div class="filters">
                <ul class="list-unstyled">
                  <li style="margin-right: 5px !important;margin-left: 5px !important;" class="margLR">
                    <div class="periodselectwhite">
                      <select
                        name="period"
                        (change)="changePeriod($event.target['value'])"
                        [(ngModel)]="this.filterData.period"
                      >
                        <option
                          *ngFor="let item of jcData"
                          value="{{ item.name }}"
                        > {{ item.name }}</option>
                      </select>
                    </div>
                    <div>
                    </div>
                  </li>
                  <!-- <li style="margin-right: 5px !important;" class="margLR">
                     <div style="background-color: white;width:45px;height:45px;display: flex;align-items: center; justify-content: center;cursor:pointer;">
                       <span title="Add Filter">
                       <img
                         style="height: 20px;"
                         src="../../assets/filter.svg"
                         [matMenuTriggerFor]="filterdropdown"
                       >
                     </span>
                       <mat-menu #filterdropdown="matMenu">
                         <app-components-filters [matMenuTriggerFor]="filterdropdown"
                                                 [showableFilters]="showableFilters"
                                                 (onsetFilter)="setFilter($event)">
                         </app-components-filters>
                       </mat-menu>
                     </div>
                   </li>-->
                  <li class="margLR">
                    <div style="background-color: white;width:45px;height:45px;display: flex;
    align-items: center; justify-content: center;cursor:pointer; "
                    >
                     <span (click)="refreshBoard()" style="cursor: pointer;">
                  <i class="fad fa-sync-alt fa-2x mt-2"></i>
                </span>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!apiLoaded">
          <div
            style=" text-align: center;display: flex;align-items: center;justify-content: center; margin-top: 350px;">
            <mat-progress-bar style="width: 200px;" [mode]="progressMode" [value]="progress"></mat-progress-bar>
            <span style="margin-left: 5px;">{{progress}}%</span>
          </div>
          <span style="margin-left: 5px; text-align:center; display:block !important">{{progressText}}</span>
        </div>
        <div *ngIf="apiLoaded" id="gridelement" class="col-md-12 pad0 content-wrap" style="height:calc(100vh - 160px);">
          <gridster [options]="options" class="info-boxes">
            <gridster-item [item]="item" *ngFor="let item of dashboard"
                           style="border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;" (click)="redirectToUrl(item)">
              <!-- your content here -->
              <app-widget-statistics
                *ngIf="item.type === 'statistics'"
                [widget]="item"
                [resizeEvent]="resizeEvent"
              ></app-widget-statistics>
              <app-widget-link
                *ngIf="item.type === 'link'"
                [widget]="item"
                [resizeEvent]="resizeEvent"
              ></app-widget-link>
              <app-widget-single
                *ngIf="item.type === 'single'"
                [widget]="item"
                [resizeEvent]="resizeEvent"
              ></app-widget-single>
              <app-widget-progressbar
                *ngIf="item.type === 'progressbar'"
                [widget]="item"
                [resizeEvent]="resizeEvent"
              ></app-widget-progressbar>
              <app-widget-grid
                *ngIf="item.type === 'grid'"
                [widget]="item"
                [resizeEvent]="resizeEvent"
              ></app-widget-grid>
              <app-widget-multigrid
                *ngIf="item.type === 'multigrid'"
                [widget]="item"
                [resizeEvent]="resizeEvent"
              ></app-widget-multigrid>
              <app-widget-donut
                *ngIf="item.type === 'donut_graph'"
                [widget]="item"
                [resizeEvent]="resizeEvent"
              ></app-widget-donut>
              <app-widget-multidonut
                *ngIf="item.type === 'multi_donut_graph'"
                [widget]="item"
                [resizeEvent]="resizeEvent"
              ></app-widget-multidonut>
              <app-widget-bar-graph
                *ngIf="item.type === 'bar_graph'"
                [widget]="item"
                [resizeEvent]="resizeEvent"
              ></app-widget-bar-graph>
              <app-widget-line-chart
                *ngIf="item.type === 'line_chart'"
                [widget]="item"
                [resizeEvent]="resizeEvent"
              ></app-widget-line-chart>
              <app-widget-table
                *ngIf="item.type === 'table'"
                [widget]="item"
                [resizeEvent]="resizeEvent"
              ></app-widget-table>
            </gridster-item>
          </gridster>
        </div>
      </div>
    </div>
  </div>
</div>
