import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
    selector: 'app-volume-distribution-component',
    templateUrl: '../_common/reportbase.html',
    styleUrls: ['../_common/reportbase.scss']
})

export class DailyUserPeriodicActivityComponent extends ReportBase implements IReport, OnInit {
    apiData: any = [];
    timeslots: any = [];

    setTitle() {
        throw new Error('Method not implemented.');
    }

    ngOnInit() {
        this.showDate = true;
        this.sharedService.setHeader('Daily User Periodic Activity');
        this.API_URL = '/api/pwa_v2_reports/getDailyUserPeriodicActivity';
        this.showableFilters.salesman = true;
        this.showableFilters.team = true;
        this.showableFilters.region = true;
        this.showableFilters.state = true;
        this.dateSelectMode = 'single';
        this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        this.configureGrid();
    }

    postProcessData() {
        this.configureData();
    }


    configureGrid() {
        const inThis = this;

        this.columnDefs = [

            {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
            {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
            {headerName: 'TEAM', field: 'team', enableRowGroup: true, width: 100},
            {headerName: 'TEAM LEAD', field: 'tl_name', enableRowGroup: true, width: 100},
            {headerName: 'ROLE', field: 'user_role', enableRowGroup: true, width: 100},
            {headerName: 'EMP NAME', field: 'user_name', enableRowGroup: true, width: 100},
            {headerName: 'EMP CODE', field: 'emp_code', width: 100},
            {headerName: 'TERRITORY', field: 'territory', width: 100},
            {headerName: 'BEAT ID', field: 'beat_id', width: 100},
            {headerName: 'BEAT NAME', field: 'beat', width: 100},
            {headerName: 'TARGET', field: 'schedule_target', width: 100},
            {headerName: 'ACH', field: 'beat_revenue', width: 100},

            {
                headerName: "TOTAL",
                children: [
                    {headerName: 'TC', field: 'retailer_count', width: 80},
                    {headerName: "VC", field: "vc", width: 100},
                    {headerName: "PC", field: "pc", width: 100},
                    {headerName: 'UNIQUE VC', field: 'visited_count', width: 80},
                    {headerName: 'UNIQUE PC', field: 'productive_calls', width: 80},
                    {
                        headerName: 'View Details', width: 80,
                        valueGetter(params) {
                            if (params.data) {
                                return 'View';
                            } else {
                                return '';
                            }
                        }, cellRenderer: this.sharedService.statusCellRenderer
                    }
                ]
            },

            {
              headerName: "7AM-9AM",
              children: [
                {headerName: "VC", field: "79_vc",  width: 100},
                {headerName: "PC", field: "79_pc",  width: 100},
                {headerName: 'UNIQUE VC', field: '79_uvc',  width: 80},
                {headerName: 'UNIQUE PC', field: '79_upc',  width: 80},
                {
                  headerName: 'Details(7AM-9AM)', width: 80,
                  valueGetter(params) {
                    if (params.data) {
                      return 'View';
                    } else {
                      return '';
                    }
                  }, cellRenderer: this.sharedService.statusCellRenderer
                }
              ]
            },
          {
            headerName: "9AM -11AM",
            children: [
              {headerName: "VC", field: "911_vc",  width: 100},
              {headerName: "PC", field: "911_pc",  width: 100},
              {headerName: 'UNIQUE VC', field: '911_uvc',  width: 80},
              {headerName: 'UNIQUE PC', field: '911_upc',  width: 80},
              {
                headerName: 'Details(9AM-11AM)', width: 80,
                valueGetter(params) {
                  if (params.data) {
                    return 'View';
                  } else {
                    return '';
                  }
                }, cellRenderer: this.sharedService.statusCellRenderer
              }
            ]
          },
          {
            headerName: "11AM -1PM",
            children: [
              {headerName: "VC", field: "1113_vc",  width: 100},
              {headerName: "PC", field: "1113_pc",  width: 100},
              {headerName: 'UNIQUE VC', field: '1113_uvc',  width: 80},
              {headerName: 'UNIQUE PC', field: '1113_upc',  width: 80},
              {
                headerName: 'Details(11AM-1PM)', width: 80,
                valueGetter(params) {
                  if (params.data) {
                    return 'View';
                  } else {
                    return '';
                  }
                }, cellRenderer: this.sharedService.statusCellRenderer
              }
            ]
          },
            {
                headerName: "1PM - 3PM",
                children: [
                    {headerName: "VC", field: "1315_vc", width: 100},
                    {headerName: "PC", field: "1315_pc", width: 100},
                    {headerName: 'UNIQUE VC', field: '1315_uvc', width: 80},
                    {headerName: 'UNIQUE PC', field: '1315_upc', width: 80},
                    {
                        headerName: 'Details(1PM-3PM)', width: 80,
                        valueGetter(params) {
                            if (params.data) {
                                return 'View';
                            } else {
                                return '';
                            }
                        }, cellRenderer: this.sharedService.statusCellRenderer
                    }
                ]
            },
          {
            headerName: "3PM - 5PM",
            children: [
              {headerName: "VC", field: "1517_vc", width: 100},
              {headerName: "PC", field: "1517_pc", width: 100},
              {headerName: 'UNIQUE VC', field: '1517_uvc', width: 80},
              {headerName: 'UNIQUE PC', field: '1517_upc', width: 80},
              {
                headerName: 'Details(3PM-5PM)', width: 80,
                valueGetter(params) {
                  if (params.data) {
                    return 'View';
                  } else {
                    return '';
                  }
                }, cellRenderer: this.sharedService.statusCellRenderer
              }
            ]
          },
          {
            headerName: "5PM - 7PM",
            children: [
              {headerName: "VC", field: "1719_vc", width: 100},
              {headerName: "PC", field: "1719_pc", width: 100},
              {headerName: 'UNIQUE VC', field: '1719_uvc', width: 80},
              {headerName: 'UNIQUE PC', field: '1719_upc', width: 80},
              {
                headerName: 'Details(5PM-7PM)', width: 80,
                valueGetter(params) {
                  if (params.data) {
                    return 'View';
                  } else {
                    return '';
                  }
                }, cellRenderer: this.sharedService.statusCellRenderer
              }
            ]
          },
          {
            headerName: "7PM - 9PM",
            children: [
              {headerName: "VC", field: "1921_vc", width: 100},
              {headerName: "PC", field: "1921_pc", width: 100},
              {headerName: 'UNIQUE VC', field: '1921_uvc', width: 80},
              {headerName: 'UNIQUE PC', field: '1921_upc', width: 80},
              {
                headerName: 'Details(7PM-9PM)', width: 80,
                valueGetter(params) {
                  if (params.data) {
                    return 'View';
                  } else {
                    return '';
                  }
                }, cellRenderer: this.sharedService.statusCellRenderer
              }
            ]
          }
        ];

        for (const key in this.timeslots) {
            this.columnDefs.push({
                headerName: key, field: key, resizable: true, filter: true, lockPosition: true, sort: true
            });
        }

    }

    configureData() {
        this.rowData = [];
        const self = this;
        const d = new Date();
        for (const key in this.apiData.main) {
            let indRow = {};
            let masterRow: any = {};
            let activityRows: any = [];

            masterRow = this.apiData.main[key];
            activityRows = this.apiData.activity.filter(act => act.salesman_id === masterRow.user_id);
            masterRow['pc'] = 0;
            masterRow['vc'] = 0;

            // 7 to 9
            const retailerVCSet_79 = new Set();
            const retailerPCSet_79 = new Set();

            // 9 to 11
            const retailerVCSet_911 = new Set();
            const retailerPCSet_911 = new Set();

            // 11 to 13
            const retailerVCSet_1113 = new Set();
            const retailerPCSet_1113 = new Set();

            // 13 to 15
            const retailerVCSet_1315 = new Set();
            const retailerPCSet_1315 = new Set();

            // 15 to 17
            const retailerVCSet_1517 = new Set();
            const retailerPCSet_1517 = new Set();

            // 17 to 19
            const retailerVCSet_1719 = new Set();
            const retailerPCSet_1719 = new Set();

            // 19 to 21
            const retailerVCSet_1921 = new Set();
            const retailerPCSet_1921 = new Set();

            for (const key1 in activityRows) {
                if (activityRows[key1].activity_time) {
                    const activity_hr = parseInt(this.datePipe.transform((new Date(activityRows[key1].activity_time + ' UTC')).toString(), 'HH'), 10);
                    console.log(activity_hr);

                    if (activityRows[key1].activity_type === 'Order') {
                      masterRow['pc'] ++;
                    }
                    if (activity_hr >= 7 && activity_hr < 9) {
                      if (activityRows[key1].activity_type === 'Order') {
                        retailerPCSet_79.add(activityRows[key1].retailer_id);
                        if (masterRow['79_pc']) {
                          masterRow['79_pc']++;
                        } else {
                          masterRow['79_pc'] = 1;
                        }
                      }
                      retailerVCSet_79.add(activityRows[key1].retailer_id);
                      if (masterRow['79_vc']) {
                        masterRow['79_vc']++;
                      } else {
                        masterRow['79_vc'] = 1;
                      }
                    }
                    if (activity_hr >= 9 && activity_hr < 11) {
                      if (activityRows[key1].activity_type === 'Order') {
                        retailerPCSet_911.add(activityRows[key1].retailer_id);
                        if (masterRow['911_pc']) {
                          masterRow['911_pc']++;
                        } else {
                          masterRow['911_pc'] = 1;
                        }
                      }
                      retailerVCSet_911.add(activityRows[key1].retailer_id);
                      if (masterRow['911_vc']) {
                        masterRow['911_vc']++;
                      } else {
                        masterRow['911_vc'] = 1;
                      }
                    }
                    if (activity_hr >= 11 && activity_hr < 13) {
                      if (activityRows[key1].activity_type === 'Order') {
                        retailerPCSet_1113.add(activityRows[key1].retailer_id);
                        if (masterRow['1113_pc']) {
                          masterRow['1113_pc']++;
                        } else {
                          masterRow['1113_pc'] = 1;
                        }
                      }
                      retailerVCSet_1113.add(activityRows[key1].retailer_id);
                      if (masterRow['1113_vc']) {
                        masterRow['1113_vc']++;
                      } else {
                        masterRow['1113_vc'] = 1;
                      }
                    }
                    if (activity_hr >= 13 && activity_hr < 15) {
                          if (activityRows[key1].activity_type === 'Order') {
                              retailerPCSet_1315.add(activityRows[key1].retailer_id);
                              if (masterRow['1315_pc']) {
                                masterRow['1315_pc']++;
                              } else {
                                masterRow['1315_pc'] = 1;
                              }
                          }
                          retailerVCSet_1315.add(activityRows[key1].retailer_id);
                          if (masterRow['1315_vc']) {
                            masterRow['1315_vc']++;
                          } else {
                            masterRow['1315_vc'] = 1;
                          }
                    }
                    if (activity_hr >= 15 && activity_hr < 17) {
                      if (activityRows[key1].activity_type === 'Order') {
                          retailerPCSet_1517.add(activityRows[key1].retailer_id);
                          if (masterRow['1517_pc']) {
                          masterRow['1517_pc']++;
                        } else {
                          masterRow['1517_pc'] = 1;
                        }
                      }
                      retailerVCSet_1517.add(activityRows[key1].retailer_id);
                      if (masterRow['1517_vc']) {
                        masterRow['1517_vc']++;
                      } else {
                        masterRow['1517_vc'] = 1;
                      }
                    }
                    if (activity_hr >= 17 && activity_hr < 19) {
                      if (activityRows[key1].activity_type === 'Order') {
                          retailerPCSet_1719.add(activityRows[key1].retailer_id);
                          if (masterRow['1719_pc']) {
                          masterRow['1719_pc']++;
                        } else {
                          masterRow['1719_pc'] = 1;
                        }
                      }
                      retailerVCSet_1719.add(activityRows[key1].retailer_id);
                      if (masterRow['1719_vc']) {
                        masterRow['1719_vc']++;
                      } else {
                        masterRow['1719_vc'] = 1;
                      }
                    }
                    if (activity_hr >= 19 && activity_hr < 21) {
                      if (activityRows[key1].activity_type === 'Order') {
                          retailerPCSet_1921.add(activityRows[key1].retailer_id);
                          if (masterRow['1921_pc']) {
                          masterRow['1921_pc']++;
                        } else {
                          masterRow['1921_pc'] = 1;
                        }
                      }
                      retailerVCSet_1921.add(activityRows[key1].retailer_id);
                      if (masterRow['1921_vc']) {
                        masterRow['1921_vc']++;
                      } else {
                        masterRow['1921_vc'] = 1;
                      }
                    }

                }
                masterRow['vc'] ++;

                masterRow['79_upc'] = retailerPCSet_79.size;
                masterRow['79_uvc'] = retailerVCSet_79.size;

                masterRow['911_upc'] = retailerPCSet_911.size;
                masterRow['911_uvc'] = retailerVCSet_911.size;

                masterRow['1113_upc'] = retailerPCSet_1113.size;
                masterRow['1113_uvc'] = retailerVCSet_1113.size;

                masterRow['1315_upc'] = retailerPCSet_1315.size;
                masterRow['1315_uvc'] = retailerVCSet_1315.size;

                masterRow['1517_upc'] = retailerPCSet_1517.size;
                masterRow['1517_uvc'] = retailerVCSet_1517.size;

                masterRow['1719_upc'] = retailerPCSet_1719.size;
                masterRow['1719_uvc'] = retailerVCSet_1719.size;

                masterRow['1921_upc'] = retailerPCSet_1921.size;
                masterRow['1921_uvc'] = retailerVCSet_1921.size;

            }

            activityRows.total_stores = this.apiData.activity.filter(activity => activity.salesman_id === masterRow.user_id).length;
            masterRow['uvc'] = this.apiData.activity.filter(activity => activity.salesman_id === masterRow.user_id).map(p => p.retailer_id)
                .filter((type, index, arr) => arr.indexOf(type) === index).length;
            indRow = {
                ...masterRow,
                ...activityRows
            };
            this.rowData.push(indRow);
        }

    }

    onCellClicked(event) {
        if (event.colDef.headerName === 'View Details' && event.data.salesman_id) {
            this.redirectToUserActivityReport(event.data.salesman_id);
            console.log("Redirecting with Salesman - " + event.data.salesman_id);
        }
        if (event.colDef.headerName === 'Details(7AM-9AM)' && event.data.salesman_id) {
            this.redirectToUserActivityReport_1(event.data.salesman_id);
            console.log("Redirecting with Salesman - " + event.data.salesman_id);
        }
        if (event.colDef.headerName === 'Details(9AM-11AM)' && event.data.salesman_id) {
            this.redirectToUserActivityReport_2(event.data.salesman_id);
            console.log("Redirecting with Salesman - " + event.data.salesman_id);
        }
        if (event.colDef.headerName === 'Details(11AM-1PM)' && event.data.salesman_id) {
            this.redirectToUserActivityReport_3(event.data.salesman_id);
            console.log("Redirecting with Salesman - " + event.data.salesman_id);
        }
        if (event.colDef.headerName === 'Details(1PM-3PM)' && event.data.salesman_id) {
            this.redirectToUserActivityReport_4(event.data.salesman_id);
            console.log("Redirecting with Salesman - " + event.data.salesman_id);
        }
        if (event.colDef.headerName === 'Details(3PM-5PM)' && event.data.salesman_id) {
            this.redirectToUserActivityReport_5(event.data.salesman_id);
            console.log("Redirecting with Salesman - " + event.data.salesman_id);
        }
        if (event.colDef.headerName === 'Details(5PM-7PM)' && event.data.salesman_id) {
            this.redirectToUserActivityReport_6(event.data.salesman_id);
            console.log("Redirecting with Salesman - " + event.data.salesman_id);
        }
        if (event.colDef.headerName === 'Details(7PM-9PM)' && event.data.salesman_id) {
            this.redirectToUserActivityReport_7(event.data.salesman_id);
            console.log("Redirecting with Salesman - " + event.data.salesman_id);
        }
    }
    private redirectToUserActivityReport(salesman_id) {
        localStorage.setItem('user_id', salesman_id);
        localStorage.setItem('start_date', this.userData.start_date);
        localStorage.setItem('end_date', this.userData.start_date);
        this.router.navigate([this.apiService.redirect_url + '/reports/user_activity'], {
            relativeTo: this.route,
            queryParams: {redirect: 'user_periodic_activity'}
        });
    }
    private redirectToUserActivityReport_1(salesman_id) {
        localStorage.setItem('user_id', salesman_id);
        localStorage.setItem('start_date', this.userData.start_date);
        localStorage.setItem('end_date', this.userData.start_date);
        localStorage.setItem('start_hr', "7");
        localStorage.setItem('end_hr', "9");
        this.router.navigate([this.apiService.redirect_url + '/reports/user_activity'], {
            relativeTo: this.route,
            queryParams: {redirect: 'user_periodic_activity'}
        });
    }

    private redirectToUserActivityReport_2(salesman_id) {
        localStorage.setItem('user_id', salesman_id);
        localStorage.setItem('start_date', this.userData.start_date);
        localStorage.setItem('end_date', this.userData.start_date);
        localStorage.setItem('start_hr', "9");
        localStorage.setItem('end_hr', "11");
        this.router.navigate([this.apiService.redirect_url + '/reports/user_activity'], {
            relativeTo: this.route,
            queryParams: {redirect: 'user_periodic_activity'}
        });
    }
    private redirectToUserActivityReport_3(salesman_id) {
        localStorage.setItem('user_id', salesman_id);
        localStorage.setItem('start_date', this.userData.start_date);
        localStorage.setItem('end_date', this.userData.start_date);
        localStorage.setItem('start_hr', "11");
        localStorage.setItem('end_hr', "13");
        this.router.navigate([this.apiService.redirect_url + '/reports/user_activity'], {
            relativeTo: this.route,
            queryParams: {redirect: 'user_periodic_activity'}
        });
    }

    private redirectToUserActivityReport_4(salesman_id) {
        localStorage.setItem('user_id', salesman_id);
        localStorage.setItem('start_date', this.userData.start_date);
        localStorage.setItem('end_date', this.userData.start_date);
        localStorage.setItem('start_hr', "13");
        localStorage.setItem('end_hr', "15");
        this.router.navigate([this.apiService.redirect_url + '/reports/user_activity'], {
            relativeTo: this.route,
            queryParams: {redirect: 'user_periodic_activity'}
        });
    }

    private redirectToUserActivityReport_5(salesman_id) {
        localStorage.setItem('user_id', salesman_id);
        localStorage.setItem('start_date', this.userData.start_date);
        localStorage.setItem('end_date', this.userData.start_date);
        localStorage.setItem('start_hr', "15");
        localStorage.setItem('end_hr', "17");
        this.router.navigate([this.apiService.redirect_url + '/reports/user_activity'], {
            relativeTo: this.route,
            queryParams: {redirect: 'user_periodic_activity'}
        });
    }

    private redirectToUserActivityReport_6(salesman_id) {
        localStorage.setItem('user_id', salesman_id);
        localStorage.setItem('start_date', this.userData.start_date);
        localStorage.setItem('end_date', this.userData.start_date);
        localStorage.setItem('start_hr', "17");
        localStorage.setItem('end_hr', "19");
        this.router.navigate([this.apiService.redirect_url + '/reports/user_activity'], {
            relativeTo: this.route,
            queryParams: {redirect: 'user_periodic_activity'}
        });
    }

    private redirectToUserActivityReport_7(salesman_id) {
        localStorage.setItem('user_id', salesman_id);
        localStorage.setItem('start_date', this.userData.start_date);
        localStorage.setItem('end_date', this.userData.start_date);
        localStorage.setItem('start_hr', "19");
        localStorage.setItem('end_hr', "21");
        this.router.navigate([this.apiService.redirect_url + '/reports/user_activity'], {
            relativeTo: this.route,
            queryParams: {redirect: 'user_periodic_activity'}
        });
    }


}
