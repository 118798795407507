import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs';
import {GridOptions} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-widget-table',
  template: `
    <div class="info-box">
      <span class="heading">{{ widget.data.label }}</span>
      <!-- <table *ngIf="dataLoaded" class="table1">
         <tbody>
         <tr>
           <ng-container *ngFor="let item of headers">
             <th>{{ item.label }}</th>
           </ng-container>
         </tr>
         <tr *ngFor="let item of widget.data.value; let i = index">
           <ng-container *ngFor="let header of headers">
             <td class="tdl" id="{{header.key}}_{{i}}" style="color:{{header['value_colour']}}">{{ item[header.key] }}</td>
           </ng-container>
         </tr>
         </tbody>
       </table>-->
      <ag-grid-angular id="myGrid2" *ngIf="dataLoaded" style="margin-top: 10px; height: {{height}}px;" class="ag-theme-alpine"
                       [columnDefs]="columnDefs"
                       [gridOptions]="gridOptions"
                       (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>`,
  styleUrls: ['./../dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TableWidgetComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;
  resizeSub: Subscription;
  headers = [];
  dataLoaded = false;
  columnDefs = [];
  gridOptions: GridOptions;
  public gridColumnApi: any;
  public gridApi: any;
  rowData = [];
  height;

  ngOnInit(): void {
    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your widget, chart, map , etc.
        console.log(widget);
      }
    });
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
    } as GridOptions;

    if (this.widget?.data?.data && this.widget.data.data.length > 0) {
      this.height = this.widget?.data.height - 60;
      this.headers = this.widget.data.data;
      this.headers.forEach(header => {
        if (!header.value_colour) {
          header.value_colour = "#000000";
        }
        this.columnDefs.push({
          header: header.label, field: header.key, cellStyle: {color: header.value_colour}, flex: 1
        });
      });
    }
    this.rowData = this.widget.data.value;
    this.dataLoaded = true;
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllDisplayedColumns().length;
    params.api.setRowData(this.rowData);
    //params.api.setDomLayout('autoHeight');
    // tslint:disable-next-line:only-arrow-functions
   /* window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });*/
    /*if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }*/
    //this.gridApi.sizeColumnsToFit();
  }
}
