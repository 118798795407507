import {Component, ElementRef, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../../_services/api.service";
import {SharedService} from "../../../_services/shared_service";
import {ToastrService} from "ngx-toastr";
import {DatePipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalforageService} from "../../../_services/localforage.service";
import {DomSanitizer} from "@angular/platform-browser";
import {AppComponent} from "../../../app.component";

@Component({
  selector: 'app-change-password-popup',
  templateUrl: './change_password_popup.component.html'
})

export class ChangePasswordPopupComponent implements OnInit {

  user_id;
  old_password;
  new_password;
  rentered_password;

  constructor(public dialog: MatDialog,
              public apiService: ApiService,
              public sharedService: SharedService,
              public toastr: ToastrService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected route: ActivatedRoute,
              protected _localCacheService: LocalforageService,
              public router: Router,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.user_id = dialogdata.id;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  updatePassword() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = this.user_id;
    paramObject.old_password = this.old_password;
    paramObject.new_password = this.new_password;
    this.appComponent.pageReady = false;
    this.apiService.post('/api/res_users/change_password', paramObject)
      .subscribe(res => {
        this.appComponent.pageReady = true;
        if (res?.result?.newpassword) {
          this.toastr.success('Password updated Successfully');
          this.dialogRef.close();
        } else if (res.hasOwnProperty('error') && res.error.message) {
          this.toastr.error(res.error.message);
        } else {
          this.toastr.error('Failed to update Password');
        }
      }, error => {
        console.log(error);
        this.appComponent.pageReady = true;
        if (error?.error?.error?.message) {
          this.toastr.error(error.error.error.message);
        } else {
          this.toastr.error('Failed to update Password');
        }
      });
  }
}
