import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-widget-bar-graph',
  template: `
    <div class="info-box">
      <span class="heading">{{ widget.data.label }}</span>
      <div style="height: 78%; width: 100%; margin-top: 10px;">
        <ngx-charts-bar-vertical
          *ngIf="display_mode === 'single' && orientation === 'vertical'"
          [scheme]="colorScheme"
          [results]="graphData"
          [gradient]=true
          [xAxis]=true
          [yAxis]=true
          [legend]=false
          [legendPosition]="'below'"
          [legendTitle]="''"
          [showXAxisLabel]=false
          [showYAxisLabel]=false
          [xAxisLabel]="widget.data.x_label"
          [yAxisLabel]="widget.data.y_label"
          [noBarWhenZero]=false
          [showDataLabel]="showDataLabel"
          [barPadding]=5>
        </ngx-charts-bar-vertical>
        <ngx-charts-bar-horizontal
          *ngIf="display_mode === 'single' && orientation === 'horizontal'"
          [scheme]="colorScheme"
          [results]="graphData"
          [gradient]=true
          [xAxis]=true
          [yAxis]=true
          [legend]=false
          [legendPosition]="'below'"
          [legendTitle]="''"
          [showXAxisLabel]=false
          [showYAxisLabel]=false
          [xAxisLabel]="widget.data.x_label"
          [yAxisLabel]="widget.data.y_label"
          [noBarWhenZero]=false
          [showDataLabel]="showDataLabel"
          [barPadding]=5>
        </ngx-charts-bar-horizontal>
        <ngx-charts-bar-vertical-2d
          *ngIf="display_mode === 'grouped' && orientation === 'vertical'"
          [scheme]="colorScheme"
          [results]="graphData"
          [gradient]=true
          [xAxis]=true
          [yAxis]=true
          [legend]=true
          [legendPosition]="'below'"
          [legendTitle]="''"
          [showXAxisLabel]=false
          [showYAxisLabel]=false
          [xAxisLabel]="widget.data.x_label"
          [yAxisLabel]="widget.data.y_label"
          [noBarWhenZero]=false
          [showDataLabel]="showDataLabel"
          [barPadding]=5
          [groupPadding]=6>
        </ngx-charts-bar-vertical-2d>
        <ngx-charts-bar-horizontal-2d
          *ngIf="display_mode === 'grouped' && orientation === 'horizontal'"
          [scheme]="colorScheme"
          [results]="graphData"
          [gradient]=true
          [xAxis]=true
          [yAxis]=true
          [legend]=true
          [legendPosition]="'below'"
          [legendTitle]="''"
          [showXAxisLabel]=false
          [showYAxisLabel]=false
          [xAxisLabel]="widget.data.x_label"
          [yAxisLabel]="widget.data.y_label"
          [noBarWhenZero]=false
          [showDataLabel]="showDataLabel"
          [barPadding]=5
          [groupPadding]=8>
        </ngx-charts-bar-horizontal-2d>
        <ngx-charts-bar-vertical-stacked
          *ngIf="display_mode === 'stacked' && orientation === 'vertical'"
          [scheme]="colorScheme"
          [results]="graphData"
          [gradient]=true
          [xAxis]=true
          [yAxis]=true
          [legend]=true
          [legendPosition]="'below'"
          [legendTitle]="''"
          [showXAxisLabel]=false
          [showYAxisLabel]=false
          [xAxisLabel]="widget.data.x_label"
          [yAxisLabel]="widget.data.y_label"
          [noBarWhenZero]=false
          [showDataLabel]="showDataLabel"
          [barPadding]=5
        >
        </ngx-charts-bar-vertical-stacked>
        <ngx-charts-bar-horizontal-stacked
          *ngIf="display_mode === 'stacked' && orientation === 'horizontal'"
          [scheme]="colorScheme"
          [results]="graphData"
          [gradient]=true
          [xAxis]=true
          [yAxis]=true
          [legend]=true
          [legendPosition]="'below'"
          [legendTitle]="''"
          [showXAxisLabel]=false
          [showYAxisLabel]=false
          [xAxisLabel]="widget.data.x_label"
          [yAxisLabel]="widget.data.y_label"
          [noBarWhenZero]=false
          [showDataLabel]="showDataLabel"
          [barPadding]=5>
        </ngx-charts-bar-horizontal-stacked>
      </div>
    </div>`,
  styleUrls: ['./../dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BarGraphWidgetComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;

  resizeSub: Subscription;
  colorScheme: any = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  graphData;
  display_mode;
  orientation;
  showDataLabel = false;

  ngOnInit(): void {
    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your widget, chart, map , etc.
        console.log(widget);
      }
    });
    this.display_mode = this.widget.data.display_mode;
    this.orientation = this.widget.data.orientation;
    this.graphData = this.widget.data.value;
    this.setCustomColor();
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }

  setCustomColor() {
    if (this.widget?.data?.data && this.widget.data.data.length > 0) {
      this.colorScheme.domain = [];
      if (this.display_mode === 'single') {
        if (this.graphData && this.graphData.length > 0) {
          this.graphData.forEach(data => {
            if (data['indicator_colour']) {
              this.colorScheme.domain.push(data['indicator_colour']);
            }
          });
        }
      } else if (this.graphData && this.graphData.length > 0 && this.graphData[0].series && this.graphData[0].series.length > 0) {
        this.graphData[0].series.forEach(data => {
          if (data['indicator_colour']) {
            this.colorScheme.domain.push(data['indicator_colour']);
          }
        });
      }
      if (!this.colorScheme.domain || this.colorScheme.domain.length === 0) {
        this.colorScheme.domain = ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'];
      }
    } else {
      this.colorScheme.domain = ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'];
    }
  }
}
