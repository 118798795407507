import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";
import {AGGridEditButtonRendererComponent} from "../../admin/users/AGGridEditButtonRendererComponent";
import {MatDialogConfig} from "@angular/material/dialog";
import {StockUploadComponent} from "./upload_stock/stock_upload.component";


@Component({
  selector: 'app-batch-stock-list',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})

export class BatchStockListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'stock',
    key: 'id',
    value: 0,
    values: [],
    show: true
  };

  ngOnInit(): void {
    this.sharedService.setHeader('Batch Wise Stock');
    // this.frameworkComponents = {
    //   buttonRenderer: AGGridEditButtonRendererComponent,
    // };
    this.customFilter.values = [
      {id: 0, itemName: 'In Stock'},
      {id: 1, itemName: 'Out Of Stock'},
      {id: 2, itemName: 'All'},
    ];
    this.configureGrid();
    this.showDate = false;
    this.showUpload = true;
    // this.customFilter.value = JSON.parse(localStorage.getItem('user')).partner_id;
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_dms_connects/get_supplier_stock_batch';
    const inthis = this;
    this.columnDefs = [
      {headerName: 'Supplier Code', field: 'sup_code', hide: true},
      {headerName: 'Supplier', field: 'supplier', hide: true},
      {headerName: 'Product ID', field: 'product_id', hide: true},
      {headerName: 'Product Code', field: 'code'},
      {headerName: 'Product Name', field: 'product', width: 250},
      {headerName: 'Category', field: 'category'},
      {headerName: 'Brand', field: 'brand'},
      {headerName: 'Batch No', field: 'batch_no'},
      {headerName: 'Stock', field: 'stock', cellStyle: {textAlign: 'right'}},
      {headerName: 'Balance', field: 'balance', cellStyle: {textAlign: 'right'}},
      {headerName: 'Purchase Inv no', field: 'invoice_no'},
      {headerName: 'Purchase Price', field: 'price', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: 'Purchase Value', field: 'p_value', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: 'Stock Value In Hand', field: 's_value', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: 'Sales', field: 'sales', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value),
        valueGetter: params => {
          if (params.data) {
            let sales_value = 0;
            if (params.data.config_json && params.data.config_json.length > 0) {
              params.data.config_json.forEach(b => {
                if (b.hasOwnProperty('q') && b.hasOwnProperty('p') && params.data.hasOwnProperty('price')) {
                  sales_value = sales_value + (b.q * b.p);
                }
              });
              return sales_value;
            } else {
              return "";
            }
          }

        }},
      {headerName: 'Margin', field: 'margin', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value),
        valueGetter: params => {
          if (params.data) {
            let sales_value = 0;
            let purchase_value = 0;
            if (params.data.config_json && params.data.config_json.length > 0) {
              params.data.config_json.forEach(b => {
                if (b.hasOwnProperty('q') && b.hasOwnProperty('p') && params.data.hasOwnProperty('price')) {
                  sales_value = sales_value + (b.q * b.p);
                  purchase_value = purchase_value + (b.q * params.data.price);
                }
              });
              if (purchase_value > 0 && purchase_value < sales_value) {
                return sales_value - purchase_value ;
              } else {
                return '';
              }
            } else {
              return "";
            }
          }

        }},
      {headerName: 'supplier_id', field: 'supplier_id', hide: true},
      {headerName: 'product_id', field: 'product_id', hide: true},
    ];
  }

  showUploadPopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(StockUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(StockUploadComponent, config);
    }
  }


}
