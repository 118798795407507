<div class="sidebar">
  <div>
    <ul class="main list-unstyled" style="padding-top: 12%;margin-top: 0px;">
      <li id="dashboard" class="mainli" style="margin-top: 0px;">
        <a id="a_dashboard" (click)="goToDashboard()" class="menuText">
          <img class="menuicon" id="img_dashboard" src="/assets/menu/dashboard.svg"/>
        </a>
      </li>
      <ng-container *ngFor="let menu of sharedService.menuList| slice:0: menuCount; let i = index">
        <li class="mainli" id="{{menu.name.toString().toLowerCase().replaceAll(' ', '_')}}">
          <a (click)="goToRoute(menu)">
            <img class="menuicon" id="img_{{menu.name.toString().toLowerCase().replaceAll(' ', '_')}}" [src]="getIcon(menu.icon)"/>
            <span id="a_{{menu.name.toString().toLowerCase().replaceAll(' ', '_')}}" style="display: block;"> {{menu.name}}</span>
          </a>
          <div class="row" *ngIf="menu.sub_menu.length > 0">
            <ul class="subul" id="ul_{{menu.name.toString().toLowerCase().replaceAll(' ', '_')}}">
              <ng-container *ngFor="let submenu of menu.sub_menu; let j = index">
                <li class="subli"
                    id="{{menu.name.toString().toLowerCase().replaceAll(' ', '_')}}_{{submenu.name.toString().toLowerCase().replaceAll(' ', '_')}}"
                    (click)="goToRoute(submenu)">
                  <a style="color: #2D2D2D;">
                    <img class="submenuicon"
                      id="img_{{menu.name.toString().toLowerCase().replaceAll(' ', '_')}}_{{submenu.name.toString().toLowerCase().replaceAll(' ', '_')}}"
                      [src]="getIcon(submenu.icon)"/>
                    <span
                      id="a_{{menu.name.toString().toLowerCase().replaceAll(' ', '_')}}_{{submenu.name.toString().toLowerCase().replaceAll(' ', '_')}}"> {{submenu.name}}</span>
                    <!--<span *ngIf="!submenu.icon || submenu.icon === ''" style="margin-left: 30px;"
                           id="a_{{menu.name.toString().toLowerCase().replaceAll(' ', '_')}}_{{submenu.name.toString().toLowerCase().replaceAll(' ', '_')}}"> {{submenu.name}}</span>-->
                  </a>
                </li>
              </ng-container>
              <div style="text-align: center;">
                <img class="companylogo" src="/assets/menu/salesdiary_logo.svg"/>
              </div>
            </ul>
          </div>
        </li>
      </ng-container>
      <li id="report" class="mainli" style="margin-top: 5px;">
        <a (click)="gotoReports()">
          <img class="menuicon" id="img_report" src="/assets/menu/report.svg"/>
          <span id="a_report" style="display: block;">Reports</span>
        </a>
      </li>
      <li id="more" class="mainli" style="margin-top: 5px;" *ngIf="menuCount < sharedService.menuList.length">
        <a (click)="gotoAllMenus()">
          <img class="menuicon" id="img_more" src="/assets/menu/more.svg"/>
          <span id="a_more" style="display: block;">More</span>
        </a>
      </li>
    </ul>
  </div>
</div>
