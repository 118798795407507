// @ts-ignore
import {Iupload} from './IUpload';
import {ElementRef, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from "@angular/platform-browser";
import {GetRowIdFunc, GetRowIdParams, GridOptions, ITooltipParams} from "@ag-grid-enterprise/all-modules";
import {ToastrService} from "ngx-toastr";
import {ngxCsv} from "ngx-csv";
import * as XLSX from "xlsx-js-style";
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";
import {DatePipe} from "@angular/common";
import {LocalforageService} from "../../_services/localforage.service";
import {ErrorHandler} from "../../error/error-handler";
import {ErrorObject} from "../../error/error-model";
import {SchemeCalculator} from "../components/SchemeCalculator";
import {TaxCalculator} from "../components/TaxCalculator";

// tslint:disable-next-line:component-class-suffix
export abstract class BaseUpload implements OnInit, IUpload {
  @Output() uploadCompletedEvent = new EventEmitter<any>();
  public header = "";
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 2000;
  viewMode = 'upload';
  API_URL = '';
  baseData = [];
  dataLoaded = false;
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: []
    },
    customFilter: {}
  };
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  protected gridApi;
  private gridColumnApi;
  frameworkComponents: any;
  uploadexcel;
  public uploadCaption = ['', 0];
  public fileUploaded: File;
  public storeData: any;
  public worksheet: any;
  public uploading = false;
  public upload_loader = false;
  public upload_enable = false;
  public uploaded_data;
  sampleText1;
  sampleURL1;
  sampleFile1;
  sampleType1;
  sampleData1 = [];
  sampleText2;
  sampleURL2;
  sampleFile2;
  sampleType2;
  sampleData2 = [];
  excel_columns = [];
  step1Text = '';
  step2Text = '';
  actionText = '';
  filesize = 0;
  type;
  styleGrid: any;
  totalRecords = 0;
  validRecords = 0;
  errorRecords = 0;
  statusConfig = {
    success: 'Valid',
    success1: 'Success',
    error: 'Invalid',
    error1: 'Error',
    warning: 'warning',
    warning1: 'In Progress'
  };
  uploadStarted = false;
  uploadCompleted = false;
  context: any;
  numchar_hyphen_underscore_Pattern = /^([A-Za-z .0-9\-\_]+)$/i;
  numcharPattern = /^([A-Za-z0-9]+)$/i;
  charspacePattern = /^[A-Za-z ]+$/;
  floatPattern = /^\d*\.?\d*$/;
  numPattern = /^[0-9]*$/;
  phonePattern = /^[6-9]\d{9}$/;
  batchSize = 10;
  progress = 0;
  completedRecords = 0;

  // supplierMasterList = [];
  territoriesList: any = [];
  supplierList = [];
  bgList = [];
  customerList = [];
  UOMMasterList = [];
  TaxMasterList = [];
  UOMList = [];
  schemesMasterList = [];
  applicableSchemes = [];
  plantList = [];
  userList = [];
  beatList = [];
  priceList = [];
  prodList = [];
  outletTypeList = [];
  classTypeList = [];
  stateList = [];
  programTypeList = [];
  OPERATION_MODE = '';
  alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  dialogData;
  completedData: any = [];
  myDate: any;
  multiSheetUpload = false;
  constructor(protected apiService: ApiService,
              protected sharedService: SharedService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected elRef: ElementRef,
              protected dialog: MatDialog,
              protected dialogRef: MatDialogRef<any>,
              protected domSanitizer: DomSanitizer,
              protected toastr: ToastrService,
              public datePipe: DatePipe,
              protected _localCacheService: LocalforageService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.userData.url = localStorage.getItem('private_url');
    this.userData.offsetID = 0;
    if (dialogdata) {
      this.sampleData1 = dialogdata.sampleData1;
      this.sampleData2 = dialogdata.sampleData2;
      this.dialogData = dialogdata;
    }
    this.statusConfig.success = 'Valid';
    this.statusConfig.success1 = 'Success';
    this.statusConfig.error = 'Invalid';
    this.statusConfig.error1 = 'Error';
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      // groupDisplayType: 'groupRows',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true
      },
      // getRowStyle: params => {
      //   if (params.data && (params.data.valid === 'Invalid' || params.data.status === 'Error' || params.data.status === 'Invalid')) {
      //     return {background: '#FDF2F4', color: '#E24260'};
      //   }
      // },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
    } as GridOptions;
    this.setViewParams();
    this.configureGrid();
    this.columnDefs.push(
      {headerName: 'Valid', field: 'valid', width: 80, pinned: 'right', cellRenderer: this.statusCellRenderer},
      {
        headerName: 'Status', field: 'status', width: 80, pinned: 'right', hide: false,
        cellRenderer: this.statusIndicatorCellRenderer,
        tooltipValueGetter: (params: ITooltipParams) => {
          if (params.data.errorMsg) {
            return params.data.errorMsg;
          }
        },
      },
      {headerName: 'Error', field: 'errorMsg', maxWidth: 250, pinned: 'right', wrapText: true, autoHeight: true,  hide: false},
      {
        headerName: '',
        field: 'delete',
        width: 50, pinned: 'right',
        menuTabs: [],
        cellRenderer(params) {
          if (params.data && params.data.valid === 'Invalid') {
            return '<span title="Delete" style="color: #E24260"><i class="fas fa-trash-alt delete" data-action-type="delete"></i></span>';
          }
        },
      }
    );

    this.fetchFilterData();

    if (!this.baseData || this.baseData.length === 0) {
      this.fetchBaseData();
    }
  }
  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.uid;
  }

  async fetchFilterData() {
    this.userList = JSON.parse(localStorage.getItem('all_salesmans'));
    this.outletTypeList = JSON.parse(localStorage.getItem('all_out_types'));
    this.classTypeList = JSON.parse(localStorage.getItem('all_out_classes'));
    this.programTypeList = JSON.parse(localStorage.getItem('all_program_types'));
    this.territoriesList = JSON.parse(localStorage.getItem('all_territories'));
    this.stateList = JSON.parse(localStorage.getItem('user_states'));
  }

  async fetchBaseData() {

  }

  ngOnInit(): void {
  }

  setViewParams() {
  }

  configureGrid() {
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.rowData && this.rowData.length > 0) {
      let uid = 0;
      this.rowData.forEach(row => {
        row['uid'] = uid;
        uid++;
      });
    }
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });
    this.gridColumnApi.autoSizeAllColumns();
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    let selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow != null) {
      selectedRow = selectedRow[0];
    }
    if (column === 'delete') {
      this.rowData.splice(event.rowIndex, 1);
      this.gridApi.setRowData(null);
      this.gridApi.setRowData(this.rowData);
      //this.gridApi?.applyTransaction({ remove: [{ id: event.rowIndex }] });
      this.errorRecords--;
    }
  }

  sampleDownload1() {
  }

  sampleDownload2() {
  }

  downloadSampleData(filename) {
    const data = [];
    const indRow: any = {};
    this.excel_columns.forEach(key => {
      indRow[key.H] = '';
    });
    data.push(indRow);
    this.downloadExcel(data, filename);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  downloadExcel(data, filename) {
    const headers = Object.keys(data[0]);
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: filename,
      useBom: true,
      noDownload: false,
      headers
    };
    return new ngxCsv(data, filename, options);
  }

  uploadedFile(event) {

    this.uploadCaption = ['', 0];
    this.fileUploaded = event.target.files[0];
    this.filesize = Math.round(this.fileUploaded.size / 1024);
    this.readExcel();
  }

  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary', cellText: false, cellDates: true});
      if (workbook.SheetNames.length > 1) {
        const data = [];
        workbook.SheetNames.forEach(sheet => {
          data[sheet] = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {raw: false, dateNF: 'yyyy-mm-dd'});
        });
        this.constructData(data);
      } else {
        const first_sheet_name = workbook.SheetNames[0];
        this.worksheet = workbook.Sheets[first_sheet_name];
        this.uploaded_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: false, dateNF: 'yyyy-mm-dd'});
        this.totalRecords = this.uploaded_data.length;
        this.validate();
      }
      console.log(this.uploaded_data);
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  constructData(data) {
    this.validate();
  }

  validate() {
  }

  nextClick() {
    if (this.rowData && this.rowData.length > 0) {
      this.viewMode = 'validate';
      if (this.gridOptions && this.gridOptions.api) {
        this.gridOptions.api.setRowData(this.rowData);
      }
    } else {
      this.toastr.error("No Valid Records to Process");
      this.dialog.closeAll();
    }
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async upload() {
    this.uploadStarted = true;
    this.progress = 0;
    let index = 0;
    let lastIndex = 0;
    this.totalRecords = this.rowData.length;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = [];
    this.completedRecords = 0;
    const validrecords = this.rowData.filter(x => x.valid === 'Valid');
    if (validrecords && validrecords.length > 0) {
      do {
        if (index + this.batchSize >= validrecords.length) {
          lastIndex = validrecords.length;
        } else {
          lastIndex = index + this.batchSize;
        }
        const batchData = validrecords.slice(index, lastIndex);
        paramObject.data = batchData;
        await this.progressUpdate(index, lastIndex, 'In Progress');
        const res = await this.apiService.postPromise(this.API_URL, paramObject);
        if (res.hasOwnProperty('results') && res['results'].status === 200) {
          this.errorRecords = 0;
          if (res.results.data.length > 0) {
            this.updateRowStatus(index, res.results.data);
          } else {
            await this.progressUpdate(index, lastIndex, 'Error');
          }
        } else {
          await this.progressUpdate(index, lastIndex, 'Error');
        }
        index = lastIndex;
      } while (index < validrecords.length);
      this.progress = 100;
      this.uploadCompleted = true;
      this.uploadCompleteEmit();
    } else {
      this.toastr.error("No Valid Records to upload");
    }
  }
  uploadCompleteEmit() {
    this.completedData = [];
    const successRecords = this.rowData.filter(x => x.status === 'Success');
    if (successRecords) {
      successRecords.forEach(result => {
        this.completedData.push(result);
      });
    }
    this.uploadCompletedEvent.emit(this.completedData);
  }

  async updateRowStatus(index, rows) {
    const updatedRows = [];
    rows.forEach(row => {
      const found = this.rowData.find(x => x.uid === row.uid && row['valid'] === 'Valid');
      if (found) {
        if (row.status) {
          found.status = row.status;
        } else {
          found.status = "Error";
        }
        if (row.errorMsg) {
          found.errorMsg = row.errorMsg;
        }
        if (row.id) {
          found.id = row.id;
        }
      } else {
        row.status = 'Error';
        row.errorMsg = 'Processing Error';
      }
      updatedRows.push(row);
    });
    if (this.gridOptions && this.gridOptions.api) {
      //this.gridOptions.api.setRowData(this.rowData);
      this.gridApi.applyTransactionAsync({update: updatedRows}, () => {
      });
    }
    this.completedRecords += rows.length;
    this.progress = Math.round((index / this.validRecords) * 100);
    await this.timeout(10);
  }

  async progressUpdate(index, lastIndex, status) {
    const rowsToUpdate = [];
    const validrecords = this.rowData.filter(x => x.valid === 'Valid');
    for (let i = index; i < lastIndex; i++) {
      if (validrecords[i].valid === "Valid") {
        validrecords[i].status = status;
        rowsToUpdate.push(validrecords[i]);
      }
    }
    if (this.gridOptions && this.gridOptions.api) {
      //this.gridOptions.api.setRowData(this.rowData);
      this.gridApi.applyTransactionAsync({update: rowsToUpdate}, () => {
      });
    }
    this.progress = Math.round((index / this.validRecords) * 100);
    this.completedRecords = index;
    await this.timeout(500);
  }

  statusCellRenderer(params) {
    let displayElem = '';
    if (params.value) {
      if (params.value === params.context.componentParent.statusConfig.success || params.value === params.context.componentParent.statusConfig.success1) {
        displayElem = '<span class="ml-1 status2" style="background-color:#E1F0E7; border:#E1F0E7; color: #379862" >' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.error || params.value === params.context.componentParent.statusConfig.error1) {
        displayElem = '<span class="ml-1 status2" style="background-color:#FBE3E7; border:#FBE3E7; color: #E24260">' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.warning || params.value === params.context.componentParent.statusConfig.warning1) {
        displayElem = '<span class="ml-1 status2" style="background-color:#FEF2DF; border:#FEF2DF; color: #F6A82C">' + params.value + '</span>';
      } else {
        if (params.value) {
          displayElem = '<span class="ml-1 status2" style="background-color:#E3F4F6; border:#E3F4F6; color: #3A6C87"">' + params.value + '</span>';
        }
      }
    }
    return displayElem;
  }

  statusIndicatorCellRenderer(params) {
    let displayElem = '';
    if (params.value) {
      if (params.value === params.context.componentParent.statusConfig.success || params.value === params.context.componentParent.statusConfig.success1) {
        displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.error || params.value === params.context.componentParent.statusConfig.error1) {
        displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
      } else if (params.value === params.context.componentParent.statusConfig.warning || params.value === params.context.componentParent.statusConfig.warning1) {
        displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #F6A82C;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
      } else {
        if (params.value) {
          displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #3A6C87;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
        }
      }
    }
    return displayElem;
  }

  getFieldValue(row, field, default_value?: any) {
    if (row[field]) {
      return row[field].toString().trim();
    } else if (default_value) {
      return default_value;
    } else {
      return undefined;
    }
  }

  getExcelFieldValue(row, fieldArray, default_value?: any) {
    let returnValue;
    fieldArray.forEach(key => {
      if (row[key]) {
        returnValue = row[key].trim();
      }
    });
    if (returnValue) {
      return returnValue;
    } else if (default_value) {
      return default_value;
    } else {
      return undefined;
    }
  }

  formatNumber(number) {
    if (number) {
      number = parseFloat(number).toFixed(this.apiService.decimalPolicy);
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return '';
    }
  }


  loadMasterDataForUpload(callback) {
    const userData = {};
    userData['access_token'] = this.userData.access_token;

    const salesmanProfiles = ['Manager', 'Executive', 'Delivery Executive'];
    const userMasterList = JSON.parse(localStorage.getItem('all_salesmans'));
    this.userList = [];
    userMasterList.forEach(user => {
      if (salesmanProfiles.includes(user.profile)) {
        this.userList.push(user);
      }
    });
    if (this.userList.length === 0 && userMasterList.length > 0) {
      this.userList = userMasterList;
    }
    this.loadMasterDataFromCache(async (masterCacheLoaded) => {
      if (!masterCacheLoaded) {

        const master_res = await this.apiService.postPromise('/api/pwa_dms_connects/get_masters_for_dms', userData);
        if (master_res) {
          this.UOMMasterList = this.parseServerResponse('uom_master', this._localCacheService, true, master_res);
          this.TaxMasterList = this.parseServerResponse('tax_master', this._localCacheService, false, master_res);
          this.plantList = this.parseServerResponse('plant_master', this._localCacheService, false, master_res);
        }
      }

      if (this.OPERATION_MODE === 'P') {
        this.loadPartnersForPurchase(userData, callback);
      } else if (this.OPERATION_MODE === 'L') {

        this.loadPartnersForVanSales(userData, callback);
      } else {

        const direct_customer = this.sharedService.ACLcheck('show_direct_customers_only');
        if (!direct_customer) {
          this.loadPartnersForSales(userData, callback);
        } else {
          this.loadDistributorsForSales(userData, callback);
        }
      }
    });
  }

  /* BEGIN COMMON FOR ALL ORDER/INVOICE PAGES */

  loadMasterDataFromCache(cacheCallback) {

    this._localCacheService.get('uom_master').then(uomCahce => {

      this._localCacheService.get('tax_master').then(taxCahce => {

        this._localCacheService.get('plant_master').then(plantCahce => {

          if (uomCahce === null || uomCahce === undefined || taxCahce === null || taxCahce === undefined) {
            return cacheCallback(false);
          } else {
            console.log('loading masters from cache');

            this.TaxMasterList = taxCahce as any[];
            this.UOMMasterList = uomCahce as any[];
            this.plantList = plantCahce as any[];
            return cacheCallback(true);
          }
        });
      });
    });
  }

  loadCustomersDataFromCache(cacheCallback) {

    this._localCacheService.get('customers').then(customerCahce => {

      this._localCacheService.get('suppliers').then(supplierCahce => {
        if (customerCahce === null || customerCahce === undefined || supplierCahce === null || supplierCahce === undefined) {
          return cacheCallback(false);
        } else {
          console.log('loading partners from Cache');
          this.customerList = customerCahce as any[];
          this.supplierList = supplierCahce as any[];
          return cacheCallback(true);
        }
      });
    });
  }

  async loadPartnersForSales(userData, callback) {
    await this.loadCustomersDataFromCache(async (partnerCache) => {
      if (!partnerCache) {
        const res = await this.apiService.postPromise('/api/pwa_dms_connects/get_partners_for_sales', userData);
        if (res) {
          this.customerList = this.parseServerResponse('customers', this._localCacheService, true, res);
          this.supplierList = this.parseServerResponse('suppliers', this._localCacheService, true, res);
          return callback(null, null);
        } else {
          return callback('Error', null);
        }
      }
      callback(null, null);
    });
  }

  async loadDistributorsForSales(userData, callback) {
    const res = await this.apiService.postPromise('/api/pwa_dms_connects/get_distributors_for_sales', userData);
    if (res) {
      console.log(res);
      this.customerList = this.parseServerResponse('customers', null, true, res);
      this.supplierList = this.parseServerResponse('suppliers', null, true, res);
      return callback();
    } else {
      callback('error', null);
    }
  }

  loadPartnersForPurchase(userData, callback) {

    this.apiService.post('/api/pwa_dms_connects/get_partners_for_purchase', userData).subscribe((res: any) => {
        this.customerList = this.parseServerResponse('customers', undefined, true, res);
        this.supplierList = this.parseServerResponse('suppliers', undefined, true, res);
        return callback();
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        return callback('Error', null);
      });
  }

  async loadPartnersForVanSales(userData, callback) {
    // await this.loadCustomersDataFromCache(async (partnerCache) => {
    //   if (!partnerCache) {
    const res = await this.apiService.postPromise('/api/pwa_van_sales_connects/get_partners_for_load_out', userData);
    if (res) {
      this.customerList = res['results'].data['vans'];
      this.supplierList = res['results'].data['suppliers'];
      return callback(null, null);
    } else {
      return callback('Error', null);
    }
    // }
    callback(null, null);
    // });
  }

  parseServerResponse(key, cache, validate, res) {
    if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
      if (res['results'].data) {

        if (validate && !res['results'].data[key]) {
          this.handleError(ErrorHandler.getErrorObject('Master Records not found!'));
          return null;
        } else {

          if (res['results'].data[key] && cache) {
            cache.set(key, res['results'].data[key]).then();
          }

          return res['results'].data[key];
        }
      }
    } else {
      this.handleError(ErrorHandler.getErrorObject(res.results.msg));
      return null;
    }
  }

  public handleError(error: ErrorObject) {

    this.toastr.error(error.summary);
  }


  loadProducts(distributor_id, territory_id, sec_pricelist_id, callback) {

    this.prodList = [];
    this.schemesMasterList = [];
    this.applicableSchemes = [];

    const userData = {};
    userData['access_token'] = this.userData.access_token;
    userData['distributor_id'] = distributor_id;
    userData['territory_id'] = territory_id;
    userData['pricelist_id'] = sec_pricelist_id;
    userData['s_type'] = this.OPERATION_MODE;


    if (sec_pricelist_id === 0 || sec_pricelist_id === undefined) {
      this.toastr.error('Error Loading products with no priselist mapped with supplier');
    } else {
      this.apiService.post('/api/pwa_dms_connects/get_products_for_dms', userData).subscribe(res => {
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.prodList = res['results'].data.products;
          this.prodList.forEach(p => {
            p['base_price'] = p['unit_price'];
            /*if (p.taxmaster_id && this.TaxMasterList) {
              const taxMaster = this.TaxMasterList.find(item => item.id === p.taxmaster_id);
              if (taxMaster && taxMaster.price_include) {
                if (taxMaster.amount_type === 'fixed') {
                  p['unit_price'] = p['unit_price'] - taxMaster.amount;
                } else {
                  p['unit_price'] = p['unit_price'] / (1 + (taxMaster.amount / 100));
                }
              }
            }*/
          });

          // if (customer_id && customer_id > 0) {
          //   this.schemesMasterList = this.filterEligibleSchemes(res['results'].data.schemes, customer_id);
          // } else {
          this.schemesMasterList = res['results'].data.schemes;
          // }
          return callback();
        } else {
          this.toastr.error('Error fetching products for given pricelist');
        }
      });
    }
  }

  filterEligibleSchemes(allSchemes, customer_id) {
    const fileredSchemes = [];

    let customer_obj;
    if (customer_id) {
      customer_obj = this.customerList.find(item => item.id === customer_id);
    }

    if (customer_id) {
      if (allSchemes) {
        allSchemes.forEach(scheme => {
          let eligibale = true;
          if (scheme.criteria) {
            scheme.criteria.forEach(cr => {
              if (cr.type === 'outlet_type') {
                if (cr.expression === '=' && cr.outlet_type_id !== customer_obj.retailer_type) {
                  eligibale = false;
                } else if (!(cr.expression === '!=' && cr.outlet_type_id === customer_obj.retailer_type)) {
                  eligibale = false;
                }
              }
            });
          }

          if (eligibale) {
            fileredSchemes.push(scheme);
          }
        });
      }
    }
    return fileredSchemes;
  }

  compute_scheme_and_tax(order, taxMasterList, schemeMasterList, prodList, UOMList) {

    // const s = new SchemeCalculator(order['lines'], schemeMasterList, prodList, UOMList);
    // order['lines'] = s.computeSchemes();

    //Assumption, Tax to be applied on Subtotal, (all discount & subtotal computation to be done before this lne
    //Tax & Total To be recomputed
    const t = new TaxCalculator(order['lines'], taxMasterList, prodList);
    order['tax_notes'] = t.CalculateLineTax();

    return order;
  }


  getLookupId(field, value) {
    if (value) {

      let fk_obj;
      if (field === 'user_id' && value) {
        if (this.userList) {
          fk_obj = this.userList.find(x => (x.itemname !== undefined && x.itemname !== null && x.itemname.toLowerCase() === value.toLowerCase()));
          if (fk_obj) {
            return fk_obj.id;
          } else {
            fk_obj = this.userList.find(x => (x.login !== undefined && x.login !== null && x.login.toLowerCase() === value.toLowerCase()));
            if (fk_obj) {
              return fk_obj.id;
            } else {
              fk_obj = this.userList.find(x => (x.emp_code !== undefined && x.emp_code !== null && x.emp_code.toLowerCase() === value.toLowerCase()));
              if (fk_obj) {
                return fk_obj.id;
              }
            }
          }
        }
      } else if (field === 'territory_id' && value) {
        if (this.territoriesList) {
          fk_obj = this.territoriesList.find(x => (x.itemname !== undefined && x.itemname !== null && x.itemname.toLowerCase() === value.toLowerCase()));
          if (fk_obj) {
            return fk_obj.id;
          } else {
            fk_obj = this.territoriesList.find(x => (x.code !== undefined && x.code !== null && x.code.toLowerCase() === value.toLowerCase()));
            if (fk_obj) {
              return fk_obj.id;
            }
          }
        }
      } else if (field === 'retailer_type') {
        if (this.outletTypeList) {
          fk_obj = this.outletTypeList.find(x => (x.itemName !== undefined && x.itemName !== null && x.itemName.toLowerCase() === value.toLowerCase()));
          if (fk_obj) {
            return fk_obj.id;
          }
        }
      } else if (field === 'supplier_type') {
        if(this.outletTypeList) {
          fk_obj = this.outletTypeList.find(x => (x.itemName !== undefined && x.itemName !== null && x.itemName.toLowerCase() === value.toLowerCase()));
          if(fk_obj) {
            return fk_obj.id;
          }
        }
      } else if (field === 'program_type') {
        if (this.programTypeList) {
          fk_obj = this.programTypeList.find(x => (x.itemName !== undefined && x.itemName.toLowerCase() === value.toLowerCase()));
          if (fk_obj) {
            return fk_obj.id;
          }
        }
      } else if (field === 'class_type') {
        if (this.classTypeList) {
          fk_obj = this.classTypeList.find(x => (x.itemname !== undefined && x.itemname.toLowerCase() === value.toLowerCase()));
          if (fk_obj) {
            return fk_obj.id;
          }
        }
      } else if (field === 'state_id') {
        if (this.stateList) {
          fk_obj = this.stateList.find(x => ((x.itemName !== undefined && x.itemName.toLowerCase() === value.toLowerCase()) || (x.code !== undefined && x.code.toLowerCase() === value.toLowerCase())));
          if (fk_obj) {
            return fk_obj.id;
          }
        }
      } else if (field === 'pricelist_id') {
        if (this.priceList) {
          fk_obj = this.priceList.find(x => (x.name !== undefined && x.name.toLowerCase() === value.toLowerCase()));
          if (fk_obj) {
            return fk_obj.id;
          }
        }
      }
    }
    return undefined;
  }

  setCellClassRulesAndTooltips() {
    /*for (let i = this.columnDefs.length - 3; i < this.columnDefs.length; i++) {
      this.columnDefs[i].hide = true;
    }*/
    this.columnDefs.forEach(columnDef => {
      columnDef.cellStyle = (params) => {
        if (params.data && params.data.errorDetails && params.data.errorDetails[columnDef.field]) {
          return {backgroundColor: '#FDF2F4', color: '#E24260'};
        }
        return null;
      };
      columnDef.tooltipValueGetter = (params) => {
        if (params.data && params.data.errorDetails && params.data.errorDetails[columnDef.field]) {
          return params.data.errorDetails[columnDef.field];
        }
        return null;
      };
    });
    if (this.gridOptions?.api) {
      this.gridOptions.api.setColumnDefs(this.columnDefs);
    }
  }

  SetInvalidStatus(row, errMsg, field) {
    row.valid = 'Invalid';
    if (!row.errorDetails) {
      row.errorDetails = {};
    }
    row.errorDetails[field] = errMsg;
    console.log(row.errorDetails);
  }

  downloadDataWithErrors(): void {
    // Prepare the data for the first sheet
    const originalData = this.rowData.map(row => {
      const { territory_id, salesman_id, supp_id, cust_id, errorMsg, errorDetails, valid, uid, supplier_type, state_id, ...rowDataWithoutErrors } = row;
      return rowDataWithoutErrors;
    });

    // Prepare the error data for the second sheet
    const errorData = [];
    this.rowData.forEach((row, index) => {
      if (row.errorDetails) {
        Object.keys(row.errorDetails).forEach(field => {
          const fieldname = this.columnDefs.find(x => x.field === field);
          errorData.push({
            rowNumber: index + 1,
            field: fieldname.headerName,
            errorMessage: row.errorDetails[field]
          });
        });
      }
    });

    // Create worksheets
    const originalDataSheet = XLSX.utils.json_to_sheet(originalData);
    const errorDataSheet = XLSX.utils.json_to_sheet(errorData);

    // Create a workbook and append sheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, originalDataSheet, 'Original Data');
    XLSX.utils.book_append_sheet(workbook, errorDataSheet, 'Error Details');

    // Write the workbook to a file
    const fileName = 'data_with_errors.xlsx';
    XLSX.writeFile(workbook, fileName);
  }
}
