import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {formatDate} from '@angular/common';
import {ErrorHandler} from "../../error/error-handler";
import {MatDialogConfig} from "@angular/material/dialog";
import {LocationPopupComponent} from "../../components/location_popup/location_popup.component";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class UserActivityReportComponent extends ReportBase implements IReport, OnInit  {

  public context;
  public checkInDistance;
  apiData: any = [];

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('User Activity Report');

    this.showableFilters.salesman = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'EMPLOYEE',
      field: 'salesman',
      minWidth: 200,
      cellRenderer: 'agGroupCellRenderer'
    };

    this.context = {componentParent: this};
    this.checkInDistance = localStorage.getItem('app') !== null ? JSON.parse(localStorage.getItem('app')).checkin_distance : 0;

    this.userData.offsetID = 0;
    this.userData.offset = 10000;
    this.loadInBatches(10000, 'id');
    this.showProgressCustomText = true;
    this.configureGrid();
    this.loadUserDetails();
    console.log('document');
    console.log(document.referrer);
      }

  configureGrid() {
    const inThis = this;
    this.API_URL = '/api/pwa_reports/getUserAllActivityReport';

    this.columnDefs = [
      { headerName: "STATE",  field: "state", enableRowGroup: true, width: 100},
      { headerName: "REGION",  field: "region", enableRowGroup: true, width: 100},
      { headerName: "CLUSTER",  field: "cluster", enableRowGroup: true, width: 100},
      { headerName: "EMP CODE",  field: "emp_code", width: 80},
      { headerName: "EMP NAME",  field: "salesman", enableRowGroup: true, width: 150},
      { headerName: "ROLE",  field: "role", enableRowGroup: true, width: 100},
      { headerName: "VERTICAL",  field: "vertical", enableRowGroup: true, hide: true, width: 120},
      { headerName: 'REPORTING MANAGER', field: 'reporting_manager', hide: true, enableRowGroup: true, width: 150},
      { headerName: 'RM CODE', field: 'rm_code', hide: true, enableRowGroup: true, width: 150},
      { headerName: "PARENT TEAM",  field: "parent_team", width: 100, enableRowGroup: true},
      { headerName: "TEAM",  field: "team_name", width: 100, enableRowGroup: true},
      { headerName: "TERRITORY CODE",  field: "territory_code", hide: true, width: 80, enableRowGroup: true},
      { headerName: "TERRITORY",  field: "territory", hide: true, width: 120, enableRowGroup: true},
      { headerName: "BEAT",  field: "beat", enableRowGroup: true, width: 100},
      { headerName: "BEAT CODE",  field: "beat_code", hide: true, width: 80},
      { headerName: "PROGRAM",  field: "program", width: 80},
      { headerName: "RETAILER ID",  field: "retailer_id",  hide: true, width: 50},
      { headerName: "RETAILER CODE",  field: "retailer_code", width: 80},
      { headerName: "RETAILER",  field: "retailer_name", enableRowGroup: true, width: 150},
      { headerName: "RETAILER TYPE",  field: "retailer_type", enableRowGroup: true,  width: 80},
      { headerName: "PARTNER TYPE",  field: "type", enableRowGroup: true, hide: true,  width: 80},
      { headerName: "MOBILE",  field: "mobile", width: 100},
      { headerName: "EMAIL",  field: "email",  hide: true, width: 150},
      { headerName: "GST ID",  field: "gst_id",  hide: true, width: 100},
      { headerName: "ADDRESS",  field: "address",  hide: true, width: 120},
      { headerName: "ZONE",  field: "zone",  hide: true, width: 80},
      { headerName: 'DATE', field: 'report_date', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.activity_time) {
              return inThis.datePipe.transform((new Date(params.data.activity_time)).toString(), 'dd-MM-yyyy');
            } else {
              return params.data.activity_time;
            }
          } else {
            return '';
          }
        }
      },
      { headerName: "ACTIVITY",  field: "user_activity", enableRowGroup: true, width: 100},
      { headerName: "DESCRIPTION",  field: "description", enableRowGroup: true, width: 100},
      { headerName: "ACTIVITY TYPE",  field: "activity_type", enableRowGroup: true, width: 100},
      { headerName: "ACTIVITY COMMENTS",  field: "activity_comments", width: 100},
      {headerName: "RLAT", field: "r_lat", enableRowGroup: true, hide: true, width: 70},
      {headerName: "RLNG", field: "r_long", enableRowGroup: true, hide: true, width: 70},
      {headerName: "ALAT", field: "a_lat", enableRowGroup: true, hide: true, width: 70},
      {headerName: "ALNG", field: "a_long", enableRowGroup: true, hide: true, width: 70},
      {
        headerName: "ACTIVITY LOCATION", field: "url", maxWidth: 80, menuTabs: [],
        valueGetter(params) {
          if (params.data) {
            if (params.data.r_lat != null && params.data.r_lat !== "0.0") {
              return 'http://www.google.com/maps/dir/' + params.data.r_lat + ',' + params.data.r_long + '/'
                + params.data.a_lat + ',' + params.data.a_long;
            } else {
              return 'http://maps.google.com/maps?q=' + params.data.a_lat + ',' + params.data.a_long;
            }
          }
        },
        cellRenderer: params => {
          if (params.data) {
            if (params.data.a_lat != null && params.data.a_lat !== "0.0") {
              return '<div style="text-align: center;"> ' +
                '<span>' +
                ' <i class="fas fa-map-marker-check fa-2x" style="color:#379862;' +
                'display: inline-block;\n' +
                '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                '</span>' +
                '</div>';
            } else {
              return '<div style="text-align: center;"> ' +
                '<span>' +
                ' <i class="fas fa-map-marker-slash fa-2x" style="color:#D82128;' +
                'display: inline-block;\n' +
                '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                '</span>' +
                '</div>';
            }
          }
        }
      },
      { headerName: "START TIME",  field: "activity_time", width: 120,
        valueGetter: params => {
          if (params.data) {
            if (params.data.activity_time) {
              return inThis.datePipe.transform((new Date(params.data.activity_time)).toString(), 'HH:mm');
            } else {
              return params.data.activity_time;
            }
          } else {
            return '';
          }
        }
        },
      { headerName: "END TIME",  field: "end_time", width: 120,
        valueGetter: params => {
          if (params.data) {
            if (params.data.end_time) {
              return inThis.datePipe.transform((new Date(params.data.end_time)).toString(), 'HH:mm');
            } else {
              return params.data.end_time;
            }
          } else {
            return '';
          }
        }},
      {
        headerName: 'TIME SPENT', field: 'time_spent', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.end_time && params.data.activity_time) {
              const diff = new Date(params.data.end_time).getTime() - new Date(params.data.activity_time).getTime();
              //return (diff / (1000 * 60)).toFixed(2);
              let msec = diff;
              const hh = Math.floor(msec / 1000 / 60 / 60);
              msec -= hh * 1000 * 60 * 60;
              const mm = Math.floor(msec / 1000 / 60);
              msec -= mm * 1000 * 60;
              const ss = Math.floor(msec / 1000);
              msec -= ss * 1000;
              return ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
            } else {
              return 0;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: "DEVIATION", field: "deviation", width: 100,
        cellRenderer(params) {
          if (params.data) {
            let displayElem = '';
            if (params._self.checkInDistance == null && params.value > 300) {
              displayElem = '<div style=" background-color:#F8D1C8;  text-align: center; padding: 0px;">' + params.value + '</div>';
            } else if (params._self.checkInDistance && params._self.checkInDistance !== null && params.value !== null && params._self.checkInDistance > 0 && params.value > params._self.checkInDistance) {
              //if (params.value > 300) {
              displayElem = '<div style=" background-color:#F8D1C8;  text-align: center;">' + params.value + '</div>';
              // return {textAlign: 'center', backgroundColor: '#F8D1C8'};
            } else {
              displayElem = '<div style=" text-align: center;">' + params.value + '</div>';
              //return {textAlign: 'center'};
            }
            return displayElem;
          }
        },
        cellRendererParams: {
          _self: this
        }
      },
      { headerName: "STATUS",  field: "status", width: 100, enableRowGroup: true,
        cellRenderer(params) {
          if (params.data) {
            let displayElem = '';
            if (params.value === 'COMPLETE') {
              displayElem = '<span style="color:#379862; font-weight: 500;">' + params.value + '</span>';
            } else {
              displayElem = '<span style="color:#B82525; font-weight: 500;">' + params.value + '</span>';
            }
            return displayElem;
          }
        },
        valueGetter(params) {
          if (params.data) {
            if (params.data.activity_type === 'Check In') {
              if (params.data.status === '1') {
                return 'COMPLETE';
              } else {
                return 'INCOMPLETE';
              }
            } else {
              return '';
            }
          }
        }
      },

      { headerName: 'Schedule', field: 'schedule', enableRowGroup: true, hide: true, width: 80}
    ];

  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'url') {

      if (rows != null && rows.length > 0 && rows[0].hasOwnProperty('a_lat') && rows[0].a_lat != null && rows[0].a_lat !== '') {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '70%',
            maxHeight: '95%',
            data: {
              latitude: parseFloat(rows[0].a_lat),
              longitude: parseFloat(rows[0].a_long),
              end_latitude: parseFloat(rows[0].r_lat),
              end_longitude: parseFloat(rows[0].r_long),
              title1: 'Activity',
              title2: 'Outlet'
            }
          };

          const creatediaeref = this.dialog.open(LocationPopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '90%',
            maxHeight: '65%',
            data: {
              latitude: parseFloat(rows[0].a_lat),
              longitude: parseFloat(rows[0].a_long),
              end_latitude: parseFloat(rows[0].r_lat),
              end_longitude: parseFloat(rows[0].r_long),
              title1: 'Activity',
              title2: 'Outlet'
            }
          };

          const creatediaeref = this.dialog.open(LocationPopupComponent, config);
        }


      }
    }
  }

  private loadUserDetails() {
    if (this.route.snapshot.queryParamMap && this.route.snapshot.queryParamMap.has('redirect')) {
      const user_id = localStorage.getItem('user_id');
      const start_date = localStorage.getItem('start_date');
      const end_date = localStorage.getItem('end_date');
      const start_hr = localStorage.getItem('start_hr');
      const end_hr = localStorage.getItem('end_hr');
      this.myDate[0] = start_date;
      this.myDate[1] = end_date;
      this.userData.start_date = this.myDate[0];
      this.userData.end_date = this.myDate[1];
      this.userData.customFilter['user_id'] = user_id;
      this.userData.customFilter['start_hr'] = start_hr;
      this.userData.customFilter['end_hr'] = end_hr;
      this.loadServerData();
    } else {
      this.loadServerData();
    }
  }

}
