import {PageBase} from "../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {ErrorHandler} from "../../error/error-handler";
import {RowNode} from "@ag-grid-enterprise/all-modules";
import {ErrortooltipComponent} from "../../errortooltip";


@Component({
  selector: 'app-meet-list',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})

export class MSRListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Filters',
    key: 'status',
    value: 'completed',
    values: [],
    show: true
  };

  unique_partner_types = [];

  matActionMenuItemDeclaration = []; //, feature: 'action_invoice_create'

  ngOnInit(): void {
    this.API_URL = '/api/pwa_manager_connects/getMonthlySalesReview';

    this.sharedService.setHeader('Monthly Sales Review (Beta)');
    this.showUpload = false;


    this.MapViewMetaData = {
      id: 'id',
      title: 'name',
      latitude: 'lat',
      longitude: 'lng',
      color: '',
      field1: 'salesman',
      field2: 'type'
    };

    this.checkAndLoadPreviousSate();

    this.showCustomFilter = false;
    this.showActionButton = true;

    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.region = true;
    this.showableFilters.cluster = true;
    this.dateFilterType = 'period';
    // this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.showDate = true;

    this.showViewSelector = true;
    this.showMapView = true;

    this.gridOptions.getRowStyle = this.getRowStyleFunc;
    this.frameworkComponents = {
      errortooltipComponent: ErrortooltipComponent
    };

    this.gridOptions.isRowSelectable = params => {
      if (params.data) {
        if (params.data.state === 'cancel') {
          return false;
        } else {
          return true;
        }
      }
    };
    this.getAuthorizedActions();
    this.checkAccessControl();
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  getRowStyleFunc(params) {
    if (params.data && params.data.id === 'TOTAL') {
      return {'font-weight': 'bold'};
    }
  }

  configureGrid() {
    const inThis = this;

    const OrderStatusCellClassRules = {
      'rag-green-outer': (params) => params.value === 'success',
      'rag-amber-outer': (params) => params.value === 'deviation',
      'rag-red-outer': (params) => params.value === 'alert',
    };

    this.columnDefs = [
      {
        headerName: "",
        children: [
          {headerName: 'SALESMAN', field: 'user_name', width: 100},
          {headerName: "Status", field: "status", width: 80, hide: true,
            cellClassRules: OrderStatusCellClassRules,
            cellRenderer(params) {
              let displayElem = '';
              if (params.data) {
                if (params.value === 'Success') {
                  displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
                } else if (params.value === 'Absent') {
                  displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
                } else if (params.value === 'PJP Not Planned') {
                  displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #fa8e1b;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
                } else {
                  if (params.value) {
                    displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #fa8e1b;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
                  }
                }
                return displayElem;
              }
            }
          },
          {headerName: 'EMP CODE', field: 'emp_code', width: 80, hide: true},
          {headerName: 'MOBILE', field: 'mobile', width: 80},
          {headerName: 'STATE', field: 'state', width: 80, hide: true },
          {headerName: 'REGION', field: 'region', width: 80, hide: true},
          {headerName: 'DATE', field: 'date', width: 80, hide: true},
          {headerName: 'ROLE', field: 'role', width: 80, hide: true},
          {headerName: 'SAC', field: 'sac', width: 80, hide: true},
          {headerName: 'EMAIL', field: 'user_email', width: 80, hide: true},
          {headerName: 'TEAM', field: 'team', width: 80, hide: true},
          {headerName: 'TEAM LEAD', field: 'tl_name', width: 80, hide: true},
          {headerName: 'TEAM LEAD CODE', field: 'tl_code', width: 80, hide: true},
        ]
      },
      {
        headerName: "KPIS",
        children: [
          {headerName: 'Universe', field: 'uc', width: 40},
          {headerName: 'Work Days', field: 'work_days',  width: 40},
          {headerName: 'Not Planned', field: 'check_out', width: 40},
          {headerName: 'Beat Jump', field: 'beat_jump', width: 40},
          {headerName: "Work Hours", field: "fat",  width: 40, hide: true},
          {headerName: 'Total Calls', field: 'tc', width: 40},
          {headerName: 'visits', field: 'vc', width: 40},
          {headerName: "Unique Visits", field: "uvc",  width: 40, },
          {headerName: "PC", field: "upc",  width: 40},
          {headerName: "Unique PC", field: "upc",  width: 40},
          {headerName: "Coverage%" , width: 40,
            valueGetter: params => {
              if (params.data.uvc && params.data.tc) {
                if (params.data.tc !== 0) {
                  return ((params.data.uvc / params.data.tc) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: "PC%",  width: 40,
            valueGetter: params => {
              if (params.data.tc && params.data.pc && params.data.pc > 0) {
                return ((params.data.pc / params.data.tc) * 100).toFixed(2);
              } else {
                return 0;
              }
            }
          },
          {headerName: 'Target', field: 'sec_target',  width: 40},
          {headerName: 'Ach', field: 'sec_ach',  width: 40,
            cellStyle: params => {
              if (params.data['status'] === 'Days Target Not Met') {
                return {backgroundColor: '#c4711c'};
              }
            }},
          {headerName: 'Run Rate', field: 'sec_curr_rate',  width: 40, hide: true, valueFormatter: params => this.formatNumber(params.data.sec_curr_rate)},
          {headerName: 'Req Rate', field: 'sec_req_rate',  width: 40, hide: true, valueFormatter: params => this.formatNumber(params.data.sec_req_rate),
            cellStyle: params => {
              if (params.data['status'] === 'Days Target Not Met') {
                return {backgroundColor: '#c4711c'};
              }
            }},
          {headerName: 'Avg Lines', field: 'avg_line',  width: 40},
          {headerName: 'Avg Value', field: 'avg_amount',  width: 40},

        ]
      },
      {
        headerName: "Primary Sales",
        hide: true,
        children: [
          {headerName: 'Target', field: 'pri_target',  width: 40, hide: true},
          {headerName: 'Achievement ', field: 'pri_ach',  width: 40, hide: true},
          {headerName: 'Ach%',  width: 40, hide: true,
            valueGetter: params => {
              if (params.data && params.data.pri_target > 0 && params.data.pri_ach > 0) {
                return ((params.data.pri_ach / params.data.pri_target) * 100).toFixed(2);
              } else {
                return 0;
              }
            }
          },
          {headerName: 'Run Rate', field: 'pri_curr_rate',  width: 40, hide: true, valueFormatter: params => this.formatNumber(params.data.pri_curr_rate) },
          {headerName: 'Req Rate', field: 'pri_req_rate',  width: 40, hide: true, valueFormatter: params => this.formatNumber(params.data.pri_req_rate)}
        ]
      },
      {
        headerName: "Partner Visits",
        children: [
        ]
      },
      {
        headerName: "NEW Business",
        children: [
          {headerName: 'Created', field: 'new',  width: 40},
          {headerName: 'Productive', field: 'new_pc',  width: 40},
          {headerName: 'Sales', field: 'new_sales',  width: 40}
        ]
      }
    ];

    this.unique_partner_types.forEach(type => {
      this.columnDefs[3].children.push(
        {headerName: type, field: type, menuTabs: [], width: 30, cellStyle: {textAlign: 'center'}},
      );
    });

    this.getRowId = (params) => params.data.user_id;
  }


  configureData() {
    this.rowData = [];

    this.apiData.forEach(dr => {
      dr['uvc'] = this.getPartnerVisitCount('retailer', dr.visits);
      dr['supplier'] = this.getPartnerVisitCount('supplier', dr.visits);
      dr['project'] = this.getPartnerVisitCount('project', dr.visits);
      dr['lead'] = this.getPartnerVisitCount('lead', dr.visits);
      dr['influencer'] = this.getPartnerVisitCount('influencer', dr.visits);
      dr['influencer'] = this.getPartnerVisitCount('influencer', dr.visits);
      this.setDayStatus(dr);
      this.rowData.push(dr);
    });
  }


  getPartnerVisitCount(partner_type, visits_data) {
    let visitscount = 0;
    if (visits_data && visits_data.length > 0) {
      const visit_array = visits_data.filter(i => i.partner_type === partner_type);
      visitscount = [...new Set(visit_array.map((item) => item.partner_id))].length;
      if (visitscount > 0 && !this.unique_partner_types.includes(partner_type) && partner_type !== 'retailer') {
        this.unique_partner_types.push(partner_type);
      }
    }
    return visitscount;
  }

  setDayStatus(month) {

    if (month.sec_target && month.sec_ach > 0 && (month.sec_ach * 100 / month.sec_target) < 80) {
      month['status'] = 'Target below 80%';
    } else if (month.day_target && month.day_ach > 0 && (month.day_ach * 100 / month.day_target) < 80) {
      month['status'] = 'Planned Target Not Met';
    } else if (month.vc && month.pc > 0 && month.pc < month.vc / 2) {
      month['status'] = 'PC Norm Not Met';
    } else if (month.vc && month.pc > 0 && month.pc < month.vc / 2) {
      month['status'] = 'PC Norm Not Met';
    } else if (!month.hasOwnProperty('check_in') || month.check_in === null || month.check_in.length === 0) {
      month['status'] = 'EOD Not marked';
    } else {
      month['status'] = 'Success';
    }


  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;

    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      this.router.navigate([this.apiService.redirect_url + '/sfa/dsr_detail'], {
        relativeTo: this.route,
        queryParams: {id: rows[0].id}
      });
    }
  }

  checkAccessControl() {
    this.showCreate = true;
  }



  onMenuItemSelected(menuitem) {

  }

  configValueGetter(field) {
    return (params) => {
      if (params.data && params.data.config_json) {
        try {
          let config_json;
          if (params.data.config_json.constructor !== Object) {
            config_json = JSON.parse(params.data.config_json);
          } else {
            config_json = params.data.config_json;
          }
          if (config_json && config_json.hasOwnProperty(field)) {
            return config_json[field];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setColumnDefs(this.columnDefs);
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  onFilterChanged($event) {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }
}
