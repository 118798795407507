import {Component, OnInit} from "@angular/core";
import {DetailPageBase} from "../../../pagebase/DetailPageBase";
import {GridOptions, IRichCellEditorParams} from "@ag-grid-enterprise/all-modules";
import {MatDialogConfig} from "@angular/material/dialog";
import {TerritoryAddComponent} from "../../../components/territory_add/territory_add.component";
import {CreditNotePopupComponent} from "../../../dmsv2/secondary/credit_notes/credit_note_popup/credit_popup.component";
import {ChangePasswordPopupComponent} from "../change_password/change_password_popup.component";

@Component({
  selector: 'app-user-detail',
  templateUrl: 'user_detail.component.html',
  styleUrls: ['user_detail.component.scss']
})

export class UserDetailComponent extends DetailPageBase implements IDetailPage, OnInit {

  terr_columnDefs = [];
  terr_gridOptions: GridOptions;
  terr_gridApi: any;
  terr_gridColumnApi: any;
  terr_rowData = [];
  terr_dataLoaded = false;

  attr_columnDefs = [];
  attr_gridOptions: GridOptions;
  attr_gridApi: any;
  attr_gridColumnApi: any;
  attr_rowData = [];
  attr_dataLoaded = false;

  dev_columnDefs = [];
  dev_gridOptions: GridOptions;
  dev_gridApi: any;
  dev_gridColumnApi: any;
  dev_rowData = [];
  dev_dataLoaded = false;

  attributeList = [];
  activetab = 'Territory';

  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/pwa_admin_connects/get_user_details";
    this.context = {componentParent: this};
    this.sharedService.setHeader('User Detail');
    this.terr_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressRowTransform: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: this,
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        } else {
          if (params.data.active === false) {
            return {background: '#FDF2F4'};
          } else if (params.data.a % 2 === 0) {
            return {background: '#F3F6F8'};
          } else {
            return {background: 'white'};
          }
        }
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;
    this.attr_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressRowTransform: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: this,
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        } else {
          if (params.data.a % 2 === 0) {
            return {background: '#F3F6F8'};
          } else {
            return {background: 'white'};
          }
        }
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;
    this.dev_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressRowTransform: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: this,
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        } else {
          if (params.data.a % 2 === 0) {
            return {background: '#F3F6F8'};
          } else {
            return {background: 'white'};
          }
        }
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    if (localStorage.getItem('all_attributes') !== null && localStorage.getItem('all_attributes') !== 'null' && JSON.parse(localStorage.getItem('all_attributes')) !== null) {
      let attrlist = JSON.parse(localStorage.getItem('all_attributes'));
      if (attrlist !== null) {
        attrlist = attrlist.filter(attr => attr.type === 'user').map(obj => obj.name);
        if (attrlist !== null) {
          this.attributeList = attrlist;
        }
      }
    }
    this.configureGrid();
    this.readPageParms(params => {
      this.loadPage();
    });
  }

  configureGrid() {
    this.terr_columnDefs = [
      {headerName: "Territory Code", field: "code"},
      {headerName: "Territory", field: "name"},
      {headerName: "Region", field: "region"},
      {headerName: "Cluster", field: "cluster"},
      {headerName: "Manager", field: "terr_mgr"}
    ];
    const inThis = this;
    this.attr_columnDefs = [
      {
        headerName: 'Attribute', field: 'name', menuTabs: [], width: 60,
        editable(params) {
          return params.context.editMode;
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: inThis.attributeList
        } as IRichCellEditorParams,
      },
      {
        headerName: 'Value', field: 'value', menuTabs: [], width: 60,
        editable(params) {
          return params.context.editMode;
        }
      },
      {
        headerName: "",
        headerClass: 'myagheader',
        field: "delete",
        cellStyle: {textAlign: 'right'},
        width: 20,
        resizable: true,
        cellRenderer(params) {
          if (params.data && params.context.editMode && params.data.active !== false) {
            return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          } else {
            return "";
          }
        }
      },
    ];
    this.dev_columnDefs = [
      {headerName: "Installed Date", field: "installed_date", valueGetter: params => this.sharedService.formatDateTime(params?.data?.installed_date)},
      {headerName: "Model", field: "model_name"},
      {headerName: "App Version", field: "app_version"}
    ];
  }

  loadPage() {
    this.dataLoaded = false;
    this.terr_dataLoaded = false;
    this.attr_dataLoaded = false;
    this.terr_rowData = [];
    this.attr_rowData = [];
    if (this.activeID > 0) {
      this.newMode = false;
      this.editMode = false;
      //   View Mode
      //   Page Can be ready only after detail Object is fetched
      this.loadPageForView(() => {
        this.pageReady = true;
      });
    } else {
      this.newMode = true;
      this.editMode = true;
      //   Page Can be ready only after detail Object is fetched
      //    Throw Error
    }
  }

  loadPageForView(callback) {
    this.loadActiveObject(() => {
      if (this.activeObject) {
        this.terr_rowData = this.activeObject.terr;
        this.terr_dataLoaded = true;
        this.dev_rowData = this.activeObject.devices;
        this.dev_dataLoaded = true;
        this.activeObject = this.activeObject.master;
        if ((this.activeObject.attributes !== null)) {
          const attr = this.activeObject.attributes;
          for (const i in attr) {
            this.attr_rowData.push({name: i, value: attr [i]});
          }
          this.attr_dataLoaded = true;
        }
        if (this.activeObject['user_store_fname']) {
          this.fetchImage([{store_fname: this.activeObject['user_store_fname'], container: "res_users"}]);
        }
      }
    });

  }

  terrOnGridReady(params) {
    this.terr_gridOptions.api.showLoadingOverlay();

    this.terr_gridApi = params.api;
    this.terr_gridColumnApi = params.columnApi;
    params.api.setRowData(this.terr_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    this.terr_gridApi.sizeColumnsToFit();
  }

  attrOnGridReady(params) {
    this.attr_gridOptions.api.showLoadingOverlay();

    this.attr_gridApi = params.api;
    this.attr_gridColumnApi = params.columnApi;
    params.api.setRowData(this.attr_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    this.attr_gridApi.sizeColumnsToFit();
  }

  devOnGridReady(params) {
    this.dev_gridOptions.api.showLoadingOverlay();

    this.dev_gridApi = params.api;
    this.dev_gridColumnApi = params.columnApi;
    params.api.setRowData(this.dev_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    this.dev_gridApi.sizeColumnsToFit();
  }

  action_edit() {
    super.action_edit();
    if (this.activetab === 'Attributes') {
      console.log(this.editMode);
      this.attr_gridApi.setRowData(this.attr_rowData);
    }
  }

  action_save() {
    this.activeObject.new_terr = [];
    this.activeObject.remove_terr = [];
    if (this.terr_rowData && this.terr_rowData.length > 0) {
      this.terr_rowData.forEach(row => {
        if (row.active === false) {
          this.activeObject.remove_terr.push(row);
        } else {
          this.activeObject.new_terr.push(row);
        }
      });
    }
    const attributeData: any = {};
    if (this.attr_rowData && this.attr_rowData.length > 0) {
      for (const i in this.attr_rowData) {
        attributeData[this.attr_rowData[i]['name']] = this.attr_rowData[i]['value'];
      }
    }
    this.activeObject.attributes = attributeData;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.activeObject;
    this.appComponent.pageReady = false;
    this.apiService.post('/api/pwa_admin_connects/user_update', paramObject)
      .subscribe(res => {
        this.appComponent.pageReady = true;
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success('User updated Successfully');
          this.loadPage();
        } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            if (res.results.msg) {
              this.toastr.error(res.results.msg);
            } else {
              this.toastr.error('Failed to update User');
            }
        } else {
          this.toastr.error('Failed to update User');
        }
      }, error => {
        console.log(error);
        this.appComponent.pageReady = true;
        this.toastr.error('Failed to update User');
      });
  }

  quickSearch() {

  }

  action_add() {
    if (this.activetab === "Territory") {
      let rows = [];
      if (this.terr_rowData && this.terr_rowData.length > 0) {
        rows = this.terr_rowData.filter(x => !x.hasOwnProperty('active') || x.active === true);
      }
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "70%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          territories: rows
        }
      };
      const creatediaeref = this.dialog.open(TerritoryAddComponent, config);

      creatediaeref.componentInstance.selectedTerritories.subscribe(data => {
        console.log(data);
        if (data !== []) {
          let indterritory: any = {};
          if (!this.terr_rowData) {
            this.terr_rowData = [];
          }
          this.terr_rowData.forEach(item => {
            const found = data.find(x => x.id === item.territory_id);
            if (!found) {
              item.active = false;
            }
          });
          for (const item of data) {
            indterritory = {};
            indterritory.territory_id = item.id;
            indterritory.name = item.name;
            indterritory.code = item.code;
            const found =  this.terr_rowData.find(x => x.territory_id ===  indterritory.territory_id);
            if (!found) {
              this.terr_rowData.push(indterritory);
            }
          }
          this.terr_gridApi.setRowData(this.terr_rowData);
        }
      });
    } else if (this.activetab === "Attributes") {
      if (this.attributeList !== null && this.attributeList.length > 0) {
        const indrow = {
          name: this.attributeList[0],
          value: "1"
        };
        this.attr_rowData.splice(0, 0, indrow);
        this.attr_gridApi.setRowData(this.attr_rowData);
      } else {
        this.toastr.error("No Attribute Master records added");
      }
    }
  }

  showConfirmDialog(type) {
    let data;
    if (type === 'inactivate') {
      data = {
        title: "Inactivate User",
        message1: "Do you wish to Inactivate this User?",
        message2: "",
        showNoButton: true,
        yesButton: "Inactivate",
        noButton: "NO",
        deleteButton: true
      };
    } else if (type === 'activate') {
      data = {
        title: "Activate User",
        message1: "Do you wish to Activate this User?",
        message2: "",
        showNoButton: true,
        yesButton: "Activate",
        noButton: "NO",
        deleteButton: false
      };
    } else if (type === 'resign') {
      data = {
        title: "Mark Resign",
        message1: "Do you wish to Mark this user as Resigned?",
        message2: "",
        showNoButton: true,
        yesButton: "Resign",
        noButton: "NO",
        deleteButton: true
      };
    }
    this.openConfirmDialog(type, data);
  }

  postProcessConfirmCallback(type) {
    if (type === 'resign') {
      this.markResign(type);
    } else {
      this.updateUserActiveStatus(type);
    }
  }

  updateUserActiveStatus(type) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.user_id = this.activeObject['id'];
    if (type === 'inactivate') {
      paramObject.active = false;
    } else {
      paramObject.active = true;
    }
    this.appComponent.pageReady = false;
    this.apiService.post('/api/res_users/activate_inactivate_user', paramObject)
      .subscribe(res => {
        this.appComponent.pageReady = true;
        if (res.hasOwnProperty('result') && (res.result.status === 200)) {
          if (type === 'inactivate') {
            this.toastr.success('User Inactivated');
          } else {
            this.toastr.success('User Activated');
          }
          this.loadPage();
        } else if (res.hasOwnProperty('error') && res.error.message) {
          this.toastr.error(res.error.message);
        } else {
          this.toastr.error('Failed to update User');
        }
      }, error => {
        console.log(error);
        this.appComponent.pageReady = true;
        if (error?.error?.error?.message) {
          this.toastr.error(error.error.error.message);
        } else {
          this.toastr.error('Failed to update User');
        }
      });
  }

  markResign(type) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.user_id = this.activeObject['id'];
    this.appComponent.pageReady = false;
    this.apiService.post('/api/pwa_admin_connects/resign_user', paramObject)
      .subscribe(res => {
        this.appComponent.pageReady = true;
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success('User Resigned!!');
          this.loadPage();
        } else if (res.hasOwnProperty('error') && res.error.message) {
          this.toastr.error(res.error.message);
        } else {
          this.toastr.error('Failed to resign User');
        }
      }, error => {
        console.log(error);
        this.appComponent.pageReady = true;
        if (error?.error?.error?.message) {
          this.toastr.error(error.error.error.message);
        } else {
          this.toastr.error('Failed to resign User');
        }
      });
  }

  openChangePasswordDialog() {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        maxWidth: "20%",
        height: "auto",
        maxHeight: '100%',
        panelClass: 'custom-dialog-container',
        data : {
          id: this.activeObject.id
        }
      };
      creatediaeref = this.dialog.open(ChangePasswordPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data : {
          id: this.activeObject.id
        }

      };
      creatediaeref = this.dialog.open(ChangePasswordPopupComponent, config);
    }
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const file: File = files[0];
    if (files && file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
    /*if (file) {
      file.arrayBuffer().then(buff => {
        const x = new Uint8Array(buff); // x is your uInt8Array
        // perform all required operations with x here.
        console.log(x);
      });
      console.log("call finished");
    }*/
  }

  _handleReaderLoaded(readerEvt, file) {
    const binaryString = readerEvt.target.result;
    const base64textString = btoa(binaryString);
    this.activeObject['photo_datas'] = base64textString;
    this.activeObject['photo'] = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + base64textString);
  }

  attrCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.attr_gridApi.getSelectedRows();
    const rowindex = event.rowIndex;
    if (column === 'delete') {
      this.attr_rowData.splice(rowindex, 1);
      this.attr_gridApi.setRowData(this.attr_rowData);
    }
  }
}
