<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Add Orders</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div class="search-list row">
        <div class="col-md-8 pad0">
          <div class="search2" style="display: inline-block;">
            <span class="search-control"><img src="assets/icons/search.svg"/></span>
            <input id="filter-text-box" type="text" (input)="quickSearch()"
                   class="search-control-area"
                   placeholder="Search">
          </div>
        </div>

        <div class="col-md-4 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li>
                <div style="display: inline-block;">
                  <button
                    class="btn confirm-blue-btn1"
                    style="height: 35px;width:45px;margin-left: 5px;margin-right:5px;margin-bottom:4px;font-size: 12px !important;padding: 8px 12px !important;border-radius: 5px;"
                    (click)="getOrders()"
                  >GO
                  </button>
                </div>
              </li>
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <div class="date-picker1">
                  <span class="datepicker-icon"><img src="/assets/images/date.svg"></span>
                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt"
                         [owlDateTime]="dt" placeholder="Date">
                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-12 pad0">
        <div style="margin: 5px;">
          <ng-container style="margin: 5px;" *ngFor="let displayData of this.userData.filterData.displayData">
            <div title="Remove Filter" class="inputfilter" (click)="removeFilter(displayData)">
              <span class="filtername">{{displayData.name}}</span>
              <span class="filtervalue">{{displayData.value}}<i style="margin-left: 5px;cursor:pointer"
                                                                class="fal fa-times"></i></span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-md-12 pad0 mt-2">
        <div>
          <ag-grid-angular id="myGrid1" *ngIf="dataLoaded" style="height: 62vh" class="ag-theme-balham"
                           [columnDefs]="columnDefs" [gridOptions]="gridOptions" [context]="context"
                           (cellClicked)="onCellClicked($event)"
                           (rowSelected)="onRowSelected($event)"
                           (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
          </ag-grid-angular>
          <div *ngIf="preloader">
            <app-loadingstate></app-loadingstate>
          </div>
          <div *ngIf="emptyState">
            <app-emptystate></app-emptystate>
          </div>
          <app-error-handler
            [errorObject]="errorObject"
            [errorExists]="errorExists">
          </app-error-handler>
        </div>
      </div>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        class="btn confirm-blue-btn"
        (click)="addOrders()"
        [disabled]="!orderSelected">
        Save
      </button>
    </div>
  </div>
</section>
