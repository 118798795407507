<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div>
        <div class="col-md-12 col-12 nopadding pl-0">
          <div class="eod-list-card">
            <!--<span style="display: block; font-weight: 400; font-size: 18px;margin-bottom: 5px;margin-left: 15px;">{{c}}</span>-->
            <div class="row">
              <div class="eod-list-card-heading">
                <h3>{{customerDetails.name}}</h3>
              </div>
              <div style="position: absolute;right: 30px;">
                <div class="button-row">
                   <span (click)="fetchPreviousData()"><i
                     [ngClass]="{'disabledspan': rowIndex === 0,
                     'enabledspan': rowIndex >0}"
                     class="fal fa-arrow-circle-left fa-2x"></i></span>
                  <span style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;">{{rowIndex + 1}}
                    /{{customerData.length}}</span>
                  <span (click)="fetchNextData()"><i
                    [ngClass]="{'disabledspan': rowIndex === customerData.length-1,
                     'enabledspan': rowIndex < customerData.length-1}"
                    class="fal fa-arrow-circle-right fa-2x"></i></span>
                </div>
              </div>
              <!--  <div style="position: absolute;right: 30px;">
                  <div *ngIf="edit" class="button-row">
                    <button
                      mat-button
                      style="margin-right:15px;"
                      class="btn teal-btn"
                    >
                      SAVE
                    </button>
                    <span>
                           <a class="btn white-btn" (click)="discardClick()">DISCARD</a>
                        </span>
                  </div>
                  <div *ngIf="!edit" class="button-row">
                        <span>
                           <a class="btn white-btn" style="margin-right:15px;" (click)="editClick()">EDIT</a>
                        </span>
                  </div>
                </div>-->
            </div>

            <div class="eod-list-card-heading">
              <span style="color:#5D5D5D">{{customerDetails.planned_revenue}} at {{customerDetails.probability}}%</span>
            </div>
            <p style="border-bottom: 2px solid #f3f3f3; margin-top: 10px;"></p>
            <div class="eod-list-card-desc" style="width: 100%;">
              <div class="col-md-12 col-12 nopadding pl-0">
                <div class="col-md-2 pad0">
                  <label style="color:#7D7D7D">Email</label>
                  <p>{{customerDetails.email_from}}</p>
                </div>
                <div class="col-md-2 pad0">
                  <label style="color:#7D7D7D">Salesperson</label>
                  <p>{{customerDetails.salesman}}</p>
                </div>
                <div class="col-md-2 pad0">
                  <label style="color:#7D7D7D">Customer</label>
                  <p>{{customerDetails.partner_name}}</p>
                </div>
                <div class="col-md-2 pad0">
                  <label style="color:#7D7D7D">Partner Type</label>
                  <p>{{customerDetails.partner_type}}</p>
                </div>
                <div class="col-md-2 pad0">
                  <label style="color:#7D7D7D">Description</label>
                  <p>{{customerDetails.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-12 nopadding pl-0">
          <div>
            <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
              <mat-tab label="Overview">
              </mat-tab>
             <!-- <mat-tab label="Gallery">
              </mat-tab>
              <mat-tab label="Sales">
              </mat-tab>-->
              <mat-tab label="Attributes">
              </mat-tab>
            <!--  <mat-tab label="Change Request">
              </mat-tab>-->
            </mat-tab-group>
          </div>
        </div>
        <div *ngIf="activetab==='Sales'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px 0px;">
          <div class="col-md-7 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="row">
                <div class="eod-list-card-heading">
                  <h3 style="font-size: 16px;">SALES TREND</h3>
                </div>
                <div style="position: absolute; right: 30px;">
                  <div class="date-pickersmall">
                    <!--   <span class="datepicker-icon"><img src="../../assets/date.png"></span>-->
                    <span ><i style="   position: absolute;left: 6px;top: 6px;"
                              class="far fa-calendar-alt fa-2x"></i></span>
                    <input [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt"
                           placeholder="Date">
                    <!--  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>-->
                    <span ><i style=" position: absolute;right: 6px;top: 6px;"
                              class="fas fa-caret-down fa-2x"></i></span>
                    <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                  </div>
                </div>
              </div>
              <div class="eod-list-card-desc">
                <div *ngIf="salesDataLoaded" class="row chartcard">
                  <ngx-charts-bar-horizontal-2d
                    [view]="view1"
                    [scheme]="colorScheme"
                    [results]="categoryChartData"
                    [gradient]="gradient"
                    [xAxis]="showXAxis"
                    [yAxis]="showYAxis"
                    [xAxisTickFormatting]="formatNumber"
                    [barPadding]="1"
                    [legend]="showLegend"
                    [legendTitle]="''"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel"
                    [animations]="animations"
                  >
                  </ngx-charts-bar-horizontal-2d>
                  <!--    <div style="clear: both">
                        <div class="row">
                          &lt;!&ndash; <div class="input-color" style="margin-left:20px;">
                             <span class="input-color color-box" style="background-color: #F3622D"></span>
                             <span  class="input-color color-box-text" >LMTD Return</span>
                           </div>&ndash;&gt;
                          <div class="input-color">
                            <span class="input-color color-box" style="background-color: #F3622D"></span>
                            <span class="input-color color-box-text">LMTD</span>
                          </div>
                          <div class="input-color">
                            <span class="input-color color-box" style="background-color: #57B757"></span>
                            <span class="input-color color-box-text">MTD</span>
                          </div>
                        </div>
                      </div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 pad0" >
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">TARGET VS ACTUAL</h3>
              </div>
              <div class="eod-list-card-desc">
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activetab==='Gallery'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px 0px;">
          <!-- <div class="col-md-12 pad0" style="padding-right: 10px;">
             <div class="eod-list-card">
               <div class="eod-list-card-heading">
                 <h3 style="font-size: 16px;">GALLERY</h3>
               </div>
               <div class="eod-list-card-desc">
                 <div class="row" style="margin-top:20px;">
                   <div style="display: inline;" >
                     <ng-container *ngFor="let tile of galleryphotodata">
                       <img  [src]="tile['photo']" style="height: 150px;width:250px;margin-right: 15px;margin-bottom: 8px;"/>
                     </ng-container>
                   </div>
                 </div>
               </div>
             </div>
           </div>-->
          <div class="col-md-12 pad0" >
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">SHELF ANALYSIS</h3>
              </div>
              <div class="eod-list-card-desc">
                <div class="row" style="margin-top:20px;">
                  <div style="display: inline;">
                    <ng-container *ngFor="let tile of shelfGalleryData">
                      <img [src]="tile['photo']"
                           style="height: 150px;width:250px;margin-right: 15px;margin-bottom: 8px;"/>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pad0" >
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">BTL ACTIVITY</h3>
              </div>
              <div class="eod-list-card-desc">
                <div class="row" style="margin-top:20px;">
                  <div style="display: inline;">
                    <ng-container *ngFor="let tile of btlGalleryData">
                      <img [src]="tile['photo']"
                           style="height: 150px;width:250px;margin-right: 15px;margin-bottom: 8px;"/>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activetab==='Overview'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px 0px;">
          <div class="col-md-5 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">ADDRESS & CONTACT</h3>
              </div>
              <div class="eod-list-card-desc">
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Beat</label>
                    <p>{{customerDetails.beat}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Email</label>
                    <p>{{customerDetails.email}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Contact Person</label>
                    <p>{{customerDetails.contact_name}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Mobile</label>
                    <p>{{customerDetails.mobile}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <label style="color:#7D7D7D">Address</label>
                  <p>{{customerDetails.address}}</p>
                </div>
              </div>
            </div>
           <!-- <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">FINANCE</h3>
              </div>
              <div class="eod-list-card-desc">
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Pricelist</label>
                    <p>{{customerDetails.pricelist}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">GSTIN</label>
                    <p>{{customerDetails.gst_id}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Credit Limit</label>
                    <p>{{customerDetails.credit_limit}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Payment Mode</label>
                    <p>{{customerDetails.payment_mode}}</p>
                  </div>
                </div>
              </div>
            </div>-->
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">DETAILS</h3>
              </div>
              <div class="eod-list-card-desc">
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">OTP verified</label>
                    <p>{{entity.otp_verified}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">GST verified</label>
                    <p>{{entity.gst_verified}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Beat</label>
                    <p>{{entity.beat}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Territory</label>
                    <p>{{entity.territory}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Program</label>
                    <p>{{entity.program}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Class</label>
                    <p>{{entity.class_type}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">GST</label>
                    <p>{{entity.gst_id}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">PAN</label>
                    <p>{{entity.pan}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Total Mandatory</label>
                    <p>{{entity.total_mandatory_items}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Mandatory Filled</label>
                    <p>{{entity.mandatory_filled}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Payment Mode</label>
                    <p>{{entity.payment_mode}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 pad0">
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">MAP VIEW</h3>
              </div>
              <div class="eod-list-card-desc" style="width:100%">
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-4 pad0">
                    <label style="color:#7D7D7D">Region</label>
                    <p>{{customerDetails.region}}</p>
                  </div>
                  <div class="col-md-4 pad0">
                    <label style="color:#7D7D7D">Cluster</label>
                    <p>{{customerDetails.cluster}}</p>
                  </div>
                  <div class="col-md-4 pad0">
                    <label style="color:#7D7D7D">Territory</label>
                    <p>{{customerDetails.territory}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="map" id="map1" style="margin-top: 20px;">
                    <agm-map
                      [latitude]="latitude"
                      [longitude]="longitude"
                      [zoom]="zoom">
                      <agm-marker [latitude]="latitude" [longitude]="longitude">
                      </agm-marker>
                    </agm-map>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activetab==='Attributes'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px 0px;">
          <div class="col-md-6 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="row">
                <div class="eod-list-card-heading">
                  <h3 style="font-size: 16px;">VERTICALS</h3>
                </div>
                <span *ngIf="!verticalEdit" (click)="verticalEditClick()" title="Edit"
                      style="position: absolute;right: 40px; color: #183153;"><i class="fas fa-pen edit fa-2x"
                                                                                 data-action-type="edit"></i></span>
                <span *ngIf="verticalEdit" (click)="verticalEditClick()" title="Edit"
                      style="position: absolute;right: 40px; color: #183153;"><i class="fad fa-save edit fa-2x"
                                                                                 data-action-type="edit"></i></span>
              </div>

              <div class="eod-list-card-desc" style="width:100%">
                <div *ngIf="verticalEdit"
                     style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;"
                     (click)="addAttributeRow()">
                  <i class="fas fa-plus-circle"
                     style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                  <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                </div>
                <ag-grid-angular *ngIf="dataLoaded" id="myGrid1" style="height: 50vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                 (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                                 (cellClicked)="onCellClicked($event)"
                                 [context]="context">
                </ag-grid-angular>
              </div>
            </div>
          </div>
          <div class="col-md-6 pad0" >
            <div class="eod-list-card">
              <div class="row">
                <div class="eod-list-card-heading">
                  <h3 style="font-size: 16px;">PARAMS</h3>
                </div>
                <span *ngIf="!paramEdit" (click)="paramEditClick()" title="Edit"
                      style="position: absolute;right: 40px; color: #183153;"><i class="fas fa-pen edit fa-2x"
                                                                                 data-action-type="edit"></i></span>
                <span *ngIf="paramEdit" (click)="paramEditClick()" title="Edit"
                      style="position: absolute;right: 40px; color: #183153;"><i class="fad fa-save edit fa-2x"
                                                                                 data-action-type="edit"></i></span>
              </div>

              <div class="eod-list-card-desc" style="width:100%">
                <div *ngIf="paramEdit" style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;"
                     (click)="addParamRow()">
                  <i class="fas fa-plus-circle"
                     style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                  <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                </div>
                <ag-grid-angular *ngIf="dataLoaded1" id="myGrid2" style="height: 50vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"
                                 (gridReady)="onGridReady1($event)" [frameworkComponents]="frameworkComponents1"
                                 (cellClicked)="onCellClicked1($event)"
                                 [context]="context1">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activetab==='Change Request'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px 0px;">
          <div class="col-md-12 pad0" >
            <div class="eod-list-card">
              <!-- <div class="row">
                 <div class="eod-list-card-heading">
                   <h3 style="font-size: 16px;">ATTRIBUTES</h3>
                 </div>
                 <span *ngIf="!attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fas fa-pen edit fa-2x" data-action-type="edit"></i></span>
                 <span *ngIf="attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fad fa-save edit fa-2x" data-action-type="edit"></i></span>
               </div>-->

              <div class="eod-list-card-desc" style="width:100%">
                <ag-grid-angular *ngIf="dataLoaded2" id="myGrid3" style="height: 50vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefs2" [gridOptions]="gridOptions2"
                                 (gridReady)="onGridReady2($event)" [frameworkComponents]="frameworkComponents2"
                                 (cellClicked)="onCellClicked2($event)"
                                 [context]="context2">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
