
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {GenrateClaimsPopupComponent} from "./generate_claims_popup/generate_claims_popup.component";
import {PageBase} from "../../../pagebase/pagebase";
import {MultiBillSchemeCalculator} from "../../../pagebase/components/MultiBillSchemeCalculator";
import {GenrateMultiBillClaimsPopupComponent} from "./generate_multibill_claims_popup/generate_multi_bill_claims_popup.component";


@Component({
  selector: 'app-multibill-schemes',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class RaiseMultiBillClaimListComponent extends PageBase implements OnInit, IPage {

  // matActionMenuItemDeclaration = [{name: 'Generate Claim'}];

  public pinnedTotalData = [];

  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'notclaimed',
    values: [],
    show: true
  };

  ngOnInit(): void {

    this.sharedService.setHeader('Multi Bill Scheme Claims');
    this.showCustomFilter = true;
    this.showActionButton = true;

    this.customFilter.values = [
      {id: 'all', itemName: 'All'},
      {id: 'claimed', itemName: 'Claimed'},
      {id: 'notclaimed', itemName: 'Not Claimed'},
    ];
    this.statusConfig.success = 'Claimed';
    this.statusConfig.error = 'Not Claimed';
    this.statusConfig.warning = 'processing';

    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    // this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/pwa_dms_connects/get_multi_bill_scheme_claims';
    const inThis = this;
    this.columnDefs = [
      {headerName: "ID", field: "id", width: 20, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
        cellRenderer(params) {
          if (params.data) {
            if (params.data.invoice_no === 'TOTAL') {
              const displayElem = '<span style="font-weight: bold">' + params.value + '</span>';
              return displayElem;
            } else {
              return params.value;
            }
          }
        }},
      {headerName: "CODE", field: "code", width: 60},
      {headerName: "NAME", field: "name", width: 100},
      {headerName: "INVOICE COUNT", field: "inv_count", width: 40, cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: "INVOICE AMOUNT", field: "inv_amount", width: 40, cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: "SCHEME ID", field: "scheme_id", width: 30, hide: true},
      {headerName: "SCHEME CODE", field: "scheme_code", width: 80},
      {headerName: "SCHEME", field: "scheme_name", width: 100},
      {headerName: "SCHEME START", field: "scheme_start", width: 40, hide: true},
      {headerName: "SCHEME END", field: "scheme_end", width: 40, hide: true},
      {headerName: "QTY", field: "quantity", width: 40, cellStyle: {textAlign: 'right'}, hide: true},
      {headerName: "CLAIM AMOUNT", field: "claim_amount", width: 40, aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      { headerName: "CLAIM NO", field: "claim_no", width: 60},
      { headerName: "STATUS", field: "claim_status", width: 60, cellRenderer: inThis.sharedService.statusCellRenderer},
    ];

    this.getRowId = (params) => params.data.id;
  }


  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Retailer');
    } else  if (rows.length > 100) {
      this.toastr.error('Please select a maximum of 100 rows : ' + rows.length + ' selected');
    } else {
      if (menuitem.name === 'Generate Claim') {
        this.openClaimsDialog(rows);
      }
    }
  }

  checkAccessControl() {
    this.showCreate = false;
  }

  openClaimsDialog(rows) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "480px",
      maxHeight: "360px",
      data: {
        lines: rows,
        type: 'scheme'
      },
      panelClass: 'custom-dialog-container'
    };

    const confirmdiaeref = this.dialog.open(GenrateMultiBillClaimsPopupComponent, config);
    confirmdiaeref.componentInstance.saveData.subscribe(data => {
      if (data !== undefined ) {
        this.dialog.closeAll();
        this.toastr.success('Claim Generated Successfully');
        const claim = data;

        const rowsToUpdate = [];
        rows.forEach(r => {
          const ord = this.rowData.find(item => item.id === r['id']);
          rowsToUpdate.push(this.updateImmutableObject(ord, {claim_status: claim.status, claim_no: claim.id}));
        });

        this.gridApi.applyTransactionAsync({update: rowsToUpdate}, () => {
        });

        if (claim.id > 0) {
          this.router.navigate([this.apiService.redirect_url + '/dms/claim_detail'], {
            relativeTo: this.route,
            queryParams: {id: claim.id}
          });
        }

        // const rowsToUpdate = [];
        // data.line_ids.forEach(line_id => {
        //   const line = this.rowData.find(item => item.id === line_id);
        //   rowsToUpdate.push(this.updateImmutableObject(line, {claim_status: data.status, claim_no: data.name}));
        // });
        //
        // this.gridApi.applyTransactionAsync({update: rowsToUpdate}, () => {
        // });


      }
    });
  }

  postProcessData() {

    const sch = new MultiBillSchemeCalculator(this.apiData['sales'], this.apiData['schemes']);
    this.rowData = sch.ComputeScheme();
    this.calculateTotalData();
  }

  calculateTotalData() {
    let inv_count = 0.00;
    let inv_amount = 0.00;
    let claim_amount = 0;
    this.rowData.forEach(data => {
      inv_count += parseFloat(data.inv_count);
      inv_amount += parseFloat(data.inv_amount);
      if (data.claim_amount) {
        claim_amount += parseInt(data.claim_amount, 10);
      }
    });

    this.pinnedTotalData = [{
      id: 'TOTAL',
      code: null,
      name: null,
      scheme_id: null,
      scheme_start: null,
      scheme_end: null,
      inv_count,
      inv_amount: inv_amount.toFixed(this.apiService.decimalPolicy),
      claim_amount: claim_amount.toFixed(this.apiService.decimalPolicy),
    }];
    this.gridOptions.pinnedBottomRowData = this.pinnedTotalData;
  }


}
