<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2 *ngIf="teamObject.id === 0">Create Team</h2>
      <h2 *ngIf="teamObject.id > 0">Edit Team</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div>
        <form novalidate  id="ngForm" #createeditteamform="ngForm">
         <!-- <div style="margin-top: 10px;">
            <span class="detaillabel2">Team Manager</span>
            <span class="requiredspan"> *</span>
            <div style="margin-top: 5px;" class="formselect">
              <div class="formselect">
                <ng-select [items]="salesmenList"
                           name="user_id"
                           #user_id="ngModel"
                           bindLabel="itemname"
                           bindValue="id"
                           (ngModelChange)="onTeamLeaderChange($event)"
                           [(ngModel)]="teamObject.user_id">
                  >
                </ng-select>
              </div>
              <div *ngIf="user_id.errors && (user_id.dirty || user_id.touched)">
                <span *ngIf="user_id.errors.required" class="text-danger">Manager is required</span>
              </div>
            </div>
          </div>-->
          <div>
            <span class="detaillabel2">Team Name</span>
            <span class="requiredspan"> *</span>
            <div style="margin-top: 5px;">
                <input
                  class="forminputwhitebg"
                  style="display: block;  font-size: 14px; width: 100%;"
                  type="text"
                  name="name"
                  [(ngModel)]="teamObject.name"
                  #name="ngModel"
                  required
                >
                <div *ngIf="name.errors && (name.dirty || name.touched)">
                  <span *ngIf="name.errors.required" class="text-danger">Name is required</span>
                </div>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <span class="detaillabel2">Team Code</span>
            <span class="requiredspan"> *</span>
            <div style="margin-top: 5px;">
              <input
                class="forminputwhitebg"
                style="display: block;  font-size: 14px; width: 100%;"
                type="text"
                name="code"
                [(ngModel)]="teamObject.code"
                #code="ngModel"
                required
              >
              <div *ngIf="code.errors && (code.dirty || code.touched)">
                <span *ngIf="code.errors.required" class="text-danger">Code is required</span>
              </div>
            </div>
          </div>
         <!-- <div style="margin-top: 15px;">
            <span class="detaillabel2">Parent Team</span>
            <div style="margin-top: 5px;"  class="formselect">
              <ng-select [items]="teamList"
                         name="parent_id"
                         #parent_id="ngModel"
                         bindLabel="itemname"
                         bindValue="id"
                         appendTo="body"
                         class="custom"
                         [(ngModel)]="teamObject.parent_id">
                >
              </ng-select>
            </div>
          </div>-->
        </form>
      </div>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="btn confirm-blue-btn"
        (click)="saveTeam()"
        form="ngForm"
        [disabled]="createeditteamform.form.invalid"
      >
        Save
      </button>
    </div>
  </div>
</section>
