<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div style="margin-right: 10px;">
        <div class="col-md-12 cardbox" style="width: 100%;">
          <div>
            <div class="search-list">
              <div class="row" style="height: 33px;">
                <div *ngIf="!edit" class="button-row">
                  <button
                    class="btn confirm-blue-btn"
                    (click)="editClick()"
                  >EDIT
                  </button>
                </div>
                <div *ngIf="edit" class="button-row" style="margin-left: 10px;">
                  <button
                    style="margin-left:0px !important;"
                    class="btn grey-btn"
                    (click)="discardClick()"
                  >
                    DISCARD
                  </button>
                </div>
                <div *ngIf="edit" class="button-row" style="margin-left: 10px;">
                  <button
                    class="btn confirm-blue-btn"
                    (click)="updateSurveyDetails()"
                    form="ngForm">
                    SAVE
                  </button>
                </div>
                <div *ngIf="!edit && listLength > 0" style="position: absolute;right: 40px;">
                  <div class="button-row">
                  <span style="margin-right: 20px;">
                         <a class="btn teal-border-btn" style="margin-right:15px;"
                            (click)="viewReport()">VIEW REPORT</a>
                        </span>
                    <span (click)="movePrevious()"><i
                      [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                      class="fal fa-arrow-circle-left fa-2x"></i></span>
                    <span
                      style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{ rowIndex + 1 }}
                      /{{ listLength }}</span>
                    <span (click)="moveNext()"><i
                      [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                      class="fal fa-arrow-circle-right fa-2x"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 40px;">
            <div class="col-md-12 pad0">
              <form novalidate id="ngForm" #taskform="ngForm">
                <div class="col-md-2 pl-0 pr-20">
                  <label class="detaillabel">Code</label>
                  <div>
                    <!-- <div  *ngIf="edit">
                       <input
                         hidden=true
                         class="forminput"
                         style="width: 80%;"
                         name="mi_code"
                         [(ngModel)]="marketIntelligenceDetails.code"
                         #mi_code="ngModel"
                         required
                       >
                       <div *ngIf="mi_code.errors && (mi_code.dirty || mi_code.touched)">
                         <span *ngIf="mi_code.errors.required" class="text-danger">Code is required</span>
                       </div>
                     </div>-->
                    <p class="detailvalue1">{{ surveyDetails.code }}</p>

                  </div>
                </div>
                <div class="col-md-3 pl-0 pr-30">
                  <label class="detaillabel">Questionnaire</label>
                  <div>
                    <!-- <div *ngIf="edit">
                       <input
                         hidden=true
                         class="forminput"
                         style="width: 70%;"
                         type="text"
                         name="mi_name"
                         [(ngModel)]="marketIntelligenceDetails.name"
                         #mi_name="ngModel"
                         required
                       >
                       <div *ngIf="mi_name.errors && (mi_name.dirty || mi_name.touched)">
                         <span *ngIf="mi_name.errors.required" class="text-danger">Name is required</span>
                       </div>
                     </div>-->
                    <p class="detailvalue1">{{ surveyDetails.name }}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-20">
                  <label class="detaillabel">Description</label>
                  <div>
                    <!--<div *ngIf="edit">
                      <input
                        hidden=true
                        class="forminput"
                        type="text"
                        name="mi_description"
                        [(ngModel)]="marketIntelligenceDetails.description"
                        #mi_description="ngModel"
                        style="width: 80%;"
                        required
                      >
                      <div *ngIf="mi_description.errors && (mi_description.dirty || mi_description.touched)">
                        <span *ngIf="mi_description.errors.required" class="text-danger">Description is required</span>
                      </div>
                    </div>-->
                    <p class="detailvalue1">{{ surveyDetails.description }}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-20">
                  <label class="detaillabel">Start Date</label>
                  <div>
                    <!--<div *ngIf="edit">
                      <input
                        hidden=true
                        class="forminput"
                        type="text"
                        name="mi_start_date"
                        [(ngModel)]="marketIntelligenceDetails.start_date"
                        #mi_start_date="ngModel"
                        [owlDateTime]="dt1"
                        [owlDateTimeTrigger]="dt1"
                        required
                      >
                      <owl-date-time (afterPickerClosed)="setStartDate(dt1)" [pickerType]="'calendar'" #dt1></owl-date-time>
                      <div *ngIf="mi_start_date.errors && (mi_start_date.dirty || mi_start_date.touched)">
                        <span *ngIf="mi_start_date.errors.required" class="text-danger">Start Date is required</span>
                      </div>
                    </div>-->
                    <p class="detailvalue1">{{ surveyDetails.start_date }}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-20">
                  <label class="detaillabel">End Date</label>
                  <div>
                    <div *ngIf="edit">
                      <input
                        class="forminput"
                        type="text"
                        name="mi_end_date"
                        #mi_end_date="ngModel"
                        [(ngModel)]="surveyDetails.end_date"
                        [owlDateTime]="dt2"
                        [owlDateTimeTrigger]="dt2"
                        [min]="surveyDetails.end_date"
                        required
                      >
                      <owl-date-time (afterPickerClosed)="setEndDate(dt2)" [pickerType]="'calendar'" #dt2></owl-date-time>
                      <div *ngIf="mi_end_date.errors && (mi_end_date.dirty || mi_end_date.touched)">
                        <span *ngIf="mi_end_date.errors.required" class="text-danger">End Date is required</span>
                      </div>
                    </div>
                    <p *ngIf="!edit" class="detailvalue1">{{ surveyDetails.end_date }}</p>
                  </div>
                </div>
                <div class="col-md-1 pl-0 pr-30">
                  <label class="detaillabel">Max Score</label>
                  <div>
                    <div *ngIf="edit">
                      <input
                        class="forminput"
                        style="width: 70%;"
                        type="text"
                        name="mi_max_score"
                        [(ngModel)]="surveyDetails.max_score"
                        #mi_max_score="ngModel"
                        required
                      >
                      <div *ngIf="mi_max_score.errors && (mi_max_score.dirty || mi_max_score.touched)">
                        <span *ngIf="mi_max_score.errors.required" class="text-danger">Max Score is required</span>
                      </div>
                    </div>
                    <p *ngIf="!edit" class="detailvalue1">{{ surveyDetails.max_score }}</p>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
        <div class="col-md-12 cardbox mt-4">
            <div class="search-list">
              <div class="col-md-6 col-12 nopadding pl-0">
                <div>
                  <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
                    <mat-tab label="Questions">
                    </mat-tab>
                    <mat-tab label="Territory">
                    </mat-tab>
                    <mat-tab label="Criteria">
                    </mat-tab>
                    <mat-tab *ngIf="surveyType === 'retail'" label="Outlets">
                    </mat-tab>
                  </mat-tab-group>
                </div>

              </div>
              <div class="col-md-6 col-12 pad0 text-right">
                <div class="filters">
                  <ul class="list-unstyled">
                    <li class="margLR" style="margin-left: 10px;">
                      <div style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;"
                           (click)="createDialog()"
                           *ngIf="edit && (activetab=='Questions' || activetab == 'Territory')">
                        <i class="fas fa-plus-circle"
                           style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                        <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                      </div>
                      <div style="display: inline-block;margin-right: 10px;" *ngIf="edit && activetab=='Outlets'"
                           (click)="uploadClick()">
                        <img data-toggle="modal" data-target="#popupModal"
                             src="/assets/upload.svg"/>
                      </div>
                      <div class="search" style="display: inline-block;">
                        <span class="search-control"><img src="assets/searchicon.png"/></span>
                        <input id="filter-text-box" type="text" (input)="quickSearch()"
                               class="search-control-area"
                               placeholder="Search">
                      </div>
                      <div style="display: inline-block;margin-right: 10px;">
                        <!-- <button (click)="createDialog()" class="btn teal-btn" style="height: 42px;margin-bottom: 10px;margin-left:10px;">NEW TASK</button>-->
                      </div>
                    </li>
                    <!-- <li class="margLR" style="margin-left: 10px;">
                       <div
                         class="actionbutton"
                         title="Upload"
                       >
                         <img style=" display:block;margin:auto;" src="../../assets/upload2.svg">
                       </div>
                     </li>-->
                    <li class="margLR" *ngIf="activetab==='Questions'">
                      <app-excelicon [gridOption]="gridOptions" [title]="'Question Report'"></app-excelicon>
                    </li>
                    <li class="margLR" *ngIf="activetab==='Territory'">
                      <app-excelicon [gridOption]="gridOptions1" [title]="'Territory Report'"></app-excelicon>
                    </li>
                    <li class="margLR" *ngIf="activetab==='Criteria'">
                      <app-excelicon [gridOption]="gridOptions2" [title]="'Criteria Report'"></app-excelicon>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-12 pad0">
                <div *ngIf="activetab==='Questions'" style="margin-top: 10px;">
                  <ag-grid-angular id="myGrid2" *ngIf="loaded" style="width: 100%;height: calc(70vh - 160px);"
                                   class="ag-theme-alpine" [defaultColDef]="defaultColDef"
                                   [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                   (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                                   (cellClicked)="onCellClicked($event)"
                                   [context]="context">
                  </ag-grid-angular>
                  <div *ngIf="preloader">
                    <app-loadingstate></app-loadingstate>
                  </div>
                  <div *ngIf="empty_state">
                    <app-emptystate></app-emptystate>
                  </div>
                </div>

                <div *ngIf="activetab==='Territory'" style="margin-top: 10px;">
                  <ag-grid-angular id="myGrid2" *ngIf="loaded1" style="width: 100%;height:calc(70vh - 160px);"
                                   class="ag-theme-alpine" [defaultColDef]="defaultColDef"
                                   [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"
                                   (gridReady)="onGridReady1($event)" [frameworkComponents]="frameworkComponents"
                                   (cellClicked)="onCellClicked1($event)"
                                   [context]="context">
                  </ag-grid-angular>
                  <div *ngIf="preloader1">
                    <app-loadingstate></app-loadingstate>
                  </div>
                  <div *ngIf="empty_state1">
                    <app-emptystate></app-emptystate>
                  </div>
                </div>

                <div *ngIf="activetab==='Criteria'" style="margin-top: 10px;">
                  <ag-grid-angular id="myGrid2" *ngIf="loaded2" style="width: 100%;height:calc(70vh - 160px);"
                                   class="ag-theme-alpine" [defaultColDef]="defaultColDef"
                                   [columnDefs]="columnDefs2" [gridOptions]="gridOptions2"
                                   (gridReady)="onGridReady2($event)" [frameworkComponents]="frameworkComponents"
                                   (cellClicked)="onCellClicked2($event)"
                                   [context]="context">
                  </ag-grid-angular>
                  <div *ngIf="preloader2">
                    <app-loadingstate></app-loadingstate>
                  </div>
                  <div *ngIf="empty_state2">
                    <app-emptystate></app-emptystate>
                  </div>
                </div>

                <div *ngIf="activetab==='Outlets'" style="margin-top: 10px;">
                  <ag-grid-angular id="myGrid2" *ngIf="outletMapLoaded" style="width: 100%;height:calc(70vh - 160px);"
                                   class="ag-theme-alpine"
                                   [columnDefs]="columnDefsOutletMap" [gridOptions]="gridOptionsOutletMap"
                                   (gridReady)="onGridReadyOutletMap($event)"
                                   [frameworkComponents]="frameworkComponents"
                                   [context]="context">
                  </ag-grid-angular>
                  <div *ngIf="preloaderOutletMap">
                    <app-loadingstate></app-loadingstate>
                  </div>
                  <div *ngIf="empty_state_OutletMap">
                    <app-emptystate></app-emptystate>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
