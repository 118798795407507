import {Component, OnInit} from "@angular/core";
import {BaseUpload} from "../../pagebase/base_upload/base_upload";

@Component({
  selector: 'app-supplier-vertical-upload',
  templateUrl: '../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../pagebase/base_upload/base_upload.scss']

})

export class SupplierVerticalUploadComponent extends BaseUpload {
  async setViewParams() {
    this.header = 'New Customer Supplier Vertical Salesman Upload';
    this.sampleText1 = 'DOWNLOAD TEMPLATE';
    this.sampleText2 = '';

    this.excel_columns = [{C: 'A', H: 'Customer Code', V: 'DN-00029726', required: true},
      {C: 'B', H: 'Supplier Code', V: '5916', required: true},
      {C: 'C', H: 'Vertical Code', V: '70', required: true},
      {C: 'D', H: 'Potential', V: '100', required: false},
      {C: 'E', H: 'Action', V: 'Add/Remove', required: true},
      {C: 'F', H: 'Territory Code', V: 'TTYKA10128', required: false},
      {C: 'G', H: 'Salesman Code', V: '210608', required: false}
    ];
    this.step1Text = 'Download all customer supplier vertical salesman list or sample template for reference.';
    this.step2Text = 'Required fields are Customer Code,Supplier Code, Vertical Code and Action. Territory Code, Potential and Salesman Code are optional';
    this.actionText = 'Fill excel with relevant details';

    await this.fetchCustomerData();
    await this.fetchSuppData();
    await this.fetchBgData();
    this.dataLoaded = true;
  }

  sampleDownload1() {
    this.downloadSampleData('customer_supplier_vertical_salesman_upload_template')
  }

  configureGrid() {
    this.API_URL = '/api/pwa_admin_connects/upload_new_customer_supp_vertical';
    this.columnDefs = [
      {headerName: "ID", field: "uid", width: 100, hide: true},
      {headerName: "Customer Code", field: "cust_code", width: 100, cellStyle: {textAlign: 'center'}},
      {headerName: "Supplier Code", field: "supp_code", width: 100, cellStyle: {textAlign: 'center'}},
      {headerName: "Salesman Code", field: "salesman_code", width: 100, cellStyle: {textAlign: 'center'}},
      {headerName: "Vertical Code", field: "vc", width: 100, cellStyle: {textAlign: 'center'}},
      {headerName: "Action", field: "action", width: 100, cellStyle: {textAlign: 'center'}},
      {headerName: "Potential", field: "potential", width: 100, cellStyle: {textAlign: 'center'}},
      {headerName: "Territory Code", field: "territory_code", width: 100, cellStyle: {textAlign: 'center'}}
    ]
  }

  async fetchCustomerData() {
    const res = await this.apiService.postPromise('/api/pwa_admin_connects/getCustomersForVerticals', this.userData);
    if (res && res.results.status === 200) {
      this.customerList = res.results.data;
    } else {
      this.toastr.error('Error fetching customer master');
    }
  }

  async fetchSuppData() {
    const res = await this.apiService.postPromise('/api/pwa_admin_connects/getSuppForVerticals', this.userData);
    if (res && res.results.status === 200) {
      this.supplierList = res.results.data;
    } else {
      this.toastr.error('Error fetching Supplier master');
    }
  }

  async fetchBgData(){
    const res = await this.apiService.postPromise('/api/pwa_admin_connects/getBgForVerticals', this.userData);
    if (res && res.results.status === 200) {
      this.bgList = res.results.data;
    } else {
      this.toastr.error('Error fetching Verticals master');
    }
  }

  SetInvalidStatus(row, errMsg, field) {
    row.valid = 'Invalid';
    if (!row.errorDetails) {
      row.errorDetails = {};
    }
    if (!row.errorDetails[field]) {
      row.errorDetails[field] = errMsg;
    } else {
      row.errorDetails[field] += '\n' + errMsg;
    }
    //console.log(row.errorDetails);
  }

  validate() {
    const inThis = this;
    let index = 0;
    this.validRecords = 0;
    this.errorRecords = 0;

    this.uploaded_data.forEach(data => {
      const indrow: any = {};
      indrow['cust_code'] = this.getExcelFieldValue(data, ['Customer Code', 'cust_code']);
      indrow['supp_code'] = this.getExcelFieldValue(data, ['Supplier Code', 'supp_code']);
      indrow['vc'] = Number(this.getExcelFieldValue(data, ['Vertical Code', 'vc']));
      indrow['action'] = this.getExcelFieldValue(data, ['Action', 'action']);
      indrow['potential'] = this.getExcelFieldValue(data, ['Potential', 'potential']);
      indrow['territory_code'] = this.getExcelFieldValue(data, ['Territory Code', 'territory_code']);
      indrow['salesman_code'] = this.getExcelFieldValue(data, ['Salesman Code', 'salesman_code']);

      if (!indrow['cust_code'] || indrow['cust_code'] === '') {
        this.SetInvalidStatus(indrow, 'Customer Code is a mandatory field', 'cust_code');
      } else {
        const customerObject = inThis.customerList.find(x => (x.hasOwnProperty('code') && String(x.code) === indrow['cust_code']));
        if (!customerObject) {
          this.SetInvalidStatus(indrow, 'No Matching record for Customer Code ' + indrow['cust_code'], 'cust_code');
        } else {
          indrow['cust_id'] = customerObject.id;
        }
      }

      if (!indrow['supp_code'] || indrow['supp_code'] === '') {
        this.SetInvalidStatus(indrow, 'Supplier Code is a mandatory field', 'supp_code');
      } else {
        const supplierObject = inThis.supplierList.find(x => (x.hasOwnProperty('code') && String(x.code) === indrow['supp_code']));
        if (!supplierObject) {
          this.SetInvalidStatus(indrow, 'No Matching record for Supplier Code ' + indrow['supp_code'], 'supp_code');
        } else {
          indrow['supp_id'] = supplierObject.id;
        }
      }

      if (!indrow['vc'] || indrow['vc'] === '') {
        this.SetInvalidStatus(indrow, 'Vertical Code is a mandatory field', 'vc');
      } else {
        const bgObject = inThis.bgList.find(x => (x.id !== undefined && x.id === Number(indrow['vc'])));
        if (!bgObject) {
          this.SetInvalidStatus(indrow, 'No Matching record for Vertical Code ' + indrow['vc'], 'vc');
        }
      }

      if (!indrow['action'] || indrow['action'] === '' || !['Add', 'add', 'Remove', 'remove'].includes(indrow['action'])) {
        this.SetInvalidStatus(indrow, 'Action is a mandatory field and must be either Add or Remove', 'action');
      }

      if (indrow['salesman_code'] && indrow['salesman_code'] !== '') {
        const salesman = inThis.userList.find(x => (x.emp_code !== undefined && String(x.emp_code) === indrow['salesman_code']));
        if (!salesman) {
          this.SetInvalidStatus(indrow, 'No Matching record for Salesman Code '+ indrow['salesman_code'], 'salesman_code');
        } else {
          indrow['salesman_id'] = salesman.id;
        }
      }

      if (indrow['territory_code'] && indrow['territory_code'] !== '') {
        const terr = inThis.territoriesList.find(x => (x.code !== undefined && String(x.code) === indrow['territory_code']));
        if (!terr) {
          this.SetInvalidStatus(indrow, 'No Matching record for Territory Code '+ indrow['territory_code'], 'territory_code');
        } else {
          indrow['territory_id'] = terr.id;
        }
      }

      if (indrow.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        indrow.valid = 'Valid';
        this.validRecords++;
      }
      index++;
      indrow['uid'] = index;
      this.rowData.push(indrow);
    });
    this.totalRecords = this.rowData.length;
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
    this.setCellClassRulesAndTooltips()
  }
  downloadDataWithErrors() {
    super.downloadDataWithErrors();
  }
}
