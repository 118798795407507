<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div class="search-list">
        <div class="col-md-5 pad0">
          <div class="search" style="display: inline-block;">
            <span class="search-control"><img src="../../../../assets/searchicon.png"/></span>
            <input  id="filter-text-box" type="text" placeholder="filter" (input)="quickSearch()" class="search-control-area"
                    placeholder="Search">
          </div>
        </div>
        <div class="col-md-2 pad0 nopadding">

        </div>
        <div class="col-md-5 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <ng-template adHost3></ng-template>
              </li>
              <!-- <li class="margLR">
                 <app-printicon [gridOption]="gridOptions" [styleGrid]="styleGrid"></app-printicon>
               </li>-->
              <!-- <li class="margLR">
                 <div
                   class="actionbutton"
                   title="Upload"
                 >
                   <img style=" display:block;margin:auto;" src="../../assets/upload2.svg">
                 </div>
               </li>-->
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <div class="date-picker">
                  <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
              <li class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'Retail Audit Report'"></app-excelicon>
              </li>
              <li class="margLR">
                <div
                  class="actionbutton"
                  title="Upload"
                >
                  <span title="Zip" (click)="zipDownload()"><i class="fas fa-folder-download fa-2x" style="color:#183153;padding-left: 4px;"></i></span>
                 <!-- <img (click)="zipDownload()" style=" display:block;margin:auto;" src="../../assets/upload2.svg">-->
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div>
            <ag-grid-angular id="myGrid1" *ngIf="loaded" style="height: 70vh" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                             [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                             (cellClicked)="onCellClicked($event)"
                             [context]="context">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
