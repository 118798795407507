import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {DomSanitizer} from "@angular/platform-browser";
import {EWAY_MASTERCODES} from "../../../assets/api/eway_mastercodes.json";
import {SharedService} from "../../_services/shared_service";

@Component({
  selector: 'app-picklist-print',
  templateUrl: './picklist_print.component.html',
  styleUrls: ['./picklist_print.component.scss']
})
export class PicklistPrintComponent implements OnInit {
  @Input() order_detail: object;
  public math = Math;
  totalQty = 0;
  totalValue = 0.0;
  showReportingUom = false;

  constructor(public apiService: ApiService,
              private sharedService: SharedService,
              private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (this.sharedService.ACLcheck('feature_print_show_reporting_uom')) {
      this.showReportingUom = true;
    } else {
      this.showReportingUom = false;
    }

    console.log(this.order_detail);

    if (this.order_detail && this.order_detail['sale_items']) {
      this.order_detail['sale_items'].forEach(line => {
        this.totalQty += line['abs_qty'];
        this.totalValue += parseFloat(line['amount_total']);
      });
    }

    if (this.order_detail['logo']) {
      this.fetchImage();
    }
    if (this.order_detail['amount_total']) {
      this.order_detail['amount_total'] = parseFloat(this.order_detail['amount_total']);
    }
    if (this.order_detail['amount_subtotal']) {
      this.order_detail['amount_subtotal'] = parseFloat(this.order_detail['amount_subtotal']);
    }
    if (this.order_detail['cgst']) {
      this.order_detail['cgst'] = parseFloat(this.order_detail['cgst']);
    }
    if (this.order_detail['sgst']) {
      this.order_detail['sgst'] = parseFloat(this.order_detail['sgst']);
    }
    if (this.order_detail['igst']) {
      this.order_detail['igst'] = parseFloat(this.order_detail['igst']);
    }
    if (this.order_detail['cess']) {
      this.order_detail['cess'] = parseFloat(this.order_detail['cess']);
    }
  }

  async fetchImage() {
    const resp = await this.apiService.fetchImageWait({
      access_token: localStorage.getItem('resfreshToken'),
      data: [{store_fname: this.order_detail['logo'], container: "res_partner"}]
    });
    console.log(resp);
    if (resp['result'].length > 0 && resp['result'][0].bill_datas) {
      this.order_detail['logo'] = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + resp['result'][0].bill_datas);
    } else {
      this.order_detail['logo'] = null;
    }
  }
}
