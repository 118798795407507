import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-widget-single',
  template: `
    <div class="info-box"  [ngClass]="{'alfilterselected': widget.selected, 'alfilternotselected': !widget.selected}">
      <span class="heading">{{ widget.data.label }}</span>
      <div class="valuecontainer">
        <span class="desc">{{ value }}</span>
        <span *ngIf="showGrowth" class="growth"
           [ngClass]="{'high': growthValue > 1, 'low': growthValue <= 1}">{{growthValue}}
       %</span>
      </div>
    </div>`,
  styleUrls: ['./../dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SingleWidgetComponent implements  OnChanges, OnDestroy {
  @Input() widget;
  resizeEvent: EventEmitter<GridsterItem>;
  resizeSub: Subscription;
  value;
  prvsValue;
  growthValue ;
  showGrowth = false;

 /* ngOnInit(): void {
    /!*this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your widget, chart, map , etc.
        console.log(widget);
      }
    });*!/

  }*/

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (this.widget?.data?.value?.value) {
      this.value = this.widget.data.value.value;
      this.value = Number(this.value);
      if (this.value % 1 !== 0) {
        this.value = parseFloat(this.value).toFixed(2);
      }
      if (this.widget?.data?.value?.prvsValue) {
        this.prvsValue = this.widget.data.value.prvsValue;
        this.prvsValue = Number(this.prvsValue);
        if (this.prvsValue % 1 !== 0) {
          this.prvsValue = parseFloat(this.prvsValue).toFixed(2);
        }
        if (this.prvsValue > 0) {
          this.growthValue = ((this.value / this.prvsValue) * 100).toFixed(2);
        } else {
          this.growthValue = this.value;
        }
        this.showGrowth = true;
      }
      if (this.widget.data.value_type) {
        this.formatNUmber();
      }
    } else {
      this.value = 0;
    }
  }

  ngOnDestroy(): void {
    //this.resizeSub.unsubscribe();
  }

  formatNUmber() {
    if (this.widget.data.value_type === 'currency') {
      if (this.value >= 10000000) {
        this.value = (this.value / 10000000).toFixed(2) + 'Cr';
      } else if (this.value >= 100000) {
        this.value = (this.value / 100000).toFixed(2) + 'L';
      } else if (this.value >= 1000) {
        this.value = (this.value / 1000).toFixed(2) + 'K';
      }
    }
  }
}
