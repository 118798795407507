import {Component} from "@angular/core";
import {BaseUpload} from "../pagebase/base_upload/base_upload";

@Component({
  selector: 'app-task-planner-create-upload',
  templateUrl: '../pagebase/base_upload/base_upload.html',
  styleUrls: ['../pagebase/base_upload/base_upload.scss']

})

export class TaskActivationUploadComponent extends BaseUpload {
  task_types = [];
  myDate: any;
  setViewParams() {
    this.header = 'Task Upload';
    this.sampleText1 = 'DOWNLOAD SAMPLE TEMPLATE';
    //this.sampleText2 = 'DOWNLOAD MASTER DATA';
    const date = new Date();
    this.myDate = [];
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
    this.excel_columns = [{C: 'A', H: 'TaskCode', V: 'UP', required: true},
      {C: 'B', H: 'TaskName', V: 'JWBSK1099866', required: true},
      {C: 'C', H: 'Type', V: 'Activation', required: true},
      {C: 'D', H: 'RetailerCode', V: 'RJ42686', required: true},
      {C: 'E', H: 'RetailerName', V: 'Retailer 1', required: false},
      {C: 'F', H: 'Start Date (dd-MM-YYYY)', V: '31-12-2024', required: true},
      {C: 'G', H: 'End Date (dd-MM-YYYY)', V: '30-01-2025', required: true},
      {C: 'H', H: 'QuestionCode', V: 'TQ01', required: false},
    ];
    this.step1Text = 'Download sample template for reference.';
    this.step2Text = 'Required fields are TaskCode, TaskName, Type, RetailerCode, Start Date and End Date';
    this.actionText = 'Create a New Activation Task';
    this.baseData = this.sampleData1;
    this.dataLoaded = true;
    //this.fetchMasterData();
  }

  sampleDownload1() {
    this.downloadSampleData( 'task_activation_template');
  }

  configureGrid() {
    this.API_URL = '/api/tasks/upload_taskv2';
    this.columnDefs = [
      {headerName: 'TaskCode', field: 'code', width: 100},
      {headerName: 'TaskName', field: 'name', width: 100},
      {headerName: 'Type', field: 'type', width: 100},
      {headerName: 'RetailerCode', field: 'retailer_code', width: 100},
      {headerName: 'RetailerName', field: 'retailer_name', width: 100},
      {headerName: 'Start Date', field: 'start_date', width: 150},
      {headerName: 'End Date', field: 'end_date', width: 100}
    ];
  }
  validate() {
    const inThis = this;
    this.validRecords = 0;
    this.errorRecords = 0;
    const uniqueCodes = new Set();
    let combiset = false;
    if (localStorage.getItem('task_types')) {
      try {
        this.task_types = JSON.parse(localStorage.getItem('task_types'));
      } catch (e) {
        this.toastr.error('Task Types not Configured');
        this.dialog.closeAll();
        return;
      }
    }
    this.task_types = this.task_types.filter(x => x.type === 'normal');
    if (!this.task_types || this.task_types.length === 0) {
      this.toastr.error('Task Types not Configured');
      this.dialog.closeAll();
      return;
    }
    this.uploaded_data.forEach(data => {
      const indrow: any = {};
      indrow.retailer_name = data['RetailerName'];
      if (!data['TaskCode'] || data['TaskCode'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'TaskCode Missing';
      } else {
        indrow.code = data['TaskCode'];
      }
      if (!data['TaskName'] || data['TaskName'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'TaskName Missing';
      } else {
        indrow.name = data['TaskName'].replace(/"/g, "").replace(/'/g, "");
      }
      if (!data['Type'] || data['Type'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Type Missing';
      } else if (!this.taskTypeValidation(data['Type'])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Type';
      } else {
        indrow.type = data['Type'];
      }
      if (!data['RetailerCode'] || data['RetailerCode'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'RetailerCode Missing';
      } else {
        indrow.retailer_code = data['RetailerCode'];
      }
      if (data['Start Date (dd-MM-YYYY)']) {
        data['Start Date'] = data['Start Date (dd-MM-YYYY)'];
      }
      if (data['End Date (dd-MM-YYYY)']) {
        data['End Date'] = data['End Date (dd-MM-YYYY)'];
      }
      if (!data['End Date'] || data['End Date'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'End Date Missing';
      } else {
        data['End Date'] = this.sharedService.convertExcelStringToDate(data['End Date']);
        if (!data['End Date'] || data['End Date'] === '') {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Invalid End Date Format';
        } else {
          indrow.end_date = data['End Date'];
          if (new Date(new Date(data['End Date']).toDateString()) < new Date(new Date().toDateString())) {
            indrow.valid = 'Invalid';
            indrow.errorMsg = 'End Date before Current Date';
          }
        }
      }
      if (!data['Start Date'] || data['Start Date'] === '') {
        indrow.allow_start_date = false;
      } else {
        indrow.allow_start_date = true;
        data['Start Date'] = this.sharedService.convertExcelStringToDate(data['Start Date']);
        if (!data['Start Date'] || data['Start Date'] === '') {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Invalid Start Date Format';
        } else {
          indrow.start_date = data['Start Date'];
          if (new Date(new Date(data['Start Date']).toDateString()) < new Date(new Date().toDateString())) {
            indrow.valid = 'Invalid';
            indrow.errorMsg = 'Start Date before Current Date';
          } else if (indrow.errorMsg !== 'Invalid End Date Format' && new Date(indrow.start_date) > new Date(indrow.end_date)) {
            indrow.valid = 'Invalid';
            indrow.errorMsg = 'End Date before Start Date';
          }
        }
      }
      if (indrow.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        indrow.valid = 'Valid';
        indrow.task_status = 'draft';
        indrow.description = '';
        indrow.instructions = '';
        indrow.question_code = data['QuestionCode'];
        let combi = "";
        if (indrow.allow_start_date) {
          combi = indrow.code  + indrow.retailer_code + indrow.start_date + indrow.end_date;
        } else {
          combi = indrow.code  + indrow.retailer_code + indrow.end_date;
        }
        if (uniqueCodes && uniqueCodes.has(combi)) {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Duplicate Task';
        } else {
          uniqueCodes.add(combi);
          this.validRecords++;
        }
      }
      this.rowData.push(indrow);
    });
  }
  taskTypeValidation(type) {
    let found = false;
    for (const key in this.task_types) {
      if (this.task_types[key]['itemname'] === type) {
        found = true;
      }
    }
    return found;
  }
}


