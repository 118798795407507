<!--<div class="center">
  <div style="display: block">
    <div >
      <div>
        <p style="display: block; font-size: 43px; font-weight: 600;color: #1D1D1D;">No Dashboard Configured!</p>
        <p style="display: block; font-size: 23px; font-weight: 400;color: #1D1D1D;">It appears that no dashboard is configured for your role. Please contact your account manager to configure your dashboard.</p>
      </div>
      <img src="/assets/images/dashboard.png">
    </div>
  </div>
</div>-->
<div class="center">
  <div style="display: block">
<div class="header">
  <div>
    <p class="title1">No Dashboard Configured!</p>
    <p class="title2">It appears that no dashboard is configured for your role. Please contact your account manager to configure your dashboard.</p>
  </div>
  <img src="/assets/images/dashboard.png" style="width: 50vw" >
</div>
</div>
</div>
