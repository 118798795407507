import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";
import {AGGridEditButtonRendererComponent} from "../../admin/users/AGGridEditButtonRendererComponent";
import {MatDialogConfig} from "@angular/material/dialog";
import {StockUploadComponent} from "./upload_stock/stock_upload.component";


@Component({
  selector: 'app-my-stock-list',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})

export class SupplierStockLogComponent extends PageBase implements OnInit, IPage {

  product_id = 0;

  ngOnInit(): void {
    this.sharedService.setHeader('Stock Log');
    this.customFilter.values = [
      {id: 0, itemName: 'All'}
    ];

    this.readPageParms(params => {

      this.product_id = params.get('id');
      this.userData.searchableFilter = this.product_id;

      this.configureGrid();
      this.showDate = true;
      this.showUpload = false;
      this.loadServerData();
    });
  }

  configureGrid() {
    this.API_URL = '/api/pwa_dms_connects/get_supplier_stock_log';
    this.columnDefs = [
      {headerName: 'Name', field: 'name'},
      {headerName: 'Product ID', field: 'prod_id', hide: true},
      {headerName: 'Code', field: 'code'},
      {headerName: 'Move', field: 'type'},
      {headerName: 'Product', field: 'product', width: 250},
      {headerName: 'Category', field: 'category'},
      {headerName: 'Brand', field: 'brand'},
      {headerName: 'Date', field: 'date'},
      {headerName: 'Invoice', field: 'inv_no'},
      {headerName: 'Stock', field: 'quantity', cellStyle: {textAlign: 'right'}},
      {headerName: 'Balance', field: 'balance', cellStyle: {textAlign: 'right'}},
      {headerName: 'supplier_id', field: 'supplier_id', hide: true},
    ];
  }

}
