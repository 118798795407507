import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {ErrorHandler} from "../../../error/error-handler";
import {RowNode} from "@ag-grid-enterprise/all-modules";
import {ErrortooltipComponent} from "../../../errortooltip";
import {MatDialogConfig} from "@angular/material/dialog";
import {PaymentCollectPopupComponent} from "../../secondary/payments/payment_collect_popup/payment_collect_popup.component";
import {PaymentAdvancePopupComponent} from "./payment_advance_popup/payment_advance_popup.component";
import {PrintInvoiceComponent} from "../../../printInvoice.component";
import {PrintClickRenderer} from "../../../printClickRenderer.component";
import {SaleOrderBulkUploadComponent} from "../../secondary/sale_order/sale_order_bulk_upload.component";
import {PurchaseOrderBulkUploadComponent} from "./purchase_order_bulk_upload.component";


@Component({
  selector: 'app-sale-order-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class PurchaseOrderListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Invoice Status',
    key: 'status',
    value: 'pending',
    values: [],
    show: true
  };

  matActionMenuItemDeclaration = [{name: 'Confirm Order'}, {name: 'Advance Payment'}]; //, feature: 'action_invoice_create'

  ngOnInit(): void {

    this.sharedService.setHeader('Purchase Orders');
    this.showUpload = true;
    this.customFilter.values = [
      {id: 'all', itemName: 'All'},
      {id: 'pending', itemName: 'Pending'},
      {id: 'to invoice', itemName: 'To Invoice'},
      {id: 'partial', itemName: 'Partial'},
      {id: 'invoiced', itemName: 'Invoiced'},
    ];
    this.checkAndLoadPreviousSate();

    this.showCustomFilter = true;
    this.showActionButton = true;
    this.showDate = true;
    this.showPinnedFooter = true;
    this.columnsWithAggregation = ['amount_untaxed'];
    this.gridOptions.getRowStyle = this.getRowStyleFunc;
    this.frameworkComponents = {
      errortooltipComponent: ErrortooltipComponent
    };

    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }

  getRowStyleFunc(params) {
    if (params.data && params.data.id === 'TOTAL') {
      return {'font-weight': 'bold'};
    }
  }

  configureGrid() {
    this.API_URL = '/api/pwa_dms_connects/get_purchase_orders';

    const OrderStatusCellClassRules = {
      'rag-green-outer': (params) => params.value === 'sale',
      'rag-amber-outer': (params) => params.value === 'confirmed',
      'rag-red-outer': (params) => params.value === 'cancel',
    };

    this.columnDefs = [
      {headerName: "ID", field: "id", width: 25, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "Date", field: "date", width: 40},
      {headerName: "Created By", field: "created_by", width: 70},
      {headerName: "Order Number", field: "order_no", width: 80},
      {headerName: "Supplier Code", field: "supp_code", width: 50, hide: true},
      {headerName: "Supplier", field: "supp_name", width: 70},
      {headerName: "Code", field: "code", width: 50, hide: true},
      {headerName: "Customer", field: "outlet", width: 70, hide: true},
      {headerName: "Type", field: "o_type", width: 50, hide: true},
      // {headerName: "Bill To", field: "bt_name", width: 50, hide: true},
      // {headerName: "Ship To", field: "st_name", width: 50, hide: true},
      {headerName: "Sub Total", field: "amount_untaxed", width: 50, aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: "Total", field: "amount_total", width: 50, hide: true, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
      {headerName: "Salesman", field: "salesman", width: 40},
      {headerName: "Emp Code", field: "emp_code", width: 30, hide: true},
      {
        headerName: "Order Status", field: "state", width: 40,
        cellClassRules: OrderStatusCellClassRules,
        valueGetter: params => {
          if (params.data && params.data.hasOwnProperty('state')) {
            if (params.data.state === 'sale') {
              return 'Confirmed';
            } else if (params.data.state === 'draft') {
              return 'Draft';
            } else if (params.data.state === 'cancel') {
              return 'Cancel';
            } else {
              return params.data.state;
            }
          }
        },
        cellRenderer(params) {
          let displayElem = '';
          if (params.data) {
            if (params.value === 'Confirmed') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else if (params.value === 'Cancel') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else if (params.value === 'Draft') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #F6A82C;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else {
              if (params.value) {
                displayElem = '<span>' + params.value + '</span>';
              }
            }

            return displayElem;
          }
        }
      },
      {
        headerName: "Invoice Status", field: "invoice_status", width: 40,
        valueGetter: params => {
          if (params.data && params.data.hasOwnProperty('invoice_status')) {
            if (params.data.invoice_status === 'to invoice' || params.data.invoice_status === 'draft') {
              return 'To Invoice';
            } else if (params.data.invoice_status === 'invoiced') {
              return 'Invoiced';
            } else if (params.data.invoice_status === 'partial') {
              return 'Partial';
            } else if (params.data.invoice_status === 'cancel') {
              return 'Canceled';
            }  else {
              return params.data.invoice_status;
            }
          }
        },
        cellStyle(params) {
          if (params.data && params.value) {
            if (params.value === 'Invoiced') {
              return {color: '#379862'};
            } else if (params.value === 'To Invoice' ) {
              return {color: '#17A2B8'};
            } else if (params.value === 'Partial') {
              return {color: '#757BC8'};
            } else if (params.value === 'Canceled') {
              return {color: '#E24260'};
            } else {
              if (params.value) {
                return {color: '#F6A82C'};
              }
            }
          }
        },
        cellRenderer(params) {
          let displayElem = '';
          if (params.data) {
            if (params.value === 'Invoiced') {
              displayElem = '<span class="ml-1 status2" style="background-color:#E7F3EC; border:#E7F3EC">' + params.value + '</span>';
            } else if (params.value === 'To Invoice') {
              displayElem = '<span class="ml-1 status2" style="background-color:#E3F4F6; border:#E3F4F6">' + params.value + '</span>';
            } else if (params.value === 'Partial') {
              displayElem = '<span class="ml-1 status2" style="background-color:#EEEFF8; border:#EEEFF8">' + params.value + '</span>';
            } else if (params.value === 'Canceled') {
              displayElem = '<span class="ml-1 status2" style="background-color:#FBE3E7; border:#FBE3E7">' + params.value + '</span>';
            } else {
              if (params.value) {
                displayElem = '<span class="ml-1 status2" style="background-color:#FEF5E6; border:#FEF5E6">' + params.value + '</span>';
              }
            }
            return displayElem;
          }
        }
      },
      {headerName: "Note", field: "note", width: 40},
      // {headerName: "Supplier", field: "sup_name", width: 40, hide: true},
      // {headerName: "Beat", field: "beat", width: 30},
      // {headerName: "Territory", field: "territory", width: 30, hide: true},
      // {headerName: "Cluster", field: "cluster", width: 30, hide: true},
      // {headerName: "State", field: "geo_state", width: 30, hide: true},
      {
        headerName: 'PRINT', field: 'PRINT', pinned: 'right',
        headerComponent: PrintInvoiceComponent,
        cellRenderer: PrintClickRenderer, width: 50
      }
    ];

    this.getRowId = (params) => params.data.id;
  }

  printAll(rows) {
    this.appComponent.printAll(rows, 'id', '/api/pwa_connects/get_order_print_details', null);
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (column === 'PRINT') {
      this.appComponent.printAll([event.data], 'id', '/api/pwa_connects/get_order_print_details', null);
    } else {
      if (filteredRowData !== null && filteredRowData.length > 0) {
        const ids = [];
        filteredRowData.forEach(r => {
          ids.push(r.data.id);
        });
        localStorage.setItem('active_ids', JSON.stringify(ids));
        this.router.navigate([this.apiService.redirect_url + '/dms/purchase_order'], {
          relativeTo: this.route,
          queryParams: {id: event.data.id}
        });
      }
    }
  }

  checkAccessControl() {
    this.showCreate = true;
  }

  openCreateDialog() {

    localStorage.setItem('active_ids', JSON.stringify([]));
    this.router.navigate([this.apiService.redirect_url + '/dms/purchase_order'], {
      relativeTo: this.route,
      queryParams: {id: 0}
    });
  }

  showUploadPopup() {
    // this.uploadSaleOrdersClick();
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(PurchaseOrderBulkUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(PurchaseOrderBulkUploadComponent, config);
    }
  }
  //
  // uploadSaleOrdersClick() {
  //   const supplier_id = JSON.parse(localStorage.getItem('user')).partner_id;
  //   const config: MatDialogConfig = {
  //     maxWidth: '100vw',
  //     maxHeight: '100vh',
  //     height: '100%',
  //     width: '100%',
  //     panelClass: 'full-screen-modal',
  //     data: {
  //       upload_type: 'sales_order_upload_v1',
  //       title: "Upload Sales Order",
  //       message: "You are Sale Order ",
  //       active_id: supplier_id,
  //       instruction: "Column 1: Outlet_code <br> Column 2: action (ADD/REMOVE), Click Here to download .",
  //     },
  //   };
  //
  //   const dialogRef = this.dialog.open(SaleOrderUploadComponent, config);
  //   dialogRef.componentInstance.uploadData.subscribe(status => {
  //     if (status === 'success') {
  //       this.dialog.closeAll();
  //       window.location.reload();
  //     }
  //   });
  // }


  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    let valid = true;
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Order');
    } else if (rows.length > 100) {
      this.toastr.error('Please select a maximum of 100 order : ' + rows.length + ' selected');
    } else {
      const ids = [];
      rows.forEach(r => {
        if (r.state !== 'sale') {
         valid = false;
        }
        ids.push(r.id);
      });
      valid = true;
      if (menuitem.name === 'Create Invoice') {
        // if (valid) {
        //   this.createBulkInvoiceClick(ids);
        // } else {
        //   this.toastr.warning('Please select only CONFIRMED Orders');
        // }
      } else if (menuitem.name === 'Confirm Order') {
        this.confirmOrdersClick(ids);
      } else if (menuitem.name === 'Advance Payment') {
        this.openAdvancePaymentPopup(ids[0]);
      }
    }
  }

  openAdvancePaymentPopup(id) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "85%",
        panelClass: 'custom-dialog-container',
        data: {
          id
        }
      };
      const creatediaeref = this.dialog.open(PaymentAdvancePopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          id
        }
      };
      const creatediaeref = this.dialog.open(PaymentAdvancePopupComponent, config);
    }
  }

  //
  // createBulkInvoiceClick(order_ids) {
  //   const config: MatDialogConfig = {
  //     maxWidth: '100vw',
  //     maxHeight: '100vh',
  //     height: '100%',
  //     width: '100%',
  //     panelClass: 'full-screen-modal',
  //     data: {
  //       ids: order_ids,
  //     },
  //   };
  //
  //   const dialogRef = this.dialog.open(BulkInvoiceComponent, config);
  //   dialogRef.componentInstance.bulkInvoiceCreate.subscribe(status => {
  //     if (status === 'success') {
  //       this.dialog.closeAll();
  //       window.location.reload();
  //     }
  //   });
  // }


  confirmOrdersClick(order_ids) {
    if (order_ids.length > 0) {
      const userData = {};
      userData['access_token'] = this.userData.access_token;
      userData['order_ids'] = order_ids;

      this.apiService.post('/api/pwa_dms_connects/confirm_orders', userData)
        .subscribe(res => {
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {

              const rowsToUpdate = [];
              res.results.data.forEach(res_id => {
                const ord = this.rowData.find(item => item.id === res_id['id']);
                rowsToUpdate.push(this.updateImmutableObject(ord, {state: res_id['state']}));
              });

              this.gridApi.applyTransactionAsync({update: rowsToUpdate}, () => {
              });

              this.toastr.success(rowsToUpdate.length + ' orders confirmed!');

            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          error => {
            this.handleError(ErrorHandler.getErrorObject(error));
          });

    } else {
      this.toastr.success('All orders are already confirmed!');
      return;
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setColumnDefs(this.columnDefs);
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  generatePinnedBottomData() {
    // generate a row-data with null values
    const result = {};
    this.gridColumnApi.getAllGridColumns().forEach(item => {
      result[item.colId] = null;
    });
    return this.calculatePinnedBottomData(result);
  }
  calculatePinnedBottomData(target: any) {
    //console.log(target);
    //**list of columns fo aggregation**
    const columnsWithAggregation = ['amount_untaxed'];
    columnsWithAggregation.forEach(element => {
      console.log('element', element);
      this.gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        //if(rowNode.index < 10){
        //console.log(rowNode);
        //}
        if (rowNode.data[element]) {
          target[element] += +Number(rowNode.data[element]).toFixed(2);
        }
      });
      if (target[element]) {
        target[element] = `${target[element]}`;
      }
    });
    target.id = 'TOTAL';
    //console.log(target);
    return target;
  }

  onFilterChanged($event) {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }
}
