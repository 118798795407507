import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {DetailPageBase} from "../../pagebase/DetailPageBase";
import {MatDialogConfig} from "@angular/material/dialog";
import {AssignVendorPopupComponent} from "../assign_vendor_popup/assign_vendor_popup.component";
import {
  AssignServiceExecutivePopupComponent
} from "../assign_service_executive_popup/assign_service_executive_popup.component";
import {AssetTravelCostPopupComponent} from "../asset_travel_cost_popup/assign_travel_cost_popup.component";


@Component({
  selector: 'app-asset-request-master-detail',
  templateUrl: 'asset_request_master_detail.html',
  styleUrls: ['../../pagebase/pagebase_detail.scss']
})

export class AssetRequestMasterDetailComponent extends DetailPageBase implements IDetailPage, OnInit {

  enableApprove = false;
  feature_asset_assign_vendor = false;
  feature_asset_assign_service_executive = false;
  feature_asset_travel_cost = false;
  enableAssignVendor = false;
  enableAssignVendorExecutive = false;
  enableTravelCostEntry = false;
  role_features;
  public tabIndex = 0;
  public prevIndex;
  public showApprove = false;
  public showApproveBtn = true;
  public showRejectBTn = true;
  public getRowId;
  approvedRows = [];

  ngOnInit(): void {
    this.setTitle('Batch Detail');
    this._Detail_GET_URL = "/api/request_masters/get_request_master_detail";

    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_asset_batch_confirm' === existed.name);
    if (this.role_features.length > 0) {
      this.enableApprove = true;
    }

    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_asset_assign_vendor' === existed.name);
    if (this.role_features.length > 0) {
      this.feature_asset_assign_vendor = true;
    }

    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_asset_assign_service_executive' === existed.name);
    if (this.role_features.length > 0) {
      this.feature_asset_assign_service_executive = true;
    }

    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_asset_travel_cost' === existed.name);
    if (this.role_features.length > 0) {
      this.feature_asset_travel_cost = true;
    }

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
    };

    this.gridOptions.isRowSelectable = params => {
      if (params.data) {
        if (params.data.status === 'approved' && this.enableApprove) {
          return true;
        } else {
          return false;
        }
      }
    };

    this.context = {componentParent: this};


    this.configureGrid();

    this.initialize_default_values(() => {

    });

    this.readPageParms(() => {
      this.loadPage();
    });
  }

  //Page Initilizefunctions
  initialize_default_values(callback) {


    return callback();
  }


  loadPage() {
    const inThis = this;
    this.loadActiveObject(() => {
      if (inThis.activeID > 0) {
        console.log(inThis.activeObject);
        this.loadProducts();
      } else {
        //Load all default Values
        this.activeObject['id'] = 0;
      }
    });
  }

  loadProducts() {
    this.rowData = [];
    if (this.activeObject && this.activeObject['assets']) {
      this.rowData = this.activeObject['assets'];
      this.enableAssignVendor = false;
      this.enableAssignVendorExecutive = false;
      this.enableTravelCostEntry = false;
      this.rowData.forEach(allAsset => {


        if (this.activeObject.status === "Confirmed") {

          if (!allAsset.vendor_id && allAsset.status === "confirmed" && !this.enableAssignVendor) {
            this.enableAssignVendor = true;
          }

          if (allAsset.vendor_id && allAsset.status === "confirmed" && !this.enableAssignVendorExecutive) {
            this.enableAssignVendorExecutive = true;
          }

          if ((allAsset.status === "installation" || allAsset.status === "deployed" || allAsset.status === "deployment_verified") && !this.enableTravelCostEntry) {
            this.enableTravelCostEntry = true;
          }

        } else {
          this.enableAssignVendor = false;
          this.enableAssignVendorExecutive = false;
          this.enableTravelCostEntry = false;
        }

      });

      this.gridOptions.api.setRowData(this.rowData);
    }
  }

  //GRID Functions
  configureGrid() {
    const inthis = this;
    this.columnDefs = [
      {
        headerName: "ID", field: "id", width: 40, pinned: 'left',
        headerCheckboxSelection: this.enableApprove,
        headerCheckboxSelectionFilteredOnly: this.enableApprove,
        checkboxSelection: params => {
          if (params.data) {
            if (params.data.status === 'approved' && this.enableApprove) {
              return true;
            } else {
              return false;
            }
          }
        },
        cellRendererParams: {
          _self: this
        }
      },
      {headerName: "NAME", field: "name", width: 40},
      {headerName: "STATUS", field: "status", width: 40},
      {headerName: "PARTNER", field: "partner_name", width: 80},
      {headerName: "ASSET", field: "asset_name", width: 40}
    ];

  }

  onSelectionChanged(event) {
    this.tabIndex = 0;
    if (this.prevIndex !== event.rowIndex) {
      if (event.node.selected && this.enableApprove) {
        this.showApprove = true;
        this.prevIndex = event.rowIndex;
      }
    } else {
      if (event.node.selected && this.enableApprove) {
        this.showApprove = true;
      } else {
        this.showApprove = false;
      }
      this.prevIndex = event.rowIndex;
    }
  }

  async confirmRequest() {
    const rows = this.gridApi.getSelectedRows();
    // const uncheckedrows = this.gridApi.getUncheckedItems();
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      id: this.activeID
    };
    paramObject.data.approved_ids = [];
    paramObject.data.rejected_ids = [];
    rows.forEach(asset => {
      paramObject.data.approved_ids.push(asset.id);
    });

    this.rowData.forEach(allAsset => {

      if (!paramObject.data.approved_ids.includes(allAsset.id)) {
        paramObject.data.rejected_ids.push(allAsset.id);
      }
    });
    paramObject.data.user = JSON.parse(localStorage.getItem('user')).user_name;
    // paramObject.status = "success";

    const resp = await this.apiService.postPromise('/api/request_masters/mark_request_master_confirmed', paramObject);
    let upload_status;
    if (resp.hasOwnProperty('results') && resp['results'].status === 200) {
      upload_status = resp['results'].data;
      this.toastr.success('Confirmed Asset Batch');
      this.activeObject.status = 'Confirmed';
      this.loadPage();
    } else if (resp.hasOwnProperty('results') && (resp['results'].status === 199 || resp['results'].status === 203)) {
      this.toastr.error(resp['results'].msg);
    } else {
      this.toastr.error('Internal Error');
    }
  }

  openAssignVendorPopup() {

    this.approvedRows = [];
    this.rowData.forEach(allAsset => {

      if (allAsset.status === "confirmed") {
        this.approvedRows.push(allAsset);
      }

    });

    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "30%",
        maxHeight: "75%",
        panelClass: 'custom-dialog-container',
        data: {
          rows: this.approvedRows
        }
      };
      const creatediaeref = this.dialog.open(AssignVendorPopupComponent, config);
      creatediaeref.componentInstance.vendorAssignedEvent.subscribe(data => {
        this.loadPage();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          rows: this.approvedRows
        }
      };
      const creatediaeref = this.dialog.open(AssignVendorPopupComponent, config);
      creatediaeref.componentInstance.vendorAssignedEvent.subscribe(data => {
        this.loadPage();
      });
    }
  }

  openAssignServiceExecutivePopup() {
    this.approvedRows = [];
    this.rowData.forEach(allAsset => {

      if (allAsset.status === "confirmed") {
        this.approvedRows.push(allAsset);
      }

    });
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "650px",
        height: "430px",
        panelClass: 'custom-dialog-container',
        data: {
          rows: this.approvedRows
        }
      };
      const creatediaeref = this.dialog.open(AssignServiceExecutivePopupComponent, config);
      creatediaeref.componentInstance.serviceExeAssignedEvent.subscribe(data => {
        this.loadPage();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          rows: this.approvedRows
        }
      };
      const creatediaeref = this.dialog.open(AssignServiceExecutivePopupComponent, config);
      creatediaeref.componentInstance.serviceExeAssignedEvent.subscribe(data => {
        this.loadPage();
      });
    }
  }

  addTravelCost() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "30%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          batch_id: this.activeID
        }
      };
      const creatediaeref = this.dialog.open(AssetTravelCostPopupComponent, config);
      creatediaeref.componentInstance.serviceExeAssignedEvent.subscribe(data => {
        this.loadPage();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "95%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          batch_id: this.activeID
        }
      };
      const creatediaeref = this.dialog.open(AssetTravelCostPopupComponent, config);
      creatediaeref.componentInstance.serviceExeAssignedEvent.subscribe(data => {
        this.loadPage();
      });
    }
  }


}
