import {Component, OnInit} from "@angular/core";
import {GetRowIdFunc, GetRowIdParams, GridOptions} from "@ag-grid-enterprise/all-modules";
import {DetailPageBase} from "../../pagebase/DetailPageBase";
import {ErrorHandler} from "../../error/error-handler";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {MatDialogConfig} from "@angular/material/dialog";
import {ApproveRejectCommentsPopupComponent} from "../../components/approve_reject_comments_popup/approve_reject_comments_popup.component";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {LocationPopupComponent} from "../../components/location_popup/location_popup.component";

@Component({
  selector: 'app-dsr-detail',
  templateUrl: 'dsr_detail.html',
  styleUrls: ['../../pagebase/pagebase_detail.scss']
})

export class DSRDetailComponent extends DetailPageBase implements IDetailPage, OnInit {

  show_approve_button = false;
  feature_meet_review = false;

  activetab = 'Activities';

  activities_gridContext: any;
  activities_columnDefs = [];
  activities_gridOptions: GridOptions;
  activities_gridApi: any;
  activities_gridColumnApi: any;
  activities_rowData = [];
  activities_gridStyle: any;

  sales_gridContext: any;
  sales_columnDefs = [];
  sales_gridOptions: GridOptions;
  sales_gridApi: any;
  sales_gridColumnApi: any;
  sales_rowData = [];
  sales_gridStyle: any;

  expense_gridContext: any;
  expense_columnDefs = [];
  expense_gridOptions: GridOptions;
  expense_gridApi: any;
  expense_gridColumnApi: any;
  expense_rowData = [];
  expense_gridStyle: any;

  galleryData = [];
  photos: any = [];

  expenseMaxImages = 0;

  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/pwa_admin_connects/get_dsr_detail";
    this.context = {componentParent: this};
    this.sharedService.setHeader('Daily Sales Review');
    // const company_config = JSON.parse(localStorage.getItem('company_config'));
    // if (company_config && company_config.length > 0) {
    //   this.approverConfig = company_config.find(x => x.type === 'Claim Approval');
    //   if (this.approverConfig && this.approverConfig?.config_json?.config && this.approverConfig.config_json.config.length > 0) {
    //     this.approverConfig = this.approverConfig?.config_json.config[0].appr_roles;
    //   }
    // }
    // this.feature_meet_approve = this.sharedService.ACLcheck('feature_meet_approve');
    this.feature_meet_review = true;
    this.show_approve_button = false;

    this.activities_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressRowTransform: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        } else {
          if (params.data.a % 2 === 0) {
            return {background: '#F3F6F8'};
          } else {
            return {background: 'white'};
          }
        }
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.sales_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
    } as GridOptions;

    this.expense_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
    } as GridOptions;
    this.readPageParms(params => {
      this.loadPage();
    });
  }

  configureGrid() {
    this.activities_columnDefs = [
      {headerName: "ID", field: "id", width: 20, hide: true},
      {headerName: "Code", field: "partner_code", width: 20},
      {headerName: "name", field: "partner_name", width: 100, valueGetter: this.configValueGetter('config_json', 'name')},
      {headerName: "Role", field: "role", width: 100},
      {headerName: "Mobile No", field: "mobile", width: 100, valueGetter: this.configValueGetter('config_json', 'mobile')},
      {headerName: "email", field: "email", width: 100, valueGetter: this.configValueGetter('config_json', 'email')},
      {headerName: "Time", field: "time", width: 100},
    ];

    this.sales_columnDefs = [
      {headerName: "ID", field: "id", width: 20, hide: true},
      {headerName: "Type", field: "type"},
      {headerName: "Budget", field: "t_bud"},
      {headerName: "per Person", field: "exp_per_att",
        valueGetter(params) {
          if (params.data) {
            if (params.data.exp_per_att) {
              return parseFloat(params.data.exp_per_att).toFixed(2);
            }
          }
        }},
      {headerName: "Variance", field: "variance",  valueGetter(params) {
          if (params.data) {
            if (params.data.variance) {
              return parseFloat(params.data.variance).toFixed(2);
            }
          }
        }},
    ];

    this.expense_columnDefs = [
      {headerName: "ID", field: "id", width: 20, hide: true},
      {headerName: "Active", field: "active", width: 30, hide: true},
      {headerName: 'Date', field: 'expense_date', width: 100},
      {headerName: "Policy", field: 'policy_name', width: 100},
      {headerName: "Expense Type", field: 'exp_type', width: 120},
      {headerName: 'Claimed', field: 'total', filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'right'}, width: 80, aggFunc: 'sum'},
      {headerName: "Approved", field: 'app_amount', cellStyle: {textAlign: 'right'}, width: 80, aggFunc: 'sum'},
      {headerName: "1st Approver", field: 'appr1', width: 100},
      {headerName: "Status", field: 'app1_state', width: 100},
      {headerName: "2nd Approver", field: 'appr2', width: 100},
      {headerName: "status", field: 'app2_state', width: 100},
      {headerName: " ", field: 'rej_amount', cellStyle: {textAlign: 'right'}, width: 80, aggFunc: 'sum'},
      {headerName: "Remarks", field: 'remarks', width: 150}
    ];

  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  }

  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
  }

  loadPage() {
    this.dataLoaded = false;

    this.activities_rowData = [];
    this.sales_rowData = [];
    this.expense_rowData = [];
    this.expenseMaxImages = 0;
    this.configureGrid();
    if (this.activeID > 0) {
      this.newMode = false;
      this.editMode = false;
      //   View Mode
      //   Page Can be ready only after detail Object is fetched
      this.loadPageForView(() => {
        this.pageReady = true;
      });
    } else {
      this.newMode = true;
      this.editMode = true;
      //   Page Can be ready only after detail Object is fetched
      //    Throw Error
    }
  }

  loadPageForView(callback) {

    function prepareLineData(lineItems) {
      const rowData = lineItems;
      return rowData;
    }

    this.show_approve_button = false;
    this.loadActiveObject(() => {
      //
      // if (this.activeObject['supplier_id'] === undefined) {
      //   this.toastr.error('invalid state: supplier_id not found');
      // }

      /*if (this.activeObject && this.activeObject['lines'] && this.activeObject.lines.length > 0) {
        this.rowData = this.activeObject.lines;
        this.gridOptions.api.setRowData(this.rowData);
      } else if (this.activeObject && this.activeObject['invoices'] && this.activeObject.invoices.length > 0) {
        this.rowData = this.activeObject.invoices;
        this.gridOptions.api.setRowData(this.rowData);
      }*/
      this.photos = [];
      this.expenseMaxImages = 0;

      if (this.activeObject && this.activeObject['expenses'] && this.activeObject.expenses.length > 0) {
        this.expense_rowData = this.activeObject['expenses'];

        this.expense_rowData.forEach(e => {
          if (e.app2_state === 'pending') {
            this.show_approve_button = true;
          }
          if (e.app2_state !== 'approved') {
            e.remarks = e.reason;
          }

          if (e.gallery && e.gallery.length > 0) {
            if (this.expenseMaxImages < e.gallery.length) {
              this.expenseMaxImages = e.gallery.length;
            }
            for (let i = 0; i < e.gallery.length; i++) {
              e['img_' + i] = e.gallery[i];
            }
          }

        });
      }

      if (this.activeObject && this.activeObject['activities'] && this.activeObject.activities.length > 0) {
        this.activities_rowData = this.activeObject['activities'];
      }

      if (this.activeObject && this.activeObject['config_json'] && this.activeObject['config_json'] !== null) {

        if (this.activeObject.config_json.hasOwnProperty('var_by_type')) {
          this.sales_rowData = this.activeObject['config_json']['var_by_type'];
        } else if (this.activeObject.config_json.hasOwnProperty('budget')) {
          this.sales_rowData = this.activeObject['config_json']['budget'];
          this.sales_rowData.forEach(b => {
            b.t_bud = b.amount;
          });
        }

        let index = 1;
        this.sales_rowData.forEach(b => {
          b.id = index;
          index = index + 1;
        });
        if (this.activeObject.config_json.hasOwnProperty('expense_status')) {
          if (this.activeObject.config_json['expense_date']) {
            try {
              this.activeObject.config_json['expense_date'] = this.datePipe.transform((new Date(this.activeObject.config_json['expense_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } catch (e) {
              console.log("Invalid expense date");
            }
          }
        }

      }

      if (this.activeObject && this.activeObject['meet_gallery']) {
        this.galleryData = this.activeObject.meet_gallery;
      }


      this.dataLoaded = true;

      if (this.galleryData && this.galleryData.length > 0) {
        this.fetchImages();
      }

      if (this.expenseMaxImages > 0) {
        for (let i = 0; i < this.expenseMaxImages; i++) {
          this.expense_columnDefs.push(
            {
              headerName: "Image " + (i + 1),
              field: 'img_' + i,
              width: 80,
              valueGetter: this.generateImageUrl('img_' + i, 'expense_gallery'),
              cellRenderer(params) {
                if (params.data) {
                  if (params.node && params.node?.rowPinned !== 'bottom') {
                    if (!params.data['img_' + i] || params.data['img_' + i] === "") {
                      return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                    } else {
                      return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                    }
                  }
                }
              }
            },
          );
        }
      }

    });

  }

  validateSame(sDate: string, eDate: string) {
    let isSameDate = false;
    if ((sDate == null || eDate == null)) {
      isSameDate = false;
    }

    if ((sDate != null && eDate != null) && (eDate) === (sDate)) {
      isSameDate = true;
    }
    return isSameDate;
  }


  onExpenseCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.expense_gridApi.getSelectedRows();
    if (column.includes('img')) {
      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty(column) && rows[0][column] !== null && rows[0][column] !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0][column]?.store_fname,
              container: 'expense_gallery'
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0][column]?.store_fname,
              container: 'expense_gallery'
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {

      }
    }
  }




  async fetchImages() {
    const gallery = [];
    this.galleryData.forEach(image => {
      gallery.push({
        store_fname: image,
        container: 'meet_gallery'
      });
    });
    const payload = {
      access_token: localStorage.getItem('resfreshToken'),
      data: gallery
    };
    const inThis = this;
    const res = await this.apiService.postPromise('/api/pwa_connects/fetch_image', payload);
    console.log(res);
    if (res.hasOwnProperty('result') && res['result'].length > 0) {
      const domSanitizer = this.domSanitizer;
      let img;
      res['result'].forEach(photo => {
        img = domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + photo.bill_datas);
        inThis.photos.push(
          {
            photo: img,
            type: photo.type
          });
      });
    }
  }

  action_edit() {

  }

  action_confirm_meet() {

    this.userContext['meet_id'] = this.activeObject.id;
    this.userContext['action'] = 'confirm';
    this.apiService.post('/api/partner_meets/meet_status_change', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            if (res.results.hasOwnProperty('data') && res.results.data.length === 1) {
              this.activeObject['status'] = res.results.data[0]['status'];
              // navigate to invoice.

              this.toastr.success('Record Saved! : ' + this.activeID);
            } else {
              this.toastr.success('Record Saved!');
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });

  }

  action_cancel() {

  }

  discard() {
    this.editMode = false;
    if (this.activeObject && (!this.activeObject.hasOwnProperty('id') || this.activeObject.id === 0)) {
      history.back();
    } else {
      this.loadPage();
    }
  }

  quickSearch() {
    if (this.activetab === 'Sale') {
      this.sales_gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
    }  else {
      this.expense_gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
    }
  }

  activitiesOnGridReady(params) {
    this.activities_gridOptions.api.showLoadingOverlay();

    this.activities_gridApi = params.api;
    this.activities_gridColumnApi = params.columnApi;
    params.api.setRowData(this.activities_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });

    this.activities_gridApi.sizeColumnsToFit();

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.activities_gridApi, this.activities_gridColumnApi);
      this.activities_gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  saleOnGridReady(params) {
    this.sales_gridOptions.api.showLoadingOverlay();

    this.sales_gridApi = params.api;
    this.sales_gridColumnApi = params.columnApi;
    params.api.setRowData(this.sales_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });

    this.sales_gridColumnApi.autoSizeAllColumns();

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  expenseOnGridReady(params) {
    this.expense_gridOptions.api.showLoadingOverlay();

    this.expense_gridApi = params.api;
    this.expense_gridColumnApi = params.columnApi;
    params.api.setRowData(this.expense_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });

    this.expense_gridApi.sizeColumnsToFit();

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  saleOnCellClicked(event) {
  }

  openApproveRejectPopup(type) {
    let title = '';
    let message1 = '';
    let message2 = '';
    let buttonText = '';

    if (type === 'rejected') {
      title = "Reject Meet";
      message1 = "Are you sure you want to Reject Meet?";
      message2 = "Please provide a detailed reason for the rejection";
      buttonText = "Reject";
    } else {
      title = "Approve Meet";
      message1 = "Are you sure you want to Approve Meet?";
      message2 = "Please provide Approval Comments";
      buttonText = "Approve";
    }

    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "50%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.approveRejectMeet(type, data);
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "95%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.approveRejectMeet(type, data);
      });
    }
  }

  approveRejectMeet(state, comments) {
    // let status = '';
    // if (state === 'reject') {
    //   status = 'cancelled';
    // } else {
    //   status = 'approved';
    // }
    const expense_approval_object = {
      apr_id: JSON.parse(localStorage.getItem('user')).id,
      apr_by: JSON.parse(localStorage.getItem('user')).user_name,
      apr_role_id: JSON.parse(localStorage.getItem('user')).role_id,
      role: JSON.parse(localStorage.getItem('user')).role.name,
      status: state,
      comments
    };
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      meet_id: this.activeObject.id,
      expense_approval_object,
      expense_status: state
    };
    this.apiService.post('/api/partner_meets/meet_expense_update', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success(state.toUpperCase() + " Successfully");
          this.loadPage();
        } else {
          this.toastr.error("Failed to " + state.toString().toUpperCase() + " Expense");
        }
      });
  }

  showImagePopup(photo) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          photo
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        data: {
          photo
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    }
  }
}
