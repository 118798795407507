export const ReportMasterList = [
  {
    title: 'DC Sales',
    role_feature: 'module_dc_report',
    id: 1,
    code: 'R001',
    category: 'Sales',
    route: '/rawsalereport'
  },
  {
    title: 'Collection Report',
    role_feature: 'module_user_collection',
    id: 2,
    code: 'R002',
    category: 'Finance',
    route: '/usercollectionreport'
  },
  {
    title: 'Loadout Report',
    role_feature: 'module_loadin_report',
    id: 3,
    code: 'R103',
    category: 'Sales',
    route: '/loadinreport'
  },
  {
    title: 'Pricelist Report',
    role_feature: 'module_pricelist_report',
    id: 4,
    code: 'R104',
    category: 'Master',
    route: '/pricelistreport'
  },
  {
    title: 'Settlement Sales',
    role_feature: 'module_team_report',
    id: 5,
    code: 'R105',
    category: 'Sales',
    route: '/settlesalereport'
  },
  {
    title: 'Product Sales',
    role_feature: 'module_product_collection',
    id: 6,
    code: 'R106',
    category: 'Sales',
    route: '/prodcollectionreport'
  },
  {
    title: 'Sales Return',
    role_feature: 'module_return_report',
    id: 7,
    code: 'R107',
    category: 'Sales',
    route: '/salereturnreport'
  },
  {
    title: 'Growth Report',
    role_feature: 'module_growth_report',
    id: 8,
    code: 'R108',
    category: 'Productivity',
    route: '/growthreport'
  },
  {
    title: 'Outlet Dump Report',
    role_feature: 'module_outlet_report',
    id: 9,
    code: 'R109',
    category: 'Master',
    route: '/outletdumpreport'
  },
  {
    title: 'Distributor Live Stock Report',
    role_feature: 'module_livestock_report',
    id: 10,
    code: 'R110',
    category: 'Stock',
    route: '/livestockreport'
  },
  {
    title: 'Van Productivity Report',
    role_feature: 'module_productivity_report',
    id: 11,
    code: 'R111',
    category: 'Productivity',
    route: '/vanreport'
  },
  {
    title: 'Scheme Report',
    role_feature: 'module_scheme_report',
    id: 12,
    code: 'R112',
    category: 'Master',
    route: '/schemereport'
  },
  {
    title: 'Cancel Report',
    role_feature: 'module_cancel_report',
    id: 13,
    code: 'R113',
    category: 'Sales',
    route: '/cancelreport'
  },
  {
    title: 'Beat Productivity Report',
    role_feature: 'module_beat_report',
    id: 14,
    code: 'R114',
    category: 'Productivity',
    route: '/beatreport'
  },
  {
    title: 'TI Dashboard Report',
    role_feature: 'module_ti_report',
    id: 15,
    code: 'R115',
    category: 'Productivity',
    route: '/tireport'
  },
  {
    title: 'Outlet Product Report',
    role_feature: 'module_outlet_prod_report',
    id: 17,
    code: 'R117',
    category: 'Sales',
    route: '/outaggprodreport'
  },
  {
    title: 'BTL Report',
    role_feature: 'module_btl_report',
    id: 18,
    code: 'R118',
    category: 'Productivity',
    route: '/btlreport'
  },
  {
    title: 'Daily Collection Report',
    role_feature: 'module_daily_collection',
    id: 19,
    code: 'R119',
    category: 'Finance',
    route: '/dailycollection'
  },
  {
    title: 'Retailer Stock Report',
    role_feature: 'module_stock_in_hand',
    id: 20,
    code: 'R120',
    category: 'Retail Audit',
    route: '/stockinhand'
  },
  {
    title: 'Einvoice Report',
    role_feature: 'module_einvoice_report',
    id: 21,
    code: 'R121',
    category: 'Finance',
    route: '/einvoice'
  },
  {
    title: 'Receivable Ageing Report',
    role_feature: 'report_payment_ageing',
    id: 23,
    code: 'R123',
    category: 'Finance',
    route: '/order_aging_report'
  },
  {
    title: 'Outlet Overdue Report',
    role_feature: 'report_outlet_overdue',
    id: 24,
    code: 'R124',
    category: 'Finance',
    route: '/outlet_overdue_report'
  },
  {
    title: 'Payment Approval Report',
    role_feature: 'report_cheque_approval',
    id: 25,
    code: 'R125',
    category: 'Finance',
    route: '/payment_approval_report'
  },
  {
    title: 'Attendance Report',
    role_feature: 'report_attendance',
    id: 26,
    code: 'R126',
    category: 'Compliance',
    route: '/reports/attendance'
  },
  {
    title: 'Secondary Sales',
    role_feature: 'report_outlet_sales',
    id: 3,
    code: 'R003',
    category: 'Sales',
    route: '/reports/outlet_sales'
  },
  {
    title: 'Shelf Analysis',
    role_feature: 'report_shelf_analysis',
    id: 2,
    code: 'R002',
    category: 'Retail Audit',
    route: '/reports/shelf_analysis'
  },
  {
    title: 'Retail Stock History',
    role_feature: 'report_retail_stock',
    id: 5,
    code: 'R005',
    category: 'Stock',
    route: '/reports/retail_stock'
  },
  {
    title: 'Availability Score',
    role_feature: 'report_retail_audit_activity',
    id: 2,
    code: 'R003',
    category: 'Retail Audit',
    route: '/retail_audit_report'
  },
  {
    title: 'Availability(Prod Summary)',
    role_feature: 'report_audit_distribution_prod',
    id: 2,
    code: 'R004',
    category: 'Retail Audit',
    route: '/retail_audit_distribution_report'
  },
  {
    title: 'Volume Report',
    role_feature: 'report_volume_distribution',
    id: 3,
    code: 'R006',
    category: 'Retail Audit',
    route: '/reports/volume_distribution'
  },
  {
    title: 'Share of Shelf',
    role_feature: 'report_visibility',
    id: 4,
    code: 'R002',
    category: 'Retail Audit',
    route: '/reports/visibility_report'
  },
  {
    title: 'Callage/Coverage Report',
    role_feature: 'report_tl_cluster_level_rollup',
    id: 4,
    code: 'R001',
    category: 'Productivity',
    route: '/reports/tl_cluster_level_rollup'
  },
  {
    title: 'Retail Survey Summary',
    role_feature: 'report_market_intelligence_summary',
    id: 2,
    code: 'R007',
    category: 'Survey',
    route: '/market_intelligence_summary_report'
  },
  {
    title: 'Retail Survey',
    role_feature: 'report_market_intelligence',
    id: 5,
    code: 'R008',
    category: 'Survey',
    route: '/market_intelligence_report'
  },
  {
    title: 'Survey KYC',
    role_feature: 'report_survey_kyc',
    id: 5,
    code: 'R009',
    category: 'Survey',
    route: '/reports/survey_kyc'
  },
  {
    title: 'User Survey Pivot Report',
    role_feature: 'report_user_survey',
    id: 5,
    code: 'R004',
    category: 'Survey',
    route: '/reports/user_survey_pivot'
  },
  {
    title: 'Pre Order Report',
    role_feature: 'report_pre_order',
    id: 130,
    code: 'R130',
    category: 'Sales',
    route: '/reports/pre-order'
  },
  {
    title: 'Distributor Stock Reconcile',
    role_feature: 'report_dist_stock_reconsile',
    id: 131,
    code: 'R131',
    category: 'Stock',
    route: '/reports/dist_stock_reconcile'
  },
  {
    title: 'Beat Performance Report',
    role_feature: 'report_beat_performance',
    id: 132,
    code: 'R132',
    category: 'Productivity',
    route: '/reports/beat_performance'
  },
  {
    title: 'GSTR Report',
    role_feature: 'report_gstr',
    id: 133,
    code: 'R133',
    category: 'Finance',
    route: '/reports/gstr_report'
  },
  {
    title: 'HSN Report',
    role_feature: 'report_hsn',
    id: 134,
    code: 'R134',
    category: 'Finance',
    route: '/reports/hsn_report'
  },
  {
    title: 'GSTR Line Report',
    role_feature: 'report_gstr_line',
    id: 135,
    code: 'R135',
    category: 'Finance',
    route: '/reports/gstr_line_report'
  },
  {
    title: 'Van Pre Order Report',
    role_feature: 'report_pre_order',
    id: 136,
    code: 'R136',
    category: 'Sales',
    route: '/reports/pre-order-van'
  },
  {
    title: 'Brand-wise Availability',
    role_feature: 'report_audit_distribution',
    id: 137,
    code: 'R137',
    category: 'Retail Audit',
    route: '/reports/report_availability'
  },
  {
    title: 'Task Report',
    role_feature: 'report_task_activation',
    id: 138,
    code: 'R138',
    category: 'Task',
    route: '/reports/task_activation'
  },
  {
    title: 'Outlet Aggregate Report',
    role_feature: 'report_outlet_agg',
    id: 139,
    code: 'R139',
    category: 'Productivity',
    route: '/reports/outlet_aggregate_report'
  },
  {
    title: 'Product Ageing Report',
    role_feature: 'report_payment_ageing',
    id: 140,
    code: 'R140',
    category: 'Finance',
    route: '/product_ageing_report'
  },
  {
    title: 'Product Master Report',
    role_feature: 'report_product_master',
    id: 141,
    code: 'R141',
    category: 'Master',
    route: '/reports/product_master_report'
  },
  {
    title: 'Secondary Sales - Detailed',
    role_feature: 'report_outlet_sale_report_v1',
    id: 143,
    code: 'R143',
    category: 'Sales',
    route: '/reports/secondary_sales_detailed'
  },
  {
    title: 'Invoice Report',
    role_feature: 'report_invoice_report',
    id: 145,
    code: 'R145',
    category: 'Finance',
    route: '/reports/invoice_report'
  },
  {
    title: 'Retailer Master Report',
    role_feature: 'report_retailer_master',
    id: 146,
    code: 'R146',
    category: 'Master',
    route: '/reports/retailer_master_report'
  },
  {
    title: 'Daily Attendance Report',
    role_feature: 'report_daily_attendance',
    id: 147,
    code: 'R147',
    category: 'Compliance',
    route: '/reports/daily_attendance_report'
  },
  {
    title: 'Promoter Visit Report',
    role_feature: 'report_promoter_visit',
    id: 148,
    code: 'R148',
    category: 'Compliance',
    route: '/reports/promoter_visit_report'
  },
  {
    title: 'Activities & Performance',
    role_feature: 'report_merchandiser_performance',
    id: 149,
    code: 'R149',
    category: 'Productivity',
    route: '/reports/activities_performance'
  },
  {
    title: 'Stock Availability',
    role_feature: 'report_stock_availability',
    id: 150,
    code: 'R150',
    category: 'Retail Audit',
    route: '/reports/stock_availability'
  },
  {
    title: 'Stock Distribution',
    role_feature: 'report_stock_distribution',
    id: 151,
    code: 'R151',
    category: 'Stock',
    route: '/reports/stock_distribution'
  },
  {
    title: 'Tertiary Sales',
    role_feature: 'report_tertiary_sales',
    id: 152,
    code: 'R152',
    category: 'Sales',
    route: '/reports/tertiary_sales_report'
  },
  {
    title: 'Employee Master',
    role_feature: 'report_employee_master',
    id: 152,
    code: 'R152',
    category: 'Master',
    route: '/reports/employee_master'
  },
  {
    title: 'Monthly Attendance',
    role_feature: 'report_monthly_attendance',
    id: 153,
    code: 'R153',
    category: 'Compliance',
    route: '/reports/monthly_attendancev2'
  },
  {
    title: 'Retail Price List',
    role_feature: 'report_pricelist',
    id: 154,
    code: 'R154',
    category: 'Master',
    route: '/reports/pricelistv2'
  },
  {
    title: 'Distributor Retailer Mapping',
    role_feature: 'report_distributor_retailer_mapping',
    id: 155,
    code: 'R155',
    category: 'Master',
    route: '/reports/distributor_retailer_report'
  },
  {
    title: 'Outlet Last Visit',
    role_feature: 'report_outlet_last_visit',
    id: 156,
    code: 'R156',
    category: 'Productivity',
    route: '/reports/outlet_last_visit'
  },
  {
    title: 'Beat Activity - Detailed',
    role_feature: 'report_beat_activity',
    id: 157,
    code: 'R157',
    category: 'Productivity',
    route: '/reports/beat_activity'
  },
  {
    title: 'Beat Activity - Summary',
    role_feature: 'report_beat_activity_summary',
    id: 158,
    code: 'R158',
    category: 'Productivity',
    route: '/reports/beat_activity_summary'
  },
  {
    title: 'User Activity',
    role_feature: 'report_user_activity',
    id: 159,
    code: 'R159',
    category: 'Productivity',
    route: '/reports/user_activity'
  },
  {
    title: 'Customer Ledger',
    role_feature: 'report_customer_ledger',
    id: 160,
    code: 'R160',
    category: 'Finance',
    route: '/reports/customer_ledger'
  },
  {
    title: 'Primary Ledger',
    role_feature: 'report_finance_ledger',
    id: 161,
    code: 'R161',
    category: 'Finance',
    route: '/reports/my_ledger'
  },
  {
    title: 'Task Adherence',
    role_feature: 'report_task_adherence',
    id: 162,
    code: 'R162',
    category: 'Task',
    route: '/reports/task_adherence'
  },
  {
    title: 'Asset Type Master',
    role_feature: 'report_asset_type_master',
    id: 163,
    code: 'R163',
    category: 'Master',
    route: '/reports/asset_type_master'
  },
  {
    title: 'Outlet Visit',
    role_feature: 'report_outlet_not_visit',
    id: 164,
    code: 'R164',
    category: 'Productivity',
    route: '/reports/outlet_not_visit'
  },
  {
    title: 'Outlet Visit(Day Wise)',
    role_feature: 'report_outlet_visit_day_wise',
    id: 165,
    code: 'R165',
    category: 'Productivity',
    route: '/reports/outlet_visit_day_wise'
  },
  {
    title: 'Retailer Target vs Achievement',
    role_feature: 'report_retailer_target_achievement',
    id: 166,
    code: 'R166',
    category: 'Targets',
    route: '/reports/retailer_target_achievement'
  },
  {
    title: 'Salesman - Month Wise',
    role_feature: 'report_salesman_target_achievement',
    id: 167,
    code: 'R167',
    category: 'Targets',
    route: '/reports/salesman_target_achievement'
  },
  {
    title: 'Distributor Target vs Achievement',
    role_feature: 'report_distributor_target_achievement',
    id: 168,
    code: 'R168',
    category: 'Targets',
    route: '/reports/distributor_target_achievement'
  },
  {
    title: 'Beat Last Visit',
    role_feature: 'report_beat_last_visit',
    id: 169,
    code: 'R169',
    category: 'Productivity',
    route: '/reports/beat_last_visit'
  },
  {
    title: 'Beat Coverage',
    role_feature: 'report_beat_coverage',
    id: 170,
    code: 'R170',
    category: 'Productivity',
    route: '/reports/beat_coverage'
  },
  {
    title: 'Credit Returns',
    role_feature: 'report_credit_returns',
    id: 172,
    code: 'R172',
    category: 'Finance',
    route: '/reports/credit_returns'
  },
  {
    title: 'MSL Compliance',
    role_feature: 'report_msl_compliance',
    id: 173,
    code: 'R173',
    category: 'Retail Audit',
    route: '/reports/report_msl_compliance'
  },
  {
    title: 'Distributor Master',
    role_feature: 'report_distributor_master',
    id: 174,
    code: 'R174',
    category: 'Master',
    route: '/reports/distributor_master'
  },
  {
    title: 'Credit Notes',
    role_feature: 'report_credit_notes',
    id: 175,
    code: 'R175',
    category: 'Sales',
    route: '/reports/credit_notes'
  },
  {
    title: 'Debit Notes',
    role_feature: 'report_credit_notes',
    id: 176,
    code: 'R176',
    category: 'Sales',
    route: '/reports/debit_notes'
  },
  {
    title: 'Salesman - Sub Target Grouped',
    role_feature: 'report_salesman_target_achievement',
    id: 177,
    code: 'R177',
    category: 'Targets',
    route: '/reports/salesman_target_achievement_bytarget'
  },
  {
    title: 'Monthly Attendance(Team Wise)',
    role_feature: 'report_monthly_attendance_team',
    id: 178,
    code: 'R178',
    category: 'Compliance',
    route: '/reports/monthly_attendance_team_wise'
  },
  {
    title: 'Daily Attendance(Team Wise)',
    role_feature: 'report_daily_attendance_team',
    id: 179,
    code: 'R179',
    category: 'Compliance',
    route: '/reports/daily_attendance_team_wise'
  },
  {
    title: 'Secondary Order Bill Scheme',
    role_feature: 'report_secondary_order_billscheme',
    id: 180,
    code: 'R180',
    category: 'Scheme',
    route: '/reports/bill_scheme_s_report'
  },
  {
    title: 'Secondary Invoice Bill Scheme',
    role_feature: 'report_secondary_invoice_billscheme',
    id: 181,
    code: 'R181',
    category: 'Scheme',
    route: '/reports/bill_scheme_invoice_s_report'
  },
  {
    title: 'Secondary Order Product Scheme',
    role_feature: 'report_secondary_order_productscheme',
    id: 182,
    code: 'R182',
    category: 'Scheme',
    route: '/reports/product_scheme_s_report'
  },
  {
    title: 'Secondary Invoice Product Scheme',
    role_feature: 'report_secondary_invoice_productscheme',
    id: 183,
    code: 'R183',
    category: 'Scheme',
    route: '/reports/product_scheme_invoice_s_report'
  },
  {
    title: 'Scheme Claim Disbursement',
    role_feature: 'report_claim_scheme',
    id: 184,
    code: 'R184',
    category: 'Scheme',
    route: '/reports/claim_scheme_report'
  },
  {
    title: 'Secondary Invoice Scheme Claim',
    role_feature: 'report_secondary_invoice_claimscheme',
    id: 185,
    code: 'R185',
    category: 'Scheme',
    route: '/reports/claim_scheme_invoice_s_report'
  },
  {
    title: 'Primary Order Bill Scheme',
    role_feature: 'report_primary_order_billscheme',
    id: 186,
    code: 'R186',
    category: 'Scheme',
    route: '/reports/bill_scheme_p_report'
  },
  {
    title: 'Primary Invoice Bill Scheme',
    role_feature: 'report_primary_invoice_billscheme',
    id: 187,
    code: 'R187',
    category: 'Scheme',
    route: '/reports/bill_invoice_scheme_p_report'
  },
  {
    title: 'Primary Order Product Scheme',
    role_feature: 'report_primary_order_productscheme',
    id: 188,
    code: 'R188',
    category: 'Scheme',
    route: '/reports/product_scheme_p_report'
  },
  {
    title: 'Primary Invoice Product Scheme',
    role_feature: 'report_primary_invoice_productscheme',
    id: 189,
    code: 'R189',
    category: 'Scheme',
    route: '/reports/product_invoice_scheme_p_report'
  },
  {
    title: 'Scheme Claim Disbursement',
    role_feature: 'report_primary_order_claimscheme',
    id: 190,
    code: 'R190',
    category: 'Scheme',
    route: '/reports/claim_scheme_p_report'
  },
  {
    title: 'Primary Invoice Scheme Claim',
    role_feature: 'report_primary_invoice_claimscheme',
    id: 191,
    code: 'R191',
    category: 'Scheme',
    route: '/reports/claim_invoice_scheme_p_report'
  },
  // {
  //   title: 'Salesman Main Target vs Achievement - Deprecated',
  //   role_feature: 'report_salesman_target_achievement',
  //   id: 192,
  //   code: 'R192',
  //   category: 'Targets',
  //   route: '/reports/salesman_main_target_achievement_month'
  // },
  {
    title: 'Salesman - Sub Targets Detailed',
    role_feature: 'report_salesman_target_achievement',
    id: 193,
    code: 'R193',
    category: 'Targets',
    route: '/reports/salesman_sub_target_achievement_month'
  },
  {
    title: 'Beat Retailer',
    role_feature: 'report_beat_retailer',
    id: 194,
    code: 'R194',
    category: 'Master',
    route: '/reports/beat_retailer'
  },
  {
    title: 'Primary Sales - Detailed',
    role_feature: 'report_primary_sales',
    id: 195,
    code: 'R195',
    category: 'Sales',
    route: '/reports/primary_sales'
  },
  {
    title: 'Beat Productivity',
    role_feature: 'report_beat_productivity',
    id: 196,
    code: 'R196',
    category: 'Productivity',
    route: '/reports/beat_productivity'
  },
  {
    title: 'Custom Retail Audit',
    role_feature: 'report_custom_retail_audit',
    id: 197,
    code: 'R197',
    category: 'Retail Audit',
    route: '/reports/custom_retail_audit'
  },
  {
    title: 'Van Stock report',
    role_feature: 'report_van_stock',
    id: 198,
    code: 'R198',
    category: 'Sales',
    route: '/reports/van_stock'
  },
  {
    title: 'Product Performance ',
    role_feature: 'report_productPerformance',
    id: 198,
    code: 'R198',
    category: 'Productivity',
    route: '/reports/product_performance'
  },
  {
    title: 'Stock Dispute Report ',
    role_feature: 'report_stock_dispute',
    id: 199,
    code: 'R199',
    category: 'Stock',
    route: '/reports/stock_dispute'
  },
  {
    title: 'Van Day Wise Summary',
    role_feature: 'report_van_day_wise_summary',
    id: 199,
    code: 'R199',
    category: 'Finance',
    route: '/reports/van_day_wise_summary_report'
  },
  {
    title: 'Retail Stock Range Distribution',
    role_feature: 'report_retail_stock_range',
    id: 200,
    code: 'R200',
    category: 'Stock',
    route: '/reports/retail_stock_range'
  },
  {
    title: 'Cumulative Sales Report',
    role_feature: 'report_cumulative_sales',
    id: 201,
    code: 'R201',
    category: 'Sales',
    route: '/reports/cumulative_sales'
  },
  {
    title: 'Trend Analysis',
    role_feature: 'report_trend_analysis',
    id: 202,
    code: 'R202',
    category: 'Sales',
    route: '/reports/trend_analysis'
  },
  {
    title: 'Purchase Order Fulfillment',
    role_feature: 'report_purchase_order_fulfillment',
    id: 203,
    code: 'R203',
    category: 'Sales',
    route: '/reports/purchase_order_fulfillment'
  },
  {
    title: 'Sales and Return Detailed',
    role_feature: 'report_sales_and_return_detailed',
    id: 204,
    code: 'R204',
    category: 'Sales',
    route: '/reports/sales_and_return_detailed'
  },
  {
    title: 'Van Stock Reconciliation',
    role_feature: 'report_van_stock_reconciliation',
    id: 205,
    code: 'R205',
    category: 'Sales',
    route: '/reports/van_stock_reconciliation'
  },
  {
    title: 'Primary Sales Stock',
    role_feature: 'report_primary_sales_stock',
    id: 206,
    code: 'R206',
    category: 'Sales',
    route: '/reports/primary_sales_stock'
  },
  {
    title: 'Zero Sales',
    role_feature: 'report_zero_sales',
    id: 207,
    code: 'R207',
    category: 'Sales',
    route: '/reports/zero_sales'
  },
  {
    title: 'Consecutive No Sales',
    role_feature: 'report_consecutive_no_sales',
    id: 208,
    code: 'R208',
    category: 'Sales',
    route: '/reports/consecutive_no_sales'
  },
  {
    title: 'Coverage And Productivity',
    role_feature: 'report_coverage_and_productivity',
    id: 209,
    code: 'R209',
    category: 'Productivity',
    route: '/reports/coverage_and_productivity'
  },
  {
    title: 'Production Order Report',
    role_feature: 'report_production_order',
    id: 210,
    code: 'R210',
    category: 'Productivity',
    route: '/reports/production_order'
  },
  {
    title: 'New Outlets Report',
    role_feature: 'report_new_outlets',
    id: 211,
    code: 'R211',
    category: 'Master',
    route: '/reports/new_outlets'
  },
  {
    title: 'Salesman Performance Measure Report',
    role_feature: 'report_salesman_performance_measure',
    id: 212,
    code: 'R212',
    category: 'Productivity',
    route: '/reports/salesman_performance_measure'
  },
  {
    title: 'SR Score Card Report',
    role_feature: 'report_sr_scorecard',
    id: 213,
    code: 'R213',
    category: 'Productivity',
    route: '/reports/sr_scorecard'
  },
  {
    title: 'Less than 50% MSS Outlet',
    role_feature: 'report_lessthan_mss_outlet',
    id: 214,
    code: 'R214',
    category: 'Sales',
    route: '/reports/less_than_mss_outlet'
  },
  {
    title: 'Revenue Dispersion Report',
    role_feature: 'report_revenue_dispersion',
    id: 215,
    code: 'R215',
    category: 'Sales',
    route: '/reports/revenue_dispersion'
  },
  {
    title: 'SKU Dispersion Report',
    role_feature: 'report_sku_dispersion',
    id: 216,
    code: 'R216',
    category: 'Sales',
    route: '/reports/sku_dispersion'
  },
  {
    title: 'Daily Goals Report',
    role_feature: 'report_daily_goals',
    id: 216,
    code: 'R216',
    category: 'Productivity',
    route: '/reports/daily_goals'
  },
  {
    title: 'Partner Meet Report',
    role_feature: 'report_partner_meet',
    id: 217,
    code: 'R217',
    category: 'Productivity',
    route: '/reports/partner_meet'
  },
  {
    title: 'Partner Visit Report',
    role_feature: 'depricated_report_partner_visit',
    id: 218,
    code: 'R218',
    category: 'Productivity',
    route: '/reports/partner_visit'
  },
  {
    title: 'Meet Attendees Report',
    role_feature: 'report_attendees_meet',
    id: 219,
    code: 'R219',
    category: 'Productivity',
    route: '/reports/meet_attendees'
  },
  {
    title: 'Scheme Claim Order Disbursement',
    role_feature: 'report_claim_order_scheme',
    id: 220,
    code: 'R220',
    category: 'Scheme',
    route: '/reports/claim_scheme_order'
  },
  {
    title: 'Retailer Supplier Mapping Report',
    role_feature: 'report_retailer_vertical_distributor_master',
    id: 221,
    code: 'R221',
    category: 'Master',
    route: '/reports/retailer_vertical_distributor_master'
  },
  {
    title: 'Beat Retailer Report',
    role_feature: 'report_beat_retailerv2',
    id: 222,
    code: 'R222',
    category: 'Master',
    route: '/reports/beat_retailerv2'
  },
  {
    title: 'Retailer Asset Sales Report',
    role_feature: 'report_retailer_asset_sales',
    id: 223,
    code: 'R223',
    category: 'Sales',
    route: '/reports/retailer_asset_sales'
  },
  {
    title: 'Partner Lead Master Report',
    role_feature: 'report_lead_master',
    id: 224,
    code: 'R224',
    category: 'Master',
    route: '/reports/partner_lead_master'
  },
  {
    title: 'Product Suggestion Report',
    role_feature: 'report_product_suggestion',
    id: 225,
    code: 'R225',
    category: 'Sales',
    route: '/reports/product_sug'
  },
  {
    title: 'Retailer Supplier Vertical Salesman Mapping',
    role_feature: 'report_retailer_sup_vertical_mapping',
    id: 226,
    code: 'R226',
    category: 'Master',
    route: '/reports/retailer_sup_vertical_map'
  },
  {
    title: 'Influencer Master Report',
    role_feature: 'report_influencer_master',
    id: 227,
    code: 'R227',
    category: 'Master',
    route: '/reports/influencer_master'
  },
  {
    title: 'Project Master Report',
    role_feature: 'report_project_master',
    id: 228,
    code: 'R228',
    category: 'Master',
    route: '/reports/project_master'
  },
  {
    title: 'Van Stock report V2',
    role_feature: 'report_van_stockv2',
    id: 229,
    code: 'R229',
    category: 'Stock',
    route: '/reports/van_stockv2'
  },

  {
    title: 'Asset Activity Report',
    role_feature: 'report_asset_activity',
    id: 230,
    code: 'R230',
    category: 'Productivity',
    route: '/reports/asset_activity'
  },

  {
    title: 'Bottom X Performers Report',
    role_feature: 'report_user_performance',
    id: 231,
    code: 'R231',
    category: 'Productivity',
    route: '/reports/user_performance'
  },
  {
    title: 'Dealer Performers Report',
    role_feature: 'report_dealer_performance',
    id: 232,
    code: 'R232',
    category: 'Productivity',
    route: '/reports/dealer_performance'
  },

  {
    title: 'YTD Dealer Billing Report',
    role_feature: 'report_ytd_dealer_billing',
    id: 233,
    code: 'R233',
    category: 'Sales',
    route: '/reports/ytd_dealer_billing'
  },

  {
    title: 'Dealer Billing Report',
    role_feature: 'report_dealer_billing',
    id: 234,
    code: 'R234',
    category: 'Sales',
    route: '/reports/dealer_billing'
  },

  {
    title: 'Visit Summary Report',
    role_feature: 'report_visit_summary',
    id: 235,
    code: 'R235',
    category: 'Productivity',
    route: '/reports/visit_summary'
  },

  {
    title: 'Dealer Outstanding Report',
    role_feature: 'report_dealer_outstanding',
    id: 236,
    code: 'R236',
    category: 'Finance',
    route: '/reports/dealer_outstanding'
  },
  {
    title: 'Van Bonus Report',
    role_feature: 'report_van_bonus',
    id: 237,
    code: 'R237',
    category: 'Productivity',
    route: '/reports/van_bonus'
  },
  {
    title: 'DC Price List',
    role_feature: 'report_dc_pricelist',
    id: 238,
    code: 'R238',
    category: 'Master',
    route: '/reports/dcpricelist'
  },
  {
    title: 'User leads Report',
    role_feature: 'report_user_leads',
    id: 239,
    code: 'R239',
    category: 'Leads',
    route: '/reports/user_leads'
  },
  {
    title: 'Daily User Performance',
    role_feature: 'report_daily_user_performance',
    id: 240,
    code: 'R240',
    category: 'Productivity',
    route: '/reports/daily_user_performance'
  },
  {
    title: 'User Performance Summary',
    role_feature: 'report_user_performance_summary',
    id: 241,
    code: 'R241',
    category: 'Productivity',
    route: '/reports/user_performance_summary'
  },
  {
    title: 'Van Sale Summary',
    role_feature: 'report_van_sale_summary',
    id: 242,
    code: 'R242',
    category: 'Productivity',
    route: '/reports/van_sale_summary'
  },
  {
    title: 'DC Stock Report',
    role_feature: 'report_dc_stock',
    id: 243,
    code: 'R243',
    category: 'Stock',
    route: '/reports/dc_stock'
  },
  {
    title: 'Bill Scheme(Single and Margin)',
    role_feature: 'report_single_margin_bill_scheme',
    id: 244,
    code: 'R244',
    category: 'Scheme',
    route: '/reports/single_margin_bill'
  },
  {
    title: 'Task Survey Report',
    role_feature: 'report_task_survey',
    id: 245,
    code: 'R245',
    category: 'Task',
    route: '/reports/task_survey'
  },
  {
    title: 'Tertiary Audit with Market Share',
    role_feature: 'report_stock_audit_market_share',
    id: 246,
    code: 'R246',
    category: 'Retail Audit',
    route: '/reports/stock_audit_market_share'
  },
  {
    title: 'Target And Visit summary',
    role_feature: 'report_target_and_visit_summary',
    id: 247,
    code: 'R246',
    category: 'Productivity',
    route: '/reports/target_and_visit_report'
  },
  {
    title: 'New Report',
    role_feature: 'report_new_report',
    id: 248,
    code: 'R247',
    category: 'Productivity',
    route: '/reports/new_report'
  },
  {
    title: 'Tertiary Audit Report',
    role_feature: 'report_tertiary_audit_report',
    id: 249,
    code: 'R249',
    category: 'Retail Audit',
    route: '/reports/tertiary_audit_report'
  },
  {
    title: 'Outlet Product Recommendation Report',
    role_feature: 'report_outlet_product_recommendation',
    id: 250,
    code: 'R250',
    category: 'Master',
    route: '/reports/outlet_product_recommendation'
  },
  {
    title: 'Distributor Last CheckIn',
    role_feature: 'report_distributor_last_checkin',
    id: 251,
    code: 'R251',
    category: 'Productivity',
    route: '/reports/distributor_last_checkin_report'
  },
  {
    title: 'Territory User Mapping Report',
    role_feature: 'territory_user_mapping',
    id: 252,
    code: 'R252',
    category: 'Master',
    route: '/reports/territory_user_mapping'
  },
  {
    title: 'User Survey Report',
    role_feature: 'report_user_survey',
    id: 253,
    code: 'R253',
    category: 'Survey',
    route: '/reports/user_survey'
  },
  {
    title: 'Purchase Order IMEI Report',
    role_feature: 'report_purchase_order_imei',
    id: 254,
    code: 'R254',
    category: 'Sales',
    route: '/reports/purchase_order_imei'
  },
  {
    title: 'Secondary Sales - Detailed (IMEI)',
    role_feature: 'report_secondary_sales_detailed_imei',
    id: 255,
    code: 'R255',
    category: 'Sales',
    route: '/reports/secondary_sales_detailed_imei'
  },
  {
    title: 'Partner Meet Gallery',
    role_feature: 'report_partner_meet_gallery',
    id: 256,
    code: 'R256',
    category: 'Productivity',
    route: '/reports/partner_meet_gallery'
  },
  {
    title: 'Partner KYC Pivot Report',
    role_feature: 'report_partner_kyc',
    id: 257,
    code: 'R257',
    category: 'Survey',
    route: 'reports/partner_kyc_survey_pivot'
  },
  {
    title: 'Partner Market Intelligence Pivot Report',
    role_feature: 'report_partner_market_intelligence',
    id: 258,
    code: 'R258',
    category: 'Survey',
    route: 'reports/partner_market_intelligence_pivot'
  },
  {
    title: 'Partner KYC Report',
    role_feature: 'report_partner_kyc',
    id: 259,
    code: 'R259',
    category: 'Survey',
    route: 'reports/partner_kyc_dump_survey'
  },
  {
    title: 'Partner Market Intelligence Report',
    role_feature: 'report_partner_market_intelligence',
    id: 260,
    code: 'R260',
    category: 'Survey',
    route: 'reports/partner_market_intelligence_dump_survey'
  },
  {
    title: 'Sale Lead Master Report',
    role_feature: 'report_sale_lead_master',
    id: 261,
    code: 'R261',
    category: 'Master',
    route: '/reports/sale_lead_master'
  },
  {
    title: 'YTD Supplier Order Growth',
    role_feature: 'report_yearly_supplier_growth',
    id: 262,
    code: 'R262',
    category: 'Productivity',
    route: '/reports/ytd_supplier_order_growth'
  },
  {
    title: 'YTD Primary Order Growth',
    role_feature: 'report_yearly_primary_growth',
    id: 263,
    code: 'R263',
    category: 'Productivity',
    route: '/reports/ytd_primary_order_growth'
  },
  {
    title: 'Daily Goals Report V2',
    role_feature: 'report_daily_goals_v2',
    id: 264,
    code: 'R264',
    category: 'Productivity',
    route: '/reports/daily_goals_v2'
  },
  {
    title: 'Product Category Growth',
    role_feature: 'report_product_category_growth',
    id: 265,
    code: 'R265',
    category: 'Productivity',
    route: '/reports/product_category_growth'
  },
  {
    title: 'Service Spare Parts',
    role_feature: 'report_service_spare_parts',
    id: 266,
    code: 'R266',
    category: 'Productivity',
    route: '/reports/service_spare_parts'
  },
  {
    title: 'Service Line Spare Parts',
    role_feature: 'report_service_line_spare_parts',
    id: 267,
    code: 'R267',
    category: 'Productivity',
    route: '/reports/service_line_spare_parts'
  },
  {
    title: 'User Alerts',
    role_feature: 'report_user_alerts',
    id: 268,
    code: 'R268',
    category: 'Master',
    route: '/reports/user_alerts'
  },
  {
    title: 'Partner Alerts',
    role_feature: 'report_partner_alerts',
    id: 269,
    code: 'R269',
    category: 'Master',
    route: '/reports/partner_alerts'
  },
  {
    title: 'Partner Checkin Master',
    role_feature: 'report_partner_checkin_master',
    id: 270,
    code: 'R270',
    category: 'Master',
    route: '/reports/partner_checkin_master'
  },
  {
    title: 'Beat Zero Sales',
    role_feature: 'report_beat_zero_sales',
    id: 271,
    code: 'R271',
    category: 'Sales',
    route: '/reports/master_zero_sales'
  },
  {
    title: 'Beat Planning & Sales Report',
    role_feature: 'report_beat_planning_sales',
    id: 272,
    code: 'R272',
    category: 'Productivity',
    route: '/reports/beat_planning_sales'
  },
  {
    title: 'RAW Partner Checkin',
    role_feature: 'report_partner_checkin_master',
    id: 270,
    code: 'R273',
    category: 'Master',
    route: '/reports/partner_raw_checkin'
  },
  {
    title: 'User KPI Summary',
    role_feature: 'report_user_kpi',
    id: 274,
    code: 'R274',
    category: 'KPI',
    route: '/reports/user_kpi_summary'
  },
  {
    title: 'User KPI Leaderboard',
    role_feature: 'report_user_kpi_leaderboard',
    id: 275,
    code: 'R275',
    category: 'KPI',
    route: '/reports/user_kpi_leaderboard'
  },
  {
    title: 'Purchase Order Report',
    role_feature: 'report_purchase_order',
    id: 276,
    code: 'R276',
    category: 'Sales',
    route: '/reports/purchase_order'
  },
  {
    title: 'Partner Contact Master Report',
    role_feature: 'report_partner_contact_master',
    id: 277,
    code: 'R277',
    category: 'Master',
    route: '/reports/partner_contact_master'
  },
  {
    title: 'RAW Visits',
    role_feature: 'report_raw_visits',
    id: 278,
    code: 'R278',
    category: 'Master',
    route: '/reports/raw_visits'
  },
  {
    title: 'Member Visits',
    role_feature: 'report_member_visits',
    id: 279,
    code: 'R279',
    category: 'Productivity',
    route: '/reports/member_visits'
  },
  {
    title: 'RAW User Alerts',
    role_feature: 'report_user_alerts',
    id: 280,
    code: 'R280',
    category: 'Master',
    route: '/reports/raw_user_alerts'
  },
  {
    title: 'Outlet Stock Ageing',
    role_feature: 'report_stock_availability',
    id: 281,
    code: 'R281',
    category: 'Stock',
    route: '/reports/outlet_stock_ageing'
  },
  {
    title: 'Asset Service',
    role_feature: 'report_asset_service',
    id: 282,
    code: 'R282',
    category: 'Productivity',
    route: '/reports/asset_service_report'
  }, {
    title: 'Visits Overview',
    role_feature: 'report_partner_visit',
    id: 283,
    code: 'R283',
    category: 'Productivity',
    route: '/reports/territory_visits'
  },
  {
    title: 'Internal Stock Transfer ',
    role_feature: 'report_internal_stock_transfer',
    id: 284,
    code: 'R284',
    category: 'Stock',
    route: '/reports/internal_stock_transfer'
  },
  {
    title: 'Territory KPI Summary',
    role_feature: 'report_territory_kpi',
    id: 285,
    code: 'R285',
    category: 'KPI',
    route: '/reports/territory_kpi_summary'
  },
  {
    title: 'Territory KPI Leaderboard',
    role_feature: 'report_territory_kpi_leaderboard',
    id: 286,
    code: 'R286',
    category: 'KPI',
    route: '/reports/territory_kpi_leaderboard'
  },
  {
    title: 'Beat Partner Report',
    role_feature: 'report_beat_partner',
    id: 287,
    code: 'R286',
    category: 'Master',
    route: '/reports/beat_partner'
  },
  {
    title: 'Service Spare Parts Request',
    role_feature: 'report_service_spare_parts_request',
    id: 288,
    code: 'R288',
    category: 'Productivity',
    route: '/reports/service_spare_parts_request'
  },
  {
    title: 'Partner Merchandise',
    role_feature: 'report_partner_merchandise',
    id: 289,
    code: 'R289',
    category: 'Audit',
    route: '/reports/partner_merchandise'
  },
  {
    title: 'Eway Bill',
    role_feature: 'report_eway_bill',
    id: 290,
    code: 'R290',
    category: 'Finance',
    route: '/reports/ewaybill'
  },
  {
    title: 'Partner Asset',
    role_feature: 'report_partner_asset',
    id: 291,
    code: 'R291',
    category: 'Productivity',
    route: '/reports/partner_asset'
  },
  {
    title: ' Beat Planning and Target Tracker',
    role_feature: 'report_beat_planning_and_target_tracker',
    id: 292,
    code: 'R292',
    category: 'Targets',
    route: '/reports/beat_planning_and_target_tracker'
  },
  {
    title: 'Loyalty Program Tracker',
    role_feature: 'report_loyalty_program_tracker',
    id: 293,
    code: 'R293',
    category: 'Scheme',
    route: '/reports/loyalty_program_tracker'
  },
  {
    title: 'Sale Metric Report',
    role_feature: 'sale_metric_report',
    id: 294,
    code: 'R294',
    category: 'Sales',
    route: '/reports/sale_metric_report'
  },
  {
    title: 'Daily Tracker Report',
    role_feature: 'daily_tracker_report',
    id: 295,
    code: 'R295',
    category: 'Compliance',
    route: '/reports/daily_tracker_report'
  },
  {
    title: 'DSR - Daywise Salesman Report',
    role_feature: 'daily_sales_report',
    id: 296,
    code: 'R296',
    category: 'Sales',
    route: '/reports/daywise_sales_report'
  },
  {
    title: 'Monthly Partner Visits',
    role_feature: 'report_monthly_partner_visit',
    id: 297,
    code: 'R297',
    category: 'Productivity',
    route: '/reports/monthly_partner_visits'
  },
  {
    title: 'Tertiary Sales Report - With Serials',
    role_feature: 'report_tertiary_sales_with_serial',
    id: 298,
    code: 'R298',
    category: 'Sales',
    route: '/reports/tertiary_sales_with_serial'
  },
  {
    title: 'Distributor Adoption Report',
    role_feature: 'report_distributor_adoption',
    id: 299,
    code: 'R299',
    category: 'Productivity',
    route: '/reports/distributor_adoption'
  },
  {
    title: 'TCS Report',
    role_feature: 'report_tcs',
    id: 300,
    code: 'R300',
    category: 'Sales',
    route: '/reports/tcs_report'
  },
  {
    title: 'Purchase Returns Report',
    role_feature: 'report_purchase_returns',
    id: 301,
    code: 'R301',
    category: 'Sales',
    route: '/reports/purchase_returns_report'
  },
  {
    title: 'Payment Ageing Report',
    role_feature: 'report_payment_ageing',
    id: 302,
    code: 'R302',
    category: 'Finance',
    route: '/reports/payment_ageing_report'
  },
  {
    title: 'Daily Collection Report',
    role_feature: 'report_daily_collection_sales',
    id: 303,
    code: 'R303',
    category: 'Finance',
    route: '/reports/sales_dailycollection'
  },
  {
    title: 'Outlet Overdue Report',
    role_feature: 'report_outlet_overdue_sales',
    id: 304,
    code: 'R304',
    category: 'Finance',
    route: '/reports/outlet_overdue_report_sales'
  },
  {
    title: 'Asset Survey Pivot Report',
    role_feature: 'report_asset_survey',
    id: 305,
    code: 'R305',
    category: 'Survey',
    route: 'reports/asset_survey_pivot_report'
  },
  {
    title: 'Task Survey Pivot Report',
    role_feature: 'report_task_survey',
    id: 306,
    code: 'R306',
    category: 'Survey',
    route: 'reports/task_survey_pivot_report'
  },
  {
    title: 'Service Survey Pivot Report',
    role_feature: 'report_service_survey',
    id: 306,
    code: 'R307',
    category: 'Survey',
    route: 'reports/service_survey_pivot_report'
  },
  {
    title: 'Daily User Periodic Activity',
    role_feature: 'daily_user_periodic_activity_report',
    id: 305,
    code: 'R308',
    category: 'Productivity',
    route: '/reports/daily_user_periodic_activity_report'
  },
  {
    title: 'Stock And Sale Report',
    role_feature: 'report_stock_and_sale',
    id: 305,
    code: 'R308',
    category: 'Stock',
    route: '/reports/stock_and_sale'
  }

];



