import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";
import {ErrorHandler} from "../../error/error-handler";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {DMSBasePage} from "../../pagebase/components/DMSBasePage";

@Component({
  selector: 'app-stock-availability-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class StockAndSaleReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Stock And Sales Report');

    this.showableFilters.territory = false;
    this.showableFilters.team = false;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = true;
    this.dateFilterType = 'period';

    this.configureGrid();

  }

  configureGrid() {
    this.API_URL = '/api/pwa_dms_connects/get_supplier_stock_and_sale_report';
    const inThis = this;
    this.columnDefs = [
      {headerName: 'supplier_id', field: 'dist_id', hide: true},
      {headerName: 'Supplier Code', field: 'supp_code', hide: true},
      {headerName: 'Supplier', field: 'supplier', width: 200},
      {headerName: 'Product ID', field: 'product_id', hide: true},
      {headerName: 'Product Code', field: 'default_code'},
      {headerName: 'Product Name', field: 'name', width: 250},
      {headerName: 'Category', field: 'category'},
      {headerName: 'Brand', field: 'brand'},
      {headerName: 'Opening', field: 'opening', cellStyle: {textAlign: 'right'}},
      {headerName: 'Inward', field: 'inward', cellStyle: {textAlign: 'right'}},
      {headerName: 'Sales', field: 'sales', cellStyle: {textAlign: 'right'}},
      {headerName: 'return', field: 'return', cellStyle: {textAlign: 'right'}},
      {headerName: 'Damage', field: 'damage', cellStyle: {textAlign: 'right'}},
      {headerName: 'Closing', field: 'closing', cellStyle: {textAlign: 'right'}},
      {headerName: 'Current Stock', field: 'stock', cellStyle: {textAlign: 'right'}},
      {
        headerName: 'Log', field: 'stock_log', menuTabs: [], cellStyle: {textAlign: 'center'},
        cellRenderer(params) {
          if (params.data) {
            return '<div><button class="btn confirm-green-btn" style="padding: 2px 18px; font-size: 83%">VIEW</button></div>';
          }
        }
      },

    ];

  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }


  configureData() {
    console.log(this.apiData);
    if (this.apiData.hasOwnProperty('stocks')) {
      this.rowData = this.apiData['stocks'];

      //For each product, distributor row, Get the logs
      if (this.apiData['logs']) {
        this.rowData.forEach(row => {
          const logs = this.apiData['logs'].filter(i => i.distributor_id === row.distributor_id && i.product_id === row.product_id);
          if (logs && logs.length > 0) {
            if (logs[0]['type'].toUpperCase() === 'P') {
              row['opening'] = logs[0]['balance'] - logs[0]['quantity'];
            } else {
              row['opening'] = logs[0]['balance'] + logs[0]['quantity'];
            }

            row['inward'] = 0;
            row['sales'] = 0;

            logs.forEach(l => {
              if (l['type'].toUpperCase() === 'P') {
                row['inward'] += l.quantity;
              } else {
                row['sales'] += l.quantity;
              }
            });
            row['closing'] = logs[logs.length - 1]['balance'];
          }
        });
      }
    }
  }
}
