import {TaxCalculator} from "./TaxCalculator";
import {DMSBasePage} from "./DMSBasePage";

export class SchemeCalculator {

  cartItems = [];
  schemes = [];
  prodList = [];
  // UOMList = [];
  UOMMasterList = [];
  bill_value = 0;

  constructor(cartItem, schemes, prodList, uomMasterList) {
    this.cartItems = [];
    cartItem.forEach(i => {
      if (i.type === 'normal' || (i.line_id !== undefined && i.line_id > 0)) {
        this.cartItems.push(i);
      }
    });
    // this.cartItems = cartItem;
    this.schemes = schemes;
    this.prodList = prodList;
    // this.UOMList = uomList;
    this.UOMMasterList = uomMasterList;
  }

  get_factor_for_selected_uom(selected_uom_id, default_uom_id) {
    let factor_inv = 1;
    if (this.UOMMasterList) {
      const selected_uom = this.UOMMasterList.find(u => u.id === selected_uom_id);
      const default_uom = this.UOMMasterList.find(u => u.id === default_uom_id);
      if (selected_uom && default_uom) {
        factor_inv = default_uom.factor * selected_uom.factor_inv;
      }
    }
    return factor_inv;
  }

  get_uom_base_factor(uom_id) {
    let factor_inv = 1;
    if (this.UOMMasterList) {
      const uom = this.UOMMasterList.find(u => u.id === uom_id);
      if (uom) {
        factor_inv = uom.factor_inv;
      }
    }
    return factor_inv;
  }

  get_uom_name(uom_id) {
    if (this.UOMMasterList) {
      const uom = this.UOMMasterList.find(u => u.id === uom_id);
      if (uom) {
        return uom.name;
      }
    }
    return 'NOT FOUND';
  }

  computeSchemes() {

    this.clearSchemes();

    this.computeNoCombo();

    this.computeCombo();

    return this.cartItems;
  }

  clearSchemes() {

    this.bill_value = 0;
    const inThis = this;

    //   TODO: Delete promo product applied in scheme
    this.cartItems.forEach((row, index, object) => {
      if (row.type === 'promo') {
        if (row.line_id !== undefined && row.line_id > 0) {
          row.deleted = true;
          row.quantity = 0;
          row.reusable_qty = 0;
          row.price_subtotal = 0;
        } else {
          object.slice(index, 1);
        }
      } else if (row.type === 'normal') {
        const same_product_scheme = inThis.schemes.find(item => item.id === row['scheme_id'] && item.scheme_type === 'qps' && item.reward_type === 'product' && item.product_reward_type === 'same');
        if (row.hasOwnProperty('config_json') && row.config_json && row.config_json.hasOwnProperty('promo_qty') && row.config_json.promo_qty > 0) {
          row['quantity'] -= row.config_json.promo_qty;
          row.config_json.promo_qty = 0;
          row.name = '';
          const prod = this.prodList.find(p => p.p_id === row.product_id);
          if (prod) {
            row.name = prod.product;
          }
        }
        //else if (same_product_scheme && !row.hasOwnProperty('promo_qty') && row.hasOwnProperty('scheme_discount') && row.scheme_discount > 0) {
        //   const promoQty = row.scheme_discount / parseFloat(row['base_price']);
        //   row['quantity'] -= promoQty;
        // }
        row['scheme_discount'] = 0;
        row['scheme_code'] = '';
        row['scheme_id'] = undefined;
        // row['promo_qty'] = 0;

        //Keep the subtotal without and discount computed. This will get recomputed if scheme is applied
        row['price_subtotal'] = (parseFloat(row['base_price']) * parseInt(row['quantity'], 10));
        row['price_tax'] = 0;
        row['price_total'] = row['price_subtotal'];
      }
      inThis.bill_value += row['price_subtotal'];
    });



  }

  applySchemePctDiscount(scheme, qualifiedItems, mSubTotal) {
    const discount = (mSubTotal * scheme['margin']) / 100;
    this.cartItems.forEach(cItem => {
      qualifiedItems.forEach(mItem => {
        if (cItem.product_id === mItem.product_id && cItem['scheme_discount'] === 0) {
          cItem['scheme_discount'] = (discount * ((parseFloat(mItem['base_price']) * parseInt(mItem['quantity'], 10)) / mSubTotal));
          cItem['scheme_id'] = scheme['id'];
          if (scheme['code'] !== null && scheme['code'] !== '') {
            cItem['scheme_code'] = scheme['code'];
          } else {
            cItem['scheme_code'] = scheme['name'];
          }
          // cItem['price_subtotal'] = (parseFloat(mItem['price_unit']) * parseInt(mItem['quantity'], 10)) - parseFloat(cItem['scheme_discount']);
        }
      });
    });
  }

  applySameProductRewardDiscount(scheme, promo_multiplier, qualifiedItems) {
    this.cartItems.forEach(cItem => {
      qualifiedItems.forEach(mItem => {
        if (cItem.product_id === mItem.product_id && cItem['scheme_discount'] === 0) {
          const promo_qty = promo_multiplier * parseInt(scheme['promo_uom_qty'], 10);
          cItem['scheme_discount'] = parseFloat(mItem['base_price']) * promo_qty;
          cItem['quantity'] = parseInt(cItem['quantity'], 10) + promo_qty;
          // cItem['promo_qty'] = promo_qty;
          if (!cItem['config_json'] || !cItem.hasOwnProperty('config_json')) {
            cItem['config_json'] = {};
          }
          cItem['config_json']['promo_qty'] = promo_qty;
          cItem['scheme_id'] = scheme['id'];
          if (scheme['code'] !== null && scheme['code'] !== '') {
            cItem['scheme_code'] = scheme['code'];
          } else {
            cItem['scheme_code'] = scheme['name'];
          }

          const prod = this.prodList.find(p => p.p_id === cItem.product_id);
          if (prod) {
            cItem['name'] = prod['product'] + '[' + promo_qty + ' PROMO]';
          } else {
            //TODO: it should never come here, Throw error
            if (scheme['code'] !== null && scheme['code'] !== '') {
              cItem['scheme_code'] = scheme['code'] + '(+' + promo_qty + ')';
            } else {
              cItem['scheme_code'] = scheme['name'] + '(+' + promo_qty + ')';
            }
          }
          // cItem['price_subtotal'] = (parseFloat(mItem['price_unit']) * parseInt(mItem['quantity'], 10)) - parseFloat(cItem['scheme_discount']);
        }

      });
    });
  }


  preparePromoItem(scheme, promo_multiplier) {

    if (scheme.promo_product_id) {

      const prod = this.prodList.find(item => item.p_id === scheme.promo_product_id);
      if (prod === undefined || prod === null) {
        return;
      }
      // const uom_obj = this.UOMMasterList.find(item => item.id === prod.default_uom_id);
      // if (uom_obj === undefined || uom_obj === null) {
      //   return;
      // }
      const promo_qty = promo_multiplier * parseInt(scheme['promo_uom_qty'], 10);
      const promo_line = DMSBasePage.ConvertInputsToBaseUOM(this.UOMMasterList, promo_qty, prod.default_uom_id, 0, prod);
      // const selected_uom_factor = this.get_factor_for_selected_uom(scheme['promo_uom_id'], prod.default_uom_id);
      let unitMeasure = 1;
      if (prod.hasOwnProperty('param_json')) {
        if (prod.param_json.hasOwnProperty('Unit Measure')) {
          unitMeasure = prod.param_json['Unit Measure'];
        }
      }
      // const unitPrice = parseFloat(prod['unit_price']) * selected_uom_factor;

      // let vol = 0;
      // let wgt = 0;
      // if (prod.hasOwnProperty('volume')) {
      //   vol = prod['volume'] * parseInt(scheme['promo_uom_qty'], 10) * selected_uom_factor;
      // }
      // if (prod.hasOwnProperty('volume')) {
      //   wgt = prod['weight'] * parseInt(scheme['promo_uom_qty'], 10) * selected_uom_factor;
      // }


      const promo_value = promo_line.base_price * promo_line.quantity;
      // let promo_uom_id = prod.uom_id;
      // let promo_uom_name = prod['uom_name'];
      // if (scheme['promo_uom_id'] && scheme['promo_uom_id'] > 0) {
      //   promo_uom_id = scheme['promo_uom_id'];
      //   promo_uom_name = this.get_uom_name(promo_uom_id);
      // }
      //TODO: exclude promo/foc items
      const line_config_json = {base_price: promo_line.base_price, promo_qty: promo_line.quantity};
      const promo_prod_match = this.cartItems.find(item => item.product_id === prod.p_id);
      let line = {};
      if (promo_prod_match) {
        //     Matching Item already exiists, Update QTY
        //     scheme, Subtotal, tax to be recompued


        this.cartItems.forEach(item => {
          if (item.product_id === prod.p_id) {

            item['quantity'] = item['quantity'] + promo_line.quantity;
            item['price_subtotal'] = parseFloat(item['price_subtotal']) + promo_value;
            item['volume'] = parseFloat(item['volume']) + promo_line.vol;
            item['weight'] = parseFloat(item['weight']) + promo_line.wgt;
            if (!item.hasOwnProperty('config_json') || item.config_json == null) {
              item['config_json'] = { base_price: promo_line.base_price};
            }
            if (item['config_json'].hasOwnProperty('promo_qty')) {
              item['config_json']['promo_qty'] = item['config_json']['promo_qty'] + promo_line.quantity;
            } else {
              item['config_json']['promo_qty'] = promo_line.quantity;
            }
            item['promo_value'] = promo_value;
            item['deleted'] = false;
            item['name'] = prod['product'] + ' [' + item.config_json['promo_qty'] + ' PROMO]';

            //Note: subtotal currently uses only promo, so the discount value can be computed
            line = {...item};
            line['new'] = false;
          //   TODO: Compute weight and volume outside this function


          }
        });

      } else {
        // Insert New Row
        const unique_id = this.cartItems.length + 10;
        line = {...prod};
        line['new'] = true;
        line['type'] = 'normal';
        line['product_id'] = prod.p_id;
        line['name'] = prod['product'] + '[' + promo_line.quantity + ' PROMO]';
        line['uid'] = unique_id;
        line['uom_id'] = promo_line.uom_id;
        line['quantity'] = promo_line.quantity;
        line['volume'] = promo_line.vol;
        line['weight'] = promo_line.wgt;
        line['factor'] = 1;
        line['factor_inv'] = 1;
        line['config_json'] = line_config_json;
        line['mid'] = DMSBasePage.generate_mid(prod.p_id);
        line['price_subtotal'] = promo_value;
        line['promo_value'] = promo_value;
        line['price_unit'] = promo_line.price_unit;
        line['base_price'] = promo_line.base_price;
        // const unique_id = this.cartItems.length + 1;
        // line = {
        //   new: true,
        //   uid: unique_id,
        //   mid: DMSBasePage.generate_mid(unique_id),
        //   line_id: 0,
        //   type: 'promo',
        //   code: prod['code'],
        //   name: prod['product'] + ' [PROMO]',
        //   categ_id: prod['categ_id'],
        //   product_id: prod.p_id,
        //   unit_measure: unitMeasure,
        //   tax_amount: parseFloat(prod['tax_amount']),
        //   uom_id: promo_line.uom_id,
        //   default_uom_id: prod.uom_id,
        //   price_unit: promo_line.price_unit,
        //   discount: 0,
        //   trade_disc: 0,
        //   fixed_cost: 0,
        //   scheme_discount: 0,
        //   quantity: promo_line.quantity,
        //   reusable_qty: 0,
        //   price_subtotal: line_subtotal, //Price_subtotal to be recomputed after scheme and discount computation
        //   price_tax: 0,
        //   price_total: 0,
        //   tax_name: prod['tax_name'],
        //   taxmaster_id: prod['taxmaster_id'],
        //   factor_inv: 1,
        //   factor: 1,
        //   volume: promo_line.vol,
        //   weight: promo_line.wgt
        // };

        // if (line['mid'] === undefined || line['mid'].length === 0) {
        //   line['mid'] = (this.cartItems.length * 10);
        // }
      }
      return line;
    }
  }

  applyDiffrentProductRewardDiscount(scheme, promo_multiplier, qualifiedItems) {

    const promo_item = this.preparePromoItem(scheme, promo_multiplier);
    // Prepare Promo Line Item
    if (promo_item && promo_item['promo_value'] && promo_item['promo_value'] > 0) {
      let lineTotal = 0;
      qualifiedItems.forEach(mItem => {
        if (mItem.scheme_discount === 0) {
          lineTotal = lineTotal + mItem['price_subtotal'];
        }
      });
      if (lineTotal > 0) {
        this.cartItems.forEach(cItem => {
          // If you have more than 1 item, them proportionally distribute the discount

          qualifiedItems.forEach(mItem => {
            if (cItem.uid === mItem.uid) {
              if (cItem['scheme_discount'] === 0) {
                cItem['scheme_discount'] = promo_item['promo_value'] * (cItem.price_subtotal / lineTotal);
                cItem['scheme_id'] = scheme['id'];
                if (scheme['code'] !== null && scheme['code'] !== '') {
                  cItem['scheme_code'] = scheme['code'];
                } else {
                  cItem['scheme_code'] = scheme['name'];
                }
                // cItem['price_subtotal'] = (parseFloat(mItem['price_unit']) * parseInt(mItem['quantity'], 10)) - parseFloat(cItem['scheme_discount']);
              }
            }
          });
        });

        if (promo_item['new'] === true) {
          this.cartItems.push(promo_item);
        }
      }
    }
  }

  getPromoMultipler(criteriaRow, mQty, mSubTotal, mVolume, mWeight) {
    let multipler = 1;
    if (criteriaRow.measure_type === 'qty' && mQty >= parseInt(criteriaRow.value, 10)) {
      const criteria_base_value = parseInt(criteriaRow.value, 10) * this.get_uom_base_factor(criteriaRow.uom_id);
      multipler = Math.floor(mQty / criteria_base_value);
    } else if (criteriaRow.measure_type === 'saleprice' && mSubTotal >= parseFloat(criteriaRow.value)) {
      const criteria_base_value = parseInt(criteriaRow.value, 10) * this.get_uom_base_factor(criteriaRow.uom_id);
      multipler = Math.floor(mSubTotal / criteria_base_value);
    } else if (criteriaRow.measure_type === 'volume' && mVolume >= parseFloat(criteriaRow.value)) {
      const criteria_base_value = parseInt(criteriaRow.value, 10);
      multipler = Math.floor(mVolume / criteria_base_value);
    }
    return multipler;
  }

  computeNoCombo() {

    // TODO: ensure to fetch schemes sequenced by priority
    const noComboSchemes = this.schemes.filter(item => item.criteria_mode === 'no_combo' && item.scheme_type === 'qps' );
    noComboSchemes.forEach(scheme => {

      let mQty = 0;
      let mSubTotal = 0;
      let mTotal = 0;
      let mVolume = 0;
      let mWeight = 0;
      let current_discount = 0;

      let min_value = 0;
      let disable_auto_apply = false;
      let excluded_products = [];

      if (scheme.hasOwnProperty('config_json')) {

        if (scheme.config_json.hasOwnProperty('min_value')) {
          min_value = scheme.config_json.min_value;
        }
        if (scheme.config_json.hasOwnProperty('disable_auto_apply')) {
          disable_auto_apply = scheme.config_json.disable_auto_apply;
        }
        if (scheme.config_json.hasOwnProperty('excl_prod')) {
          excluded_products = scheme.config_json.excl_prod;
        }
      }


      if (scheme.definition && scheme.definition.length > 0) {

        scheme.definition.forEach(criteriaRow => {
          // const criteriaRow = scheme.definition[0];

          const matchingItems = this.cartItems.filter(item => {
            return (((criteriaRow.criteria_type === 'category' && item.categ_id === criteriaRow.category_id)
                    || (criteriaRow.criteria_type === 'product' && item.product_id === criteriaRow.product_id)
                    || (criteriaRow.criteria_type === 'template' && item.family_id === criteriaRow.template_id)
                    || (criteriaRow.criteria_type === 'brand' && item.brand_id === criteriaRow.brand_id))
                      && (item.scheme_id === undefined || item.scheme_id === 0) && (item.type === 'normal'));
                });

          matchingItems.forEach(item => {
            let factor_inv = 1;
            if (item.hasOwnProperty('factor_inv')) {
              factor_inv = item.factor_inv;
            }

            if (!excluded_products.includes(item.product_id)) {
              mQty = mQty + parseInt(item['quantity'], 10) * factor_inv;
              mSubTotal = mSubTotal + (parseFloat(item['base_price']) * parseInt(item['quantity'], 10));
              mTotal = mTotal + parseFloat(item['total']);
              current_discount = current_discount + parseFloat(item['scheme_discount']);

              mVolume = mVolume + parseInt(item['volume'], 10);
              mWeight = mVolume + parseInt(item['weight'], 10);
            }
          });

          if (matchingItems.length > 0) {
            if ((criteriaRow.measure_type === 'qty' && mQty >= parseInt(criteriaRow.value, 10 ) && mQty >= min_value)
              || (criteriaRow.measure_type === 'saleprice' && mSubTotal >= parseFloat(criteriaRow.value) && mSubTotal >= min_value )
              || (criteriaRow.measure_type === 'volume' && mVolume >= parseFloat(criteriaRow.value) && mVolume >= min_value )) {

              const promo_multiplier = this.getPromoMultipler(criteriaRow, mQty, mSubTotal, mVolume, mWeight);

              //Get applicable Reward
              if (scheme['product_reward_type'] === 'same') {
                //   Increase the quantity by X and set scheme discount to unit price
                this.applySameProductRewardDiscount(scheme, promo_multiplier, matchingItems);

              } else if (scheme['product_reward_type'] === 'different') {
                //   Add new promo item, and set original line item with the discount of promos unit price
                this.applyDiffrentProductRewardDiscount(scheme, promo_multiplier, matchingItems);
              } else {
                //TODO: ignore reward type , if margin > 0 apply discount
                if (scheme['reward_type'] === 'discount') {
                  //   Check additional logic
                  this.applySchemePctDiscount(scheme, matchingItems, mSubTotal);
                }
              }

              // if (scheme['margin'] && scheme['margin'] > 0) {
              //   this.applySchemePctDiscount(scheme, matchingItems, mSubTotal);
              // }

            }
          }
        });
      }
    });
  }

  computeCombo() {
    const comboSchemes = this.schemes.filter(item => item.criteria_mode === 'and_combo' && item.scheme_type === 'qps');
    comboSchemes.forEach(scheme => {
      let line_discount = 0;
      let mQty = 0;
      let mSubTotal = 0;
      let mVolume = 0;
      let mWeight = 0;
      let applied_discount = 0;
      let matchingItems = [];
      const elligibleItems = [];
      const matchingCriterias = [];
      let criteriasMatch = false;
      const comboMultiplier = [];
      this.cartItems.forEach(item => {
        criteriasMatch = false;
      });

      let promo_multiplier = 1;
      scheme.definition.forEach(criteriaRow => {

        if (scheme.definition.length > 0) {

          if (criteriaRow.criteria_type === 'bill_value') {

            if ( this.bill_value >=  parseFloat(criteriaRow.value)) {
              matchingCriterias.push(true);
            } else {
              matchingCriterias.push(false);
            }

          } else {

            matchingItems = this.cartItems.filter(item => {
              return ((criteriaRow.criteria_type === 'category' && item.categ_id === criteriaRow.category_id && item.uom_id === criteriaRow.uom_id)
                || (criteriaRow.criteria_type === 'product' && item.product_id === criteriaRow.product_id && item.uom_id === criteriaRow.uom_id)
                || (criteriaRow.criteria_type === 'template' && item.family_id === criteriaRow.template_id && item.uom_id === criteriaRow.uom_id)
                || (criteriaRow.criteria_type === 'brand' && item.brand_id === criteriaRow.brand_id  && item.uom_id === criteriaRow.uom_id));
            });

            let matched = false;
            mQty = 0;
            mSubTotal = 0;
            mVolume = 0;
            mWeight = 0;

            //TODO: handle excuded products

            matchingItems.forEach(item => {
              let factor_inv = 1;
              if (item.hasOwnProperty('factor_inv')) {
                factor_inv = item.factor_inv;
              }
              // mQty = mQty + parseInt(item['quantity'], 10) * factor_inv;
              mQty = mQty + parseInt(item['quantity'], 10);
              mSubTotal = mSubTotal + (parseFloat(item['base_price']) * parseInt(item['quantity'], 10));

              mVolume = mVolume + parseInt(item['volume'], 10);
              mWeight = mVolume + parseInt(item['weight'], 10);

            });
            if (criteriaRow.measure_type === 'qty' && mQty >= parseInt(criteriaRow.value, 10)
              || criteriaRow.measure_type === 'saleprice' && mSubTotal >= parseFloat(criteriaRow.value)) {
              matchingItems.forEach(item => {
                if (!elligibleItems.find(i => i.uid === item.uid)) {
                  elligibleItems.push(item);
                }
              });
              matched = true;

              const item_multiplier = this.getPromoMultipler(criteriaRow, mQty, mSubTotal, mVolume, mWeight);
              if (item_multiplier < promo_multiplier) {
                promo_multiplier = item_multiplier;
              }
            }
            matchingCriterias.push(matched);
          }
        }

      });

      const elligibleForComboScheme = matchingCriterias.every(element => {
        if (element === true) {
          return true;
        }
      });

      if (elligibleForComboScheme) {

        let rQty = 0;
        let rSubTotal = 0;

        //Get applicable Reward
        if (scheme['reward_type'] === 'discount') {
          //   Check additional logic
          elligibleItems.forEach(mItem => {
            let factor_inv = 1;
            if (mItem.hasOwnProperty('factor_inv')) {
              factor_inv = mItem.factor_inv;
            }
            rQty = parseInt(mItem['quantity'], 10) * factor_inv;
            rSubTotal = (parseFloat(mItem['base_price']) * parseInt(mItem['quantity'], 10));
            line_discount = (rSubTotal * scheme['margin']) / 100;
            applied_discount = parseFloat(mItem['scheme_discount']);

            // Check if the scheme is already applied
            if (line_discount > applied_discount) {
              this.cartItems.forEach(cItem => {
                  if (cItem.uid === mItem.uid) {
                    cItem['scheme_discount'] = line_discount;
                    cItem['scheme_id'] = scheme['id'];
                    if (scheme['code'] !== null && scheme['code'] !== '') {
                      cItem['scheme_code'] = scheme['code'];
                    } else {
                      cItem['scheme_code'] = scheme['name'];
                    }
                    // cItem['price_subtotal'] = (parseFloat(mItem['price_unit']) * parseInt(mItem['quantity'], 10)) - parseFloat(cItem['scheme_discount']);
                  }
              });
            }
          });
        } else if (scheme['reward_type'] === 'product') {
          if (scheme['product_reward_type'] === 'same') {
            //   Increase the quantity by X and set scheme discount to unit price
            this.applySameProductRewardDiscount(scheme, promo_multiplier, matchingItems);

          } else {
            //   Add new promo item, and set original line item with the discount of promos unit price
            this.applyDiffrentProductRewardDiscount(scheme, promo_multiplier, matchingItems);
          }
        }
      }
    });
  }

  computeVPS() {
    const vpsSchemes = this.schemes.filter(item => item.scheme_type === 'vps' && item.bill_type === 'single');
    vpsSchemes.forEach(scheme => {
      scheme.definition.forEach(criteriaRow => {});
    });
  }

}
