import {Component, ElementRef, Inject, OnInit, Renderer2} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe, DOCUMENT} from "@angular/common";
import {SharedService} from "../../_services/shared_service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {RetailAuditSKUCreateComponent} from "../retail_audit_sku_create/retail_audit_sku_create.component";
import {ConfirmDialogComponent} from "../../components/confirmdialog/confirmdialog.component";
import {AppComponent} from "../../app.component";

@Component({
  selector: 'app-retail-audit-detail-component',
  templateUrl: './retail_audit_detail.component.html',
  styleUrls: ['./retail_audit_detail.component.scss']
})
export class RetailAuditDetailComponent implements OnInit {

  public columnDefs = [];
  public columnDefs1 = [];
  public columnDefs2 = [];
  public rowData = [];
  public rowData1 = [];
  public rowData2 = [];
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public gridOptions2: GridOptions;
  public loaded = false;
  public loaded1 = false;
  public loaded2 = false;
  public preloader = false;
  public preloader1 = false;
  public preloader2 = false;
  public empty_state = false;
  public empty_state1 = false;
  public empty_state2 = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  public edit = false;
  API_URL = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      id: null,
      audit_id: null,
      prod_data: null
    }
  };
  viewtype = 'edit';
  retailAuditDetails: any = {};
  activetab = 'SKU List';
  //score = 35;
  selectedProducts = [];
  labelData: any = [];
  priorityList: any = [];
  private gridApi;
  private gridApi1;
  private gridApi2;
  private gridColumnApi;
  private gridColumnApi1;
  private gridColumnApi2;

  public queryParams = {};
  public activeID = 0;
  public active_ids: any = {};
  public rowIndex = 0;
  public listLength = 0;

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private elRef: ElementRef,
              public dialog: MatDialog,
              private toastr: ToastrService,
              protected route: ActivatedRoute,
              public appComponent: AppComponent,
              @Inject(DOCUMENT) private _document: Document) {

    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.data.id = localStorage.getItem("retailauditid");
    this.user_data.data.audit_id = localStorage.getItem("retailauditid");
    this.activeID = parseInt(localStorage.getItem("retailauditid"), 10);
    this.labelData = JSON.parse(localStorage.getItem('custom_labels'));

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        } else {
          if (params.data.active === false) {
            return {background: '#FDF2F4'};
          } else if (params.data.a % 2 === 0) {
            return {background: '#F3F6F8'};
          } else {
            return {background: 'white'};
          }
        }
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions2 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rowSelection: 'multiple'
    } as GridOptions;

    if (this.labelData !== null && this.labelData.length > 0) {
      this.priorityList = [this.labelData.filter(p1 => p1.label === 'p5').map(obj => obj.display)[0],
        this.labelData.filter(p1 => p1.label === 'p4').map(obj => obj.display)[0],
        this.labelData.filter(p1 => p1.label === 'p3').map(obj => obj.display)[0],
        this.labelData.filter(p1 => p1.label === 'p2').map(obj => obj.display)[0],
        this.labelData.filter(p1 => p1.label === 'p1').map(obj => obj.display)[0]

      ];
    }

    this.columnDefs = [
      {headerName: "SKU Code", headerClass: 'myagheader', field: "prod_code"},
      {headerName: "Category", headerClass: 'myagheader', field: "categ"},
      { headerName: "Template", headerClass: 'myagheader', field: "template", width: 400},
      {headerName: "Product", headerClass: 'myagheader', field: "prod_name", width: 400},
      {headerName: "Competitor", headerClass: 'myagheader', field: "comp"},
      {headerName: "Priority", headerClass: 'myagheader', field: "priority",  editable: false, singleClickEdit: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: this.priorityList
        },
        valueGetter: (params) => {
          let pValue: any = "";
          if (params.data.priority !== '1' && params.data.priority !== '2'
            && params.data.priority !== '3' && params.data.priority !== '4'
            && params.data.priority !== '5') {
            const newList = this.priorityList.slice().reverse();
            params.data.priority = (newList.indexOf(params.data.priority) + 1).toString();
          }
          if (this.labelData !== null && this.labelData.length > 0) {
            if (params.data.priority === '1') {
              pValue = this.labelData.filter(p1 => p1.label === 'p1').map(obj => obj.display);
            } else if (params.data.priority === '2') {
              pValue = this.labelData.filter(p2 => p2.label === 'p2').map(obj => obj.display);
            } else if (params.data.priority === '3') {
              pValue = this.labelData.filter(p3 => p3.label === 'p3').map(obj => obj.display);
            } else if (params.data.priority === '4') {
              pValue = this.labelData.filter(p4 => p4.label === 'p4').map(obj => obj.display);
            } else if (params.data.priority === '5') {
              pValue = this.labelData.filter(p5 => p5.label === 'p5').map(obj => obj.display);
            }
          }
          return pValue;
        }
      },
      {headerName: "", headerClass: 'myagheader', field: "delete", cellStyle: {textAlign: 'right'},
          width: 50,
        cellRenderer: params => {
          if (params._self.edit && (params.data && params.data.active !== false)) {
            return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          } else {
            return "";
          }
        }, cellRendererParams: {
          _self: this
        }
      },
    ];

    this.columnDefs1 = [
      {headerName: "Code", headerClass: 'myagheader', field: "code"},
      {headerName: "Name", headerClass: 'myagheader', field: "name"},
      {headerName: "Beat Count", headerClass: 'myagheader', field: "beat_count"},
      {headerName: "Zone", headerClass: 'myagheader', field: "zone"},
      {headerName: "State", headerClass: 'myagheader', field: "state"},
    ];

    this.columnDefs2 = [
      {headerName: "Type", headerClass: 'myagheader', field: "type"},
      {headerName: "Outlet Type", headerClass: 'myagheader', field: "outlet_type"},
      {headerName: "Program Type", headerClass: 'myagheader', field: "program_type"},
      {headerName: "Value", headerClass: 'myagheader', field: "value",
        valueGetter: params => {
          if (params.data.outlet_type !== null && params.data.outlet_type !== "") {
            return params.data.outlet_type;
          } else if (params.data.program_type !== null && params.data.program_type !== "") {
            return params.data.program_type;
          } else {
            return params.data.value;
          }
        }
      },
    ];
    this.active_ids = JSON.parse(localStorage.getItem('active_ids'));
    this.readPageParms(params => {
      this.fetchRetailAuditDetails();
    });
  }

  readPageParms(callback) {
    this.route.queryParamMap
      .subscribe(params => {
        this.queryParams = params;
        if (this.active_ids) {
          this.rowIndex = this.active_ids.indexOf(this.activeID);
          this.listLength = this.active_ids.length;
        }
        return callback(params);
      });
  }


  ngOnInit(): void {
    this.sharedService.setHeader('Retail Audit');
  }

  getPriorityValue(params) {
    let pvalue = "";
    if (this.labelData !== null && this.labelData.length > 0) {
      if (params.data.priority === '1') {
        pvalue = this.labelData.filter(p1 => p1.label === 'p1');
      } else if (params.data.priority === '2') {
        pvalue = this.labelData.filter(p2 => p2.label === 'p2');
      } else if (params.data.priority === '3') {
        pvalue = this.labelData.filter(p3 => p3.label === 'p3');
      } else if (params.data.priority === '4') {
        pvalue = this.labelData.filter(p4 => p4.label === 'p4');
      } else if (params.data.priority === '5') {
        pvalue = this.labelData.filter(p5 => p5.label === 'p5');
      }
    }

    if (pvalue !== null && pvalue.length > 0) {
      return pvalue[0];
    } else {
      return "";
    }
  }

  fetchRetailAuditDetails() {
    this.appComponent.pageReady = false;
    this.edit = false;
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;

    this.loaded1 = false;
    this.preloader1 = true;
    this.empty_state1 = false;

    this.loaded2 = false;
    this.preloader2 = true;
    this.empty_state2 = false;

    this.API_URL = '/api/audit_masters/get_retail_audit_detail';
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          this.appComponent.pageReady = true;
          if (res.hasOwnProperty('results') && (res.results.status <= 200)) {

            this.retailAuditDetails = res.results.data;
            //this.retailAuditDetails.score = parseInt(this.retailAuditDetails.score);
            this.rowData = this.retailAuditDetails.line_items;
            this.rowData1 = this.retailAuditDetails.terr_lines;
            this.rowData2 = this.retailAuditDetails.criteria_lines;

            if (this.gridApi !== null && this.gridApi !== undefined) {
              this.gridApi.setRowData(this.rowData);
            }
            if (this.gridApi1 !== null && this.gridApi1 !== undefined) {
              this.gridApi1.setRowData(this.rowData1);
            }
            if (this.gridApi2 !== null && this.gridApi2 !== undefined) {
              this.gridApi2.setRowData(this.rowData2);
            }

            this.loaded = true;
            this.loaded1 = true;
            this.loaded2 = true;

            if (res.results.status < 200 && res.results.msg !== null) {
              this.toastr.warning(res.results.msg);
            }

          } else {
            this.preloader = false;
            this.empty_state = true;

            this.preloader1 = false;
            this.empty_state1 = true;

            this.preloader2 = false;
            this.empty_state2 = true;
          }
        },
        error => {
          console.log(error);
          this.appComponent.pageReady = false;
          this.preloader = false;
          this.preloader1 = false;
          this.preloader2 = false;
        });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    //this.gridApi.setDomLayout('autoHeight');
    this.preloader = false;

    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();

    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;

    params.api.setRowData(this.rowData1);
    //this.gridApi1.setDomLayout('autoHeight');

    this.preloader1 = false;

    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi1.sizeColumnsToFit();
    } else {
      this.gridColumnApi1.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid2');
  }

  onGridReady2(params) {
    this.gridOptions2.api.showLoadingOverlay();

    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;

    params.api.setRowData(this.rowData2);
    //this.gridApi2.setDomLayout('autoHeight');

    this.preloader2 = false;

    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi2.sizeColumnsToFit();
    } else {
      this.gridColumnApi2.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid3');
  }


  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }


  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'delete') {

      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "650px",
        maxHeight: "250px",
        data: {
          title: "Delete Audit Line !!",
          message1: rows[0].prod_code + " - " + rows[0].prod_name,
          message2: "",
          showNoButton: true,
          yesButton: "DELETE",
          noButton: "CANCEL",
          deleteButton: true
        }
      };

      const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

      confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

        if (data === 'yes') {
          rows[0].active = false;

          this.updateRetailAudit();
        } else {

        }
      });
    } else {

    }
  }

  onCellClicked1(event) {
    const column = event.column.getColId();
  }

  onCellClicked2(event) {
    const column = event.column.getColId();
  }

  updateRetailAudit() {
    this.appComponent.pageReady = false;
    const paramObject: any = {};
    paramObject.access_token = this.user_data.access_token;
    if (!this.retailAuditDetails.hasOwnProperty("line_items")) {
      this.retailAuditDetails.line_items = [];
    } else {
      for (let i = 0; i <= this.retailAuditDetails.line_items.length - 1; i++) {
        if (!this.retailAuditDetails.line_items[i].hasOwnProperty("active")) {
          this.retailAuditDetails.line_items[i].active = true;
        } else {
          continue;
        }
      }
    }

    this.user_data.data.prod_data = this.retailAuditDetails.line_items;
    this.API_URL = '/api/audit_masters/update_audit_lines';
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
        this.appComponent.pageReady = true;
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success('Retail Audit updated Successfully');
          this.edit = !this.edit;
          this.fetchRetailAuditDetails();
        } else if (res.hasOwnProperty('results')) {
          this.toastr.error(res.results.msg);
        } else {
          this.toastr.error("Retail Audit Updation Failed");
        }
      });

  }

  editClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
    if (this.edit) {
      this.gridColumnApi.getColumn('priority').getColDef().editable = true;
    } else {
      this.gridColumnApi.getColumn('priority').getColDef().editable = false;
    }
  }

  discardClick() {
    this.edit = !this.edit;
    this.fetchRetailAuditDetails();
  }

  createDialog() {
    let rows = [];
    if (this.rowData && this.rowData.length > 0) {
      rows = this.rowData.filter(x => !x.hasOwnProperty('active') || x.active === true);
    }
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "70%",
      maxHeight: "90%",
      panelClass: 'custom-dialog-container',
      data: {
        type: this.retailAuditDetails.type,
        products: rows
      }
    };
    const creatediaeref = this.dialog.open(RetailAuditSKUCreateComponent, config);

    creatediaeref.componentInstance.selectedProducts.subscribe(data => {
      this.selectedProducts = data;
      let indproduct: any = {};
      if (!this.rowData) {
        this.rowData = [];
      }
      this.rowData.forEach(item => {
        const found = data.find(x => x.code.toString() === item.prod_code.toString() && x.prod_name === item.prod_name);
        if (!found) {
          item.active = false;
        }
      });
      for (let i = 0; i < this.selectedProducts.length; i++) {
        indproduct = {};
        indproduct.name = this.selectedProducts[i].name;
        indproduct.prod_name = this.selectedProducts[i].prod_name;
        if (this.selectedProducts[i].competitor_id) {
          indproduct.comp_product_id = this.selectedProducts[i].id;
        } else {
          indproduct.product_id = this.selectedProducts[i].id;
        }
        indproduct.prod_code = this.selectedProducts[i].code;
        indproduct.categ_id = this.selectedProducts[i].categ_id;
        indproduct.categ = this.selectedProducts[i].categ_name;
        indproduct.template = this.selectedProducts[i].temp_name;
        indproduct.template_id = this.selectedProducts[i].temp_id;
        indproduct.brand_id = this.selectedProducts[i].brand_id;
        indproduct.competitor_id = this.selectedProducts[i].competitor_id;
        indproduct.comp = this.selectedProducts[i].competitor;
        indproduct.audit_id = this.retailAuditDetails.id;
        indproduct.priority = '3';

        const found =  this.rowData.find(x =>  x.prod_code.toString() === indproduct.prod_code.toString() && x.prod_name === indproduct.prod_name);
        if (!found) {
          this.rowData.push(indproduct);
        }
      }

      if (this.rowData === null || this.rowData.length === 0) {
        this.retailAuditDetails.line_items = this.rowData;
        this.loaded = true;
      }
      this.gridApi.setRowData(this.rowData);
    });
  }

  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
  }

  viewReport() {
    this.router.navigate([this.apiService.redirect_url + '/retail_audit_report'], {
      state: {

        retailauditid: this.retailAuditDetails.id
      }
    });
  }

  moveNext() {
    const curIndex = this.active_ids.indexOf(this.activeID);
    if (curIndex >= 0 && curIndex < (this.active_ids.length - 1)) {
      this.rowIndex = curIndex + 1;
      this.activeID = this.active_ids[this.rowIndex];
      this.user_data.data.id = this.activeID;
      this.fetchRetailAuditDetails();
    }
  }

  movePrevious() {
    const curIndex = this.active_ids.indexOf(this.activeID);
    if (curIndex > 0) {
      this.rowIndex = curIndex - 1;
      this.activeID = this.active_ids[this.rowIndex];
      this.user_data.data.id = this.activeID;
      this.fetchRetailAuditDetails();
    }
  }

}
