import {Component, Inject, OnInit} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {DatePipe} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {ImagePopupComponent} from "../image_popup/image_popup.component";

@Component({
  selector: 'app-take-survey-popup',
  templateUrl: './take_survey_popup.component.html',
  styleUrls: ['./take_survey_popup.component.scss']

})

export class TakeSurveyPopupComponent implements OnInit {

  surveyQuestions: any = [];
  completedQuestions = 0;
  progress = 0;
  survey_id;
  partner_id;
  service_id;
  partner_asset_id;
  attendee_id;
  name;
  currentQuestionID;
  edit;
  galleryData = [];
  dataLoaded = false;
  standalone = false;
  container;
  pendingMandatory = false;

  constructor(private apiService: ApiService,
              public datePipe: DatePipe,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.survey_id = dialogdata.survey_id;
    this.partner_id = dialogdata.partner_id;
    this.service_id = dialogdata.service_id;
    this.partner_asset_id = dialogdata.partner_asset_id;
    this.attendee_id = dialogdata.attendee_id;
    this.container = dialogdata.container;
    this.standalone = dialogdata.standalone;
    this.name = dialogdata.name;
    this.edit = dialogdata.edit;
    this.getSurveyData();
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async getSurveyData() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      id: this.survey_id,
      service_id: this.service_id,
      attendee_id: this.attendee_id
    };
    let url = '/api/crm_profiling_questionnaires/get_survey_details';
    if (this.standalone) {
      url = '/api/crm_profiling_questionnaires/standalone_get_survey_details';
    }
    this.apiService.post(url, paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.dataLoaded = true;
          this.surveyQuestions = res.results.data;
          this.checkQuestionCompletionStatus();

          let image_present = false;
          this.surveyQuestions.forEach(question => {
            if (question.type === 'image' || question.type === 'image_with_marker') {
              image_present = true;
              this.galleryData.push(question.store_fname);
              return;
            }
            if (!question.sequence) {
              question.sequence = 100;
            }
          });
          this.surveyQuestions = this.surveyQuestions.sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
          if (!this.edit && image_present) {
            this.fetchImages();
          }
        }
      });
  }

  async fetchImages() {
    const gallery = [];
    this.galleryData.forEach(image => {
      gallery.push({
        store_fname: image,
        container: this.container
      });
    });
    const payload = {
      access_token: localStorage.getItem('resfreshToken'),
      data: gallery
    };
    const inThis = this;
    let url = '/api/pwa_connects/fetch_image';
    if (this.standalone) {
      url = '/api/pwa_connects/standalone_fetch_image';
    }
    const res = await this.apiService.postPromise(url, payload);
    if (res.hasOwnProperty('result') && res['result'].length > 0) {
      const domSanitizer = this.domSanitizer;
      let img;
      res['result'].forEach(photo => {
        img = domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + photo.bill_datas);
        const question = inThis.surveyQuestions.find(x => x.store_fname === photo.bill_store_fname);
        if (question) {
          question.photo = img;
        }
      });
    }
  }

  textAnswerChange(event) {
    this.checkQuestionCompletionStatus();
  }

  setDate(dt, questionrow) {
    const question = this.surveyQuestions.find(x => x.question_id === questionrow.question_id);
    question.answer_text = this.datePipe.transform(question.answer_text, 'yyyy-MM-dd');
    this.checkQuestionCompletionStatus();
  }

  handleFileSelect(ev, question) {
    // console.log(evt);
    this.currentQuestionID = question.question_id;
    const files = ev.target.files;
    const file = files[0];
    if (files && file) {
      const reader = new FileReader();
      question['file_name'] = file.name;
      question['file_type'] = file.type;
      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt, file) {
    const binaryString = readerEvt.target.result;
    const base64textString = btoa(binaryString);
    const question = this.surveyQuestions.find(x => x.question_id === this.currentQuestionID);
    question.image = base64textString;
    question.store_fname = 'Survey_image_' + question.question_id;
    question.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + base64textString);
    this.checkQuestionCompletionStatus();
  }

  radioChange(event) {
    this.checkQuestionCompletionStatus();
  }

  checkQuestionCompletionStatus() {
    this.completedQuestions = 0;
    let mandatory = false;
    this.surveyQuestions.forEach(question => {
      if (question.answer_id || (question.answer_text && question.answer_text !== "") || question.image) {
        this.completedQuestions++;
      } else if (question.mandatory) {
        mandatory = true;
      }
    });
    this.pendingMandatory = mandatory;
    this.progress = parseInt(String((this.completedQuestions / this.surveyQuestions.length) * 100), 10);
  }

  submitSurvey() {
    this.surveyQuestions.forEach(question => {
      if (question.answer_id) {
        question.answer_id = parseInt(question.answer_id, 10);
      }
      if (question.answer_id || (question.answer_text && question.answer_text !== "") || question.image) {
        this.completedQuestions++;
      }
    });
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      questionnaire_id: this.survey_id,
      partner_id: this.partner_id,
      service_id: this.service_id,
      partner_asset_id: this.partner_asset_id,
      attendee_id: this.attendee_id,
      name: this.name,
      status: "completed",
      answers: this.surveyQuestions
    };
    let url = '/api/crm_profiling_results/create_survey_result';
    if (this.standalone) {
      url = '/api/crm_profiling_results/standalone_create_survey_result';
    }
    this.apiService.post(url, paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success("Survey Submitted");
          this.dialog.closeAll();
        } else {
          this.toastr.error("Failed to submit Survey");
        }
      });
  }

  showImagePopup(photo) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          photo
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        data: {
          photo
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    }
  }
}
